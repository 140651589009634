import { GET } from "../functions";

const getPlanCategories = async () => {
  const path = `/plans/plan_category`;
  return await GET(path)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      // console.log("getPlanCategories from db> ", result);
      return err;
    });
};

export default getPlanCategories;
