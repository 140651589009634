import { PROPERTY_API } from "../const";

const GET = async (apiUri?: string, signal?: AbortSignal, token?: string) => {
  const endpoint = `${PROPERTY_API}${apiUri}`;
  // console.log("endpoint > ", endpoint);
  const res = await fetch(endpoint, {
    method: "GET",
    mode: "cors",
    signal: signal,
  })
    .then(async (res) => {
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`error in api ${apiUri} >`, error);
      return [500, error];
    });
  return res;
};

export const getPropsFromOthers = async (path) => {
  return await GET(path)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err;
    });
};
