import React, { useState, useContext } from "react";
import { DelBtn } from "../../../../assets/AgentDashboard";
import { AuthContext } from "../../../../context/AuthContext";
import deletePropFromBucket from "../../../../api/Agent/deletePropFromBucket";
function PropertyDetailsCard(props) {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { agentData } = useContext(AuthContext);

  const handleDelete = async () => {
    // onDelete(property.id);
    console.log("works handleDelete");
    await props?.onDelete();
    setShowDeleteConfirm(false);
  };

  const handleCancel = () => {
    setShowDeleteConfirm(false);
    console.log("Cancel", showDeleteConfirm);
  };

  return (
    <>
      {showDeleteConfirm ? (
        <DeleteConfirmation
          onConfirmDelete={handleDelete}
          onCancelDelete={handleCancel}
        />
      ) : (
        <button
          className="del-btn-bkt"
          onClick={() => setShowDeleteConfirm(true)}
        >
          <DelBtn />
        </button>
      )}
    </>
  );
}

function DeleteConfirmation({ onConfirmDelete, onCancelDelete }) {
  return (
    <div className="delete-confirmation">
      <p>Are you sure you want to remove this property?</p>
      <div className="d-flex justify-content-center">
      <button className="btn  p-2 d-flex m-3" onClick={onConfirmDelete} style={{background:"#ff0000"}}>
        Yes
      </button>
      <button className="btn p-2 d-flex m-3" onClick={onCancelDelete} style={{background:"#dadada"}}>
        No
      </button>

      </div>
    </div>
  );
}

export default PropertyDetailsCard;
