import React from "react";
import { Navbar } from "./Components";
// import { Layout } from "../../v1"
import Footer from "../../Footer";
import "./style.css";
import SideBar from "../../../PagesAgent/Dashboard/components/SideIconBar";
interface Props {
  children: JSX.Element[] | JSX.Element;
}

function Index(props) {
  return (
    <>
      <Navbar children={props.children} />
      {/* <div> {props.children}</div> */}
    </>
  );
}

export default Index;
