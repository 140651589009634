import React, { useEffect, useState } from "react";
import { PropertyCard } from "./components";
import "./style.css";
import { ArrowLeftCircle, ArrowRightCircle } from "react-bootstrap-icons";


// type homeType = { id: number; place: string; config: string; image: string };
interface Props {
  homes: any[];
}
export default function Index({ homes }: Props) {
  const [currentPropIndex, setCurrentPropIndex] = useState(0);
  const [nextPropIndex, setNextPropIndex] = useState(currentPropIndex + 1);

  const increasePropIndex = () => {
    if (currentPropIndex === homes?.length - 1) {
      setCurrentPropIndex(0);
    } else {
      setCurrentPropIndex((e) => e + 1);
    }
  };

  const decreasePropIndex = () => {
    if (currentPropIndex === 0) {
      setCurrentPropIndex(homes?.length - 1);
    } else {
      setCurrentPropIndex((e) => e - 1);
    }
  };

  useEffect(() => {
    if (currentPropIndex === homes?.length - 1) {
      setNextPropIndex(0);
    } else {
      setNextPropIndex(currentPropIndex + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPropIndex]);
  return (
    <>
      <div className="discover-section-container">
        <div className="left-container p-3">
          <div className="f-b f-48 ">
            Discover Your New <span className="secondary-color">HOME</span>
          </div>
          <div className="f-22">
            We Provide complete service for rental and purchase
          </div>
        </div>
        {homes?.length == 0 ? (
          <div className="p-3 m-5 w-100" style={{
            display:"grid",
            justifyItems:"end"
          }}>
            <div className="f-b f-48 ">
              Upcoming <span className="secondary-color">Premium</span>
            </div>
            <div className="f-22">Listings</div>
          </div>
        ) : (
          <div className="right-container">
            <div className="prop-container d-flex flex-row mt-5">
              <PropertyCard home={homes[currentPropIndex]} />
              <div className="next-prop-container">
                <img
                  src={homes[nextPropIndex]?.img_src}
                  alt={"property images"}
                />
              </div>
            </div>
            <div className="discover-prop-control-btn-container secondary-color">
              <div className=" cursor-p">
                <ArrowLeftCircle size={30} onClick={decreasePropIndex} />
              </div>
              <div className=" cursor-p ">
                <ArrowRightCircle size={30} onClick={increasePropIndex} />
              </div>
            </div>
          </div>
        )}

        {/* <div className=" bd-highlight discover ">
          <div className="discover_your">Discover Your</div>
          <div className="d-flex flex-row another">
            <div className="new">New</div>
            <div className="home">HOME</div>
          </div>
          <div className="provide">We Provide complete service for</div>
          <div className="rental">rental and purchase</div>
        </div> */}
        {/* <div>
          <div className="prop-container d-flex flex-row mt-5">
            <PropertyCard home={homes[currentPropIndex]} />
            <div className="next-prop-container">
              <img src={homes[nextPropIndex]?.image} alt={"property images"} />
            </div>
          </div>
          <div className="mb-5">
            <div className="discover-prop-control-btn-container">
              <div className=" t-btn">
                <ArrowLeftCircle size={30} onClick={decreasePropIndex} />
              </div>
              <div className=" t-btn ">
                <ArrowRightCircle size={30} onClick={increasePropIndex} />
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
