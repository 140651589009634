import React, { useEffect } from "react";
import { reverseGeoode } from "../../helpers/reverseGeoCode";
export default function Index() {
  // useEffect(() => {
  //  reverseGeoode();
  // }, [])
  
  return (
    <div>
      <div>Dev</div>
    </div>
  );
}
