import React from 'react'

const payments = () => {
  return (
    <>
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.375 30.875V17.75H10.125V30.875H6.375ZM17.625 30.875V17.75H21.375V30.875H17.625ZM0.75 38.375V34.625H38.25V38.375H0.75ZM28.875 30.875V17.75H32.625V30.875H28.875ZM0.75 14V10.25L19.5 0.875L38.25 10.25V14H0.75ZM9.09375 10.25H29.9062L19.5 5.09375L9.09375 10.25Z" fill=""/>
</svg>

    </>
  )
}

export default payments