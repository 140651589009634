import React from "react";
import "./style.css";
import active from "../../../../../assets/svg/active.svg"
import inactive from "../../../../../assets/svg/inactive.svg"
const Index = (props: any) => {
  return (
    <div className="card-container-my-act">
      <div className="d-flex justify-content-between align-items-end w-100 m-2">
        {props.isActive ? <div><img src={active} style={{height:"1.5em"}}/>Active </div>: <div><img src={inactive} style={{height:"1.5em"}}/>Inactive</div>}
        {props.isSubscribed ? <div className="subscribed-button">Subscribed</div> : null}
      </div>
      <h3 className="property-name">{props?.propertyName}</h3>
      <p className="property-date"> Posted Date: {props?.postedDate}</p>
      <div className="date-container-acc text-secondary">
        {/* <div className=""> 
          Listing validation
          </div> */}
      <div className="date-contain">
        {/* <div className="start-acc"> From: <span className="text-dark">{props?.startDate}</span></div> */}
      <div className="end-acc"> Valid upto: <span className="text-danger">{props?.endDate}</span></div></div>
      </div>
      <button className="view-btn" onClick={props?.onClick}>
        View
      </button>
    </div>
  );
};

export default Index;