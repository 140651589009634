import { POST } from "../functions";

const createOrderId = async (data: any) => {
  const path = `/orders`;
  return await POST(path, data)
    .then((result) => {
      console.debug("Create order id in db > ", result);
      return result;
    })
    .catch((err) => {
      return err;
    });
};

export default createOrderId;
