import React, { useEffect, useState } from 'react'
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

export default function Index ({ btns, value, onChange, title='' }) {
        const [selectedBtn, setSelectedBtn] = useState(value);
        // const onChange
        useEffect(() => {
          console.log(selectedBtn)
        }, [selectedBtn])
        return (
          <div className='mb-2'>
                    <div className="f-b mb-1 text-muted">{title}</div>
          <ButtonGroup>
            {btns.map((btn, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}-${title}`}
                type="radio"
                variant={"outline-primary"}
                name={`radio-${idx}-${title}`}
                value={btn.value || ''}
                checked={selectedBtn === btn.value}
                onChange={(e) => {
                  setSelectedBtn(e.currentTarget.value);
                  onChange(e.currentTarget.value);
                }}
              >
                {btn.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
          </div>
        );
      
}
