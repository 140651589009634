import React, { useEffect, useState } from "react";
import { MapBox } from "../../../../components";
import { props } from "../../../../data/props";
import { BasicInfo, Schools } from "./Components";
import { getPropsFromOthers } from "../../../../api/propsFromOthers";
import "./style.css";

export default function Index({ zpid }) {
  const [detail, setDetail] = useState(null);
  const fetchProperty = async () => {
    const res = await getPropsFromOthers(`/zillow/homedetails/${zpid}`);
    setDetail(res[1]?.data?.property);
    // console.log(res[1]?.data?.property);
  };
  useEffect(() => {
    fetchProperty();
  }, [zpid]);
  return (
    detail && (
      <div className="">
        <BasicInfo detail={detail} />
        {/* //* map
         */}
        <div className="container mt-5">
          <div className="fs-4 f-b  mb-3">Location on Map</div>
          <MapBox
            lat={detail?.latitude}
            lon={detail?.longitude}
            width="80%"
            staticMap
            style={{
              display: "flex",
              justifyContent: "center"
            }}
            zoom={14}
            markCenter={!detail?.hasBadGeocode}
          />
        </div>

        {/* ///* nearby schools
         */}
        <div className="mt-5">
          {detail?.schools && <Schools detail={detail} />}
        </div>
      </div>
    )
  );
}
