import React from "react";
import "./style.css";

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  label: string;
  name: string;
  variant?: "secondary" | "success" | "primary";
  enableHover?: Boolean;
}

export default function Index({
  onClick,
  className,
  label,
  name,
  variant = "secondary",
  enableHover = false,
  ...props
}: Props) {
  return (
    <div
      className={`t-btn-v1 ${className} ${variant} ${enableHover && "hover"}`}
      onClick={onClick}
      {...props}
    >
      <div className="label">{label}</div>
      <div className={`inner `}></div>
    </div>
  );
}
