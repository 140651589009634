import React, { useEffect, useState } from "react";
import { geoCordApi } from "../../../../api";
import { FormControl } from "react-bootstrap";
import {
    ArrowRight,
    ArrowRightSquare,
    GeoAltFill,
  } from "react-bootstrap-icons";
import { parseMapboxApiResponse } from "../../../../helpers/parseMapboxApi";

export const LocationSelector = ({ setLocationData }) => {
    const [searchText, setSearchText] = useState(null);
    // const [locationData, setLocationData] = useState(null);
    const [results, setResults] = useState([]);
    const [resultDisplay, setResultDisplay] = useState(false);
    const handleChange = (value) => {
      setSearchText(value);
      setResultDisplay(true);
    };
    const fetchLocation = async () => {
      const res = await geoCordApi({ query: searchText });
      if (res["status"] === 200) {
        setResults(res["data"]?.features);
      } else {
        alert("error in mapbox geocoord");
      }
    };
    useEffect(() => {
      if (searchText) {
        fetchLocation();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);
  
    // const handleClick = () => {
    //   onClick(locationData);
    //   console.log("location data to the agent DB",locationData)
    // };
    return (
      <>
      <div className="listing-modal-row">
        <div className="fs-5 f-b px-3 ">Select your work Location</div>
        </div>
  
        {/* <div className="f-b fs-4 ms-5">Select Your Location</div> */}
        <div
          className="d-flex flex-column align-items-center align-content-center justify-content-around mt-4"
          style={{ height: "100px" }}
        >
          <div className="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
            <FormControl
              placeholder="Search by location"
              onChange={(e) => handleChange(e.target.value)}
              value={searchText}
            />
            <div
              style={{
                zIndex: 8,
                position: "absolute",
                display:
                  resultDisplay && searchText && results?.length > 0
                    ? "block"
                    : "none",
                maxHeight: "20%",
                overflowY: "scroll",
                width: "70%",
              }}
              className="bg-light rounded shadow p-3"
            >
              {results?.map((result) => (
                <div
                  className="cursor-p mb-3"
                  key={result?.id}
                  onClick={async () => {
                    setSearchText(result?.place_name);
                    setResultDisplay(false);
                    console.log(result)
                    const locationData=parseMapboxApiResponse(result)
                    console.log("Location data",locationData)
                    setLocationData(locationData);
                  }}
                >
                  <div>
                    <GeoAltFill color="#aaa" size={20} />
                    {result?.place_name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
  
      </>
    );
  };