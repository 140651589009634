import React, { CSSProperties, useEffect, useState } from "react";
import ReactMapGL from "react-map-gl";
import { MAPBOX_TOKEN } from "../../../api/const";
import { GeoAltFill } from "react-bootstrap-icons";
import Marker from "./marker";
import { useHistory } from "react-router-dom";
interface Props {
  style?: CSSProperties;
  lat: number;
  lon: number;
  width?: any;
  height?: any;
  markers?: any;
  staticMap?: boolean;
  zoom?: number;
  markCenter?: boolean;
  onBoundsChange?: any;
  locations?: any;
}
export default function Index({
  lat,
  lon,
  style,
  width,
  height,
  staticMap,
  zoom,
  markCenter,
  onBoundsChange,
  locations,
}: Props) {
  const [viewport, setViewport] = useState({
    width: width,
    height: height || 400,
    latitude: lat,
    longitude: lon,
    zoom: zoom || 9,
  });
  useEffect(() => {
    setViewport({
      ...viewport,
      latitude: lat,
      longitude: lon,
    });
  }, [lat, lon, zoom]);
  const history = useHistory();
  return (
    <div style={{ position: "relative" }}>
      <div style={{ ...style, zIndex: 1 }}>
        <ReactMapGL
          mapStyle="mapbox://styles/mapbox/streets-v11"
          {...viewport}
          onViewportChange={(nextViewport) => {
            !staticMap && setViewport(nextViewport);
          }}
          mapboxApiAccessToken={MAPBOX_TOKEN}
        >
          {locations?.length > 0 &&
            locations?.map((loc, idx) => (
              <Marker
                key={idx}
                offsetTop={0}
                offsetLeft={0}
                lat={loc?.lat}
                lon={loc?.lon}
                icon={
                  <GeoAltFill color={loc?.markerColor || "red"} size={25} />
                }
                onClick={() => (loc?.link ? history.push(loc?.link) : {})}
              />
            ))}
          {/* {markCenter && (
          <Marker
          offsetTop={}
          offsetLeft={}
          lat={lat}
          lon={lon}
          icon={<GeoAltFill color="red" size={25} />}
          />
        )} */}
        </ReactMapGL>
      </div>
      {markCenter && (
        <div
          style={{
            position: "absolute",
            top: "45%",
            left: "49%",
            zIndex: 23,
          }}
        >
          <GeoAltFill color="red" size={25} />
        </div>
      )}
    </div>
  );
}

// ? refer
// https://docs.mapbox.com/playground/geocoding/
