import React from "react";
import Navbar from "../Components/NavBar";
// import "./style.css";
interface Props {
  children: JSX.Element[] | JSX.Element;
}

function Index(props) {
  return (
    <>
      <Navbar children={props.children}/>
    </>
  );
}

export default Index;
