import { useState, useEffect } from "react";
import { calculateDistance } from "../../../../utils";
import verifyProperty from "../../../../api/Agent/verifyProperty"
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import GetLocBtn from "../GetLocBtn";
import { useHistory } from "react-router-dom";


export default function VerifyButton({
  propertyLocation,
  propertyKey,
  agentKey,
}) {
  const [agentLocation, setAgentLocation] = useState(null);
  const [distance, setDistance] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();
  const options = [
    { label: "Phone Number Verified", name: "phoneVerified" },
    { label: "Documents Verified from Owner", name: "addressVerified" },
    {
      label: "Addresss and Propoerty Location Verified",
      name: "documentsVerified",
    },
    {
      label: "Listed Amenities Verified",
      name: "amenitiesVerified",
    },
  ];

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setAgentLocation({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  // console.log("agentLoc", JSON.stringify(agentLocation))
  useEffect(() => {
    if (agentLocation !== null) {
      const newDistance = calculateDistance(agentLocation, propertyLocation);
      setDistance(newDistance);
      // console.log("distance",distance)
      // console.table([agentLocation, propertyLocation, newDistance]);
    }
  }, [agentLocation, propertyLocation, distance]);

  const [checkboxState, setCheckboxState] = useState({
    phoneVerified: false,
    addressVerified: false,
    documentsVerified: false,
    amenitiesVerified: false
  });

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxState((prevState) => ({ ...prevState, [name]: checked }));
  };

  const isButtonEnabled =
    Object.values(checkboxState).filter(Boolean).length === 4;
  const handleVerify = async () => {
    console.log("options", checkboxState);
    const temp = {
      property_key: propertyKey
    }
    await verifyProperty(agentKey,temp).then((res)=>{console.log("verify proprerty done.......", res); if (res[0]===200){alert("Approved successfully")} else{
      alert("something went wrong")
    }
   })
   history.push("/agent/dashboard");
  };

  return (
    <>
      <button className="verify-btn" onClick={handleShow}>
        Verify
      </button>
      <div
        className={`distance-to-prop${distance <= 5 ? " short" : ""}${
          distance >= 5 ? " medium" : ""
        }${distance >= 10 ? " long" : ""}`}
      >
        {Math.round(distance)} KM to Reach
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Verify the Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {options.map((option) => (
            <div className="vrfy-chklst">
              <label>
                <input
                  type="checkbox"
                  name={option.name}
                  checked={checkboxState[option.name]}
                  onChange={handleCheckboxChange}
                />
                {option.label}
              </label>
            </div>
          ))}
          <p className="verify-alert">
            *Verify button will activate only if you’re near to the property location.{" "}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="vrfy-btn-container">
            {distance === null || distance > 20 ? (
              <button className="verify-btn" disabled={true}>
                Verify
              </button>
            ) : (
              <button
                className="verify-btn"
                disabled={!isButtonEnabled || distance > 20}
                onClick={handleVerify}
              >
                Verify
              </button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
