import React, { useEffect, useState } from "react";
import { Button, FormControl, InputGroup, Modal } from "react-bootstrap";
import {
  ArrowRight,
  ArrowRightSquare,
  GeoAltFill,
} from "react-bootstrap-icons";
import { useHistory } from 'react-router-dom';
import { propTypes } from "react-bootstrap/esm/Image";
import { geoCordApi } from "../../../../../api";
import { getPropTypes } from "../../../../../api/property";
import { LoadingV1 } from "../../../../../components/Loading";
import { parseMapboxApiResponse } from "../../../../../helpers/parseMapboxApi";

export default function Index({ show, onClose, handleClose }) {
  const [listingType, setListingType] = useState<any>(null);
  const [propertyType, setPropertyType] = useState<any>(null);
  const [locationData, setLocationData] = useState<any>(null);
  let history = useHistory();

  useEffect(() => {
    if (listingType && propertyType && locationData) {
      onClose({
        listingType,
        propertyType,
        locationData,
      });
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyType, listingType, locationData]);
  return (
    <div>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton 
        onClick={()=> history.goBack()}
        ></Modal.Header>
        <div
          style={{
            minHeight: "60vh",
          }} 
          className="d-flex flex-column justify-content-center"
        >
          <ListingTypeSelector onClick={setListingType} />
          <PropertyTypeSelector onClick={setPropertyType} />
          <LocationSelector onClick={setLocationData} />
        </div>
      </Modal>
    </div>
  );
}

const LocationSelector = ({ onClick }) => {
  const [searchText, setSearchText] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [results, setResults] = useState([]);
  const [resultDisplay, setResultDisplay] = useState(false);
  const handleChange = (value) => {
    setSearchText(value);
    setResultDisplay(true);
  };
  const fetchLocation = async () => {
    const res = await geoCordApi({ query: searchText });
    if (res["status"] === 200) {
      setResults(res["data"]?.features);
    } else {
      alert("error in mapbox geocoord");
    }
  };
  useEffect(() => {
    if (searchText) {
      fetchLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleClick = () => {
    onClick(locationData);
  };
  return (
    <>
    <div className="listing-modal-row">
      <div className="fs-5 f-b px-3 ">Select Your Location</div>
      </div>

      {/* <div className="f-b fs-4 ms-5">Select Your Location</div> */}
      <div
        className="d-flex flex-column align-items-center align-content-center justify-content-around mt-4"
        style={{ height: "100px" }}
      >
        <div className="col-8 col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-8">
          <FormControl
            placeholder="Search by location"
            onChange={(e) => handleChange(e.target.value)}
            value={searchText}
          />
          <div
            style={{
              zIndex: 8,
              position: "absolute",
              display:
                resultDisplay && searchText && results?.length > 0
                  ? "block"
                  : "none",
              maxHeight: "20%",
              overflowY: "scroll",
              width: "70%",
            }}
            className="bg-light rounded shadow p-3"
          >
            {results?.map((result) => (
              <div
                className="cursor-p mb-3"
                key={result?.id}
                onClick={async () => {
                  console.log("result > ", result);
                  setSearchText(result?.place_name);
                  setResultDisplay(false);
                  setLocationData(await parseMapboxApiResponse(result));
                }}
              >
                <div>
                  <GeoAltFill color="#aaa" size={20} />
                  {result?.place_name}
                </div>
              </div>
            ))}
          </div>
        </div>
        <button className="list-your-prop" onClick={() => handleClick()}>
          Next <ArrowRight />
        </button>
      </div>

    </>
  );
};

const ListingTypeSelector = ({ onClick }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const listingTypes = [
    {
      label: "Sale",
      value: "SALE",
      id: 1,
    },
    {
      label: "Rent",
      value: "RENT",
      id: 2,
    },
  ];

  const handleClick = (data) => {
    onClick(data);
    setSelectedValue(data);
  };
  const classNames = {
    // selected: "bg-primary text-light",
    // default: "border border-primary text-primary bg-light",
    selected: "listing-btn-selected",
    default: "listing-btn-default",
  };
  return (
    <SelectorContainer title="Select Listing Type">
      {listingTypes.map((listingType, idx) => (
        <div
          key={idx}
          className={`${
            selectedValue?.id === listingType.id
              ? classNames?.selected
              : classNames?.default
          } d-flex  justify-content-center  shadow m-1 p-2 cursor-p`}
          onClick={() => handleClick(listingType)}
          style={{ borderRadius: "100px", minWidth: "80px" }}
        >
          {listingType.label}
        </div>
      ))}
    </SelectorContainer>
  );
};
const PropertyTypeSelector = ({ onClick }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [propTypes, setPropTypes] = useState([]);
  const fetchPropTypes = async () => {
    await getPropTypes().then(([statusCode, res]) => {
      if (statusCode === 200) {
        let d = [];
        res?.map((e) => {
          d = [
            ...d,
            {
              id: e?.property_type_id,
              label: e?.name,
              value: e?.name,
            },
          ];
        });
        setPropTypes(d);
      }
    });
  };
  useEffect(() => {
    setIsLoading(true);
    fetchPropTypes().then(() => setIsLoading(false));
  }, []);

  const classNames = {
    // selected: "bg-primary text-light",
    // default: "border border-primary text-primary bg-light",
    selected: "listing-btn-selected",
    default: "listing-btn-default",
    
  };
  const [selectedValue, setSelectedValue] = useState(null);
  const handleClick = (data) => {
    onClick(data);
    setSelectedValue(data);
  };
  return (
    <SelectorContainer title="Select Property Type">
      {!isLoading ? (
        propTypes.map((propType, idx) => (
          <div
            className={`${
              selectedValue?.id === propType.id
                ? classNames?.selected
                : classNames?.default
            } d-flex  justify-content-center  shadow m-1 p-2 cursor-p`}
            onClick={() => handleClick(propType)}
            style={{ borderRadius: "100px", minWidth: "80px" }}
            key={idx}
          >
            {propType.label}
          </div>
        ))
      ) : (
        <LoadingV1 />
      )}
    </SelectorContainer>
  );
};

const SelectorContainer = ({ children, title }) => {
  return (
    <div className="listing-modal-row p-2 px-3 h-100" style={{ height: "100%" }}>
      <div className="fs-5 f-b ">{title}</div>
      <div className="d-flex flex-wrap flex-row align-items-center p-3">
        {children}
      </div>
    </div>
  );
};
