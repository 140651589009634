import React from "react";
import "./style.css";
import Footer from "../../layouts/Footer";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SubscribeBtn from "../Razorpay/PaymentButton";

export default function Index() {
  const history = useHistory();
  const pricing = [
    {
      "price-1": "Start-Up",
      "price-2": "Price Rs. 300",
      "price-3": " - ",
      "price-4": "No agent support",
      "price-5": "Premium Listing for 7 days",
      "price-6": " - ",
      "price-7": "Premium Leads",
      priceValue: "30000",
    },
    {
      "price-1": "Premium",
      "price-2": "Price Rs. 600",
      "price-3": "Free for 60 days",
      "price-4": "Agent Support",
      "price-5": "Premium Listing for 20 days",
      "price-6": "Agent Visit",
      "price-7": "Client handle by Agent",
      priceValue: "60000",
    },
  ];
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bnr-bg">
        {/* <div className="home-icon"><Home/></div> */}
        <div className="bnr-content">
          <div onClick={() => history.push("./")}>
            <img
              className="brand-logo"
              src="https://truprops.com/static/media/brandlogo.106f7c99d4a8ffbd67c4.png"
              alt="truprops"
            />
          </div>
          <button className="list-your-prop">Subscription plans</button>
        </div>
      </div>
      <div className="pricing-cards d-flex">
        <div className="d-flex all-cards">
          {pricing.map((pricingdata) => {
            return (
              <div className="card-1">
                <div className="grid-container">
                  <div className="price-1">{pricingdata["price-1"]}</div>
                  <div className="price-2">{pricingdata["price-2"]}</div>
                  <div className="price-3">{pricingdata["price-3"]}</div>
                  <div className="price-4">{pricingdata["price-4"]}</div>
                  <div className="price-5">{pricingdata["price-5"]}</div>
                  <div className="price-6">{pricingdata["price-6"]}</div>
                  <div className="price-7">{pricingdata["price-7"]}</div>
                  <div className="price-8">
                    <SubscribeBtn pricing={pricingdata["priceValue"]} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <br></br>
      <Footer />
    </>
  );
}
