import React from 'react';

const Pagination = ({ currentPage, itemsPerPage, totalItems, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pages = Array.from(Array(totalPages).keys()).map((x) => x + 1);

  return (
    <>
    <div className="pagination-container">
   {currentPage>1 ? <button className='pagi-btn'onClick={() => onPageChange(currentPage-1)}>{"<"}</button> : ("") }
      {/* <button className='pagi-btn'onClick={() => onPageChange(currentPage-1)}>Prev</button> */}
      {pages.map((page) => (
        <button className='pagi-btn' key={page} onClick={() => onPageChange(page)} disabled={page === currentPage}>
          {page}
        </button>
      ))}
 {currentPage<totalPages ? <button className='pagi-btn'onClick={() => onPageChange(currentPage+1)}>{'>'}</button> : ("") }

      {/* <button className='pagi-btn' onClick={() => onPageChange(currentPage+1)}>Next</button> */}
      </div>
    </>
  );
}
export default Pagination