import React, { useEffect, useState } from "react";
import getImageUrls from "../../helpers/getImages";
import ImageGallery from "react-image-gallery";
// import "./style.css";

interface Props {
  propData: any;
}

const Index = ({ propData }: Props) => {
  // console.log("prop data", propData);
  const [currentImageIdx, setCurrentImageIdx] = useState(0);

  const nextImage = () => {
    if (currentImageIdx === propData?.images?.length - 1) {
      setCurrentImageIdx(0);
    } else {
      setCurrentImageIdx((e) => e + 1);
    }
  };

  const previosImage = () => {
    if (currentImageIdx === 0) {
      setCurrentImageIdx(propData?.images?.length - 1);
    } else {
      setCurrentImageIdx((e) => e - 1);
    }
  };

  return (
    <>
      <div className="carousal-main-img">
        <div className="nav-btn">
          <div className="left-arrow-btn" onClick={previosImage}>
            <svg
              width="15"
              height="15"
              viewBox="0 0 11 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 1.7625L4.20108 7.5L11 13.2375L8.90688 15L6.25839e-07 7.5L8.90688 -9.14931e-08L11 1.7625Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="right-arrow-btn" onClick={nextImage}>
            <svg
              width="15"
              height="15"
              viewBox="0 0 11 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M-7.70413e-08 13.2375L6.79892 7.5L-5.78629e-07 1.7625L2.09312 1.81586e-06L11 7.5L2.09312 15L-7.70413e-08 13.2375Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div className="image-class w-100 h-100">

        <img className="carousal-img"
          src={propData?.images? propData?.images[currentImageIdx]?.img_src : propData?.img_src}
          style={{ width: "100%", height: "100%"}}
        />
        </div>
      </div>
      <div className="image-count mt-3">{propData?.images?.length} Images</div>
      <div className="d-flex flex-row p-3 rounded bg-white m-3">
        {propData?.images ? (
          propData?.images?.map((img, i) => (
            <div key={i}>
              <img
                src={img?.img_src}
                style={{ width: "79px", height: "79px", margin: "5px" }}
              />
            </div>
          ))
        ) : (
          <div>
            <img
              src={propData?.img_src}
              style={{ width: "79px", height: "79px", margin: "5px" }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Index;

// {/* <div className="d-flex flex-column"> */}
// <div className="img-container">
// <div className="nav-btn">
//   <div className="left-arrow-btn" onClick={previosImage}>fdsf</div>
//   <div className="right-arrow-btn" onClick={nextImage}>fdsfds</div>
// </div>
// <img className="carousal-main-img"
//   src={images[currentImageIdx]?.img_src}
//   // style={{ width: "100%", height: "100%", margin: "10px, 10px" }}
// />
// </div>
// <div className="image-count">10 Photos - 1 Videos</div>
// {/* <div className="img-bottom-row d-flex flex-row"> */}
// <div className="d-flex flex-row">
// {images?.map((img, i) => (
//   <div key={i}>
//     <img
//       src={img?.img_src}
//       style={{ width: "79px", height: "79px" }}
//     />
//   </div>
// ))}
// </div>
// {/* </div> */}
// {/* </div> */}
// {/* <button onClick={previosImage}>previos</button>
// <button onClick={nextImage}>next</button> */}
