import React from "react";

export default function index({agentData}) {

  const limit = agentData?.property_limit;
  console.log("limit", limit)
  return (
    <>
    <div>
        {limit<= 0 ? (
          <>
          <div className="limit-alrt-card zl">
          <p>Your Current Limit is: {limit}</p>
          <p>Total Property Approved: {agentData?.no_approved_property}</p>
            <p>Total Property Assigned: {agentData?.no_assigned_property}</p> 
          <p>Your Limit about to add property is Over..</p>
          <p>Subscribe to Add Property to Earn More!</p>
          </div>
          </>
        ) : limit <= 5 ? (
          <>
          <div className="limit-alrt-card ol">
          <p>Your Current Limit is: {limit}</p>
          <p>Total Property Approved: {agentData?.no_approved_property}</p>
            <p>Total Property Assigned: {agentData?.no_assigned_property}</p>
          <p>Your Limit is going to end soon..</p>
          <p>Subscribe to Add Property to Earn More!</p>
          </div>
          </>
        ) : (
          <>
          <div className="limit-alrt-card gl">
            <p>Your Current Limit is: {limit}</p>
            <p>Total Property Approved: {agentData?.no_approved_property}</p>
            <p>Total Property Assigned: {agentData?.no_assigned_property}</p>
            </div>
          </>
        )}
      </div>
      {/* </div> */}
    </>
  );
}
