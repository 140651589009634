import { POST } from "../functions";

const contactUs = async (data) => {
  const path = `/contact_us`;
  return await POST(path, data)
    .then((result) => {
      console.log("contact_us > ", result);
      return result;
    })
    .catch((err) => {
      return err;
    });
};

export default contactUs;
