import React, { useState, useEffect } from "react";
import data from "./data.json";
import * as ReactDOM from "react-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import "./style.css";
// import Delete from "../../assets/delete-task.png";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import index from "../Property/Timeline";
import { boolean } from "yup";
import ProfileImage from "../../assets/profileimage.png"

export default function Task() {
  const initialValues = {
    customername: "",
    location: "",
    fromTime: "",
    toTime: "",
    date: "",
    phone: 0,
    comment: "",
  };
  const { register, handleSubmit, getValues, setValue } = useForm({
    defaultValues: initialValues,
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formValues, setFormValues] = useState(data);
  const [editFormValues, setEditFormValues] = useState<any>(null);

  const [editFormValuePosition, setEditFormValuePosition] = useState<
    null | number
  >(null);
  const [isEditing, setIsEditing] = useState(false);

  let addFormFields = () => {
    //
    setFormValues([...formValues, getValues()]);
  };

  useEffect(() => {
    if (editFormValues) {
      //alert(JSON.stringify(editFormValues));

      setValue("customername", editFormValues?.customername);
      setValue("location", editFormValues?.location);
      setValue("fromTime", editFormValues?.fromTime);
      setValue("toTime", editFormValues?.toTime);
      setValue("date", editFormValues?.date);
      setValue("phone", editFormValues?.phone);
      setValue("comment", editFormValues?.comment);
    }
    return () => {
      //setFormValues(editFormValues);
      //setEditFormValues(null);
    };
  }, [editFormValues]);

  const handleAddEvent = () => {
    setIsEditing(false);
    handleShow();
  };

  const handleEdit = (e, index) => {
    setIsEditing(true);
    handleShow();

    //alert(JSON.stringify(e));
    //alert(index);
    setEditFormValues(e);
    setEditFormValuePosition(index);
    // alert(JSON.stringify("we are going to edit" + editFormValues));
  };

  let removeFormFields = (i) => {
    setIsEditing(false);
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };
  const onSubmit = (data: any) => {
    if (isEditing) {
      const temp = formValues;
      temp[editFormValuePosition] = data;
      setFormValues(temp);
    } else {
      console.log(formValues.indexOf(data));
      //alert(JSON.stringify(getValues()));
      addFormFields();
    }
    handleClose();
  };

  return (
    <>
      <div className="new-event">
        <div onClick={handleAddEvent} className="add-btn">
          <svg
            width="20"
            height="20"
            viewBox="0 0 38 38"
            fill="none"
            className="hand-hover"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.332 37.6667V21.6667H0.332031V16.3333H16.332V0.333344H21.6654V16.3333H37.6654V21.6667H21.6654V37.6667H16.332Z"
              fill="white"
            />
          </svg>
        </div>
        {/* <img onClick={handleShow} src={Add} width={40} height={40} /> */}
        <span className="mx-2">Add New Event</span>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Title>
          <div className="title">
            <h3 className="fw-bold mt-3">
              {isEditing ? "Edit" : "New"} Appoinment
            </h3>
          </div>
        </Modal.Title>

        <Modal.Body style={{ height: "80%" }}>
          <Form className="mt-5 mx-auto" onSubmit={handleSubmit(onSubmit)}>
            <Row className="mt-2">
              <Col>
                <Form.Label className="form-label">Customer Name:</Form.Label>
              </Col>
              <Col>
                <input
                  type="text"
                  className="form-control"
                  name="customername"
                  // placeholder={editFormValues?.customername}
                  {...register("customername")}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <Form.Label className="form-label">Phone :</Form.Label>{" "}
              </Col>
              <Col>
                <input
                  type="number"
                  className="form-control"
                  name="phone"
                  {...register("phone")}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <Form.Label className="form-label">Location:</Form.Label>
              </Col>
              <Col>
                <input
                  type="text"
                  className="form-control"
                  name="location"
                  {...register("location")}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <Form.Label className="form-label">Time:</Form.Label>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <Form.Label className="form-label">
                  From <br />
                  To
                </Form.Label>
              </Col>

              <Col>
                <input
                  type="time"
                  placeholder="From"
                  className="form-control"
                  name="fromTime"
                  {...register("fromTime")}
                />
                <input
                  type="time"
                  placeholder="To"
                  className="form-control"
                  name="toTime"
                  {...register("toTime")}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <Form.Label className="form-label">Date:</Form.Label>
              </Col>
              <Col>
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  {...register("date")}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: "70px" }}
                  {...register("comment")}
                />
              </Col>
            </Row>
            <div className="text-center mt-5">
              <Button
                type="submit"
                style={{ backgroundColor: "#4CAF50", color: "white" }}
                className="btn-light"
              >
                Submit
              </Button>
              <Button
                style={{ backgroundColor: "#C4C4C4", color: "white" }}
                className="btn-light  "
                onClick={handleClose}
              >
                Reset
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {formValues.map((e, index) => (
        <div className="tasklist-card">
          <div className="d-flex tasklist-allcards">
            <div>
          <div className="task-profiles ">
            <img src={ProfileImage}className="profileimages-1"/>
          </div>
          <div className=" types-profile mt-3 ">Type: Rent</div>
          </div>
          <div className="profile-tasklist">
          <div className="profile-name">{e.customername}</div>
          <div className="d-flex profiles-location">
                <svg
                  width="15"
                   height="20"
                  viewBox="0 0 20 25"
                   fill="none"
                   xmlns="http:www.w3.org/2000/svg"
                 >
                   <path
                     d="M19 10.2667C19 17.4741 10 23.6519 10 23.6519C10 23.6519 1 17.4741 1 10.2667C1 7.80899 1.94821 5.45198 3.63604 3.71414C5.32387 1.97631 7.61305 1 10 1C12.3869 1 14.6761 1.97631 16.364 3.71414C18.0518 5.45198 19 7.80899 19 10.2667Z"
                     stroke="#6C6CFD"
                     stroke-width="2"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                   />
                   <path
                     d="M10 13.3555C11.6569 13.3555 13 11.9726 13 10.2667C13 8.56071 11.6569 7.17776 10 7.17776C8.34315 7.17776 7 8.56071 7 10.2667C7 11.9726 8.34315 13.3555 10 13.3555Z"
                     stroke="#6C6CFD"
                     stroke-width="2"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                   />
                 </svg>
                 <span className=" ms-2 span-locations">{e.location}</span>
               </div>

<div className="d-flex mt-1 time-date">
               <div className="dt-time">
                 <svg
                   width="20"
                   height="20"
                   viewBox="0 0 22 23"
                   fill="none"
                   xmlns="http:www.w3.org/2000/svg"
                 >
                   <path
                     d="M11 21.5926C16.5228 21.5926 21 16.9828 21 11.2963C21 5.60981 16.5228 1 11 1C5.47715 1 1 5.60981 1 11.2963C1 16.9828 5.47715 21.5926 11 21.5926Z"
                     stroke="#747474"
                     stroke-width="2"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                   />
                   <path
                     d="M11 5.11852V11.2963L15 13.3556"
                     stroke="#747474"
                     stroke-width="2"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                   />
                 </svg>
                 <span className="span">
                   {e.fromTime}-{e.toTime}
                 </span>
               </div>
               <div className="d-flex dt-date">
                <div className="dates">
                 Date :
                </div>
                 <div className="ms-3 span-dates">
                   {e.date}
                 </div>
               </div>
               </div>



               <div className="mt-2 d-flex">
               <div className="cmts-title">Customer Comments:</div>
                <div className="ms-2 cust-cmnts">{e.comment}</div>
                </div>
                <br></br>
                <div className="comment-box"></div>
                <br></br>
                <div className="del-reschedule">
                  <div className="delete-lists">
                 <div
                   className="d-flex hand-hover"
                   onClick={() => removeFormFields(index)}
                 >
                   <svg
                     width="20"
                     height="20"
                     viewBox="0 0 23 23"
                     fill="none"
                     xmlns="http:www.w3.org/2000/svg"
                   >
                     <path
                       d="M10 3.21381H3C2.46957 3.21381 1.96086 3.43077 1.58579 3.81695C1.21071 4.20314 1 4.72692 1 5.27307V19.6879C1 20.234 1.21071 20.7578 1.58579 21.144C1.96086 21.5302 2.46957 21.7471 3 21.7471H17C17.5304 21.7471 18.0391 21.5302 18.4142 21.144C18.7893 20.7578 19 20.234 19 19.6879V12.4805"
                       stroke="#747474"
                       stroke-width="2"
                       stroke-linecap="round"
                       stroke-linejoin="round"
                     />
                     <path
                       d="M17.5 1.66936C17.8978 1.25975 18.4374 1.02963 19 1.02963C19.5626 1.02963 20.1022 1.25975 20.5 1.66936C20.8978 2.07897 21.1213 2.63453 21.1213 3.21381C21.1213 3.79309 20.8978 4.34864 20.5 4.75825L11 14.5397L7 15.5694L8 11.4508L17.5 1.66936Z"
                       stroke="#747474"
                       stroke-width="2"
                       stroke-linecap="round"
                       stroke-linejoin="round"
                     />
                   </svg>
                   <div className="span">Delete</div>
                 </div>
                 </div>
                 
                 <div className="delete-lists">
                 <div
                   className="d-flex hand-hover"
                   onClick={() => handleEdit(e, index)}
                 >
                   <svg
                     width="20"
                     height="20"
                     viewBox="0 0 22 23"
                     fill="none"
                     xmlns="http:www.w3.org/2000/svg"
                   >
                     <path
                       d="M11 21.5926C16.5228 21.5926 21 16.9828 21 11.2963C21 5.60981 16.5228 1 11 1C5.47715 1 1 5.60981 1 11.2963C1 16.9828 5.47715 21.5926 11 21.5926Z"
                       stroke="#747474"
                       stroke-width="2"
                       stroke-linecap="round"
                       stroke-linejoin="round"
                     />
                     <path
                       d="M11 5.11852V11.2963L15 13.3556"
                       stroke="#747474"
                       stroke-width="2"
                       stroke-linecap="round"
                       stroke-linejoin="round"
                     />
                   </svg>
                   <div className="span">Reschedule</div>
                 </div>
                 </div>
                 
               </div>
               
               </div>
               <div className="ms-5 mt-3 straight-line"></div>
              <div>
               <div className="customer-contact">
               <div className="call-cust">
                 <div className="call-icon ">
                   <svg
                     width="25"
                     height="25"
                     viewBox="0 0 35 35"
                     fill="none"
                     xmlns="http:www.w3.org/2000/svg"
                   >
                     <path
                       d="M21.4402 7.3073C22.9833 7.60779 24.4015 8.36103 25.5132 9.47061C26.6249 10.5802 27.3796 11.9956 27.6806 13.5358M21.4402 1C24.6462 1.35547 27.6357 2.7884 29.9181 5.06349C32.2004 7.33859 33.6398 10.3206 34 13.52M32.4202 26.1031V30.8335C32.4219 31.2727 32.3318 31.7074 32.1555 32.1097C31.9793 32.5121 31.7208 32.8733 31.3965 33.1702C31.0723 33.4671 30.6896 33.6931 30.2727 33.8338C29.8559 33.9745 29.4143 34.0267 28.9761 33.9872C24.1146 33.46 19.4449 31.8019 15.342 29.1463C11.5248 26.7254 8.28854 23.4953 5.86295 19.6854C3.19298 15.5718 1.5314 10.8883 1.01282 6.01431C0.973344 5.57826 1.02526 5.13879 1.16528 4.72387C1.30529 4.30895 1.53033 3.92768 1.82607 3.60432C2.12181 3.28096 2.48177 3.02261 2.88303 2.84571C3.28429 2.66881 3.71806 2.57724 4.15672 2.57683H8.89625C9.66296 2.56929 10.4063 2.84028 10.9876 3.33927C11.5689 3.83827 11.9486 4.53122 12.0559 5.28897C12.256 6.80282 12.627 8.28924 13.1618 9.71985C13.3744 10.2842 13.4204 10.8976 13.2944 11.4873C13.1684 12.077 12.8757 12.6182 12.4509 13.047L10.4445 15.0495C12.6935 18.9972 15.9684 22.2658 19.9236 24.5105L21.93 22.5079C22.3595 22.084 22.9018 21.7918 23.4926 21.666C24.0834 21.5403 24.698 21.5862 25.2635 21.7983C26.6968 22.3322 28.1861 22.7025 29.7028 22.9021C30.4703 23.0102 31.1711 23.396 31.6722 23.9862C32.1732 24.5764 32.4394 25.3298 32.4202 26.1031Z"
                       stroke="#6C6CFD"
                       stroke-width="2"
                       stroke-linecap="round"
                       stroke-linejoin="round"
                     />
                   </svg>
                 </div>
                 <div className=" ms-2 cont-num">
                   <div className=" mt-1 ms-1 call-title">CustomerNumber 
                  </div>
                   <div className="ms-1 phone-num">{e.phone}</div>
                 </div>
                 </div>
                 <div className="d-flex">
               <div className="navigate ">
                 <svg
                   width="30"
                   height="30"
                   viewBox="0 0 41 41"
                   fill="none"
                   xmlns="http:www.w3.org/2000/svg"
                 >
                   <path
                     d="M19.2646 36.4459C19.6055 36.7868 19.9975 36.9572 20.4406 36.9572C20.8838 36.9572 21.2758 36.7868 21.6167 36.4459L36.3429 21.7197C36.6838 21.3788 36.8542 20.9698 36.8542 20.4925C36.8542 20.0153 36.6838 19.6062 36.3429 19.2653L21.6167 4.53915C21.2758 4.19826 20.8838 4.02782 20.4406 4.02782C19.9975 4.02782 19.6055 4.19826 19.2646 4.53915L4.53838 19.2653C4.1975 19.6062 4.02706 20.0153 4.02706 20.4925C4.02706 20.9698 4.1975 21.3788 4.53838 21.7197L19.2646 36.4459ZM12.3105 26.6284V18.4472C12.3105 17.8336 12.498 17.3393 12.873 16.9644C13.248 16.5894 13.7423 16.4019 14.3558 16.4019H22.8439L20.6963 14.1521L23.5597 11.2887L30.7183 18.4472L23.5597 25.6058L20.6963 22.7424L22.8439 20.4925H16.4012V26.6284H12.3105ZM20.5429 40.9967C17.7136 40.9967 15.0547 40.4595 12.5662 39.385C10.0778 38.3119 7.91314 36.855 6.07236 35.0142C4.23159 33.1734 2.77465 31.0088 1.70154 28.5203C0.627077 26.0319 0.0898438 23.373 0.0898438 20.5437C0.0898438 17.7143 0.627077 15.0554 1.70154 12.567C2.77465 10.0785 4.23159 7.9139 6.07236 6.07312C7.91314 4.23235 10.0778 2.77473 12.5662 1.70026C15.0547 0.627158 17.7136 0.0906067 20.5429 0.0906067C23.3722 0.0906067 26.0311 0.627158 28.5196 1.70026C31.008 2.77473 33.1727 4.23235 35.0134 6.07312C36.8542 7.9139 38.3111 10.0785 39.3842 12.567C40.4587 15.0554 40.9959 17.7143 40.9959 20.5437C40.9959 23.373 40.4587 26.0319 39.3842 28.5203C38.3111 31.0088 36.8542 33.1734 35.0134 35.0142C33.1727 36.855 31.008 38.3119 28.5196 39.385C26.0311 40.4595 23.3722 40.9967 20.5429 40.9967ZM20.5429 36.9061C25.1107 36.9061 28.9798 35.321 32.15 32.1508C35.3202 28.9805 36.9053 25.1115 36.9053 20.5437C36.9053 15.9758 35.3202 12.1068 32.15 8.93655C28.9798 5.76633 25.1107 4.18122 20.5429 4.18122C15.975 4.18122 12.106 5.76633 8.93579 8.93655C5.76557 12.1068 4.18045 15.9758 4.18045 20.5437C4.18045 25.1115 5.76557 28.9805 8.93579 32.1508C12.106 35.321 15.975 36.9061 20.5429 36.9061Z"
                     fill="#4CAF50"
                   />
                 </svg>
               </div>
                 <div className="span">Navigate</div>
               </div>
               </div>
               </div>
               </div>
        </div>




        // <div className="task-container">
        //   <div className="task-card">
        //     {/* <div className="avatar-column"> Type</div> */}
        //     <div className="task-card-details">
        //       <p className="cutomer-name">Agent Name : {e.customername}</p>
        //       <div className="cust-location">
        //         <svg
        //           width="15"
        //           height="20"
        //           viewBox="0 0 20 25"
        //           fill="none"
        //           xmlns="http://www.w3.org/2000/svg"
        //         >
        //           <path
        //             d="M19 10.2667C19 17.4741 10 23.6519 10 23.6519C10 23.6519 1 17.4741 1 10.2667C1 7.80899 1.94821 5.45198 3.63604 3.71414C5.32387 1.97631 7.61305 1 10 1C12.3869 1 14.6761 1.97631 16.364 3.71414C18.0518 5.45198 19 7.80899 19 10.2667Z"
        //             stroke="#6C6CFD"
        //             stroke-width="2"
        //             stroke-linecap="round"
        //             stroke-linejoin="round"
        //           />
        //           <path
        //             d="M10 13.3555C11.6569 13.3555 13 11.9726 13 10.2667C13 8.56071 11.6569 7.17776 10 7.17776C8.34315 7.17776 7 8.56071 7 10.2667C7 11.9726 8.34315 13.3555 10 13.3555Z"
        //             stroke="#6C6CFD"
        //             stroke-width="2"
        //             stroke-linecap="round"
        //             stroke-linejoin="round"
        //           />
        //         </svg>
        //         <span className="span">{e.location}</span>
        //       </div>
        //       <div className="dt-time">
        //         <svg
        //           width="20"
        //           height="20"
        //           viewBox="0 0 22 23"
        //           fill="none"
        //           xmlns="http://www.w3.org/2000/svg"
        //         >
        //           <path
        //             d="M11 21.5926C16.5228 21.5926 21 16.9828 21 11.2963C21 5.60981 16.5228 1 11 1C5.47715 1 1 5.60981 1 11.2963C1 16.9828 5.47715 21.5926 11 21.5926Z"
        //             stroke="#747474"
        //             stroke-width="2"
        //             stroke-linecap="round"
        //             stroke-linejoin="round"
        //           />
        //           <path
        //             d="M11 5.11852V11.2963L15 13.3556"
        //             stroke="#747474"
        //             stroke-width="2"
        //             stroke-linecap="round"
        //             stroke-linejoin="round"
        //           />
        //         </svg>
        //         <span className="span">
        //           {e.fromTime} {e.toTime}
        //         </span>
        //       </div>
        //       <div className="cmts-title">Customer Comments:</div>
        //       <div className="cust-cmnts">{e.comment}</div>
        //       <div className="del-reschedule">
        //         <div
        //           className="hand-hover"
        //           onClick={() => removeFormFields(index)}
        //         >
        //           <svg
        //             width="20"
        //             height="20"
        //             viewBox="0 0 23 23"
        //             fill="none"
        //             xmlns="http://www.w3.org/2000/svg"
        //           >
        //             <path
        //               d="M10 3.21381H3C2.46957 3.21381 1.96086 3.43077 1.58579 3.81695C1.21071 4.20314 1 4.72692 1 5.27307V19.6879C1 20.234 1.21071 20.7578 1.58579 21.144C1.96086 21.5302 2.46957 21.7471 3 21.7471H17C17.5304 21.7471 18.0391 21.5302 18.4142 21.144C18.7893 20.7578 19 20.234 19 19.6879V12.4805"
        //               stroke="#747474"
        //               stroke-width="2"
        //               stroke-linecap="round"
        //               stroke-linejoin="round"
        //             />
        //             <path
        //               d="M17.5 1.66936C17.8978 1.25975 18.4374 1.02963 19 1.02963C19.5626 1.02963 20.1022 1.25975 20.5 1.66936C20.8978 2.07897 21.1213 2.63453 21.1213 3.21381C21.1213 3.79309 20.8978 4.34864 20.5 4.75825L11 14.5397L7 15.5694L8 11.4508L17.5 1.66936Z"
        //               stroke="#747474"
        //               stroke-width="2"
        //               stroke-linecap="round"
        //               stroke-linejoin="round"
        //             />
        //           </svg>
        //           <span className="span">Delete</span>
        //         </div>
        //         <div
        //           className="hand-hover"
        //           onClick={() => handleEdit(e, index)}
        //         >
        //           <svg
        //             width="20"
        //             height="20"
        //             viewBox="0 0 22 23"
        //             fill="none"
        //             xmlns="http://www.w3.org/2000/svg"
        //           >
        //             <path
        //               d="M11 21.5926C16.5228 21.5926 21 16.9828 21 11.2963C21 5.60981 16.5228 1 11 1C5.47715 1 1 5.60981 1 11.2963C1 16.9828 5.47715 21.5926 11 21.5926Z"
        //               stroke="#747474"
        //               stroke-width="2"
        //               stroke-linecap="round"
        //               stroke-linejoin="round"
        //             />
        //             <path
        //               d="M11 5.11852V11.2963L15 13.3556"
        //               stroke="#747474"
        //               stroke-width="2"
        //               stroke-linecap="round"
        //               stroke-linejoin="round"
        //             />
        //           </svg>
        //           <span className="span">Reschedule</span>
        //         </div>
        //       </div>
        //     </div>


        //     <div className="customer-contact">
        //       <div className="call-cust">
        //         <div className="call-icon m-2">
        //           <svg
        //             width="25"
        //             height="25"
        //             viewBox="0 0 35 35"
        //             fill="none"
        //             xmlns="http://www.w3.org/2000/svg"
        //           >
        //             <path
        //               d="M21.4402 7.3073C22.9833 7.60779 24.4015 8.36103 25.5132 9.47061C26.6249 10.5802 27.3796 11.9956 27.6806 13.5358M21.4402 1C24.6462 1.35547 27.6357 2.7884 29.9181 5.06349C32.2004 7.33859 33.6398 10.3206 34 13.52M32.4202 26.1031V30.8335C32.4219 31.2727 32.3318 31.7074 32.1555 32.1097C31.9793 32.5121 31.7208 32.8733 31.3965 33.1702C31.0723 33.4671 30.6896 33.6931 30.2727 33.8338C29.8559 33.9745 29.4143 34.0267 28.9761 33.9872C24.1146 33.46 19.4449 31.8019 15.342 29.1463C11.5248 26.7254 8.28854 23.4953 5.86295 19.6854C3.19298 15.5718 1.5314 10.8883 1.01282 6.01431C0.973344 5.57826 1.02526 5.13879 1.16528 4.72387C1.30529 4.30895 1.53033 3.92768 1.82607 3.60432C2.12181 3.28096 2.48177 3.02261 2.88303 2.84571C3.28429 2.66881 3.71806 2.57724 4.15672 2.57683H8.89625C9.66296 2.56929 10.4063 2.84028 10.9876 3.33927C11.5689 3.83827 11.9486 4.53122 12.0559 5.28897C12.256 6.80282 12.627 8.28924 13.1618 9.71985C13.3744 10.2842 13.4204 10.8976 13.2944 11.4873C13.1684 12.077 12.8757 12.6182 12.4509 13.047L10.4445 15.0495C12.6935 18.9972 15.9684 22.2658 19.9236 24.5105L21.93 22.5079C22.3595 22.084 22.9018 21.7918 23.4926 21.666C24.0834 21.5403 24.698 21.5862 25.2635 21.7983C26.6968 22.3322 28.1861 22.7025 29.7028 22.9021C30.4703 23.0102 31.1711 23.396 31.6722 23.9862C32.1732 24.5764 32.4394 25.3298 32.4202 26.1031Z"
        //               stroke="#6C6CFD"
        //               stroke-width="2"
        //               stroke-linecap="round"
        //               stroke-linejoin="round"
        //             />
        //           </svg>
        //         </div>
        //         <div className="cont-num">
        //           <div className="call-title">Call Customer</div>
        //           <div className="phone-num">{e.phone}</div>
        //         </div>
        //       </div>
        //       <div className="navigate m-2 p-2">
        //         <svg
        //           width="30"
        //           height="30"
        //           viewBox="0 0 41 41"
        //           fill="none"
        //           xmlns="http://www.w3.org/2000/svg"
        //         >
        //           <path
        //             d="M19.2646 36.4459C19.6055 36.7868 19.9975 36.9572 20.4406 36.9572C20.8838 36.9572 21.2758 36.7868 21.6167 36.4459L36.3429 21.7197C36.6838 21.3788 36.8542 20.9698 36.8542 20.4925C36.8542 20.0153 36.6838 19.6062 36.3429 19.2653L21.6167 4.53915C21.2758 4.19826 20.8838 4.02782 20.4406 4.02782C19.9975 4.02782 19.6055 4.19826 19.2646 4.53915L4.53838 19.2653C4.1975 19.6062 4.02706 20.0153 4.02706 20.4925C4.02706 20.9698 4.1975 21.3788 4.53838 21.7197L19.2646 36.4459ZM12.3105 26.6284V18.4472C12.3105 17.8336 12.498 17.3393 12.873 16.9644C13.248 16.5894 13.7423 16.4019 14.3558 16.4019H22.8439L20.6963 14.1521L23.5597 11.2887L30.7183 18.4472L23.5597 25.6058L20.6963 22.7424L22.8439 20.4925H16.4012V26.6284H12.3105ZM20.5429 40.9967C17.7136 40.9967 15.0547 40.4595 12.5662 39.385C10.0778 38.3119 7.91314 36.855 6.07236 35.0142C4.23159 33.1734 2.77465 31.0088 1.70154 28.5203C0.627077 26.0319 0.0898438 23.373 0.0898438 20.5437C0.0898438 17.7143 0.627077 15.0554 1.70154 12.567C2.77465 10.0785 4.23159 7.9139 6.07236 6.07312C7.91314 4.23235 10.0778 2.77473 12.5662 1.70026C15.0547 0.627158 17.7136 0.0906067 20.5429 0.0906067C23.3722 0.0906067 26.0311 0.627158 28.5196 1.70026C31.008 2.77473 33.1727 4.23235 35.0134 6.07312C36.8542 7.9139 38.3111 10.0785 39.3842 12.567C40.4587 15.0554 40.9959 17.7143 40.9959 20.5437C40.9959 23.373 40.4587 26.0319 39.3842 28.5203C38.3111 31.0088 36.8542 33.1734 35.0134 35.0142C33.1727 36.855 31.008 38.3119 28.5196 39.385C26.0311 40.4595 23.3722 40.9967 20.5429 40.9967ZM20.5429 36.9061C25.1107 36.9061 28.9798 35.321 32.15 32.1508C35.3202 28.9805 36.9053 25.1115 36.9053 20.5437C36.9053 15.9758 35.3202 12.1068 32.15 8.93655C28.9798 5.76633 25.1107 4.18122 20.5429 4.18122C15.975 4.18122 12.106 5.76633 8.93579 8.93655C5.76557 12.1068 4.18045 15.9758 4.18045 20.5437C4.18045 25.1115 5.76557 28.9805 8.93579 32.1508C12.106 35.321 15.975 36.9061 20.5429 36.9061Z"
        //             fill="#4CAF50"
        //           />
        //         </svg>
        //         <span className="span">Navigate</span>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      ))}
    </>
  );
}
