import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import ChipV1 from "../../../../../components/Inputs/Chip/V1/index";
import "./style.css";
import { pricesIn } from "../../../data";
import { numToWords } from "../../../../../utils";
interface Sell {
  prop_tax: string;
  occupancy: string;
  title: string;
  lease: string;
  price: string;
  token_amt: string;
}
export default function Index({ register, errors, control, propertyType, watch }) {
  const chipList = {
    prefOption: [
      {
        label: "Yes",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
    pricesIn: pricesIn,
  };
  const schema = yup.object().shape({
    number: yup.number().required("Field cannot be empty"),
  });
  const [ownerType, setOwnerType] = useState(null);

  const [floorType, setFloorType] = useState(null);
  const onSubmit = (data: Sell) => {
    console.log("Common", data);
  };
  return (
    <div className="mt-3">
      {["Shop", "Office"].includes(propertyType) && (
        <div>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label className="lable">
                  Currently Leased out
                  <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="lease"
                  render={({ field: { onChange, value } }) => (
                    <ChipV1
                      onClick={(e) => {
                        onChange(e);
                      }}
                      chipList={chipList?.prefOption}
                      value={value}
                    />
                  )}
                />
                {errors?.lease?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label className="lable">Prices in
                <span className="text-danger">*</span></Form.Label>
                <Controller
                  control={control}
                  name="currency_id"
                  render={({ field: { onChange, value } }) => (
                    <ChipV1
                      onClick={(e) => {
                        onChange(e);
                      }}
                      chipList={chipList?.pricesIn}
                      value={value}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label className="lable">
                  Expected Price
                  <span className="text-danger">*</span>
                </Form.Label>

                <Form.Control
                  className="formdata"
                  type="number"
                  name="price"
                  placeholder="Type the amount"
                  {...register("price", {
                    required: true,
                  })}
                />
                {numToWords(watch("price"))}
                {errors?.price?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label className="lable">
                  Token Amount
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className="formdata"
                  type="number"
                  name="token_amt"
                  placeholder="Type the amount"
                  {...register("token_amt", {
                    required: true,
                  })}
                />
                {numToWords(watch("token_amt"))}
                {errors?.token_amt?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </Form.Group>
            </Col>
          </Row>
        </div>
      )}
      {["Apartment", "House"].includes(propertyType) && (
        <div>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label className="lable">
                  Prices in
                  <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="prices_in"
                  render={({ field: { onChange, value } }) => (
                    <ChipV1
                      onClick={(e) => {
                        onChange(e);
                      }}
                      chipList={chipList?.pricesIn}
                      value={value}
                    />
                  )}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label className="lable">
                  Expected Price
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className="formdata"
                  type="number"
                  name="price"
                  placeholder="Type the amount"
                  {...register("price", {
                    required: true,
                  })}
                />
                {numToWords(watch("price"))}
                {errors?.price?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label className="lable">
                  Token Amount
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  className="formdata"
                  type="number"
                  name="token_amt"
                  placeholder="Type the amount"
                  {...register("token_amt", {
                    required: true,
                  })}
                />
                {numToWords(watch("token_amt"))}
                {errors?.token_amt?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </Form.Group>
            </Col>
          </Row>
        </div>
      )}
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">Have paid property tax?
            <span className="text-danger">*</span></Form.Label>
            <Controller
              control={control}
              name="paid_tax"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefOption}
                  value={value}
                />
              )}
            />
            {errors?.paid_tax?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">Do you have occupancy?
            <span className="text-danger">*</span></Form.Label>
            <Controller
              control={control}
              name="occupancy"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefOption}
                  value={value}
                />
              )}
            />
            {errors?.occupancy?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
}
