import React, { useState } from "react";
import { PersonFill } from "react-bootstrap-icons";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../../hooks";
import updateUserProfile from "../../../../api/user/updateUserProfile";
import "./style.css";
import { LoadingV1 } from "../../../../components/Loading";

const EditForm = () => {
  const auth = useAuth();
  console.log(auth?.userInfo);
  const [firstName, setFirstName] = useState(
    auth?.userAttr?.["custom:first_name"] || ""
  );
  const [lastName, setLastName] = useState(
    auth?.userAttr?.["custom:last_name"] || ""
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState(auth?.userAttr?.email || "");
const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      first_name: auth?.userInfo?.first_name,
      last_name: auth?.userInfo?.last_name,
      phone_number: auth?.userInfo?.phone_number,
      email: auth?.userAttr?.email,
    },
  });

  async function onSubmit(e) {
    setIsLoading(true)
    await updateUserProfile(auth?.userInfo?.user_key, e);
    auth?.fetchUser();
    console.log(auth?.userAttr?.sub, e);
    alert("Profile updated successfully")
    setIsLoading(false)
    window.location.reload();
  }

  return (
    <div>
      <div className="form_div_container">
        <div className="d-flex justify-content-center align-items-center interprtation-content p-2">
          Edit your profile
        </div>
        <div className="pro_pic_con">
          <PersonFill color="" size={128} className="pro_pic" />
        </div>
        <div className="form_container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form_field_con">
              <label htmlFor="first_name">First Name</label>
              <input type="text" id="first_name" {...register("first_name")} />
            </div>
            <div className="form_field_con">
              <label htmlFor="last_name">Last Name</label>
              <input type="text" id="last_name" {...register("last_name")} />
            </div>
            <div className="form_field_con">
              <label htmlFor="phone_number">Phone Number</label>
              <input
                type="telephone"
                id="phone_number"
                {...register("phone_number")}
              />
            </div>
            <div className="form_field_con">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" {...register("email")} />
            </div>
            <div className="btn_con">
              { isLoading ? <LoadingV1/> :
              <input type="submit" className="btn1" />
              }
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditForm;
