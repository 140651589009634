import React from "react";
import { Card } from "react-bootstrap";
import { GeoAltFill, Heart, Share } from "react-bootstrap-icons";
import "./style.css";
import BtnV1 from "../../../../../../components/Buttons/V1";
import { Button1 } from "../../../../../../components/Buttons";
import { useHistory } from "react-router-dom";
export default function Index({ home }) {
  const history = useHistory();
  return (
    <>
      <div className="card-container">
        <Card style={{ width: "100%", height: "auto" }}>
          <div className="property-card-container">
            {/* <div className="icons">
              <div>
                <Share size={20} />
              </div>
              <div>
                <Heart size={20} />
              </div>
            </div> */}

            <div className="img-container1">
              <img src={home?.img_src} alt="property" />
            </div>
            <Card.Body className="property-card-detail">
              <div className="details">
{ home.bath ? <div className="f-b">{home?.bath}BHK</div>: null}
                <div className="f-b">{home?.property_type_name}</div>
                <div className="f-b">
                  {/* <GeoAltFill /> */}
                  {home?.listed_for}
                </div>
              </div>
              <div className="button-container">
                <Button1
                  label="View"
                  name="View property"
                  onClick={() =>
                    history.push(`/properties/${home?.property_key}`)
                  }
                />
              </div>
            </Card.Body>
          </div>
        </Card>
      </div>
    </>
  );
}
