import React, { useContext } from "react";
import "./style.css";
import { useHistory } from "react-router-dom";
import { ProfileDropDown } from "../../components/Dropdown";
import { AuthContext } from "../../context/AuthContext";
import logo from "../../assets/brandlogo.png";
import SearchBar from "../../pages/Home/Components/SearchBar";

// export default function Index() {
export default function Index() {
  const pathincludes = "edit-your-property";
  const path = window.location.pathname;
  const history = useHistory();
  const { isAuthenticated } = useContext(AuthContext);
  return (
    <main className="">
      <nav className="navbar navbar-expand-lg navbar-top-common">
        <div className="container-fluid">
          <div className="navbar-mobile-btn"></div>
          <div
            id="nav-logo"
            className="f-b text-light ms-2 cursor-p"
            onClick={() => history?.push("/")}
          >
            <img src={logo} alt="" width="150px" />
          </div>
          {path.includes(pathincludes) ? null : (
            <div id="nav-searchbar" className="searchbar-nav">
              <SearchBar />
            </div>
          )}
          {/* <div className="collapse navbar-collapse text-light" id="navbarNavAltMarkup">
          <div className="navbar-nav " >
            <p className="m-1" aria-current="page">
              Buyer
            </p>
            <p className="m-1">
              Renter
            </p>
            <p className="m-1">
              Seller
            </p>
            <p className="m-1">
              Tenant
            </p>
          </div>
        </div> */}
          {/* <div className="collapse navbar-collapse text-light" id="navbarNavAltMarkup">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link active" aria-current="page">Buyer</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" >Renter</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">Seller</a>
        </li>
        <li className="nav-item">
          <a className="nav-link disabled">Tenant</a>
        </li>
      </ul>
    </div> */}
          {isAuthenticated ? (
            <ProfileDropDown />
          ) : (
            <button
              id="nav-dropdown"
              className="re-navbar-menu light-bg no-border"
              onClick={() => history.push("/logIn")}
            >
              Log In
            </button>
          )}
        </div>
      </nav>
      {/* {children} */}
    </main>
  );
}
