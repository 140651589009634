import { PATCH } from "../functions";

const updateUserProfile = async (user_key: any, data: any) => {
  const path = `/users/${user_key}`;
  return await PATCH({ apiUri: path, data })
    .then((result) => {
      console.log("createGroup in db > ", result);
      return result;
    })
    .catch((err) => {
      return err;
    });
};

export default updateUserProfile;
