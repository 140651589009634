import React, { useState } from "react";
import { JsxElement } from "typescript";

interface InputFieldV1Props {
  onChange: Function;
  value?: string;
  type?: "text" | "password" | "number"|"email";
  left?: any;
  leftOnClick?: Function;
  label?: string;
  containerClassName?: string;
  inputContainerClassName?: string;
  placeholder?: string;
  error?: boolean |string;
  errorMsg?: string;
}

const timeStampId = () => {
  const randLetter = String.fromCharCode(65 + Math.floor(Math.random() * 26));
  return randLetter + Date.now();
};

export default function Index({
  type,
  left,
  leftOnClick,
  onChange,
  label,
  containerClassName,
  inputContainerClassName,
  placeholder,
  error,
  errorMsg,
}: InputFieldV1Props) {
  const [value, setValue] = useState("");
  const inputId = timeStampId();
  const handleValueChange = (e) => {
    setValue(e);
    onChange(e);
  };
  return (
    <div className={`d-flex flex-column ${containerClassName}`}>
      {label && (
        <label htmlFor={inputId} className="text-muted mb-2">
          {label}
        </label>
      )}

      <div
        className={`ps-3 pe-3 p-2 w-100  border border-${error ? "danger" : "secondary"
          } d-flex flex-row ${inputContainerClassName}`}
      >
        <input
          width="100%"
          className="hide-input-border hide-background w-100"
          id={inputId}
          type={type || "text"}
          value={value}
          onChange={(e) => handleValueChange(e.target?.value)}
          placeholder={placeholder || ""}
        />
        <div className="ms-1" onClick={leftOnClick && (() => leftOnClick())}>
          {left}
        </div>
      </div>
      <div className="text-danger f-12">{errorMsg || ""}</div>
    </div>
  );
}
