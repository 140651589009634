import React from "react";
import collections from "../../../../assets/collections.png";
import Home from "../../../../assets/home.png";
import Mansion from "../../../../assets/mansion.png";
import Kids from "../../../../assets/kids.png";
import Office from "../../../../assets/office.png";
import Style from "../../../../assets/style.png";
import Nature from "../../../../assets/nature.png";

export default function Index() {

  
  return (
    <div className="container mt-5 pt-5">
      <h4>Collections</h4>
      <div className="d-flex flex-row mt-5" style={{width:"570px"}}>
      

    {/* <img src="https://www.flaticon.com/premium-icon/home_2544087"></img> */}
    <Card image={Home} info="BUDGET HOMES" />
    <Card image={Mansion} info="LUXURY HOMES" />
    <Card image={Kids} info="KIDS FRIENDLY HOMES" />
    <Card image={Office} info="HOME OFFICE HOMES" />
    <Card image={Style} info="STYLISH HOMES" />
    <Card image={Nature} info="NATURE HOMES" />
    {/* <Home/> */}


    </div>
    </div>
  );
}

const Card = ({ image, info }) => {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center col-4'>
      <img src={image} alt={info} width={80} />
      <p className='m-1'>
          {info}
      </p>
    </div>
  );
};
