import React from "react";
import { Modal } from "react-bootstrap";
import "./style.css";
import logo from "../../../assets/brandlogo.png";
interface CheckoutModalProps {
  show: boolean;
  handleClose: () => void;
  props: any;
  handlePaymentRequest: () => void;
}
function Index({
  props,
  show,
  handleClose,
  handlePaymentRequest,
}: CheckoutModalProps) {
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Proceed to payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="img_con">
            <img src={logo} alt="Truprops Logo" className="logo_img" />
          </div>
          <div>
            <form
              method="POST"
              name="customerData"
              action={`${props?.api_endpoint_be}/ccavRequestHandler`}
            >
              <table style={{ width:"100%", borderCollapse:"collapse"}}>
                <tr style={{ display: "none" }}>
                  <td>Merchant Id</td>
                  <td>
                    <input
                      type="text"
                      name="merchant_id"
                      id="merchant_id"
                      value={props?.merchant_id}
                    />
                  </td>
                </tr>
                <tr 
                style={{ display: "none" }}
                >
                  <td>Order Id</td>
                  <td>
                    <input
                      type="text"
                      name="order_id"
                      value={props?.order_id}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Currency</td>
                  <td>
                    <input
                      type="text"
                      name="currency"
                      value={props?.currency}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td>
                    <input type="text" name="amount" value={props?.amount} />
                  </td>
                </tr>
                <tr style={{ display: "none" }}>
                  <td>Redirect URL</td>
                  <td>
                    <input
                      type="text"
                      name="redirect_url"
                      value={props?.redirect_url}
                    />
                  </td>
                </tr>
                <tr style={{ display: "none" }}>
                  <td>Cancel URL</td>
                  <td>
                    <input
                      type="text"
                      name="cancel_url"
                      value={props?.cancel_url}
                    />
                  </td>
                </tr>
                <tr style={{ display: "none" }}>
                  <td>Language</td>
                  <td>
                    <input
                      type="text"
                      name="language"
                      id="language"
                      value={props?.language}
                    />
                  </td>
                </tr>
                <tr style={{ display: "none" }}>
                  <td>Merchant Param1</td>
                  <td>
                    <input
                      type="text"
                      name="merchant_param1"
                      value={props?.api_endpoint_fe}
                    />
                  </td>
                </tr>
                {/* <tr style={{ display: "none" }}>
                  <td>Merchant Param2</td>
                  <td>
                    <input
                      type="text"
                      name="merchant_param2"
                      value={props?.api_endpoint}
                    />
                  </td>
                </tr> */}
                <tr style={{ display: "none" }}>
                  <td>Customer Id:</td>
                  <td>
                    <input
                      type="text"
                      name="customer_identifier"
                      value={props?.customer_identifier}
                    />
                  </td>
                </tr>
                <tr>                  
                </tr>
              </table>
              <button
                      type="submit"
                      className="checkOut"
                      onClick={handlePaymentRequest}
                    >Pay Now</button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Index;
