import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import PropertyCard from "../../../SearchResultsV2/components/PropertyCard";
import { AuthContext } from "../../../../context/AuthContext";
import { buildQueryFromObj } from "../../../../helpers";
import { useApi } from "../../../../hooks";
import { Filter } from "./components";
import { FilterValueType } from "./components/Filter";
import { LayoutSidebar } from "../../../../components";
import "./style.css";

const Index = () => {
  const [filterValues, setFilterValues] = useState<FilterValueType>();
  const [isFetching, setIsFetching] = useState(false);
  const { userInfo } = useContext(AuthContext);

  const api = useApi();
  const [propertyList, setPropertyList] = useState<any>(null);
  const [approvedValue, setApprovedValue] = useState(null);
  const user_key = userInfo?.user_key;

  const fetchProperties = async () => {
    const path = `/properties?user_key=${user_key}${
      approvedValue === null ? "" : `&is_approved=${approvedValue}`
    }`;

    await api.get({
      path,

      callback: setPropertyList,

      statusCallback: setIsFetching,
    });
  };

  useEffect(() => {
    fetchProperties();
    console.log(buildQueryFromObj(filterValues));
  }, [filterValues, AuthContext, approvedValue]);
  console.log(propertyList);
  return (
    <div>
      {/* <Filter onFilterChange={setFilterValues} /> */}

      <div className="d-flex flex-row">
        <LayoutSidebar />
        <div className="w-auto">
          <div className=" flex-coulmn mx-5 justify-content-around">
            <button
              type="button"
              className="btn mx-2 btn-primary"
              onClick={() => setApprovedValue(null)}
            >
              All
            </button>
            <button
              type="button"
              className="btn mx-2 btn-success"
              onClick={() => setApprovedValue(1)}
            >
              Approved
            </button>
            <button
              type="button"
              className="btn mx-2 btn-secondary"
              onClick={() => setApprovedValue(0)}
            >
              Not Approved
            </button>
          </div>
        </div>
      </div>
      <div className="properties-list">
        {propertyList?.length > 0 ? (
          propertyList?.map((property, idx) => (
            <PropertyCard data={property} key={idx} />
          ))
        ) : (
          <div className="fs-5 text-muted w-100 d-flex justify-content-center">
            {isFetching ? "loading..." : " No Properties"}
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
