import { GOOGLE_MAP_TOKEN } from "../../../const";
import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import MyLoc from "../../../assets/my-location.png"

const containerStyle = {
  width: '100%',
  height: '400px'
};

function MapPicker(props) {
  const { loc, onChange } = props;
  const [center, setCenter] = useState(loc);
  const [marker, setMarker] = useState(loc);
  const [apiLoaded, setApiLoaded] = useState(false);

  useEffect(() => {
    // Check if the Google Maps API is already loaded
    if (!window.google) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_TOKEN}`;
      script.onload = () => setApiLoaded(true);
      document.body.appendChild(script);
    } else {
      setApiLoaded(true);
    }
  }, [GOOGLE_MAP_TOKEN]);

  const handleMapClick = event => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setCenter({ lat, lng });
    setMarker({ lat, lng });
    console.log({ lat, lng }); // log the value of the location
  };

  const handleMarkerDragEnd = event => {
    const { latLng } = event;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setMarker({ lat, lng });
    onChange({ lat: lat, lon: lng });
    console.log({ lat, lng }); // log the value of the location
  };
  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        setCenter({ lat, lng });
        setMarker({ lat, lng });
        onChange({ lat: lat, lon: lng });
        console.log({ lat, lng }); // log the value of the location
      }, error => {
        console.error(error);
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  return (
    apiLoaded && (
      <>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={12} onClick={handleMapClick}>
        {marker && <Marker position={marker} draggable={true} onDragEnd={handleMarkerDragEnd} />}
        <div className="current-loc" onClick={handleGetCurrentLocation} style={{ position: 'absolute', top: '40%', right: '10px' }}><img src={MyLoc}/></div>
      </GoogleMap>
      </>
    )
  );
}

export default MapPicker;
