import React from "react";
import {
  HashRouter  as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ProtectedRouter from "./ProtectedRouter";
// import { AgentLayout, Layout, Navbar } from "../layouts";
import AgentLayout from "../pages/AgentDashboard/Layout";
// import Navbar  from "../layouts/Agent/V1/Components/Navbar";
// import {Navbar} from "../Components/Navbar";
import AgentRoutes from "./AgentRoutes";
// import RentalAgreement from "../pages/Rental Agreement";
import RentalAgreement from "../pages/AgreementsV2";
import Task from "../pages/Task";
import RefundPolicy from "../pages/RefundPolicy";
import Termscondition from "../pages/Termscondition";
import ContactUs from "../pages/ContactUs";
import AboutUs from "../pages/AboutUs";
import Pricing from "../pages/Pricing";
import UploadProperty from "../pages/Home/UploadProperty";
import PropHistory from "../pages/Property/Timeline";
import AadhaarVerfication from "../pages/AadhaarVerification/GetOtp";
import { EditProfile } from "../pages";
// import {DashBoard} from "../pages/AgentDashboard";
import { PaymentHistory } from "../pages";
import {
  Home,
  LogIn,
  SignUp,
  // PropertiesSearchResult,
  SearchResults,
  Property,
  ZillowPropertyV1,
  CreateNewPassword,
  VerifyUser,
  Favorites,
  Profile,
  ViewedProperties,
  ScheduledTours,
  PostPropertyForm,
  MyProperties,
  PrivacyPolicy,

  // Career,
  // Page404,
  Dev as DevPage,
  MyAccountLayout,
  PostPropertyV2,
  AgreementPreview,
  SubscriptionPlans,
  ForgetPassword,
  FAQ,
  CCavenue,
  Guidelines
} from "../pages";
import Apply from "../pages/Apply";
import AgentRoute from "./AgentRouter";
// import { AgentDashBoard, Properties, ManageProperties } from "../PagesAgent";
import {
  DashBoard,
  MyBucket,
  PropNearby,
  Payments,
  AgentProfile,
} from "../pages/AgentDashboard";
import Share from "../components/Share";
import Test from "../test";

export default function index() {
  return (
    <div className="web-bg">
      <Router>
        <Switch>
          <Route exact path="/" component={() => <Home />} />
          <Route exact path="/logIn" component={() => <LogIn />} />
          <Route exact path="/signUp" component={() => <SignUp />} />
          <Route
            exact
            path="/forgetPassword"
            component={() => <ForgetPassword />}
          />
          <Route exact path="/verify" component={() => <VerifyUser />} />
          <Route exact path="/FAQ" component={() => <FAQ />} />
          <Route exact path="/policy" component={() => <PrivacyPolicy />} />
          <Route exact path="/guidelines" component={() => <Guidelines />} />
          <Route
            exact
            path="/subscriptionPlans"
            component={() => <SubscriptionPlans />}
          />
          <Route
            exact
            path="/subscriptionPlans/:subscriptionPlanId/pricing"
            component={() => <Pricing />}
          />

          <Route exact path="/aboutus" component={() => <AboutUs />} />
          <Route
            exact
            path="/termscondition"
            component={() => <Termscondition />}
          />

          <Route exact path="/refund" component={() => <RefundPolicy />} />
          <Route exact path="/contact" component={() => <ContactUs />} />

          <Route
            exact
            path="/agreements"
            component={() => <RentalAgreement />}
          />
          <Route
            exact
            path="/agreements/preview"
            component={() => <AgreementPreview />}
          />
          <Route
            exact
            path="/CreateNewPassword"
            component={() => <CreateNewPassword />}
          />
          {/* closing form */}

          <Route
            exact
            path="/account"
            render={() => <Redirect to="/account/dashboard" />}
          />
          <Route
            exact
            path="/account"
            render={() => <Redirect to="/account/payment-history" />}
          />
          <Route exact path="/test" component={() => <Test />} />

          <ProtectedRouter
            path="/account"
            component={() => <MyAccountLayout />}
          />
          <ProtectedRouter
            exact
            path="/prop-history/:prop_key"
            component={({ match }) => (
              <PropHistory propKey={match?.params?.prop_key} />
            )}
          />
          <Route
            exact
            path="/properties/:prop_key"
            component={({ match }) => (
              <Property propKey={match?.params?.prop_key} />
            )}
          />
          <Route
            exact
            path="/results"
            // component={() => <PropertiesSearchResult />}
            component={() => <SearchResults />}
          />
          <Route
            exact
            path="/verification"
            component={() => <AadhaarVerfication />}
          />
          <ProtectedRouter
            exact
            path="/my-favorites"
            component={() => <Favorites />}
          />
          <ProtectedRouter
            exact
            path="/post-your-property"
            component={() => <PostPropertyV2 />}
          />
          <ProtectedRouter
            exact
            path="/edit-my-profile"
            component={() => <EditProfile />}
          />
          {/* <Layout> 

</Layout> */}

          <ProtectedRouter
            exact
            path="/edit-your-property/:prop_key"
            component={({ match }) => (
              <PostPropertyV2 propertyId={match?.params?.prop_key} />
            )}
          />
          {/* <Navbar> */}
          {/* <ProtectedRouter
              exact
              path="/post-your-property"
              component={() => <PostPropertyForm />}
            /> */}

          <Route
            exact
            path="/dev"
            // component={() => <PropertiesSearchResult />}
            component={() => <DevPage />}
          />

          {/* //? proflie */}
          <ProtectedRouter
            exact
            path="/my-profile"
            component={() => <Profile />}
          />

          {/* //? Account */}

          {/* //? Viewed properties */}
          <ProtectedRouter
            exact
            path="/viewed-properties"
            component={() => <ViewedProperties />}
          />

          {/* //? scheduled-tours */}
          <ProtectedRouter
            exact
            path="/scheduled-tours"
            component={() => <ScheduledTours />}
          />
          {/* //? rental-agreement */}

          {/* <ProtectedRouter
              exact
              path="/prop-history"
              component={() => <PropHistory />}
            /> */}
          <ProtectedRouter exact path="/tasks" component={() => <Task />} />

          {/* //? fav */}

          {/* //? my properties */}
          <ProtectedRouter
            exact
            path="/my-properties"
            component={() => <MyProperties />}
          />
          {/* <ProtectedRouter
              exact
              path="/closing"
              component={() => <Closing />}
            /> */}

          <Route
            exact
            path="/properties/:prop_key"
            component={({ match }) => (
              <Property propKey={match?.params?.prop_key} />
            )}
          />

          <Route
            exact
            path="/zillow-properties/:prop_key"
            component={({ match }) => (
              <ZillowPropertyV1 zpid={match?.params?.prop_key} />
            )}
          />
          <Route exact path="/search" component={() => <SearchResults />} />
          <Route exact path="/aboutCC" component={() => <CCavenue />} />

          {/* <ProtectedRouter
              exact
              path="/career"
              component={() => <Career />}
            /> */}

          {/* Agreements */}
          {/* <ProtectedRouter
              exact
              path="/rental-agreement"
              component={() => <RentalAgreement />}
            /> */}
          {/* Tenant Details*/}
          {/* <ProtectedRouter
              exact
              path="/tenant-details"
              component={() => <Tenant/>}
            /> */}
          {/* Property Details*/}
          {/* <ProtectedRouter
              exact
              path="/property-details"
              component={() => <Property/>}
            /> */}
          {/* 
               //? 404 page * // 
            */}
          {/* <Route path="*">
            <Page404 />
          </Route> */}
          <ProtectedRouter exact path="/apply" component={() => <Apply />} />
          <AgentLayout>
            {/* <AgentRoute
              path="/agent/properties/:listType"
              component={({ match }) => (
                <Properties listType={match?.params?.listType} />
              )}
            /> */}
            <AgentRoute path="/agent/property-nearby" component={PropNearby} />
            <AgentRoute path="/agent/dashboard" component={DashBoard} />
            <AgentRoute path="/agent/my-bucket" component={MyBucket} />
            <AgentRoute path="/agent/payments" component={Payments} />
            <AgentRoute path="/agent/profile" component={AgentProfile} />
          </AgentLayout>
          {/* </Navbar> */}
        </Switch>

        {/* <AgentLayout>
              <AgentRoute exat path="/agent/dashboard" component={()=><AgentDashBoard/>} />
              <AgentRoute
                path="/agent/properties/:listType"
                component={({ match }) => (
                  <Properties listType={match?.params?.listType} />
                )}
              />
            </AgentLayout> */}
      </Router>
      {/* <Router>
        <Switch>
        <AgentLayout>
              <AgentRoute path="/agent/dashboard" component={AgentDashBoard} />
              <AgentRoute
                path="/agent/properties/:listType"
                component={({ match }) => (
                  <Properties listType={match?.params?.listType} />
                )}
              />
            </AgentLayout>
        </Switch>
      </Router> */}
    </div>
  );
}
