export function numToWords(num) {
  var sglDigit = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ],
    dblDigit = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ],
    tensPlace = [
      "",
      "Ten",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ],
    handle_tens = function (dgt, prevDgt) {
      return 0 == dgt
        ? ""
        : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
    },
    handle_utlc = function (dgt, nxtDgt, denom) {
      return (
        (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") +
        (0 != nxtDgt || dgt > 0 ? " " + denom : "")
      );
    };

  var str = "",
    digitIdx = 0,
    digit = 0,
    nxtDigit = 0,
    words = [];
  if (((num += ""), isNaN(parseInt(num)))) str = "";
  else if (parseInt(num) > 0 && num.length <= 10) {
    for (digitIdx = num.length - 1; digitIdx >= 0; digitIdx--)
      switch (
        ((digit = num[digitIdx] - 0),
        (nxtDigit = digitIdx > 0 ? num[digitIdx - 1] - 0 : 0),
        num.length - digitIdx - 1)
      ) {
        case 0:
          words.push(handle_utlc(digit, nxtDigit, ""));
          break;
        case 1:
          words.push(handle_tens(digit, num[digitIdx + 1]));
          break;
        case 2:
          words.push(
            0 != digit
              ? " " +
                  sglDigit[digit] +
                  " Hundred" +
                  (0 != num[digitIdx + 1] && 0 != num[digitIdx + 2]
                    ? " and"
                    : "")
              : ""
          );
          break;
        case 3:
          words.push(handle_utlc(digit, nxtDigit, "Thousand"));
          break;
        case 4:
          words.push(handle_tens(digit, num[digitIdx + 1]));
          break;
        case 5:
          words.push(handle_utlc(digit, nxtDigit, "Lakh"));
          break;
        case 6:
          words.push(handle_tens(digit, num[digitIdx + 1]));
          break;
        case 7:
          words.push(handle_utlc(digit, nxtDigit, "Crore"));
          break;
        case 8:
          words.push(handle_tens(digit, num[digitIdx + 1]));
          break;
        case 9:
          words.push(
            0 != digit
              ? " " +
                  sglDigit[digit] +
                  " Hundred" +
                  (0 != num[digitIdx + 1] || 0 != num[digitIdx + 2]
                    ? " and"
                    : " Crore")
              : ""
          );
      }
    str = words.reverse().join("") + " Only";
  } else str = "";
  return str;
}

// ----------------------------------------------------

export function ChangeFormateDate(oldDate) {
  return oldDate.toString().split("-").reverse().join("/");
}

// ----------------------------------------------------

export function printMonth(inputDate) {
  const date = new Date(inputDate);
  const month = date.toLocaleString("default", { month: "long" });
  return month;
}

// ----------------------------------------------------

export function printDay(inputDate) {
  const d = new Date(inputDate);
  const day = d.getDate();
  return day;
}

// ----------------------------------------------------

export function printYear(inputDate) {
  const y = new Date(inputDate);
  const year = y.getFullYear();
  return year;
}

// ----------------------------------------------------

export function addMonths(date, num) {
  const dt = new Date(date);
  dt.setMonth(dt.getMonth() + num);
  const newdt = new Date(dt);
  const year = newdt.getFullYear();
  const month = String(newdt.getMonth() + 1).padStart(2, "0");
  const day = String(newdt.getDate()).padStart(2, "0");
  const joined = [day, month, year].join("/");
  return joined;
}

// ----------------------------------------------------
export function currentDay() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  const joined = [dd, mm, yyyy].join("/");
  return joined;
}

export function printDiv(divName) {
  // var originalContent = document.body.innerHTML;
  document.body.setAttribute("data-original-content", document.body.innerHTML);
  var printContents = document.getElementById(divName).innerHTML;
  var originalContents = document.body.innerHTML;
  document.body.innerHTML = printContents;
  window.print();
  // document.body.innerHTML = originalContents;
  console.log("clicked", document.body.getAttribute("data-original-content"));
  setTimeout(() => {
    document.body.innerHTML = document.body.getAttribute(
      "data-original-content"
    );
  }, 1000);
}

export function printDivNew(divName) {
  var divToPrint = document.getElementById(divName);
  var newWin = window.open("", "_blank");
  newWin.document.open();
  newWin.document.write(
    `<html>
    <head>
    <style>
    
    ol li{list-style: numeric; padding-bottom: 10px;}
    .prev-header, .center-text{text-align: center; margin: 10px 0px;}
    .agree-whitespace{display: block !important; margin-top: 600px;}
    .stamp-header, .agree-header, .agree-left, .agree-btn-container {display: none;} 
    @media print{
      @page{margin-top: 0; margin-bottom: 0;} 
      body{margin-top: 1.6cm; margin-bottom: 1.6cm;}
    }
    </style>
    </head>
    <body onload="window.print()">` +
      divToPrint.innerHTML +
      "</body></html>"
  );
  newWin.document.close();
  setTimeout(function () {
    newWin.close();
  }, 5000);
}

//--------------------------------------------------------
  // Calculate the distance between two locations using the Haversine formula

export function calculateDistance(location1, location2) {
  const earthRadius = 6371; // in km
  const dLat = toRadians(location2.lat - location1.lat);
  const dLon = toRadians(location2.lon - location1.lon);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(location1.lat)) *
      Math.cos(toRadians(location2.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c;
  return distance;
}

export function toRadians(degrees) {
  return degrees * (Math.PI / 180);
}

// -------------------------------------------------------------
// Open Gmap in browser

export function openMap(latitude, longitude) {
  const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
  window.open(url, '_blank');
}
