const getInitialsFormUserAttr = (userAttr) => {
  let fn = userAttr?.["custom:first_name"] || "";
  let ln = userAttr?.["custom:last_name"] || "";
  let name: any = fn + " " + ln;
  //   let name: any = "sda ug we";
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  let initials = [...name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();
  return initials;
};
export { getInitialsFormUserAttr };
