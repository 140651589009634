import React from "react";
import { Layout } from "../../../../layouts";
import EditForm from "./editForm";
import Footer from "../../../../layouts/Footer";
function Index() {
  return (
    <div>
      <Layout />
      <EditForm />
      <Footer />
    </div>
  );
}

export default Index;
