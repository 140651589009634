import { useState } from "react";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text-dark">
      {isReadMore ? text?.slice(0, 150) : text}
      <span
        onClick={toggleReadMore}
        className="read-or-hide text-muted cursor-p"
      >
        {isReadMore ? "...read more" : " show less"}
      </span>
    </p>
  );
};
export default ReadMore;
