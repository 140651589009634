import React from "react";
import { Button, Form } from "react-bootstrap";
import "./style.css";
import { useState } from "react";
import { useEffect } from "react";
import Logo from "../../../assets/brandlogo.png";
import { useHistory } from "react-router-dom";
import { aadhaar_regex } from "./regex";
import { getOtp } from "../../../api/Aadhaar/getOtp";
import { submitOtp } from "../../../api/Aadhaar/submitOtp";
import { useAuth } from "../../../hooks";
import Footer from "../../../layouts/Footer";
import { validate } from "../../../utils/aadhaarValidate";


export default function Index() {
  const InitialValues = {
    aadhaar_number: "",
  };
  const auth = useAuth();
  const [disabled, setDisabled] = useState(true);
  const [registration, setRegistration] = useState(InitialValues);
  const [otp, setOtp] = useState<string>();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [aadhaar, setAadhaar] = useState<any>({});
  const [otpbox, setOtpbox] = useState(false);
  const [form, setForm] = useState(true);
  const [otpres, setOtpres] = useState<any>();
  const history = useHistory();
  const onValueChange = (key: any) => (value: any) => {
    setRegistration({ ...registration, [key]: value });
  };
  const validateAadhaar = (aadhaar) => {
    const isValid = validate(aadhaar); // Use your custom validate function
    return isValid;
  };
  const validation = () => {
    return Boolean(isSubscribed && validateAadhaar(aadhaar) );
  };
  useEffect(() => {
    setDisabled(!validation());
  }, [isSubscribed, aadhaar]);

  const submitRegistration = async () => {
    const temp = registration;
    console.log("aadhaar data", temp);

    await getOtp(temp)
      .then(([status, res]) => {
        if (status === 200) {
          alert("OTP Sent Successful");
          console.log(res, "otp response");
          setOtpres(res);
          setOtpbox(true);
          setForm(false);
        } else {
          alert("Something went wrong");
        }
      })

      .catch((err) => console.log(err));
  };

  const handleOtpSubmit = async () => {
    await submitOtp({
      client_id: otpres?.data?.client_id,
      otp: otp,
      user_key: auth?.userInfo?.user_key,
    })
      .then(([status, res]) => {
        if (status === 200) {
          alert("Aadhaar Verified Successfully");
          auth.fetchUser();
          history.push("/");
        } else {
          alert("Something went wrong");
        }
      })

      .catch((err) => console.log(err));
  };

  return (
    <>
      <div>
        <div
          className="nav-bar-aadhaar"
          style={{ backgroundColor: "#FF8504 " }}
        >
          <img
            className="brand-logo-lay"
            src={Logo}
            alt="truprops"
            style={{
              height: "70px",
              cursor: "pointer",
            }}
            onClick={() => history.push("/")}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center aadhaar p-3 fw-bold">
        Aadhaar Verification
      </div>
      {form == true ? (
        <div className="d-flex justify-content-center align-items-center ">
          <div className="form-container d-flex flex-column justify-content-center align-items-center">
            <Form className="d-flex flex-row justify-content-center ">
              <div className=" d-flex flex-column gap-2  align-items-center  justify-content-center  ">
                <Form.Label className="fw-bold aadhar-head ">Aadhaar Number:</Form.Label>
                <input
                  onChange={(e) => {
                    onValueChange("aadhaar_number")(e?.target?.value);
                    setAadhaar(e?.target?.value);
                  }}
                  type="number"
                  placeholder="Enter Your Aadhaar Number"
                  className=" inventory-input"
                ></input>
                <div className="  input-otp  " >
                  <div className="d-flex flex-column justify-content-center align-items-center receive-otp-checkbox  ">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="valueCheckbox"
                      onChange={(_) => {
                        onValueChange(setIsSubscribed(Boolean(_.target.checked)));
                        console.log(isSubscribed);
                      }}
                    />
                  </div>
                  <div className="col p-2 ">
                    Do you wish to receive OTP
                  </div>
                </div>

                <Button
                  onClick={() => submitRegistration()}
                  // onClick={() => console.log("registeed data", registration)}
                  disabled={disabled}
                  className="reg-btn d-flex align-items-center justify-content-center mt-2 mx-auto"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      ) : null}

      {otpbox == true ? (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="aadhaar d-felx flex-row p-3 fw-bold">Submit OTP</div>
          <div className="form-container d-flex flex-column justify-content-center">
            <Form className="d-flex justify-content-center gap-5 ">
              <div className=" d-flex flex-column gap-2 col-sm-4 p-3">
                <Form.Label className="fw-bold">One Time Password</Form.Label>
                <input
                  onChange={(e) => {
                    setOtp(e?.target?.value);
                  }}
                  placeholder="Enter Your OTP"
                  className="form-control inventory-input"
                ></input>
              </div>
            </Form>
            <button
              onClick={handleOtpSubmit}
              className="otp-btn d-flex flex-column align-items-center justify-content-center mx-auto "
            >
              Submit OTP
            </button>
          </div>
        </div>
      ) : null}
      <div className="footer-aadhaar">
        <Footer />
      </div>
    </>
  );
}
