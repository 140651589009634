import React, { useContext, useState, useEffect } from "react";
import "./style.css";
import { useHistory } from "react-router-dom";
import { ProfileDropDown } from "../../components/Dropdown";
import { AuthContext } from "../../context/AuthContext";
import MailIcon from "../../assets/svg/mailicon";
import AlertIcon from "../../assets/svg/alertIcon";
import Dropdown from "../../components/Dropdown/Profile/V1";
import logo from "../../assets/brandlogo.png";
import SearchBar from "../../pages/Home/Components/SearchBar";

// export default function Index() {
export default function Index() {
  const history = useHistory();
  const { userAttr, signOut, isAuthenticated, userInfo } =
  useContext(AuthContext);
  const [showNav, setShowNav] = useState(false);
  const togglenav = () => setShowNav(!showNav);
  const [isAgent, setIsAgent] = useState(false);
  useEffect(() => {
    setIsAgent(userInfo?.is_agent);
  }, [userInfo]);

  const agentOptions = [
    {
      label: "Agent Dashboard",
      to: "/agent/dashboard",
    },
  ];

  const commonOptions = [
    // {
    //   label: "Dashboard",
    //   to: "/account/dashboard",
    // },
    {
      label: "Favorites",
      to: "/my-favorites",
    },
    {
      label: "Apply",
      to: "/Apply",
    },
    {
      label: "My Listing",
      to: "/account/my-listing",
    },

    {
      label: "List Property",
      to: "/post-your-property"
    },
  ];

  return (
    <main className="">
      <nav className="navbar navbar-expand-lg navbar-top-common1">
        <div className="container-fluid">
          <div
            id="nav-logo"
            className="f-b text-light ms-2 cursor-p"
            onClick={() => history?.push("/")}
          >
            <img src={logo} alt="" width="150px" />
          </div>
          <div className="d-flex agent-name2 ">
            <span className="agent-welcome1 ">Welcome,</span>
            <div className="agent-welcome1 ms-2">
              {userAttr ? (
                <span>
                  {" "}
                  {userAttr?.["custom:first_name"] +
                    " " +
                    userAttr?.["custom:last_name"]}
                </span>
              ) : null}
            </div>
          </div>
          <ul className="agent-nav-links1">
            <div className="agent-hamburger1" onClick={togglenav}>
              &#9776;
            </div>
            <div
              className="agent-menu1 mt-2"
              style={{ display: showNav ? "block" : "" }}
            >
                  {/* <li className="mt-2 agent-welcome1">
                    <MailIcon />
                  </li>
                  <li className="mt-2 agent-welcome1">
                    <AlertIcon />
                  </li> */}

              <li>
                {isAuthenticated ? (
                  <div>
                    <div className="prof-drop">
                    <ProfileDropDown />
                    </div>
                    <div className="prof-drop-mob">
                    <div className="profile-dropdown-menu-container">
              {isAgent
                ? agentOptions?.map((opt) => (
                    <div
                      className=" highlight-hover "
                      onClick={() => history.push(opt?.to)}
                    >
                      {opt?.label}
                    </div>
                  ))
                : null}
              {commonOptions?.map((opt) => (
                <div
                  className=" highlight-hover"
                  onClick={() => history.push(opt?.to)}
                >
                  {opt?.label}
                </div>
              ))}
            </div>
            <div
                className=" d-flex justify-content-center"
                onClick={signOut}
              >
                Sign out
              </div>
                    </div>

                  </div>
                ) : (
                  <button
                    id="nav-dropdown"
                    className="re-navbar-menu light-bg no-border"
                    onClick={() => history.push("/logIn")}
                  >
                    Log In
                  </button>
                )}
              </li>
            </div>
          </ul>
        </div>
      </nav>
    </main>
  );
}
