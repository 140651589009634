const parseMapboxApiResponse =  (response) => {
  const getBounds = () => ({
    swlat: response?.bbox[1],
    swlon: response?.bbox[0],
    nelat: response?.bbox[3],
    nelon: response?.bbox[2]
  });
  const [lon, lat] = response?.center;
  console.log(lat,lon)
  return {
    id: response?.id,
    map_bounds: getBounds(),
    center: [lat, lon]?.join(","),
    text: response?.text,
    placeName: response?.place_name,
    lat,
    lon
  };
};
export { parseMapboxApiResponse };
