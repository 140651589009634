import React from "react";
import { Button1 } from "../../../../components/Buttons";
import "./style.css";
import {useHistory } from "react-router-dom"

export default function Index() {
  const history = useHistory();
  return (
    <>
    <div className="guidelines">
      <div className=" question">
        <svg
          width="250"
          height="250"
          viewBox="0 0 310 310"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M155 0C69.44 0 0 69.44 0 155C0 240.56 69.44 310 155 310C240.56 310 310 240.56 310 155C310 69.44 240.56 0 155 0ZM170.5 263.5H139.5V232.5H170.5V263.5ZM202.585 143.375L188.635 157.635C177.475 168.95 170.5 178.25 170.5 201.5H139.5V193.75C139.5 176.7 146.475 161.2 157.635 149.885L176.855 130.355C182.59 124.775 186 117.025 186 108.5C186 91.45 172.05 77.5 155 77.5C137.95 77.5 124 91.45 124 108.5H93C93 74.245 120.745 46.5 155 46.5C189.255 46.5 217 74.245 217 108.5C217 122.14 211.42 134.54 202.585 143.375Z"
            fill="#212121"
          />
        </svg>
      </div>
     
      <div className="listing_guidelines">
        <div className="guide-title">Hard time on <br />List or Find properties online?</div>
        <Button1
        onClick={() => {history.push("/guidelines")}}
        label="Truprops Guidelines"
        name="home-seller-guidelines"
      />
        {/* <div className="button mt-5"> */}

        {/* </div> */}
        <div></div>
      </div>
    </div>
    </>
  );
}
