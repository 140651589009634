import React from "react";
import "./style.css";
import SaleIcon from "../../../../../assets/svg/sale";
import RentIcon from "../../../../../assets/svg/rent";
import View from "../../../../../assets/svg/view";
import {
  Location,
  Task,
  Earnings,
  Profile,
} from "../../../../../assets/AgentDashboard";
import { useLocation, useHistory } from "react-router-dom";

export default function Index({ agentData, bucketData, isLoaded }) {
  console.log(bucketData)
  const AgentCard = [
    {
      title: "My Profile",
      content1: `Agent ID: ${agentData.agent_id}`,
      content2: `Limit Available: ${agentData.property_limit}`,
      url: "/agent/profile",
      icon: <Profile />,
    },
    {
      title: "My Bucket",
      content1: `Total Pending Properties: ${bucketData}`,
      content2: `Total Approved: ${agentData.no_approved_property}` ,
      url: "/agent/my-bucket",
      icon: <Task />,
    },
    {
      title: "My Locality",
      content1: "Total Property Nearby: ",
      content2: "Total Property: ",
      url: "/agent/property-nearby",
      icon: <Location />,
    },
    {
      title: "My Earnings",
      content1: `Total Approved: ${agentData.no_approved_property}`,
      content2: `Total Earnings: ${agentData.no_approved_property*150}`,
      url: "/agent/payments",
      icon: <Earnings />,
    },
  ];
  const history = useHistory();
  const variants = ["#939393", "#f69736", "#6c6cfc", "#31b57d"];
  return (
    <>
      {AgentCard.map((data, i) => (
        <div
          className="dashboard-card"
          style={{ backgroundColor: variants[i] }}
        >
          <div className="dashboard-card-title">
            {data.title}
            <span className="adc-icons">{data.icon}</span>
          </div>
          <div className="content">
            <p>{data?.content1}</p>
            <p>{data?.content2}</p>
          </div>
          <div
            className="view-all-btn cursor-p"
            onClick={() => history.push(data?.url)}
          >
            View All{" "}
            <span>
              <View />
            </span>
          </div>
        </div>
      ))}
    </>
  );
}
