import React from 'react'

const view = () => {
  return (
    <>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.9993 22.3332L22.3327 15.9998L15.9993 9.6665L13.7827 11.8832L16.316 14.4165H9.66602V17.5832H16.316L13.7827 20.1165L15.9993 22.3332ZM15.9993 31.8332C13.8091 31.8332 11.7507 31.4173 9.82435 30.5855C7.89796 29.7548 6.22227 28.6269 4.79727 27.2019C3.37227 25.7769 2.2444 24.1012 1.41368 22.1748C0.581905 20.2484 0.166016 18.1901 0.166016 15.9998C0.166016 13.8096 0.581905 11.7512 1.41368 9.82484C2.2444 7.89845 3.37227 6.22275 4.79727 4.79775C6.22227 3.37275 7.89796 2.24436 9.82435 1.41259C11.7507 0.581865 13.8091 0.166504 15.9993 0.166504C18.1896 0.166504 20.248 0.581865 22.1743 1.41259C24.1007 2.24436 25.7764 3.37275 27.2014 4.79775C28.6264 6.22275 29.7543 7.89845 30.585 9.82484C31.4168 11.7512 31.8327 13.8096 31.8327 15.9998C31.8327 18.1901 31.4168 20.2484 30.585 22.1748C29.7543 24.1012 28.6264 25.7769 27.2014 27.2019C25.7764 28.6269 24.1007 29.7548 22.1743 30.5855C20.248 31.4173 18.1896 31.8332 15.9993 31.8332ZM15.9993 28.6665C19.5355 28.6665 22.5306 27.4394 24.9848 24.9853C27.4389 22.5311 28.666 19.5359 28.666 15.9998C28.666 12.4637 27.4389 9.46859 24.9848 7.01442C22.5306 4.56025 19.5355 3.33317 15.9993 3.33317C12.4632 3.33317 9.4681 4.56025 7.01393 7.01442C4.55977 9.46859 3.33268 12.4637 3.33268 15.9998C3.33268 19.5359 4.55977 22.5311 7.01393 24.9853C9.4681 27.4394 12.4632 28.6665 15.9993 28.6665Z" fill="white"/>
</svg>

    </>
  )
}

export default view