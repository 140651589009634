import React from "react";
import { Modal, Button } from "react-bootstrap";
interface ContactDetailModalProps {
  props: any
  show: boolean;
  handleClose: () => void;
}

const Index = (data: ContactDetailModalProps) => {
  return (
    <div>
      <Modal show={data?.show} onHide={data?.handleClose} aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header closeButton>
          <Modal.Title>Contact Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="contact_container">
            { data?.props?.statuscode ? <div>{data?.props?.response}</div> : (<>
              <div>Name: {data?.props?.name}</div>
              <div>Phone Number: {data?.props?.phone_number}</div>
              <div>Email: <a href={`mailto:${data?.props?.email}`} className="text-dark">{data?.props?.email}</a></div></>
            )
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={data?.handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Index;
