import React, { useRef } from "react";
import { API_URL } from "../const";
// import S3 from "react-aws-s3";

// function Upload({ file, newFileNameWithPath, onUpload }) {
//   const config = {
//     // dirName: process.env.REACT_APP_DIR_NAME /* optional */,
//     bucketName: process.env.REACT_APP_BUCKET_NAME,
//     region: process.env.REACT_APP_REGION,
//     accessKeyId: process.env.REACT_APP_ACCESS_ID,
//     secretAccessKey: process.env.REACT_APP_ACCESS_KEY
//   };
//   const ReactS3Client = new S3(config);
//   ReactS3Client.uploadFile(file, newFileNameWithPath).then((data) => {
//     console.log(data);
//     if (data.status === 204) {
//       console.log("success");
//     } else {
//       console.log("fail");
//     }
//   });
//   // const handleClick = (event) => {
//   //   // event.preventDefault();
//   //   // let file = fileInput.current.files[0];
//   //   // let newFileName = fileInput.current.files[0].name.replace(/\..+$/, "");

//   // };
// }

// export default Upload;

// export const uploadImage = async (newFileName, image, path = "") => {
//   const getFileExtension = (fileName) => {
//     const nameList = fileName?.split(".");
//     return nameList[nameList?.length - 1];
//   };
//   const fileName = `${newFileName}.${getFileExtension(image?.name)}`;
//   const contentType = image?.type;
//   const a = await fetch(`${API_URL}/getSignedURL`, {
//     method: "POST",
//     mode: "cors",
//     body: JSON.stringify({
//       file_name: newFileName,
//       content_type: contentType,
//       extension: getFileExtension(image?.name),
//       path: path
//     })
//   })
//     .then((res) => res.json())
//     .then(async (response) => {
//       const bucketUrl = response.url;
//       const headers = new Headers();
//       let res = await fetch(bucketUrl, {
//         method: "PUT",
//         body: image,
//         headers: {
//           ...headers,
//           "Content-Type": contentType
//         }
//       })
//         .then(async (res) => {
//           if (res.status === 200) {
//             console.log(`image upload success ${res.status} - ${res}`);
//           } else {
//             alert("Failed");
//             console.log(
//               `error while uploading file ${res.status} - ${res.statusText}`
//             );
//           }
//           return res.status;
//         })
//         .catch((err) => {
//           console.log(err);
//           console.log(`error on bucket url ${err.status} - ${err.statusText}`);
//         });
//       if (res === 200) return response?.fileUrl;
//     })
//     .catch((err) => console.log(err));
//   return a;
// };

export const uploadImage = async (newFileName, image, path = "") => {
  try {
    const getFileExtension = (fileName) => {
      const nameList = fileName?.split(".");
      return nameList[nameList?.length - 1];
    };
    const fileName = `${newFileName}.${getFileExtension(image?.name)}`;
    const contentType = image?.type;
    const signedURLJSON = await fetch(`${API_URL}/getSignedURL`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        file_name: newFileName,
        content_type: contentType,
        extension: getFileExtension(image?.name),
        path: path,
      }),
    });
    const signedURL = await signedURLJSON.json();
    console.log("Signed URL from backend",signedURL)
    const headers = new Headers();
    const imageUploadJSON = await fetch(signedURL.url, {
      method: "PUT",
      body: image,
      headers: {
        ...headers,
        "Content-Type": contentType,
      },
    });
    if (imageUploadJSON.status === 200) {
      console.log(`image upload success ${imageUploadJSON.status} - ${imageUploadJSON}`);
      return signedURL.fileUrl
    } else {
      alert("Failed");
      console.log(
        `error while uploading file ${imageUploadJSON.status} - ${imageUploadJSON.statusText}`
      );

    }
  } catch (error) {
    return error.msg;
  }
};
