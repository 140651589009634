import React from 'react'

const whatsapp = () => {
  return (
    <>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_349_38)">
<path d="M25.0063 0H24.9938C11.2094 0 3.28029e-05 11.2125 3.28029e-05 25C-0.00852688 30.2657 1.65831 35.3975 4.75941 39.6531L1.64378 48.9406L11.2532 45.8688C15.3293 48.573 20.1146 50.0105 25.0063 50C38.7907 50 50 38.7844 50 25C50 11.2156 38.7907 0 25.0063 0V0Z" fill="#4CAF50"/>
<path d="M39.5531 35.3031C38.95 37.0062 36.5562 38.4187 34.6468 38.8312C33.3406 39.1093 31.6343 39.3312 25.8906 36.95C18.5437 33.9062 13.8125 26.4406 13.4437 25.9562C13.0906 25.4718 10.475 22.0031 10.475 18.4156C10.475 14.8281 12.2969 13.0812 13.0312 12.3312C13.6344 11.7156 14.6312 11.4343 15.5875 11.4343C15.8969 11.4343 16.175 11.45 16.425 11.4625C17.1594 11.4937 17.5281 11.5375 18.0125 12.6968C18.6156 14.15 20.0843 17.7375 20.2593 18.1062C20.4375 18.475 20.6156 18.975 20.3656 19.4593C20.1312 19.9593 19.925 20.1812 19.5562 20.6062C19.1875 21.0312 18.8375 21.3562 18.4687 21.8125C18.1312 22.2093 17.75 22.6343 18.175 23.3687C18.6 24.0875 20.0687 26.4843 22.2312 28.4093C25.0219 30.8937 27.2843 31.6875 28.0937 32.025C28.6968 32.275 29.4156 32.2156 29.8562 31.7468C30.4156 31.1437 31.1062 30.1437 31.8093 29.1593C32.3093 28.4531 32.9406 28.3656 33.6031 28.6156C34.2781 28.85 37.85 30.6156 38.5844 30.9812C39.3187 31.35 39.8031 31.525 39.9812 31.8343C40.1562 32.1437 40.1562 33.5968 39.5531 35.3031V35.3031Z" fill="#FAFAFA"/>
</g>
<defs>
<clipPath id="clip0_349_38">
<rect width="50" height="50" fill="white"/>
</clipPath>
</defs>
</svg>

    </>
  )
}

export default whatsapp