import React, { useEffect, useState } from "react";
import { Button1 } from "../../../../components/Buttons";
import { Card } from "./styledComp";
import verified from "../../../../assets/svg/verified.svg";
import { Heart, Share } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ShareModal from "../../../../components/Share";
import { reverseGeoode } from "../../../../helpers/reverseGeoCode";

export default function Index({
  data,
  isAgent = false,
  onMouseLeave = (e) => {},
  onMouseEnter = (e) => {},
}) {
  const history = useHistory();
  const [socialModal, setSocialModal] = useState(false);
  const modalShow = () => setSocialModal(true);
  const modalClose = () => setSocialModal(false);
  const [placeName, setPlaceName] = useState()
  const reverseGeocoding = async()=>{
    setPlaceName(await reverseGeoode(data?.lat,data?.lon)); 
  }
  useEffect( () => {
    reverseGeocoding();
  }, [data])
  

  return (
    <Card
      onMouseEnter={(e) => onMouseEnter(data?.property_key)}
      onMouseLeave={(e) => onMouseLeave(data?.property_key)}
      // onMouseOver={(e) => console.log(e?.target?.className)}
    >
      <div className="prop-card">
        <div
          className="image-section"
          onClick={() => history.push(`/properties/${data?.property_key}`)}
        >
          <div className="image-details">
            <div className="badges">
              {data?.is_new ? (
                <div className="new shadow-preset-1">NEW</div>
              ) : null}
            </div>

            <div className="prop-image-container ">
              <img src={data?.img_src} alt="prop" className="shadow-preset-1" />
              {data?.is_approved ? (
                <div className="verified">
                  <img src={verified} alt="verified" />
                </div>
              ) : null}
            </div>
          </div>

          {data?.images && data?.images?.length > 0 ? (
            <div className="more-images-container">
              <div className="more-images">
                <div className="more-image"></div>
                <div className="more-image"></div>
                <div className="more-image"></div>
              </div>
              <div className="view-more">View More {">"}</div>
            </div>
          ) : null}
        </div>

        <div className="prop-card-right">
          <div className="area-and-price">
            <div className="price">
              {data?.currency_symbol || '\u20B9'} {data?.price.toLocaleString("en-IN")}
            </div>

            <div className="area">{data?.built_area} Sq.ft</div>
            <div className="prop-action-btns">
              <Share
                onClick={modalShow}
                className="action-btn primary-text m-1"
                size={15}
              />
              <Modal
                show={socialModal}
                onHide={modalClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Share Property Via
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ textAlign: "center" }}>
                  <ShareModal propData={data} />
                </Modal.Body>
              </Modal>
            </div>
          </div>
          <div className="type-and-location">
            <div className="type">
              {data?.bed == 0 ? data?.property_type_name : (`${data?.bed} BHK ${data?.property_type_name}`)} 
            </div>

            <div className="property-location">
              {placeName}
            </div>
          </div>
          <div className="configs">
            {[
              data?.bed ? data?.bed + " Beds " : null,
              data?.bath ? data?.bath + " Baths " : null,
            ].join(" | ")}
          </div>
          <div className="prop-desc">
            {data?.description?.slice(0, 40)}.....
          </div>
          <div className="prop-card-btns">
            <a
              onClick={() => history.push(`/properties/${data?.property_key}`)}
            >
              View
            </a>
          </div>
          <div className="listing-type-card-prop">{data?.listed_for}</div>
        </div>
      </div>
    </Card>
  );
}

{
  /* <Card
      onMouseEnter={(e) => onMouseEnter(data?.property_key)}
      onMouseLeave={(e) => onMouseLeave(data?.property_key)}

    >
      <div className="image-section" onClick={() => history.push(`/properties/${data?.property_key}`)}>
        <div className="image-details">
          <div className="badges">
            {data?.is_new ? (
              <div className="new shadow-preset-1">NEW</div>
            ) : null}
            {data?.is_verified ? (
              <div className="verified">
                <img src={verified} alt="verified" />
              </div>
            ) : null}
          </div>

          <div className="image-container ">
            <img src={data?.img_src} alt="prop" className="shadow-preset-1" />
          </div>
        </div>

        {data?.images && data?.images?.length > 0 ? (
          <div className="more-images-container">
            <div className="more-images">
              <div className="more-image"></div>
              <div className="more-image"></div>
              <div className="more-image"></div>
            </div>
            <div className="view-more">View More {">"}</div>
          </div>
        ) : null}
      </div>

      <div className="content-container">
        <div className="content">
          <div className="details" onClick={() => history.push(`/properties/${data?.property_key}`)}>
            <div className="area-and-price">
              <div className="price">
                {data?.currency_symbol || "Rs."} {data?.price}
              </div>

              <div className="area">{data?.built_area} Sq.ft</div>
            </div>

            <div className="type-and-location">
              <div className="type">{data?.bed} BHK</div>

              <div className="location">{data?.title}</div>
            </div>

            <div className="configs">

              {[
                data?.bed ? data?.bed + " Beds " : null,
                data?.bath ? data?.bath + " Baths " : null,
              ].join(" | ")}
            </div>
          </div>
          <div className="action-btns">
          <Share onClick= {modalShow} className="action-btn primary-text m-1" size={15} />
                <Modal
      show={socialModal}
      onHide={modalClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Share Property Via
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{textAlign:"center"}}>
       <ShareModal propData={data}/>
      </Modal.Body>
    </Modal>
            <Heart className="primary-text m-1" size={24} />
          </div>
        </div>

        <div className="btn-container">
          <Button1
            onClick={() => history.push('/pricing')}
            name="card-contact-btn"
            label="Contact"
            variant="success"
            enableHover
          />
        </div>
      </div>
    </Card> */
}
