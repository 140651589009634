import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  FloatingLabel,
  InputGroup,
  DropdownButton,
  Dropdown,
  FormControl,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ChipV1 } from "../../../../../components";
import { pricesIn } from "../../../data";
import "./style.css";
import { numToWords } from "../../../../../utils";
interface Rent {
  maintenance_charge: string;
  price: string;
  expec_deposit: string;
  pref_tenant: string;
  parking: string;
  currency_id: string;
  has_pool: string;
  is_pet_allowed: boolean;
  non_veg: boolean;
  water_supply: boolean;
  gym: boolean;
  gated_security: boolean;
}

export default function Index({
  register,
  errors,
  handleFormData,
  control,
  propertyType,
  watch
}) {
  const chipList = {
    prefTenantList: [
      {
        label: "Family",
        value: "FAMILY",
      },
      {
        label: "Bachelors",
        value: "BACHELORS",
      },
      {
        label: "Doesn't Matter",
        value: "ANY",
      },
    ],

    propOption: [
      {
        label: "Yes",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
    pricesIn: pricesIn,
  };

  return (
    <div>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">Prices in</Form.Label>
            <span className="text-danger">*</span>
            <Controller
              control={control}
              name="currency_id"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.pricesIn}
                  value={value}
                />
              )}
              rules={{required: true}}
            />
            {errors?.currency_id?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Expected Rent
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="formdata"
              type="number"
              name="price"
              placeholder="Enter the amount..."
              {...register("price", {
                required: true,
              })}
            />
            {numToWords(watch("price"))}
            {errors?.price?.type === "required" && (
              <p>This field is required</p>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Maintenance Charge
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="formdata"
              type="number"
              name="maintenance_charge"
              placeholder="Enter the amount..."
              {...register("maintenance_charge", {
                required: true,
              })}
            />
            {numToWords(watch("maintenance_charge"))}
            {errors?.maintenance_charge?.type === "required" && (
              <p>This field is required</p>
            )}
          </Form.Group>
        </Col>

        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Expected Deposit
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="formdata"
              type="number"
              name="expec_deposit"
              placeholder="Enter the amount..."
              {...register("expec_deposit", {
                required: true,
              })}
            />
            {numToWords(watch("expec_deposit"))}
            {errors?.expec_deposit?.type === "required" && (
              <p>This field is required</p>
            )}
          </Form.Group>
        </Col>
      </Row>

      {["House", "Apartment"].includes(propertyType) && (
        <div>
          <Row>
            <Col>
              <Form.Group className="mb-3 ">
                {/* //preferred tenant */}
                <Form.Label className="lable">Preferred Tenants
                <span className="text-danger">*</span></Form.Label>
                <Controller
                  control={control}
                  name="pref_tenant"
                  render={({ field: { onChange, value } }) => (
                    <ChipV1
                      onClick={(e) => {
                        onChange(e);
                      }}
                      chipList={chipList?.prefTenantList}
                      value={value}
                    />
                  )}
              rules={{required: true}}
                />

                {errors?.pref_tenant?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>

            <Col>
              <Form.Group className="mb-3 ">
                {/* //preferred tenant */}
                <Form.Label className="lable">Is Pet Allowed?
                <span className="text-danger">*</span></Form.Label>
                <Controller
                  control={control}
                  name="is_pet_allowed"
                  render={({ field: { onChange, value } }) => (
                    <ChipV1
                      onClick={(e) => {
                        onChange(e);
                      }}
                      chipList={chipList?.propOption}
                      value={value}
                    />
                  )}
              rules={{required: true}}
                />

                {errors?.is_pet_allowed?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3 ">
                {/* //preferred tenant */}
                <Form.Label className="lable">Non-Veg Allowed?
                <span className="text-danger">*</span></Form.Label>
                <Controller
                  control={control}
                  name="non_veg"
                  render={({ field: { onChange, value } }) => (
                    <ChipV1
                      onClick={(e) => {
                        onChange(e);
                      }}
                      chipList={chipList?.propOption}
                      value={value}
                    />
                  )}
                  rules={{required: true}}
                />

                {errors?.non_veg?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
          <Col>
              <Form.Group className="mb-3 ">
                {/* //preferred tenant */}
                <Form.Label className="lable">Water Supply
                <span className="text-danger">*</span></Form.Label>
                <Controller
                  control={control}
                  name="water_supply"
                  render={({ field: { onChange, value } }) => (
                    <ChipV1
                      onClick={(e) => {
                        onChange(e);
                      }}
                      chipList={chipList?.propOption}
                      value={value}
                    />
                  )}
              rules={{required: true}}
                />

                {errors?.water_supply?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3 ">
                {/* //preferred tenant */}
                <Form.Label className="lable">Gated Security
                <span className="text-danger">*</span></Form.Label>
                <Controller
                  control={control}
                  name="gated_security"
                  render={({ field: { onChange, value } }) => (
                    <ChipV1
                      onClick={(e) => {
                        onChange(e);
                      }}
                      chipList={chipList?.propOption}
                      value={value}
                    />
                  )}
              rules={{required: true}}
                />

                {errors?.gated_security?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )}
              </Form.Group>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
