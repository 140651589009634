import React from 'react'
import doc from './doc'
import agreementInput from './input'

function Index() {
  return (
    <div>
      {doc(agreementInput)}
    </div>
  )
}

export default Index