import React, { useState } from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import Pool from "./UserPool";
import "./style.css";
import { useHistory } from "react-router-dom";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import backArrow from "../../assets/arrow_left_circle.png";
import loginVecotor from "../../assets/loginPageVector.png";

const Index = () => {
  const [stage, setStage] = useState(1); // 1 = email stage, 2 = code stage
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };
  const getUser = () => {
    return new CognitoUser({
      Username: email.toLowerCase(),
      Pool,
    });
  };

  const sendCode = (event) => {
    event.preventDefault();

    getUser().forgotPassword({
      onSuccess: () => {
        setMessage("Code sent successfully!");
      },
      onFailure: () => {
        setError("Failed to send code. Please try again.");
      },
      inputVerificationCode: () => {
        setMessage("Please enter the verification code.");
        setStage(2);
        setError("")
        setMessage("")
      },
    });
  };

  const resetPassword = (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords are not the same");
      return;
    }

    getUser().confirmPassword(code, password, {
      onSuccess: (data) => {
        setMessage("Password reset successfully!");
        alert("Password reset successfully!")
        history.push("/login")
      },
      onFailure: (err) => {
        setError("Failed to reset password. Please try again.");
      },
    });
  };

  return (
    <div className="otp-input-container">
      <div className="">
        <div><img className="" onClick={() => history.goBack()}
          style={{ cursor: "pointer" }} src={backArrow} /></div>

        {stage === 1 && (
          <div className="d-flex flex-column border h-50 forget-password justify-content-center align-items-center">
            <div className="d-flex align-items-center justify-content-center m-3 login-index">
              Forget Password
            </div>
            {/* <p className="action-command">Enter your Email address</p> */}
            {/* <div className="forget-form"> */}
            <div>{message && <p className="">{message}</p>}{error && <p className="text-danger">{error}</p>}</div>
            <form onSubmit={sendCode} className="forget-form">
              <input
                className="forget-input"
                // type="email"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                  validateEmail(event.target.value);
                }}
                placeholder="Enter your Email address"

              />
              {emailError && <p className="text-danger">{emailError}</p>}
              <button type="submit" className="btn-login">
                Send verification code
              </button>
            </form>
            {/* </div> */}
            <div className="login-image">
              <img className="login-vector mt-2" src={loginVecotor} alt="" />
            </div>
          </div>
        )}

        {stage === 2 && (
          <div className="d-flex flex-column border h-75 forget-password justify-content-center align-items-center">
            <div className="d-flex align-items-center justify-content-center m-3 login-index">
              Reset Password
            </div>
            <div>{message && <p className="">{message}</p>}{error && <p className="text-danger">{error}</p>}</div>
            <form onSubmit={resetPassword} className="forget-form">
              <input
                className="forget-input"
                value={code}
                onChange={(event) => setCode(event.target.value)}
                placeholder="Enter your OTP"
                required
              />
              <div className="d-flex">
                <input
                  className="forget-input flex-column"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  placeholder="Enter your New Password"
                />
                <div
                  className="ms-1 flex-column"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Eye /> : <EyeSlash />}
                </div>
              </div>
              <div className="d-flex">
                <input
                  className="forget-input"
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  placeholder="Confirm your Password"
                />
                <div
                  className="ms-1 flex-column"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Eye /> : <EyeSlash />}
                </div>
              </div>
              <button type="submit" className="btn-login">
                Change password
              </button>
            </form>
            <div className="login-image">
              <img className="login-vector mt-2" src={loginVecotor} alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
