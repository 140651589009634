import React from "react";

const twiter = () => {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_169_7)">
          <path
            d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
            fill="white"
          />
          <path
            d="M30 13.9506C29.2483 14.2767 28.4534 14.4923 27.64 14.5906C28.4982 14.0779 29.1413 13.2714 29.45 12.3206C28.6436 12.8007 27.7608 13.1389 26.84 13.3206C26.2245 12.6532 25.405 12.2089 24.5098 12.0575C23.6147 11.906 22.6945 12.056 21.8938 12.4838C21.093 12.9116 20.4569 13.5931 20.0852 14.4214C19.7135 15.2497 19.6273 16.178 19.84 17.0606C18.2094 16.9781 16.6144 16.5536 15.1587 15.8145C13.7029 15.0754 12.4188 14.0383 11.39 12.7706C11.0291 13.4008 10.8395 14.1144 10.84 14.8406C10.8387 15.515 11.0042 16.1792 11.3218 16.7742C11.6393 17.3691 12.099 17.8763 12.66 18.2506C12.008 18.2329 11.3699 18.0579 10.8 17.7406V17.7906C10.8049 18.7355 11.136 19.6497 11.7373 20.3786C12.3386 21.1074 13.1733 21.6062 14.1 21.7906C13.7433 21.8992 13.3729 21.9564 13 21.9606C12.7419 21.9576 12.4844 21.9342 12.23 21.8906C12.4939 22.7034 13.0046 23.4137 13.6911 23.9228C14.3775 24.4318 15.2056 24.7142 16.06 24.7306C14.6172 25.8659 12.8359 26.4855 11 26.4906C10.6657 26.4917 10.3317 26.4717 10 26.4306C11.8744 27.6409 14.0588 28.2833 16.29 28.2806C17.8297 28.2966 19.3571 28.0056 20.7831 27.4247C22.2091 26.8437 23.505 25.9845 24.5952 24.8971C25.6854 23.8097 26.548 22.516 27.1326 21.0915C27.7172 19.667 28.012 18.1403 28 16.6006C28 16.4306 28 16.2506 28 16.0706C28.7847 15.4854 29.4615 14.768 30 13.9506V13.9506Z"
            fill="#FF8504"
          />
        </g>
        <defs>
          <clipPath id="clip0_169_7">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default twiter;
