import React, { useState, useRef, useEffect } from "react";
import Footer from "../../layouts/Footer";
import Navbar from "../../layouts/v1";
import "./style.css";

const FAQCard = (props) => {
  const answerRef = useRef(null);

  const [answerHeight, setAnswerHeight] = useState(0);

  useEffect(() => {
    if (props?.isExpanded) {
      setAnswerHeight(answerRef.current.scrollHeight);
    } else {
      setAnswerHeight(0);
    }

  }, [props?.isExpanded]);
useEffect(() => {
  window.scrollTo(0,0)
}, [])


  return (
    <div className="card__container">
      <div className="question__container">
        <div className="question_">{props?.question}</div>
        <span className="plus__btn" onClick={props?.onClick}>
          {props?.isExpanded ? "-" : "+"}
        </span>
      </div>
      {props?.isExpanded && (
        <div
          ref={answerRef}
          className={props?.isExpanded ? "answer--expanded" : ""}
          style={{ maxHeight: answerHeight }}
        >
          <p className="ptag">{props?.answer}</p>
        </div>
      )}
    </div>
  );
};

const data = [
  {
    "id": 0,
    "question": "1. What is Truprops?",
    "answer": "Truprops is a real estate listing app designed to simplify the process of buying, selling, renting, or leasing properties. It provides a platform where property owners, buyers, renters, and agents can connect seamlessly."
  },
  {
    "id": 1,
    "question": "2. How do I list my property on Truprops? ",
    "answer": "Listing your property on Truprops is easy. Simply create an account, provide accurate details about your property, upload photos, and set your listing preferences. Our user-friendly interface guides you through the process. "
  },
  {
    "id": 2,
    "question": "3. Can I list both residential and commercial properties? ",
    "answer": "Yes, you can list both residential and commercial properties on Truprops. Whether it's a house, apartment, office space, or retail property, you can showcase it to potential buyers, renters, or tenants. "
  },
  {
    "id": 3,
    "question": "4. What is the role of an agent on Truprops? ",
    "answer": "Agents play a crucial role in verifying the accuracy of property listings. They physically inspect properties to ensure that the information provided by the listing owner is accurate, creating a trustworthy environment for all users. "
  },
  {
    "id": 4,
    "question": "5. How can I contact property owners or agents? ",
    "answer": "Truprops provides a detail of property listed person that allows users to communicate directly with property owners. Simply click on the 'Contact' button within the listing to initiate a conversation."
  },
  {
    "id": 5,
    "question": "6. Is my personal information secure on Truprops? ",
    "answer": " Yes, we take privacy seriously. Your personal information is protected through secure encryption and is only shared with relevant parties involved in the property transaction. "
  },
  {
    "id": 6,
    "question": "7. Are there any fees for using Truprops? ",
    "answer": " Truprops offers both free and premium listing options. While basic listings are free, there may be additional features and services available under premium plans. Check our pricing page for more details. "
  },
  {
    "id": 7,
    "question": "8. Can I search for properties in specific locations? ",
    "answer": "Absolutely. Truprops allows you to search for properties based on location other preferences. Use our filters to find the perfect property that matches your criteria."
  },
  {
    "id": 8,
    "question": "9. How do I know if a property is still available? ",
    "answer": "Listings on Truprops are regularly updated by the owners or agents. However, we recommend reaching out to the property owner or agent directly for the most up-to-date information on availability. "
  },
  {
    "id": 9,
    "question": "10. What makes Truprops different from other real estate platforms?",
    "answer": "Truprops stands out for its verification process carried out by agents, ensuring the accuracy of listings. We focus on providing a transparent and secure platform that facilitates smooth property transactions. "
  },
  {
    "id": 10,
    "question": "11. Can I access Truprops on my mobile device? ",
    "answer": "Yes, Truprops is designed to be accessible on both desktop and mobile devices. You can download our mobile app for easy browsing and listing management on the go. "
  }
];
const Index = () => {
  const [expandedStates, setExpandedStates] = useState(data.map(() => false));

  const toggleVisibility = (index) => {
    setExpandedStates((prevStates) =>
      prevStates.map((prevState, i) => (i === index ? !prevState : false))
    );
  };


  return (
    <div className="main__container">
      <Navbar />
      <div className="hero__container">
        <div className="header__container">
          <h2>Frequently Asked Questions</h2>
        </div>

        <div className="cards__container">
          {data.map((e, i) => {
            return (
              <FAQCard
                key={e?.id}
                question={e?.question}
                isExpanded={expandedStates[i]}
                onClick={() => toggleVisibility(i)}
                answer={e?.answer}
              />
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Index;
