import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import "./style.css";
import SideBar from "../Components/SideIconBar";
import { DashboardCard, DashTables } from "./components";
import getAgentBucket from "../../../api/Agent/getAgentBucket";
import { Button } from "react-bootstrap";

export default function Index() {
  const history = useHistory();
  const{agentData, userInfo} = useContext(AuthContext);
  const [bucketCount, setBucketCount] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const handleBucketCount = async () => {
    const properties = await getAgentBucket(agentData?.agent_id);
    setBucketCount(properties[1][0]?.assigned_property?.length);
    setIsLoaded(true);
  };
  useEffect(() => {
    if (!bucketCount) handleBucketCount();
  }, [bucketCount]);

  return (
    <>
      <div className="dashboard-container">
        <div className="sidebar">
          <SideBar />
        </div>
        <div className="right-column">
        {!userInfo?.is_aadhar_verified && (
        <div className="d-flex justify-content-end align-items-center">
          <Button
            variant="success"
            onClick={() => history.push(`/verification`)}
          >
            Verify Aadhaar
          </Button>
        </div>
      )}
          {agentData && (
            <div className="dash-card-container">
              {isLoaded && (
                <DashboardCard
                  agentData={agentData}
                  bucketData={bucketCount}
                  isLoaded={isLoaded}
                />
              )}
            </div>
          )}
          <div className="recent-approval">
            {/* <DashTables /> */}
          </div>
          <div className="recent-properties"></div>
        </div>
      </div>
    </>
  );
}
