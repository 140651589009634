import React from "react";

const facebook = () => {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_169_7)">
          <path
            d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
            fill="white"
          />
          <path
            d="M25.9821 8H21.9066C19.4881 8 16.7979 8.99723 16.7979 12.4341C16.8097 13.6317 16.7979 14.7786 16.7979 16.0694H14V20.4343H16.8845V33H22.185V20.3513H25.6835L26 16.0571H22.0936C22.0936 16.0571 22.1024 14.1469 22.0936 13.5921C22.0936 12.2339 23.5352 12.3117 23.6219 12.3117C24.3079 12.3117 25.6417 12.3137 25.9841 12.3117V8H25.9821V8Z"
            fill="#FF8A14"
          />
        </g>
        <defs>
          <clipPath id="clip0_169_7">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default facebook;
