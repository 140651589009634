import { POST } from "../functions";

const createUserInDb = async (data) => {
  const path = "/users";
  return await POST(path, data)
    .then((result) => {
      console.log("createuser in db > ", result);
      return result;
    })
    .catch((err) => {
      return err;
    });
};

export default createUserInDb;
