import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { QuestionCircleFill } from "react-bootstrap-icons";
import "./style.css";
import Navbar from "react-bootstrap/Navbar";
import icon from "./assets/icon.svg"
import seller from "./assets/seller.svg"
import upload from "./assets/upload.svg"

export default function Index() {
  const openPlaystoreLink = () => {
    window.open('https://play.google.com/store/apps/details?id=com.xbi4.truprops', '_blank');
  };
  return (
    <div className="download_mobile">
      <div className="download_icons">
        <div className="dwnld">Download</div>
        <div className="app" style={{
          cursor: "pointer"
        }} onClick={openPlaystoreLink}>truprops app</div>
        <div className="icons-rows">
          <SvgContainer label="Listing Made Easy" svg={icon} />
          <SvgContainer label="Contact Seller" svg={seller} />
          <SvgContainer label="Upload Instantly" svg={upload} />
              </div>
      </div>
      <div className="dwld-right-col">
       <div className="mob-frame"></div> 
       <div className="mob-prop">
        <div className="mob-top"></div>
        <div className="main-logo" style={{
          cursor:"pointer"
        }} onClick={openPlaystoreLink}><img
                src="https://truprops.com/static/media/brandlogo.106f7c99d4a8ffbd67c4.png"
                alt="truprops"
              /></div>
        <div className="mob-bottom"></div>
       </div>
      </div>
    </div>
  );
}

const SvgContainer = ({ svg, label }) => {
  return (
    <div className="t-icon-container">
      <div className="svg">
        <img src={svg} alt="svg" />
      </div>
      <div className="icon-text">{label}</div>
    </div>
  );
};