import React, { createContext, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { createUserInDb, getUserInfo } from "../../api/user";
import getAgentLimit  from "../../api/Agent/getAgentLimit"

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authParams, setAuthParams] = useState<any>({});
  const [userAttr, setUserAttr] = useState<any>(null);
  const [userInfo, setUserInfo] = useState<any>(null);
  const [user, setUser] = useState<any>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<any>(false);
  const [isAuthenticating, setIsAuthenticating] = useState<any>(true);
  const [idToken, setIdToken] = useState<any>(null);
  const [agentData, setAgentData] = useState<any>(null);
  const user_key = userInfo?.user_key;
  const [tenantDetails, setTenantDetails] = useState<any>({
    name: "",

    email: "",

    phone: "",

    address: "",

    zipcode: "",

    state: "",

    country: "",

    city: "",
  });
  const [ownerDetails, setOwnerDetails] = useState<any>({
    ownerName: "",

    email: "",

    phone: "",

    address: "",

    zipcode: "",

    state: "",

    country: "",

    city: "",
  });
  const [furnishedDetails, setFurnishedDetails] = useState<any>({
    floor: "",

    area: "",
  });
  const [nonFurnishedDetails, setNonFurnishedDetails] = useState<any>({
    floor: "",
  });
  const [dateDetails, setDateDetails] = useState<any>({
    date: "",

    email: "",

    rent: "",

    payment: "",

    deposit: "",

    notice: "",
  });

  async function fetchUser() {
    try {
      const session = await Auth.currentSession(); //returns tokens
      setIdToken(session?.getIdToken());
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      setUser(authenticatedUser);
      const userInfoRes = await getUserInfo(authenticatedUser?.attributes?.sub);

      if (userInfoRes[0] === 200) {
        setUserInfo(userInfoRes[1]);
      } else {
        console.log("error occured. signing out...");
        await signOut();
        return;
      }
      setIsAuthenticated(true);
      setUserAttr(authenticatedUser?.attributes);
      return authenticatedUser;
    } catch (error) {
      console.log("fetchUser >", error);
    }
  }
  useEffect(() => {
    //prevent logged in user from log out
    fetchUser().then((_) => setIsAuthenticating(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //  * ------sign up--------
  async function signUp(username, password, attributes) {
    return await Auth.signUp({ username, password, attributes })
      .then(async (result) => {
        console.log("signUp res > ", result);
        setAuthParams({ ...authParams, username });
        const userKey = result?.userSub;

        // * ----create user in database----
        await createUserInDb({
          first_name: attributes?.["custom:first_name"],
          last_name: attributes?.["custom:last_name"],
          phone_number: attributes?.["custom:phone_number"],
          cognito_key: userKey,
          // [validateEmail(username)?'email':'phone_number']:username,
          [result?.codeDeliveryDetails?.AttributeName]: username,
        });

        return {
          statusCode: 201,
          message: result,
          redirectTo: `/verify?user=${username}`,
        };
      })
      .catch((err) => {
        console.log("error in signUp > ", err);
        return {
          statusCode: 201,
          message: err,
          redirectTo: `/verify?user=${username}`,
        };
      });
  }

  // * confirm sign up otp * //
  const confirmSignUpCode = async (username, code) => {
    return await Auth.confirmSignUp(username, code)
      .then((result) => {
        console.log("confirm signup otp > ", result);
        return {
          statusCode: 200,
          message: result,
          redirectTo: "/logIn",
        };
      })
      .catch((err) => {
        console.log("confirmSignUpCode error - ", err);
        console.log("confirm signup otp > ", err?.message);
        console.log(err);
        return {
          statusCode: 500,
          message: {
            code: err?.code,
            info: err?.message,
          },
        };
      });
  };

  // * resend confirmation code * //
  const resendSignUpCode = async (username) => {
    return await Auth.resendSignUp(username)
      .then((result) => {
        console.log("resend sign up code > ", result);
        return result;
      })
      .catch((err) => {
        console.log("error while resending sign up code > ", err);
        return err;
      });
  };

  // * sign in * //
  async function signIn(username, password) {
    const res = await Auth.signIn(username, password)
      .then(async (result) => {
        setUser(result);
        setUserAttr(result?.attributes);
        setIdToken(result?.signInUserSession?.idToken?.jwtToken);
        fetchUser();
        console.log(result);

        if (result?.challengeName === "NEW_PASSWORD_REQUIRED") {
          return {
            statusCode: 201,
            message: result,
            redirectTo: "/setNewPassword",
          };
        }
        setIsAuthenticated(true);
        return { statusCode: 200, message: result, redirectTo: "/" };
      })
      .catch((err) => {
        if (err?.code === "UserNotConfirmedException") {
          return {
            statusCode: 403,
            message: err,
            redirectTo: `/verify?user=${username}`,
          };
        }
        return {
          statusCode: 400,
          message: { code: err.code, info: err.message },
        };
      });
    return { ...res, username };
  }

  const signOut = async () => {
    await Auth.signOut()
      .then((res) => {
        console.log("signout res", res);
        setIsAuthenticated(false);
        setUser(null);
        window.location.reload();
      })
      .catch((err) => {
        console.log("err in signout", err);
      });
  };

  async function newPasswordChallenge(newPassword) {
    console.log("user new password challenge > ", user);
    if (user) {
      try {
        const response = await Auth.completeNewPassword(user, newPassword);
        console.log("newPasswordChallenge > ", response);
        return { statusCode: 200, response };
      } catch (error) {
        console.log("error newPasswordChallenge in", error);
        return { statusCode: 400, error };
      }
    } else {
      const error = "error in newPasswordChallenge > user not found ";
      console.log(error);
      return { statusCode: 400, error };
    }
  }

   
    const fetchData = async () => {
      try {
        const res = await getAgentLimit(user_key);
        setAgentData(res[1][0]);
      } catch (error) {
        console.error(error);
      }
    };
  
    useEffect(() => {
      if (user_key) {
        fetchData().then((_) => setIsAuthenticating(false));;
      }
    }, [user_key]);
  
    // return [data];
  

  return (
    <AuthContext.Provider
      value={{
        tenantDetails,
        setTenantDetails,
        ownerDetails,
        setOwnerDetails,
        user,
        userAttr,
        userInfo,
        agentData,
        fetchData,
        fetchUser,
        isAuthenticated,
        isAuthenticating,
        furnishedDetails,
        setFurnishedDetails,
        nonFurnishedDetails,
        setNonFurnishedDetails,
        dateDetails,
        setDateDetails,
        setIsAuthenticated,
        signIn,
        signUp,
        confirmSignUpCode,
        resendSignUpCode,
        signOut,
        newPasswordChallenge,
        tokens: {
          idToken,
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
