import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { getProperties } from "../../api/property";
import { PropCardV1 } from "../../components/PropCard";
import { AuthContext } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";

export default function Index() {
  const history = useHistory();
  const [propertyList, setPropertyList] = useState<any>([]);
  const { userInfo } = useContext(AuthContext);
  const fetchProperties = async () => {
    await getProperties(`user_key=${userInfo?.user_key}`)
      .then(([statusCode, res]) => {
        if (statusCode === 200) {
          setPropertyList(res);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="container">
        <div className="f-b fs-3 my-3">My Properties</div>

        <Button className=" btn-light" style={{backgroundColor:"grey"}} onClick={() => history.push("/closing")} >
            Request for Closing
          </Button>

        <div className="d-flex flex-row flex-wrap">
          {propertyList?.map((property, idx) => (
            <PropCardV1 propData={property} key={idx} />
          ))}
        </div>
      </div>
    </div>
  );
}
