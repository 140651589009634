import { AgreementDocProps } from "./type"

const agreementInput:AgreementDocProps ={
name:'Rajendran',
doorNo: '58a',
monthFrom: '1 jan 2023',
rentAmount: '10000',

}

export default agreementInput