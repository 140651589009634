import React, { useEffect, useState } from "react";

const Index = ({ onCityChange, onStateChange, values, error }) => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [currentCountry, setCurrentCountry] = useState("");
  const REQUEST_PARAMS = {
    method: "GET",
    headers: {
      "X-CSCAPI-KEY":
        "QW94aFRROVVKNHJBdXZmSFZKeEtabHc3dlhzenBCQVJYWENHRkJxNA==",
    },
  };

  const getCountries = () => {
    return fetch(
      "https://api.countrystatecity.in/v1/countries/",
      REQUEST_PARAMS
    )
      .then((_) => _.json())
      .then((response) => {
        // console.log(response);
        return response;
      });
  };
  const getStatesByCountry = () => {
    return fetch(
      `https://api.countrystatecity.in/v1/countries/IN/states`,
      REQUEST_PARAMS
    )
      .then((_) => _.json())
      .then((response) => {
        // console.log(response);
        return response;
      });
  };
  const getCitiesByStateAndCountry = () => {
    return fetch(
      `https://api.countrystatecity.in/v1/countries/IN/states/TN/cities`,
      REQUEST_PARAMS
    )
      .then((_) => _.json())
      .then((response) => {
        // console.log(response);
        return response;
      });
  };

  // const handleCountryChange = (e: any) => {
  //   const iso2 = e.target.value.split("-")[1];
  //   console.log(e.target.value)
  //   setCurrentCountry(e.target.value);
  //   console.log(iso2);
  //   getStatesByCountry().then((_) => {
  //     setStates(_);
  //   });
  // };
  const handleStateChange = (e: any) => {
    const iso2 = "TN"
    // e.target.value.split("-")[1];
    onStateChange(e);
    const countryIso = currentCountry.split("-")[1];
    getCitiesByStateAndCountry().then((_) => setCities(_));
  };

  // useEffect(() => {
  //   getStatesByCountry().then((_) => setStates(_));
  // }, []);
  useEffect(() => {
    getCitiesByStateAndCountry().then((_) => setCities(_));
  }, []);

  return (
    <>
      {/* {states.length > 0 && ( */}
      
        <label htmlFor="state" className="form-label">
          State
        </label>
        <select
          id="state"
          name="state"
          className="form-select"
          aria-label="Default select example"
          onChange={handleStateChange}
          // onClick={console.log}
        >
          <option value="Tamilnadu">Tamilnadu</option>
          {/* {states
            ? states.map((state) => (
                <option key={state.id} value={`${state.name}-${state.iso2}`}>
                  {state.name}
                </option>
              ))
            : ""} */}
        </select>
        <div>
          <span className="text-danger"></span>
        </div>

      {/* )} */}

      {/* {cities.length > 0 && ( */}
      
        <label htmlFor="city" className="form-label">
          City
        </label>
        <select
          id="city"
          name="city"
          className="form-select"
          aria-label="Default select example"
          onChange={onCityChange}
        >
          <option value={values.propCity}>{values.propCity? (values.propCity):("Select City")}</option>
          {cities.length > 0
            ? cities.map((city) => (
                <option key={city.id} value={city.name}>
                  {city.name}
                </option>
              ))
            : ""}
        </select>
        {/* {error?(<div className="error">* {error}</div>):("")} */}
        <div>
          <span className="text-danger"></span>
        </div>
              
      {/* )} */}
    </>
  );
};

export default Index;
