import React, { useContext, useEffect, useState } from "react";
import { Heart, Share, HeartFill } from "react-bootstrap-icons";
import { getProperties } from "../../../../api/property";
import { AuthContext } from "../../../../context/AuthContext";
import { ImageCarousal } from "./components";
import { useHistory } from "react-router-dom";
import Maptile from "../V2/components/GoogleMapTile";
import Navbar from "../../../../layouts/v1";
import "./style.css";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { log } from "console";
import ShareModal from "../../../../components/Share";
import { useUserRole } from "../../../../hooks";
import addToWishlist from "../../../../api/Wishlist/addToWishlist";
import deleteFromWishList from "../../../../api/Wishlist/deleteFromWishList";
import getWishListId from "../../../../api/Wishlist/getWishListId";
import getContactForUser from "../../../../api/user/getContactForUser";
import ContactDetailsModal from "./components/Contact Details Modal";
import  Footer  from "../../../../layouts/Footer";
import PropDetails from "./components/PropDetails/propdetails";
const Index = ({ propKey }) => {
  const [propertyData, setPropertyData] = useState<any>({});
  const { userInfo } = useContext(AuthContext);
  const [contactInfoModal, setContactInfoModal] = useState(false);
  const [contactInfo, setContactInfo] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAgent, setIsAgent] = useState(false);
  useEffect(() => {
    setIsAgent(userInfo?.is_agent);
    handleReadWishListId();
  }, [userInfo]);
  // console.log(userInfo);

  const getContactLimit = async (property_key: number | string) => {
    if (!userInfo?.user_key) {
      alert("Please sign in to view contact details");
    }else{
      const res = await getContactForUser(userInfo?.user_key, property_key);
      setContactInfo(res[1][0]);
      setIsLoaded(true);
    }
   
  };
  useEffect(() => {
    setContactInfoModal(true);
  }, [isLoaded]);
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  
  const getPropertyData = async () => {
    await getProperties("", propKey)
      .then(([statusCode, res]) => {
        if (statusCode === 200) {
          setPropertyData(res);
          console.log(res, "result prop");
        } else {
          alert(`Error - ${statusCode}`);
        }
      })
      .catch((err) => console.log(err));
  };
  console.log(typeof propertyData?.available_from);
  useEffect(() => {
    if (propKey) {
      console.log(">>>>>>>>>>>>>>> prop key detected");
      getPropertyData();
      handleReadWishListId();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propKey, propertyData?.property_id]);
  const history = useHistory();
  const [individualSiteDetail, setIndividualSiteDetail] = useState(null);

  // modal for view details

  // const [show, setShow] = useState(false);
  const [socialModal, setSocialModal] = useState(false);

 
  // const handleClose = () => setShow(false);
  // {console.log(propertyData)}

  const modalShow = () => setSocialModal(true);
  const modalClose = () => setSocialModal(false);
  const { roles, isPropertyOwner } = useUserRole();
  const [isFavorite, setIsFavorite] = useState(0);
  const handleReadWishListId = async () => {
    try {
      const wishlistResponse = await getWishListId(
        userInfo?.user_key,
        propertyData?.property_id
      );

      console.log("elemt rezpoins wihlist", JSON.parse(wishlistResponse[1][0].result));
      setIsFavorite(JSON.parse(wishlistResponse[1][0]?.result).wishlist_id);
      console.log(
        "ksndacojanvdo",
        JSON.parse(wishlistResponse[1][0]?.result).wishlist_id
      );
    } catch (error) {
     console.log(error) 
    }
  };

  const handleSwitchOnIsFavorite = () => {
    addToWishlist(
      { property_id: propertyData?.property_key },
      userInfo?.user_key
    ).then(() => {
      handleReadWishListId();
    });
  };
  const handleSwitchOffIsFavorite = () => {
    deleteFromWishList(userInfo?.user_key, isFavorite).then(() => {
      handleReadWishListId();
    });
  };
  return (
    <>
      <Navbar />
      <div>
        <div className=" p-2 m-2">
          Property title - <span className="interprtation-content">
            {propertyData?.title}
            </span>
        </div>
        <div className="prop-details-container">
          <div className=" left-img-container">
            <ImageCarousal propData={propertyData} />
          </div>
          <div className="right-prop-container ">
            <div className="basic-prop-details  ">
              <div className="prop-top-details">
                <div className="action-button d-flex flex-row justify-content-around">
                  <Share
                    onClick={modalShow}
                    className="action-btn primary-text m-1"
                    size={25}
                  />
                  <Modal
                    show={socialModal}
                    onHide={modalClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter">
                        Share Property Via
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: "center" }}>
                      <ShareModal propData={propertyData} />
                    </Modal.Body>
                  </Modal>
                  {/* <ShareModal propData={propertyData} show={socialModal} onHide={()=>{setSocialModal(false)} }/> */}
                  {isFavorite ? (
                    <div onClick={() => handleSwitchOffIsFavorite()}>
                      <HeartFill
                        className="action-btn primary-text m-1"
                        size={25}
                      />
                    </div>
                  ) : (
                    <div onClick={() => handleSwitchOnIsFavorite()}>
                      <Heart
                        className="action-btn primary-text m-1"
                        size={25}
                      />
                    </div>
                  )}
                  {
                  isPropertyOwner(propertyData) === true ? (
                    <button
                      className="edit-prop-btn"
                      onClick={() =>
                        history.push(
                          `/edit-your-property/${propertyData?.property_key}`
                        )
                      }
                    >
                      Edit Property
                    </button>
                  ) : null}
                </div>
                <div className="prop-price-rating d-flex flex-row w-50 justify-conten-start">
                  <div className="price">
                    {propertyData?.currency_symbol} {propertyData?.price?.toLocaleString("en-IN")}{" "}
                  </div>
                  <div className="rating"></div>
                </div>
                <div className="d-flex flex-row">
                <div className="house-type flex-col">
                  {propertyData?.bed == 0 ? propertyData?.property_type_name : (`${propertyData?.bed} BHK ${propertyData?.property_type_name}`)}
                </div>
                <div className="flex-col mx-5" style={{
                  color:"var(--primary-color)"
                }}>{propertyData?.listed_for}</div></div>
                <div className="prop-features d-flex flex-row">
                  <div className="prop-size">
                    {propertyData?.built_area} Sq.ft
                  </div>
                  <div className="muted-features">
                    {propertyData.bed ? (`${propertyData?.bed} BHK | ${propertyData?.bed} Beds |
                    ${propertyData?.bath} Bath`): null }
                  </div>
                </div>
                <div className="upload-by">
                  Address: {" "}<span className="prop-address">
                    {propertyData?.address_line_1} {propertyData?.address_line_2}{" "}
                  {propertyData?.location_data?.placeName}
                    </span>
                </div>
                <div className="upload-by">
                  Uploaded by:{" "}
                  <span className="price">{propertyData &&
                    propertyData?.listed_by_details}{" "}</span>
                  {/* Uploaded by: {propertyData?.listed_by_details}{" "} */}
                </div>
              </div>
              <div className="prop-location">
              <span className="m-2">Location</span>
                {/* <img src="https://maps.googleapis.com/maps/api/staticmap?center=40.714%2c%20-73.998&zoom=12&size=400x400&key=AIzaSyCI1NfGNoPyn31Nx_LAkcQiCKbkKj88tI0" alt="" /> */}
                <Maptile propData={propertyData} />
              </div>
              <div className="seller-contact d-flex flex-column">
                <div className="m-2">Contact Seller</div>
                <div className="contact-details d-flex flex-row justify-content-center align-content-center">
                  <div className="seller-avatar m-2">
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 64 64"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.5007 31.2083C20.3157 31.2083 18.5423 32.9817 18.5423 35.1667C18.5423 37.3517 20.3157 39.125 22.5007 39.125C24.6857 39.125 26.459 37.3517 26.459 35.1667C26.459 32.9817 24.6857 31.2083 22.5007 31.2083ZM41.5007 31.2083C39.3157 31.2083 37.5423 32.9817 37.5423 35.1667C37.5423 37.3517 39.3157 39.125 41.5007 39.125C43.6857 39.125 45.459 37.3517 45.459 35.1667C45.459 32.9817 43.6857 31.2083 41.5007 31.2083ZM32.0007 0.333336C14.5207 0.333336 0.333984 14.52 0.333984 32C0.333984 49.48 14.5207 63.6667 32.0007 63.6667C49.4807 63.6667 63.6673 49.48 63.6673 32C63.6673 14.52 49.4807 0.333336 32.0007 0.333336ZM32.0007 57.3333C18.0357 57.3333 6.66732 45.965 6.66732 32C6.66732 31.0817 6.73065 30.1633 6.82565 29.2767C14.299 25.9517 20.2207 19.84 23.324 12.2717C29.0557 20.3783 38.4923 25.6667 49.164 25.6667C51.634 25.6667 54.009 25.3817 56.289 24.8433C56.954 27.0917 57.334 29.4983 57.334 32C57.334 45.965 45.9657 57.3333 32.0007 57.3333Z"
                        fill="#FF8E23"
                      />
                    </svg>
                  </div>
                </div>
                <button
                  onClick={() => getContactLimit(propertyData?.property_key)}
                >
                  Get Contact Details
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="d-flex"> */}
        <div className=" prop-desc-card">
         <PropDetails propertyData={propertyData}/>
        </div>
       
        {/* </div> */}
        <div className="prop-desc-card">
          <div className="prop-description">
            <div className="interprtation-content pb-2">Description</div>
            <div className="desc-content">{propertyData?.description === "null" ? "No description" : propertyData.description }</div>
          </div>
        </div>

        {isPropertyOwner(propertyData) === true ? (
          <div className="d-flex align-items-center justify-content-center prop-desc-card p-5">
            Here is your history of activites
            <button
              className="edit-prop-btn m-2"
              onClick={() =>
                history.push(`/prop-history/${propertyData?.property_key}`)
              }
            >
              Property History
            </button>
          </div>
        ) : null}
        <br></br>
      </div>
      {isLoaded && (
        <ContactDetailsModal
          props={contactInfo}
          show={contactInfoModal}
          handleClose={() => (setContactInfoModal(false), setIsLoaded(false))}
        />
      )}
      <Footer/>
    </>
  );
};

export default Index;
