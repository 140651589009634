import { type } from "os";
import React, { useState } from "react";
import { ChipV1 } from "../../../../../components";
import { numToWords } from "../../../../../utils";

const Index = ({ nextStep, handleFormData, prevStep, values }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState("");
  const submitFormData = (e) => {
    e.preventDefault();
    if (
      values.userType.agreeDate === 0 ||
      values.monthlyRent.length === 0 ||
      values.rentDate.length === 0 ||
      values.secAmt.length === 0 ||
      values.noticePeriod.length === 0
    ) {
      return setError("* All fields required");
    } else {
      setError("");
      nextStep();
    }
    // nextStep();
  };

  const chipList = {
    noticePeriod: [
      {
        label: "1",
        value: "1",
      },
      {
        label: "2",
        value: "2",
      },
      {
        label: "3",
        value: "3",
      },
    ],
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    console.log(event.target.checked ? "checked" : "unchecked");
  };

  return (
    <>
      <div className="agree-form-header">Contract Details</div>
      <div className="agree-form-subheader">
        Agreement is valid for 11 months from date of agreement. You will need
        to Renew.
      </div>
      <form action="" className="agree-form">
        <div className="agree-form-field">
          <label htmlFor="agreeDate">
            Agreement Start Date <span className="required"> *</span>
          </label>
          <input
            type="date"
            id="agreeDate"
            defaultValue={values.agreeDate}
            onChange={handleFormData("agreeDate")}
          />
        </div>
        <div className="agree-form-field">
          <label htmlFor="monthlyRent">
            Monthly Rent <span className="required"> *</span>
          </label>
          <input
            type="number"
            id="monthlyRent"
            placeholder="Monthly Rent"
            defaultValue={values.monthlyRent}
            onChange={handleFormData("monthlyRent")}
          />
          <span onChange={handleFormData("monthlyRentInWords")}>
            {numToWords(values.monthlyRent)}{" "}
          </span>
        </div>
        {/* <div className="agree-form-field-check">
          <input
            type="checkbox"
            id="charges"
            defaultValue={values.ebCharges}
            value={`${isChecked}`}
            checked={isChecked}
            onChange={(e) => {
              handleFormData("ebCharges");
              {
                handleCheckboxChange(e);
              }
            }}
          />
          <label htmlFor="charges" className="agree-label">
            Electricity/ Water charges not Included
          </label>
        </div> */}
        <div className="agree-form-field">
          <label htmlFor="rentDate">
            Rent Payment Date <span className="required"> *</span>
          </label>
          <select name="" id="rentDate" onChange={handleFormData("rentDate")}>
            <option value="">
              {values.rentDate ? values.rentDate : "Select Rent Payment Date"}
            </option>
            {Array.from({ length: 31 }, (_, i) => (
              <option
                value={`${i + 1}`}
                defaultValue={values.rentDate}
                onChange={handleFormData("rentDate")}
              >
                {i + 1}
              </option>
            ))}
          </select>
        </div>
        <div className="agree-form-field">
          <label htmlFor="secamt">
            Security Amount <span className="required"> *</span>
          </label>
          <input
            type="number"
            id="secAmt"
            placeholder="Security Amount"
            defaultValue={values.secAmt}
            onChange={handleFormData("secAmt")}
          />
          <span>
            {numToWords(values.secAmt)}{" "}
          </span>
        </div>
        <div className="agree-form-field">
          <label htmlFor="notice">
            Notice Period in months<span className="required"> *</span>
          </label>
          {/* <input type="text" id="notice" placeholder="Notice Period" defaultValue={values.noticePeriod}  onChange={handleFormData("noticePeriod")}/> */}
          <ChipV1
            chipList={chipList?.noticePeriod}
            value={values?.noticePeriod}
            onClick={(e) => {
              handleFormData("noticePeriod")({ target: { value: e } });
            }}
          />
        </div>
        <div className="agree-form-field-check">
          <input
            type="checkbox"
            id="rentIncr"
            defaultValue={values.rentIncr}
            checked={isChecked}
            onChange={(e) => {
              handleFormData("rentIncr");
              {
                handleCheckboxChange(e);
              }
            }}
          />
          <label htmlFor="rentIncr"  className="agree-label">
            Rent will be increase after 11 months
          </label>
          {isChecked ? (
            <>
              <div className="agree-form-field">
                <label htmlFor="rentIncr">
                  Rent Increment in Percentage{" "}
                  {/* <span className="required"> *</span> */}
                </label>
                <input
                  type="number"
                  id="rentIncr"
                  defaultValue={values.rentIncrPer}
                  placeholder="Enter the Percentage"
                  onChange={handleFormData("rentIncrPer")}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </form>
      <div className="agree-btn-container">
        {error && (
          <p className="error">
            <strong>{error}</strong>
          </p>
        )}
        <button className="agree-btn" onClick={prevStep}>
          Previous
        </button>
        <button className="agree-btn" type="submit" onClick={submitFormData}>
          Next
        </button>
      </div>
    </>
  );
};

export default Index;
