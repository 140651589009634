import React, { useCallback } from "react";
import "./style.css";
import {
  numToWords,
  ChangeFormateDate,
  printMonth,
  printDay,
  printYear,
  addMonths,
  currentDay,
  printDiv,
  // printDivNew
} from "../../../../../utils";
import printDoc from "../../PrintDoc";
import agree from "../../../../../assets/svg/agree";

// const handlePrint = (divName) => {
//   printDiv(divName)
// }

export default function Index({ prevStep, values }) {
  const {
    userType,
    propCity,
    propState,
    OfullName,
    OfatherName,
    Ophone,
    Oadress,
    Opan,
    Oemail,
    TfullName,
    TfatherName,
    Tphone,
    Tadress,
    Tpan,
    Temail,
    floorNo,
    propType,
    bhkType,
    houseno,
    houseaddr,
    pincode,
    agreeDate,
    monthlyRent,
    ebCharges,
    rentDate,
    secAmt,
    noticePeriod,
    rentIncr,
    itemQuantity,
    items,
  } = values;

  const handlePrint = useCallback(() => {
    window.print();
  }, []);

  return (
    <>
      <div className="prev-doc" id="printdoc">
        <h2 className="agree-header">Agreement Preview</h2>
        <div className="agree-whitespace"></div>
        <img
          src="https://housing-ca.s3-ap-southeast-1.amazonaws.com/housing-edge/assets/SJ105AB349.png"
          alt="stamp-img"
          className="stamp-header"
        ></img>
        <div className="prev-header">
          <strong>
            <u>LEASE DEED</u>
          </strong>
        </div>

        <div className="paragraph">
          <ol>
            <li>
              Property Address: <span>{houseaddr}</span>{" "}
            </li>
            <li>
              Rent: Rs{" "}
              <span>
                {monthlyRent} ({numToWords(monthlyRent)})
              </span>{" "}
              per month from{" "}
              <span>
                {agreeDate ? <span>{ChangeFormateDate(agreeDate)}</span> : null}
              </span>
            </li>
            <li>
              Period of Lease: Eleven (11) months (
              {agreeDate ? (
                <>
                  <span>{ChangeFormateDate(agreeDate)}</span> to{" "}
                  <span>{addMonths(agreeDate, 11)}</span>
                </>
              ) : null}
              )
            </li>

            <li>
              Security Amount: Rs{" "}
              <span>
                {secAmt} ({numToWords(secAmt)})
              </span>
            </li>
            <li>
              Electricity/Water Charges: As per Meters payable as per the
              billing cycle paid by Lessee before the due dates.
            </li>
          </ol>
        </div>

        <div className="paragraph">
          This Lease Deed/Rent Agreement is executed at <span>{propCity}</span>{" "}
          on day {agreeDate ? <span>{printDay(agreeDate)}</span> : null} of{" "}
          {agreeDate ? (
            <span>
              {printMonth(agreeDate)} {printYear(agreeDate)}
            </span>
          ) : null}
          .
        </div>
        <div className="center-text">Between</div>
        <div className="paragraph">
          Mr./Mrs. <span>{OfullName}</span> So/Do Mr. <span>{OfatherName}</span>{" "}
          <span>{Oadress}</span> (Hereinafter called the{" "}
          <strong> Lessor No. 1 and/ or the First Party </strong>)
        </div>
        <div className="paragraph">
          Mr./Mrs. <span>{TfullName}</span> So/Do Mr. <span>{TfatherName}</span>{" "}
          <span>{Tadress}</span> (Hereinafter called the{" "}
          <strong> Lessor No. 1 and/ or the First Party </strong>)
        </div>
        {addMonths(rentDate, 11)}
        {currentDay()}
        <div>
          <strong>ANNEXURE 1</strong>
        </div>
        <div className="annex-tab">
        {items?.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Items</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {items?.map((item, idx) => (
                <tr>
                  <td>{idx + 1}</td>
                  <td>{item.itemName}</td>
                  <td>{item.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          "No Items were added as a part of this agreement"
        )}
        </div>
      </div>

      <div className="agree-btn-container">
        <button className="agree-btn" onClick={prevStep}>
          Previous
        </button>
        {/* <button onClick={()=>handlePrint("printdoc")}>print</button> */}
        {/* <button onClick={handlePrint}>print</button> */}
        <button className="agree-btn" onClick={() => printDoc("printdoc")}>
          print
        </button>
        {/* <input  type="submit" name="print" onClick={()=>printDiv("printdoc")}/> */}
      </div>
    </>
  );
}
