import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  ButtonGroup,
  Form,
  Button,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import { Controller } from "react-hook-form";
import ChipV1 from "../../../../components/Inputs/Chip/V1/index";
import CheckBoxV1 from "../../../../components/Inputs/CheckBox/V1/index";
import "./style.css";

interface Apartment {
  title: string;
  description: string;
  bed: number;
  bath: string;
  kitchen: string;
  parking: string;
  built_area: number;
  address_line_1: string;
  address_line_2: string;
  facing: string;
  prop_tax: boolean;
  occupancy: boolean;
  landmark: string;
  floor_type: string;
  furnish: string;
  available_from: string;
  has_basement: boolean;
  open_tour: string;
  survillance: string;
  security: string;
  lift: string;
  wifi: string;
  power: string;
  play: string;
  fire_safety: string;
  park: string;
  servant_room: string;
  gas: string;
  sewage: string;
  house_keeping: string;
  rain_water: string;
  pool: string;
  shopping: string;
  garden: string;
}
export default function Index({ register, errors, handleFormData,getValues, control }) {
  const schema = yup.object().shape({
    title: yup
      .string()
      // .typeError("Must contain only number")
      .required("Enter Alphabetic characters only"),
    built_area: yup
      .number()
      // .typeError("Must contain only number")
      .required("Enter Numeric characters only"),
  });
  // const schema = yup.object({
  //   firstName: yup.string().required(),
  //   age: yup.number().positive().integer().required(),
  // }).required();
  const chipList = {
    prefOption: [
      {
        label: "Yes",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],

    prefParkingList: [
      {
        label: "Car",
        value: "CAR",
      },
      {
        label: "Bike",
        value: "BIKE",
      },
      {
        label: "Both",
        value: "BOTH",
      },
      {
        label: "None",
        value: null,
      },
    ],
    prefBedList: [
      {
        label: "1",
        value: 1,
      },
      {
        label: "2",
        value: 2,
      },
      {
        label: "3",
        value: 3,
      },
      {
        label: "4",
        value: 4,
      },
      {
        label: "5",
        value: 5,
      },
      {
        label: "6",
        value: 6,
      },
    ],
    prefBathList: [
      {
        label: "1",
        value: 1,
      },
      {
        label: "2",
        value: 2,
      },
      {
        label: "3",
        value: 3,
      },
      {
        label: "4",
        value: 4,
      },
      {
        label: "5",
        value: 5,
      },
      {
        label: "6",
        value: 6,
      },
    ],
    prefKitchenList: [
      {
        label: "1",
        value: 1,
      },
      {
        label: "2",
        value: 2,
      },
      {
        label: "3+",
        value: 3,
      },
    ],
    prefFacingList: [
      {
        label: "North",
        value: "NORTH",
      },
      {
        label: "South",
        value: "SOUTH",
      },
      {
        label: "East",
        value: "EAST",
      },
      {
        label: "West",
        value: "WEST",
      },
      {
        label: "North-East",
        value: "NORTHEAST",
      },
      {
        label: "South-west",
        value: "SOUTHWEST",
      },
      {
        label: "South-East",
        value: "SOUTHEAST",
      },
      {
        label: "North-West",
        value: "NORTHWEST",
      },
    ],
    prefFloorTypeList: [
      {
        label: "Tiles",
        value: "TILES",
      },
      {
        label: "Marbles",
        value: "MARBLES",
      },
      {
        label: "Granite",
        value: "GRANITE",
      },
      {
        label: "Cement",
        value: "CEMENT",
      },
      {
        label: "Mosaic",
        value: "MOSAIC",
      },
    ],
    prefFurnishList: [
      {
        label: "Fully Furnished",
        value: "FULL",
      },
      {
        label: "Semi-Furnished",
        value: "SEMI",
      },
      {
        label: "Unfurnished",
        value: "UN",
      },
    ],
  };
  const aminitiesList = [
    { label: "Garden", value: "garden" },
    { label: "Park", value: "park" },
    { label: "Gym", value: "gym" },
    { label: "Lift", value: "lift" },
    { label: "Wifi", value: "wifi" },
    { label: "Play Area", value: "play_area" },
    { label: "Fire Safety", value: "fire_safety" },
    { label: "Gas Pipeline", value: "gas" },
    { label: "Sewage Treatment", value: "sewage" },
    { label: "Shopping Center", value: "shopping" },
    { label: "Swimming Pool", value: "pool" },
    { label: "Rain Water Harvesting", value: "rain_water" },
  ];
  return (
    <>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Title
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="formdata"
              type="text"
              name="title"
              placeholder="Title"
              {...register("title", {
                required: true,
              })}
            />

            {errors?.title?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">Description </Form.Label>
            <Form.Control
              className="formdata"
              name="description"
              type="text"
              placeholder="Description"
              {...register("description", {
                required: false,
              })}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Address Line 1<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="formdata"
              name="address_line_1"
              type="text"
              placeholder="address"
              {...register("address_line_1", {
                required: true,
              })}
            />
            {errors?.address_line_1?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Address Line 2<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="formdata"
              name="address_line_2"
              type="text"
              placeholder="Address Line 2"
              {...register("address_line_2", {
                required: true,
              })}
            />
            {errors?.address_line_2?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Landmark
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="formdata"
              name="landmark"
              type="text"
              placeholder="Landmark"
              {...register("landmark", {
                required: true,
              })}
            />
            {errors?.landmark?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Bedroom
              <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="bed"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefBedList}
                  value={value}
                />
              )}
              rules={{required: true}}
            />

            {<span className="text-danger">{errors?.bed?.message}</span>}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Bathroom
              <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="bath"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefBathList}
                  value={value}
                />
              )}
              rules={{required: true}}
            />
            {errors?.bath?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Kitchen
              <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="kitchen"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefKitchenList}
                  value={value}
                />
              )}
              rules={{required: true}}
            />
            {errors?.kitchen?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Col className="w-100">
        <Form.Group className="mb-3">
          <Form.Label className="lable">
            Builtup Area
            <span className="text-danger">*</span>
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              className="formdata number-field-disable-stepper"
              name="built_area"
              type="number"
              pattern="[0-9]*"
              errors="true"
              placeholder="Builtup"
              {...register("built_area", {
                required: true,
              })}
            />
            <InputGroup.Text id="basic-addon2">Sq.Ft.</InputGroup.Text>
          </InputGroup>
          {errors?.builtup?.type === "pattern" && (
            <p>Numeric characters only</p>
          )}
          {errors?.builtup?.type === "required" && (
            <span className="text-danger">This field is required</span>
          )}
        </Form.Group>
      </Col>
      <Row>
        <Col>
          <Form.Group className="mb-3 ">
            <Form.Label className="lable">Facing</Form.Label>
            <Controller
              control={control}
              name="facing"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefFacingList}
                  value={value}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3 ">
            <Form.Label className="lable">Parking</Form.Label>
            <Controller
              control={control}
              name="parking"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefParkingList}
                  value={value}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3 ">
            <Form.Label className="lable">Floor Type</Form.Label>
            <Controller
              control={control}
              name="floor_type"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefFloorTypeList}
                  value={value}
                />
              )}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3 ">
            <Form.Label className="lable">Furnishing<span className="text-danger">*</span></Form.Label>
            <Controller
              control={control}
              name="furnish"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefFurnishList}
                  value={value}
                />
              )}
              rules={{required:true}}
            />
            {errors?.furnish?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Available From
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="formdata"
              name="available_from"
              type="date"
              {...register("available_from", {
                required: true,
              })}
              value={getValues("available_from")?.slice(0, 10)}
            />
            {errors?.available_from?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">Has Basement?</Form.Label>
            <Controller
              control={control}
              name="has_basement"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefOption}
                  value={value}
                />
              )}
            />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Is Surveillance available?
              <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="survillance"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefOption}
                  value={value}
                />
              )}
              rules={{required: true}}
            />
            {errors?.survillance?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>

        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Is Security available
              <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="security"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefOption}
                  value={value}
                />
              )}
              rules={{required: true}}
            />
            {errors?.security?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <div className="mt-4">
        <span className="text-center">
          <h2 className="lable">Amenities</h2>
        </span>
      </div>

      <h5 className="lable">Select the available amenities</h5>
      <div className="mx-5">
      <Row>
        {aminitiesList.map((amenity) => (
          <Col key={amenity.value} xs={5}>
            <div className="checkbox">
              <label>
                <input
                  {...register(amenity.value, {
                    required: false,
                  })}
                  type="checkbox"
                  name={amenity.value}
                />{" "}
                {amenity.label}
              </label>
            </div>
          </Col>
        ))}
      </Row>
      </div>
    </>
  );
}
