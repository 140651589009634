import React from "react";

interface subscribeProps {
  pricing:any
}

export default function Home(subscribeProps) {
  const makePayment = async () => {
    console.log("here...");
    const res = await initializeRazorpay();

    if (!res) {
      alert("Razorpay SDK Failed to load");
      return;
    }

    const paymentData = {
      amount: subscribeProps?.pricing,
      currency: "INR",
    };
    // Make API call to the serverless API
    const data = await fetch(
      "https://ezhcchpyn0.execute-api.ap-south-1.amazonaws.com/dev/razorpay/create_order_id",
      { method: "POST", body: JSON.stringify(paymentData) }
    ).then((t) => t.json());
    console.log(data);
    var options = {
      key: "rzp_test_xlcWEVDXw1g54c", // Enter the Key ID generated from the Dashboard
      name: "Truprops",
      currency: data.currency,
      amount: data.amount,
      order_id: data.id,
      description: "Thankyou for your test donation",
      image: "https://xbi4.s3.ap-southeast-1.amazonaws.com/Frame+16.png",
      handler: function (response: any) {
        // Validate payment at server - using webhooks is a better idea.
        alert(response.razorpay_payment_id);
        console.log("payment ID", response.razorpay_payment_id)
        alert(response.razorpay_order_id);
        console.log("order ID", response.razorpay_order_id)
        alert(response.razorpay_signature);
        console.log("Signature", response.razorpay_signature)
      },
      prefill: {
        name: "Contact_person",
        email: "test@gmail.com",
        contact: "9999999999",
      },
    };

    const paymentObject = new (window as any).Razorpay(options);
    paymentObject.open();

    console.log("data to razorpay", options)
    // console.log("alert", response.)
  };
  const initializeRazorpay = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      // document.body.appendChild(script);

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };
  return (
    <>
      <button className="list-your-prop" onClick={makePayment}>Subscribe Now</button>
    </>
  );
}
