import { GET } from "../functions";

const Index = async (user_key) => {
  const path = `/users/${user_key}/agents`;
  return await GET(path)
    .then((result) => {
      console.log(result)
      return result;
    })
    .catch((err) => {
      console.log("error on prop types from db > ", err);
      return err;
    });
};
export default Index;