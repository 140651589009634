import React, { useState } from "react";
import { ChipV1 } from "../../../../../components";

const Index = ({ nextStep, handleFormData, prevStep, values }) => {

  const [error, setError] = useState("")
  const submitFormData = (e) => {
    e.preventDefault();
    if (values.userType.floorNo === 0 || values.propType.length === 0 ||values.bhkType.length === 0 || values.houseNo.length === 0 || values.houseaddr.length === 0 ||values.pincode.length === 0) {
      return(setError("* All fields required"));
    } 
    else {
      setError("")
      nextStep();    
    }
    // nextStep();
  };
  const chipList = {
    propType: [
      {
        label: "Independent",
        value: "1",
      },
      {
        label: "Apartment",
        value: "2",
      },
    ],
    bhkType: [
      {
        label: "1RK",
        value: "1",
      },
      {
        label: "1BHK",
        value: "2",
      },
      // {
      //   label: "1.5BHK",
      //   value: "3",
      // },
      {
        label: "2BHK",
        value: "4",
      },
      // {
      //   label: "2.5BHK",
      //   value: "5",
      // },
      {
        label: "3BHK",
        value: "6",
      },
      // {
      //   label: "3.5BHK",
      //   value: "7",
      // },
      {
        label: "4BHK",
        value: "8",
      },
    ],
  };

  return (
    <>
      <div className="agree-form-header">Property Details</div>
      <form action="" className="agree-form" >
        <div className="agree-form-field">
          <label htmlFor="floorNo">Floor No <span className="required"> *</span></label>
          <select name="" id="floorNo">
          <option value="-">{values.floorNo?(values.floorNo):("Select Floor")}</option>
            <option value="G">G</option>
            {Array.from({ length: 50 }, (_, i) => (
              <option
                value={`${i + 1}`}
                defaultValue={values.floorNo}
                onClick={handleFormData("floorNo")}
              >
                {i + 1}
              </option>
            ))}
          </select>
        </div>
        <div className="agree-form-field">
          <label htmlFor="">House Type <span className="required"> *</span></label>
          {/* <input type="text" id="notice" placeholder="Notice Period" defaultValue={values.bhkType}  onChange={handleFormData("bhkType")}/> */}
          <ChipV1
            chipList={chipList?.propType}
            value={values?.propType}
            onClick={(e) => {
              handleFormData("propType")({ target: { value: e } });
            }}
          />
        </div>
        <div className="agree-form-field">
          <label htmlFor="">BHK Type <span className="required"> *</span></label>
          {/* <input type="text" id="notice" placeholder="Notice Period" defaultValue={values.bhkType}  onChange={handleFormData("bhkType")}/> */}
          <ChipV1
            chipList={chipList?.bhkType}
            value={values?.bhkType}
            onClick={(e) => {
              handleFormData("bhkType")({ target: { value: e } });
            }}
          />
        </div>
        <div className="agree-form-field">
          <label htmlFor="houseno">House No <span className="required"> *</span></label>
          <input
            type="text"
            id="houseno"
            placeholder="House No"
            defaultValue={values.houseNo}
            onChange={handleFormData("houseNo")}
          />
        </div>

        <div className="agree-form-field">
          <label htmlFor="houseaddr">House Address <span className="required"> *</span></label>
          <input
            type="text"
            id="houseaddr"
            placeholder="House Address"
            defaultValue={values.houseaddr}
            onChange={handleFormData("houseaddr")}
          />
        </div>
        {/* <div className="agree-form-field">
          <label htmlFor="locality" >Locality</label>
          <input type="text" id="locality"  placeholder="Locality" defaultValue={values.locality}  onChange={handleFormData("locality")}/>
        </div> */}
        <div className="agree-form-field">
          <label htmlFor="pincode">Pincode <span className="required"> *</span></label>
          <input
            type="number"
            id="pincode"
            placeholder="Pincode"
            defaultValue={values.pincode}
            onChange={handleFormData("pincode")}
          />
        </div>
        <div className="agree-form-field">
          {/* <label htmlFor="houseaddr" >House Address</label> */}
          {/* <input type="text" id=""  placeholder="" defaultValue={values.houseaddr}/> */}
        </div>
        
      </form>
      <div className="agree-btn-container">
      {error && <p className="error"><strong>{error}</strong></p>}
          <button className="agree-btn" onClick={prevStep}>
            Previous
          </button>
          <button className="agree-btn" type="submit" onClick={submitFormData}>
            Next
          </button>
        </div>
    </>
  );
};

export default Index;
