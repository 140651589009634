import React from "react";

const youtube = () => {
  return (
    <>
      <svg
        width="30"
        height="30"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_169_7)">
          <path
            d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
            fill="white"
          />
          <path
            d="M32.4776 13.1293C32.1901 11.8976 31.3429 10.9274 30.2673 10.5982C28.3177 10 20.5 10 20.5 10C20.5 10 12.6823 10 10.7327 10.5982C9.65706 10.9275 8.80992 11.8976 8.5224 13.1293C8 15.362 8 20.0202 8 20.0202C8 20.0202 8 24.6784 8.5224 26.9111C8.80992 28.1429 9.65706 29.0726 10.7327 29.4018C12.6823 30 20.5 30 20.5 30C20.5 30 28.3177 30 30.2673 29.4018C31.3429 29.0726 32.1901 28.1429 32.4776 26.9111C33 24.6784 33 20.0202 33 20.0202C33 20.0202 33 15.362 32.4776 13.1293V13.1293ZM17.9432 24.2495V15.7909L24.4772 20.0203L17.9432 24.2495V24.2495Z"
            fill="#FF8504"
          />
        </g>
        <defs>
          <clipPath id="clip0_169_7">
            <rect width="40" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default youtube;
