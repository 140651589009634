import React from "react";
import "./style.css";
import Footer from "../../layouts/Footer";
import contact from "../../assets/contact.jpg";
import { Button } from "react-bootstrap";
import location from "../../assets/locations.png";
import call from "../../assets/call.png";
import Home from "../../assets/svg/home";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import contactUs from "../../api/contactUs/contactUs";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <p>Submitting your details please wait!</p>
    </div>
  );
};


export default function Index() {
  const [isLoading, setIsLoading] = React.useState(false);
  const schema = yup.object().shape({
    email: yup.string().email().required("Enter a valid email ID"),
    name: yup.string().max(32).required("Enter a valid Name"),
    message: yup.string().max(50).required("Provide a valid message"),
    phone_number: yup
    .string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Enter a valid phone number"
    ),
  });
  type ContactType= {
    email: string;
    name: string;
    message: string;
    phone_number: string;
}
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ContactType>({
    resolver: yupResolver(schema)
  });
  const history = useHistory();
  const handleCreate = async (e) => {
    await contactUs(e).then(
      (res) => {
        if (res[0] == 200){
          alert("Thank You for your message! Your message has been sent to Trupops team!")
        }
        else {
          alert("Something went wrong!")
        }
      }
    );
    reset();
  };
  const onSubmit = async(e) => {
   try {
    setIsLoading(true);
    await handleCreate(e);
    setIsLoading(false)
   } catch (error) {
    alert(error);
    setIsLoading(false)
   }
    
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bnr-bg">
        {/* <div className="home-icon"><Home/></div> */}
        <div className="bnr-content">
          <img
            onClick={() => history.push("./")}
            className="brand-logo"
            src="https://truprops.com/static/media/brandlogo.106f7c99d4a8ffbd67c4.png"
            alt="truprops"
          />

          <button className="list-your-prop">Contact Us</button>
        </div>
      </div>
      <div className="heading-1">Contact us</div>
      <div className="contact-truprops">
        <div className="contact-left">
          <div className="image-1 ">
            <img src={contact} className="contactus-img"></img>
          </div>
        </div>

        <div className="contact-right">
          <div className=" icons-allcontents">
            <div className="get-touch">Get in Touch</div>
            <div className="icon-contents1">
              {/* <div className="d-flex adress-line1 ">
                <div>
                  <img src={location} className="location"></img>
                </div>
                <div className="address-1 ms-2">
                  XBi4, Panpozhi, Tenkasi Tamilnadu - 627807
                </div>
              </div> */}
              <div className="d-flex adress-line2">
                {/* <div>
                  <img src={call} className="calls"></img>
                </div> */}
                <div className="ph-number ms-2 mt-2">kindly fill your details and our team will contact you soon!</div>
              </div>
            </div>
          </div>
          {isLoading ? <LoadingScreen/> : 
          <form
            className="contact-form-inputs"
            id="contact-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <label>Name</label>
            <input type="text" placeholder="Name" {...register("name" )} />
            <p>{errors.name?.message}</p>
            <label>Email</label>
            <input type="text" placeholder="Email" {...register("email")} />
            <p>{errors.email?.message}</p>
            <label>Mobile</label>
            <input
              type="text"
              placeholder="Mobile Number"
              {...register("phone_number")}
            />
            { errors.phone_number  && <p>{errors.phone_number.message }</p>}
            <label>Message</label>
            <textarea placeholder="Message" {...register("message")} />
            <p>{errors.message?.message}</p>
            <button className="list-your-prop">Submit</button>
          </form> }
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
}
