import React from 'react'

const truBlackLogo = () => {
  return (
    <>
    <svg width="150" height="50" viewBox="0 0 600 190" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="91.7302" y="90.0753" width="12.6437" height="12.145" fill="black"/>
<rect x="106.48" y="90.0753" width="12.6437" height="12.145" fill="black"/>
<rect x="106.48" y="104.244" width="12.6437" height="12.145" fill="black"/>
<rect x="91.7302" y="104.244" width="12.6437" height="12.145" fill="black"/>
<rect x="140.197" y="69.8337" width="12.6437" height="24.29" fill="black"/>
<path d="M104.374 50.6044L140.724 82.4849L177.075 114.366L104.374 74.8943L37.9943 110.317L104.374 50.6044Z" fill="black"/>
<path d="M9.53445 94.6239C9.53445 146.899 60.0719 186.223 101.212 186.223C49.5439 188.603 -2.04314 146.805 0.0623359 93.7918C0.0623359 41.517 37.9631 3.05843 83.2335 0C43.2271 10.1948 9.53445 42.3491 9.53445 94.6239Z" fill="black"/>
<path d="M119.526 172.291C171.669 165.393 200.094 121.318 197.989 79.5192C205.358 128.454 171.669 174.947 119.526 180.447C68.5798 185.821 24.6285 152.088 19.0129 102.967C31.6464 148.844 69.5472 178.904 119.526 172.291Z" fill="black"/>
<path d="M238.553 48.1792C240.266 48.1792 241.694 48.6849 242.836 49.6964C243.978 50.7079 244.478 51.909 244.335 53.2997V66.3855H251.402C253.686 66.3855 255.399 67.2705 256.541 69.0406C257.683 70.5578 257.683 72.2014 256.541 73.9715C255.399 75.7415 253.686 76.5633 251.402 76.4369H244.335V96.1604C244.335 98.0569 244.692 99.3212 245.406 99.9534C245.834 100.459 246.548 100.775 247.547 100.902C248.689 101.281 250.117 101.218 251.83 100.712C254.114 100.08 256.113 100.522 257.826 102.039C259.253 103.304 259.682 104.947 259.111 106.97C258.54 108.867 257.183 110.068 255.042 110.574L250.973 111.143C244.692 111.522 239.981 110.005 236.84 106.591C234.27 103.936 232.985 100.459 232.985 96.1604V76.4369H229.559C227.275 76.5633 225.633 75.7415 224.634 73.9715C223.492 72.2014 223.492 70.5578 224.634 69.0406C225.633 67.2705 227.275 66.3855 229.559 66.3855H232.985V53.2997C232.985 51.2768 233.985 49.6964 235.983 48.5585C236.84 48.3056 237.696 48.1792 238.553 48.1792Z" fill="black"/>
<path d="M285.418 66.0062C293.27 66.0062 299.194 69.1038 303.192 75.299C304.334 77.1955 304.334 79.0288 303.192 80.7988C301.907 82.4424 300.122 83.201 297.838 83.0746C295.554 82.8217 293.984 81.7471 293.127 79.8506C292.128 78.0805 290.843 77.0058 289.273 76.6265C288.131 76.3737 286.846 76.2472 285.418 76.2472C281.706 76.2472 279.208 77.1323 277.923 78.9023C277.78 79.1552 277.566 79.4713 277.281 79.8506C276.567 81.2413 276.139 82.6953 275.996 84.2125V106.022C275.996 107.919 275.139 109.373 273.426 110.384C271.428 111.396 269.5 111.396 267.644 110.384C265.789 109.373 264.861 107.919 264.861 106.022V84.2125C265.574 75.4886 269.929 69.8624 277.923 67.3337C280.207 66.4487 282.706 66.0062 285.418 66.0062Z" fill="black"/>
<path d="M318.631 66.0062C320.201 66.0062 321.629 66.5119 322.914 67.5234C324.056 68.5349 324.627 69.7992 324.627 71.3164V92.9364C324.627 94.7064 324.984 96.1604 325.697 97.2983C326.697 98.9419 327.91 100.017 329.338 100.522C330.623 100.902 332.05 101.091 333.621 101.091C337.19 101.091 339.617 100.143 340.901 98.2465L341.544 97.2983C342.258 95.9075 342.615 94.4535 342.615 92.9364V71.3164C342.615 69.2934 343.614 67.7763 345.613 66.7648C347.611 65.7533 349.467 65.7533 351.18 66.7648C353.179 67.7763 354.178 69.2934 354.178 71.3164V92.9364C353.322 101.66 348.896 107.35 340.901 110.005C338.617 110.763 336.19 111.143 333.621 111.143C326.768 111.143 321.415 108.867 317.56 104.315C316.846 103.557 316.204 102.735 315.633 101.85C313.92 98.9419 313.063 95.9707 313.063 92.9364V71.3164C313.063 69.0406 314.134 67.4602 316.275 66.5751C316.989 66.1958 317.774 66.0062 318.631 66.0062Z" fill="black"/>
<path d="M384.97 66.1958H384.328C380.759 65.943 377.404 66.5751 374.263 68.0923C369.409 70.3681 366.269 74.4772 364.841 80.4195C364.27 82.4424 363.984 84.5918 363.984 86.8676V123.659C363.984 125.556 364.841 127.01 366.554 128.021C368.553 129.033 370.551 129.033 372.55 128.021C374.263 127.01 375.12 125.556 375.12 123.659V109.246C378.26 110.51 381.33 111.143 384.328 111.143C388.468 110.763 391.965 109.752 394.821 108.108C400.103 104.947 403.315 99.9534 404.457 93.126C404.6 91.7352 404.671 90.218 404.671 88.5744C404.671 79.5977 401.388 73.1496 394.821 69.2302C391.823 67.3337 388.539 66.3855 384.97 66.3855V66.1958ZM375.12 92.9364V86.8676C375.12 81.5574 376.619 78.2702 379.617 77.0058C380.901 76.3737 382.258 76.1208 383.685 76.2472C383.971 76.2472 384.185 76.2472 384.328 76.2472L386.683 76.6265C391.109 78.3966 393.322 82.3792 393.322 88.5744C393.322 94.011 391.751 97.7408 388.611 99.7637C387.183 100.649 385.755 101.091 384.328 101.091C379.617 100.459 376.761 99.0051 375.762 96.7293C375.334 95.7179 375.12 94.4535 375.12 92.9364Z" fill="black"/>
<path d="M434.627 66.0062C442.478 66.0062 448.403 69.1038 452.4 75.299C453.542 77.1955 453.542 79.0288 452.4 80.7988C451.115 82.4424 449.331 83.201 447.047 83.0746C444.763 82.8217 443.192 81.7471 442.336 79.8506C441.336 78.0805 440.051 77.0058 438.481 76.6265C437.339 76.3737 436.054 76.2472 434.627 76.2472C430.915 76.2472 428.416 77.1323 427.132 78.9023C426.989 79.1552 426.775 79.4713 426.489 79.8506C425.775 81.2413 425.347 82.6953 425.204 84.2125V106.022C425.204 107.919 424.348 109.373 422.635 110.384C420.636 111.396 418.709 111.396 416.853 110.384C414.997 109.373 414.069 107.919 414.069 106.022V84.2125C414.783 75.4886 419.137 69.8624 427.132 67.3337C429.416 66.4487 431.914 66.0062 434.627 66.0062Z" fill="black"/>
<path d="M483.043 66.1958C478.76 66.1958 474.906 67.2705 471.48 69.4199C465.055 73.2129 461.843 79.5977 461.843 88.5744C461.701 97.0454 464.342 103.304 469.767 107.35C473.621 109.878 478.047 111.143 483.043 111.143C487.469 111.143 491.466 110.131 495.035 108.108C501.459 104.315 504.671 97.804 504.671 88.5744C504.671 79.977 501.459 73.5289 495.035 69.2302C491.609 67.2073 487.612 66.1958 483.043 66.1958ZM472.979 89.333V88.5744C472.979 83.1378 474.549 79.5345 477.69 77.7644C479.403 76.753 481.187 76.2472 483.043 76.2472C490.039 76.2472 493.536 80.3563 493.536 88.5744C493.536 97.0454 490.039 101.281 483.043 101.281C476.334 101.281 472.979 97.2983 472.979 89.333Z" fill="black"/>
<path d="M534.91 66.1958H534.268C530.699 65.943 527.344 66.5751 524.203 68.0923C519.35 70.3681 516.209 74.4772 514.781 80.4195C514.21 82.4424 513.925 84.5918 513.925 86.8676V123.659C513.925 125.556 514.781 127.01 516.494 128.021C518.493 129.033 520.492 129.033 522.49 128.021C524.203 127.01 525.06 125.556 525.06 123.659V109.246C528.201 110.51 531.27 111.143 534.268 111.143C538.408 110.763 541.906 109.752 544.761 108.108C550.043 104.947 553.255 99.9534 554.397 93.126C554.54 91.7352 554.611 90.218 554.611 88.5744C554.611 79.5977 551.328 73.1496 544.761 69.2302C541.763 67.3337 538.479 66.3855 534.91 66.3855V66.1958ZM525.06 92.9364V86.8676C525.06 81.5574 526.559 78.2702 529.557 77.0058C530.842 76.3737 532.198 76.1208 533.626 76.2472C533.911 76.2472 534.125 76.2472 534.268 76.2472L536.624 76.6265C541.049 78.3966 543.262 82.3792 543.262 88.5744C543.262 94.011 541.692 97.7408 538.551 99.7637C537.123 100.649 535.696 101.091 534.268 101.091C529.557 100.459 526.702 99.0051 525.702 96.7293C525.274 95.7179 525.06 94.4535 525.06 92.9364Z" fill="black"/>
<path d="M580.07 65.8166C584.21 65.8166 587.779 66.5119 590.777 67.9027C595.631 70.3049 597.701 73.0232 596.987 76.0576C596.702 77.9541 595.345 79.2184 592.918 79.8506C590.777 80.3563 588.992 79.9138 587.565 78.523C585.566 75.8679 582.354 74.7933 577.929 75.299C577.643 75.299 577.429 75.3622 577.286 75.4886C574.859 75.7415 573.217 76.8162 572.361 78.7127C571.933 79.7241 572.075 80.7988 572.789 81.9367C574.502 82.9482 577.286 83.3907 581.141 83.2643C583.425 83.2643 585.138 83.3907 586.28 83.6436C590.848 84.0229 594.56 86.0458 597.415 89.7123C599.271 91.9881 600.128 94.4535 599.985 97.1086C600.128 101.154 598.272 104.505 594.417 107.16C592.847 108.171 591.205 108.93 589.492 109.436C581.926 111.585 575.216 111.585 569.363 109.436C564.652 107.413 561.868 105.011 561.011 102.229C560.44 100.08 561.368 98.3729 563.795 97.1086C566.079 95.8443 568.149 95.9075 570.005 97.2983C570.291 97.4247 570.505 97.5511 570.648 97.6776C573.075 100.459 577.357 101.407 583.496 100.522C585.923 100.396 587.565 99.5741 588.421 98.0569C588.992 97.1718 588.992 96.2236 588.421 95.2121C587.137 93.9478 584.852 93.3156 581.569 93.3156C574.431 93.5685 569.22 92.3042 565.937 89.5227C562.368 86.8676 560.726 83.3907 561.011 79.092C561.011 76.3105 561.939 73.845 563.795 71.6957C567.079 67.6498 572.361 65.6901 579.642 65.8166H580.07Z" fill="black"/>
</svg>
    </>
  )
}

export default truBlackLogo