import { GET } from "../functions";

const index = async (user_key) => {
  const path = `/users/${user_key}/payments`;
  return await GET(path)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      // console.log("erroruser info from db > ", result);
      return err;
    });
};

export default index;
