import React from 'react'

const manageprop = () => {
  return (
    <>
     <svg width="43" height="39" viewBox="0 0 43 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.875 34.625V15.875L14 6.5L27.125 15.875V17.1406C26.4062 17.4844 25.7344 17.8825 25.1094 18.335C24.4844 18.7887 23.9062 19.2969 23.375 19.8594V17.75L14 11.0938L4.625 17.75V30.875H8.375V21.5H19.625V30.875H19.7656C19.8594 31.5312 20 32.1719 20.1875 32.7969C20.375 33.4219 20.6094 34.0312 20.8906 34.625H15.875V25.25H12.125V34.625H0.875ZM42.125 0.875V19.8594C41.5938 19.2969 41.0156 18.7887 40.3906 18.335C39.7656 17.8825 39.0938 17.4844 38.375 17.1406V4.625H21.5V7.25L17.75 4.53125V0.875H42.125ZM30.875 12.125H34.625V8.375H30.875V12.125ZM32.75 38.375C30.1562 38.375 27.9456 37.4613 26.1181 35.6338C24.2894 33.805 23.375 31.5938 23.375 29C23.375 26.4062 24.2894 24.195 26.1181 22.3662C27.9456 20.5387 30.1562 19.625 32.75 19.625C35.3438 19.625 37.555 20.5387 39.3838 22.3662C41.2113 24.195 42.125 26.4062 42.125 29C42.125 31.5938 41.2113 33.805 39.3838 35.6338C37.555 37.4613 35.3438 38.375 32.75 38.375ZM31.8125 34.625H33.6875V29.9375H38.375V28.0625H33.6875V23.375H31.8125V28.0625H27.125V29.9375H31.8125V34.625Z" fill=""/>
</svg>
   
    </>
  )
}

export default manageprop