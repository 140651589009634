import { MAPBOX_TOKEN } from "../const";

const get_uri = (searchText, country = "in") => {
  const countries = country;
  const autocomplete = "true";
  const types = "country,region,district,postcode,locality,place";
  const limit = "10";
  return `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchText}.json?access_token=${MAPBOX_TOKEN}&autocomplete=${autocomplete}&country=${countries}&types=${types}&limit=${limit}`;
};

export const GET = async (
  query?: string,
  country?: string,
  signal?: AbortSignal
) => {
  const endpoint = get_uri(query, country);
  const res = await fetch(endpoint, {
    method: "GET",
    mode: "cors"
    // signal: controller.signal,
    // signal: signal,
  })
    .then(async (res) => {
      // console.log(t)
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`error in mapbox api >`, error);
      return [500, error];
    });
  return res;
};

interface Props {
  query?: any;
  countryList?: "in";
  signal?: AbortSignal;
}
const geoCordApi = async ({ query, countryList }: Props) => {
  const country = countryList;
  const res = await GET(query, country)
    .then(([status, res]) => {
      let data: any = [];
      if (status == 200) {
        data = res;
        // res?.map((district) => {
        //   data = [
        //     ...data,
        //     {
        //       ...district,
        //       label: `${district.district_code} - ${district.district_name}`,
        //       value: district.district_id,
        //     },
        //   ];
        // });
      } else {
        console.log("error > ", res);
        data = "Error";
      }
      return { status, data };
    })
    .catch((err) => console.log(err));

  // ----------------------------
  return res;
};
export default geoCordApi;
