import { GET } from "../functions";

const getUserPlan = async (userId) => {
  const path = `/users/${userId}/user_plans`;
  return await GET(path)
    .then((result) => {
      // console.log("get favorite list > ", result);
      return result;
    })
    .catch((err) => {
      return err;
    });
};

export default getUserPlan;
