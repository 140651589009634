import React, { useContext, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import Dropdown from "../../../../components/Dropdown/Profile/V1";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../../../assets/svg/truBlackLogo";

function Navbar({ children }) {
  const { userAttr } = useContext(AuthContext);
  const [isMobile, setIsMobile] = useState(false);

  const toggleNav = () => {
    setIsMobile(!isMobile);
  };
const location = useLocation();

const NavMenu = [
  {
  label: "Home",
  path : "/agent/dashboard"
},
{
  label: "Prop Nearby",
  path : "/agent/property-nearby"
},
{
  label: "My Bucket",
  path : "/agent/my-bucket"
},
{
  label: "Earning",
  path : "/agent/payments"
},
{
  label: "Profile",
  path : "/agent/profile"
},
]
  return (
    <main className="mb-1">
      <nav className="agnt-navbar">
        <div className="logo">
          <Link to="/"><Logo /></Link>
        </div>
        <div className="agnt-name ">
          <div className="agent-welcome ms-2">
            <span className="agent-welcome ">Welcome </span>
            {userAttr ? (
              <span>
                {userAttr?.["custom:first_name"] +
                  " " +
                  userAttr?.["custom:last_name"]}
              </span>
            ) : null}
          </div>
        </div>
        <ul className={isMobile ? "nav-links-mobile" : "nav-links"}>
          <div className="d-flex agnt-name-mob">
            <div className="agent-welcome ms-2">
              <span className="agent-welcome ">Welcome </span>
              {userAttr ? (
                <span>
                  {userAttr?.["custom:first_name"] +
                    " " +
                    userAttr?.["custom:last_name"]}
                </span>
              ) : null}
            </div>
            <Dropdown />
          </div>
          {NavMenu?.map((menu)=> (
            <>
            <li className={`agt-nav-menu ${menu?.path===location?.pathname?'actv':'inact'}`}>
            <Link to={menu.path} onClick={toggleNav}>{menu.label}</Link>
          </li>
          </>
          ))}
        </ul>
        <button className="mobile-menu-icon" onClick={toggleNav}>
          {isMobile ? (
            <div> &#10005;</div>
          ) : (
            <div> &#9776;</div>
          )}
        </button>
        <Dropdown />
      </nav>
      {children}
    </main>
  );
}

export default Navbar;
