import sc from "styled-components";

export const Card = sc.div`


// color: #000 ;
//  max-width: 600px;
margin: 16px 8px;
background-color: #fff;

padding:24px;
  min-width: 500px;
  width:600px;
  display:flex;
  flex-direction: row;
  overflow:hidden;
justify-content: space-between;
  align-items:center;

  -webkit-box-shadow: 1px 1px 19px 3px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 1px 1px 19px 3px rgba(0, 0, 0, 0.24);
  box-shadow: 1px 1px 19px 3px rgba(0, 0, 0, 0.24);
  border-radius: 16px;
  overflow: hidden;
  
  .details{
    .date{
      span{
        background-color: #F1EEEE;
        color: #7a7979;
        padding: 2px 8px;
      }
    }

    .name{
      font-size: 24px;
      color: var(--secondary-color);
      font-weight: bold;
      text-transform: uppercase;
    }

    .description{
      color:#9a9898;
    }
  }

  .profile{
    .img{
      background-color: var(--secondary-color);
      border-radius: 50%;
      padding: 8px;
      display:flex;
      justify-content:center;
      align-items:center;
    }
  }

`;
