import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { uploadImage } from "../../api/uploadImage";
import { LocationSelector } from "./components/LocationSelector";
import { AuthContext } from "../../context/AuthContext";
import applyAsAgent from "../../api/Agent/applyAgent";
import {useHistory} from "react-router-dom"
import { validate } from "../../utils/aadhaarValidate";

type FormData = {
  name: string;
  email: string;
  phone: string;
  address: string;
  lat?: string;
  lon?: string;
  photo_src: string;
  aadhaar: string;
  country: string;
  state: string;
  city: string;
  zip_code: string;
};

export default function Apply() {
  const history = useHistory();
  const validateAadhaar = (aadhaarNumber) => {
    const isValid = validate(aadhaarNumber); // Use your custom validate function
    return isValid;
  };
  const schema = yup.object().shape({
    name: yup
      .string()
      .required("Field cannot be empty")
      .min(2, "Min. 2 char Required"),
    phone: yup
      .number()
      .min(10, "Min Invalid number")
      .required("Invalid number"),
    address: yup.string().required("Address Field cannot be empty"),
    email: yup.string().email().required("Invalid Email"),
    aadhaar: yup
      .string()
      .required("Field cannot be empty")
      .test("aadhaar-validation", "Invalid Aadhaar number", (value) =>
      validateAadhaar(value)
    ).min(12).max(12),
    country: yup
      .string()
      .required("Country Field cannot be empty")
      .not(["-"], "select country"),
    city: yup.string().not(["-"], "select city"),
    state: yup.string().not(["-"], "select state"),
    zip_code: yup.string().required("zip code Field cannot be empty"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const REQUEST_PARAMS = {
    method: "GET",
    headers: {
      "X-CSCAPI-KEY":
        "QW94aFRROVVKNHJBdXZmSFZKeEtabHc3dlhzenBCQVJYWENHRkJxNA==",
    },
  };
  const { userInfo, agentData } = useContext(AuthContext);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [currentCountry, setCurrentCountry] = useState("");
  const [imageList, setImageList] = useState([]);
  const [uploadData, setUploadData] = useState([]);
  const [locationData, setLocationData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const user_key = userInfo?.user_key;
  const addImage = (image, filename) => {
    setImageList([...imageList, { file: image, name: filename }]);
  };
  console.log("locationData from main component",locationData)

  const LoadingScreen = ()=>{
    return (
      <div className="spinner-border" role="status">
</div>
    )
  }
  const uploadAgentData = async (data) => {
    try {
    // await setTimeout(async () => {
      await applyAsAgent(
        {
          ...data,
          lat: locationData?.lat,
          lon: locationData?.lon,
          user_key: user_key,
        },
        user_key
      );
    // }, 5000);
      console.log("DATA>>>>>>",{
        ...data,
        lat: locationData?.lat,
        lon: locationData?.lon,
        user_key: user_key,
      })
      setImageList([])
    } catch (error) {
      alert("something went wrong!");
      console.log("error in post agent data", error);
    }
    
    // alert("Successfully Applied")
    // ?wait 1000ms to update state
  };

  const onSubmit = async (data) => {
    setIsLoading(true)
    let links = [];
      console.log(imageList)
      try {
       const imageResponse=await uploadImage(imageList[0]?.name, imageList[0]?.file, "path/")
        links = [...links, { img_src: imageResponse}];
        console.log(imageResponse)
      } catch (error) {
        console.log(error)
      }
    console.log("Image upload completed")
    // setUploadData(data)
    await uploadAgentData({...data,photo_src:links[0].img_src} );
    reset();
    setIsLoading(false);
    alert("Applied successfully")
    window.location.reload()
  };
  // console.log("inint", initialFormData)
  // const onSubmit = async (formData) => {
  //   setIsUploading(true);
  //   const data = {
  //     ...initialFormData,
  //     ...formData,
  //   };
  //   console.log("data",data);
  //   try {
  //     const result = await addAgentInDb(data, userKey);
  //     console.log("Result:", result);
  //     // do something with the result here
  //   } catch (error) {
  //     console.error("Error adding agent:", error);
  //     // handle error here
  //   }
  // };

  const getCountries = () => {
    return fetch(
      "https://api.countrystatecity.in/v1/countries/",
      REQUEST_PARAMS
    )
      .then((_) => _.json())
      .then((response) => {
        // console.log(response);
        return response;
      });
  };
  const getStatesByCountry = (countryIso2) => {
    return fetch(
      `https://api.countrystatecity.in/v1/countries/${countryIso2}/states`,
      REQUEST_PARAMS
    )
      .then((_) => _.json())
      .then((response) => {
        // console.log(response);
        return response;
      });
  };
  const getCitiesByStateAndCountry = (countryIso2, stateIso2) => {
    return fetch(
      `https://api.countrystatecity.in/v1/countries/${countryIso2}/states/${stateIso2}/cities`,
      REQUEST_PARAMS
    )
      .then((_) => _.json())
      .then((response) => {
        // console.log(response);
        return response;
      });
  };
  const handleCountryChange = (e) => {
    const iso2 = e.target.value.split("-")[1];
    // console.log(e.target.value)
    setCurrentCountry(e.target.value);
    // console.log(iso2);
    getStatesByCountry(iso2).then((_) => {
      setStates(_);
    });
  };
  const handleStateChange = (e) => {
    const iso2 = e.target.value.split("-")[1];
    const countryIso = currentCountry.split("-")[1];
    // console.log(iso2);
    getCitiesByStateAndCountry(countryIso, iso2).then((_) => setCities(_));
  };

  useEffect(() => {
    getCountries().then((_) => setCountries(_));
  }, []);
  return (
    <>
    
    
        <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label htmlFor="name" className="form-label">Full Name</label>
        <input
          id="name"
          type="text"
          name="name"
          className={`form-control`}
          placeholder="Enter Your Full name"
          {...register("name")}
        />
        <div>
          <span className="text-danger">{errors.name?.message}</span>
        </div>
      </div>
      <div>
        <label htmlFor="email" className="form-label">Email</label>
        <input
          id="email"
          type="email"
          name="email"
          className={`form-control`}
          placeholder="Enter Your Email"
          {...register("email")}
        />
        <div>
          <span className="text-danger">{errors.email?.message}</span>
        </div>
      </div>
      <div>
        <label htmlFor="phone" className="form-label">Phone</label>
        <input
          id="phone"
          type="tel"
          name="phone"
          className={`form-control`}
          placeholder="Enter Mobile number"
          {...register("phone")}
        />
        <div>
          <span className="text-danger">{errors.phone?.message}</span>
        </div>
      </div>
      <div>
        <label htmlFor="address" className="form-label">
          Address
        </label>
        <input
          id="address"
          type="tel"
          name="address"
          className={`form-control`}
              placeholder="Enter full address"
          {...register('address')}
        />
        <div>
          <span className="text-danger">{errors.address?.message}</span>
        </div>
      </div>
      <div>
        <label htmlFor="aadhaar" className="form-label">
          Aadhaar Number
        </label>
        <input
          id="aadhaar"
          type="number"
          name="aadhaar"
          className={`form-control`}
              placeholder="Enter 12 Digit Aadhaar Number"
          {...register('aadhaar')}
        />
        <div>
          <span className="text-danger">{errors.aadhaar?.message}</span>
        </div>
      </div>
      <div className="row col-12 mt-3  ">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label htmlFor="country" className="form-label">
            Country
          </label>
          <select
            id="country"
            name="country"
            className={`form-select `}
            aria-label="Default select example"
            {...register("country")}
            onChange={(_) => {
              handleCountryChange(_);
            }}
          >
            <option value="-">-</option>
            {countries
              ? countries.map((country) => (
                  <option
                    key={country.id}
                    value={`${country.name}-${country.iso2}`}
                  >
                    {country.name}
                  </option>
                ))
              : ""}
          </select>
          <div>
            <span className="text-danger">{errors.country?.message}</span>
          </div>
        </div>
        {states.length > 0 && (
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label htmlFor="state" className="form-label">
              State
            </label>
            <select
              id="state"
              name="state"
              className={`form-select`}
              aria-label="Default select example"
              {...register("state")}
              onChange={(_) => {
                handleStateChange(_);
              }}
            >
              <option value="-">-</option>
              {states
                ? states.map((state) => (
                    <option
                      key={state.id}
                      value={`${state.name}-${state.iso2}`}
                    >
                      {state.name}
                    </option>
                  ))
                : ""}
            </select>
            <div>
              <span className="text-danger">{errors.state?.message}</span>
            </div>
          </div>
        )}

        {cities.length > 0 && (
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label htmlFor="city" className="form-label">
              City
            </label>
            <select
              id="city"
              name="city"
              className={`form-select`}
              aria-label="Default select example"
              {...register("city")}
              onChange={(_) => {
                setValue("city", _.target.value);
              }}
            >
              <option value="-">-</option>
              {cities.length > 0
                ? cities.map((city) => (
                    <option key={city.id} value={city.name}>
                      {city.name}
                    </option>
                  ))
                : ""}
            </select>
            <div>
              <span className="text-danger">{errors.city?.message}</span>
            </div>
          </div>
        )}

        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <label htmlFor="zip_code" className="form-label">
            Zip Code
          </label>
          <input
            autoComplete={"nope"}
            id="zip_code"
            name="zip_code"
            placeholder="Enter Zip code / Postal Code"
            type="text"
            className={`form-control`}
            {...register("zip_code")}
          />
          <div>
            <span className="text-danger">{errors.zip_code?.message}</span>
          </div>
        </div>
      </div>
      <div>
        <label htmlFor="ImageUpload" className="form-label">
          Upload your image
        </label>
        <input
          id="imageUpload"
          name="imageUpload"
          type="file"
          accept="image/*"
          className={`form-control`}
          onChange={({ target }) => {
            addImage(target.files[0], target.files[0].name.split(".")[0]);
          }}
          // {...register("zip_code")}
        />
      </div>
      <div>
        <LocationSelector setLocationData={setLocationData} />
      </div>
      {
      isLoading ? <LoadingScreen/> : (<button className="list-your-prop" onClick={() => handleSubmit}>
            Submit Application
          </button>)}
    </form>
    </>
  );
}
