import React from "react";
import "./styles.css";
const PaymentHistoryCard = (props) => {
  // const { tracking_id, order_id, payment_mode, plan_name, price, order_date } =
  //   props?.payment;
  // const date = new Date(order_date);


  return (
    <div className="" style={{
      height:"auto"
    }}>
      <div className="table-responsive">
        <table className="table table-bordered table-striped ">
          <thead className="history-table-head">
            <tr>
              <th>Tracking ID</th>
              <th>Order ID</th>
              <th>Payment Status</th>
              <th>Plan</th>
              <th>Price</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody className="history-table-body">
            {props.payment.map((item, index) => (
              <tr key={index}>
                <td className="text-dark w-auto">{item.tracking_id}</td>
                <td className="w-auto">{item.order_id}</td>
                <td className="w-auto"><div className={item.order_status}>{item.order_status}</div></td>
                <td className="w-auto">{item.plan_name}</td>
                <td className="w-auto">{item.price}</td>
                <td className="w-auto">{item.order_date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>
  );
};

export default PaymentHistoryCard;
