import React, { useEffect, useState } from 'react'
import './style.css'
import Home from '../../../../assets/svg/home'
import ManageProp from '../../../../assets/svg/manageprop'
import Tasks from '../../../../assets/svg/tasks'
import Payments from '../../../../assets/svg/payments'
import Settings from '../../../../assets/svg/settings'
import {useLocation, useHistory} from 'react-router-dom'
import navigate from '../../../../assets/svg/navigate'

export default function Index() {
    const [currentUrl, setCurrentUrl] = useState('/agent/dashboard')
    const location = useLocation()
    const history = useHistory()
    // useEffect(()=>{
    //     location?.host
    // },[])
    
    const icons = [
        {
            label:'Home',
            icon: <Home/>,
            url:'/agent/dashboard'
        },
        {
            label:'Property Nearby',
            icon: <ManageProp/>,
            url:'/agent/property-nearby'
        },
        {
            label:'My Bucket',
            icon: <Tasks/>,
            url:'/agent/my-bucket'
        },
        {
            label:'payments',
            icon: <Payments/>,
            url:'/agent/payments'
        },
        {
            label:'Profile',
            icon: <Settings/>,
            url:'/agent/profile'
        },
    ]
  return (
    <>
      <div className="side-icon-bar-container">
        {icons?.map((icon, idx)=>(
            <div key={idx} className={`icon-card ${icon?.url===location?.pathname?'active':''}`} 
            onClick={()=>history.push(icon?.url)}
            ><figure>{icon?.icon}<figcaption>{icon?.label}</figcaption></figure></div>
        ))}
        </div>  
    </>
  )
}
