import React from 'react'
import { Col, Form, Modal, Row } from "react-bootstrap";

type ApartmentDataType = {
  propertyData: any;
  handleModelShow: () => void;
  show: boolean;
};
export default function Apartment(propertyProps: ApartmentDataType) {
  return (
    <>
    <div className="interprtation-content">Listed specifications</div>
    <div className="prop-details-row">
      {propertyProps.propertyData.listed_for === "RENT" ? (
        <>
          <div className="left-prop-details">
            <table className="w-100">
              <tr>
                <td>Maintenance charge</td>
                <td>{propertyProps.propertyData?.currency_symbol}{propertyProps.propertyData?.maintenance_charge?.toLocaleString("en-IN")}</td>
              </tr>
              <tr>
                <td>Preferred  Tenant</td>
                <td>{propertyProps.propertyData?.pref_tenant}</td>
              </tr>
            </table>
          </div>
          <div className="right-prop-details">
            <table className="w-100">
              <tr>
                <td>Expected Deposit</td>
                <td>{propertyProps.propertyData?.currency_symbol}{propertyProps.propertyData?.expec_deposit?.toLocaleString("en-IN")}</td>
              </tr>
              <tr>
                <td>Pet allowed</td>
                <td>{propertyProps.propertyData?.is_pet_allowed ? "Yes" : "No"}</td>
              </tr>
            </table>
          </div>
        </>
      ) : (
        <>
          <div className="left-prop-details">
            <table className="w-100">
              <tr>
                <td>Token Amount</td>
                <td>{propertyProps.propertyData?.currency_symbol}{propertyProps.propertyData?.token_amt?.toLocaleString("en-IN")}</td>
              </tr>
            </table>
          </div>
          <div className="right-prop-details">
            <table className="w-100">
              <tr>
                <td>Paid Tax</td>
                <td>{propertyProps.propertyData?.paid_tax ? "Yes" : "No"}</td>
              </tr>
            </table>
          </div>
        </>
      )}
    </div>

    <div className="interprtation-content">Listed features</div>
    <div className="prop-details-row">
      <div className="left-prop-details">
        <table className="w-100">
          <tr>
            <td>Buildup area</td>
            <td>{propertyProps.propertyData?.built_area} sqft</td>
          </tr>
          {/* <tr>
            <td>Super buildup area</td>
            <td>{propertyProps.propertyData?.super_builtup} sqft</td>
          </tr> */}
          <tr>
            <td>Parking</td>
            <td>{propertyProps.propertyData?.parking}</td>
          </tr>
          <tr>
            <td>Facing</td>
            <td>{propertyProps.propertyData?.facing}</td>
          </tr>
          <tr>
            <td>Available from</td>
            <td>
              {new Date(
                propertyProps.propertyData?.available_from
              ).toLocaleDateString()}
            </td>
          </tr>
        </table>
      </div>
      <div className="right-prop-details">
        <table className="w-100">
          <tr>
            <td>Bedroom</td>
            <td>{propertyProps.propertyData?.bed}</td>
          </tr>

          <tr>
            <td>Bathrooms</td>
            <td>{propertyProps.propertyData?.bath}</td>
          </tr>
          <tr>
            <td>Kitchen</td>
            <td>{propertyProps.propertyData?.kitchen}</td>
          </tr>
         
          {/* <tr>
            <td>Floor Type</td>
            <td>{propertyProps.propertyData.floor_type}</td>
          </tr> */}
        </table>
      </div>
    </div>
    <div className=" ">
      <button className="btn-details" onClick={propertyProps.handleModelShow}>
        More Details
      </button>
    </div>
    <Modal
      className=""
      size="lg"
      show={propertyProps.show}
      onHide={propertyProps.handleModelShow}
      scrollable={true}
    >
      <Modal.Header closeButton>
        <div className=" fw-bold mt-3 ">Additional Details</div>
      </Modal.Header>
      <Modal.Body
        className="completed-modal-body jsutify-content-center align-items-center"
        style={{ overflow: "scroll" }}
      >
        <Row>
          <Col sm={6} className="mt-3">
            <Form.Label className="req-form fw-bold  ">Address:</Form.Label>
            <div className="w-75  mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData?.address_line_1 +
                " " +
                propertyProps.propertyData?.address_line_2}
            </div>
          </Col>
          {/* Window type */}
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Landmark:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData?.landmark}
            </div>
          </Col>
          <Col sm={6} className="mt-3">
            <Form.Label className="req-form fw-bold ">Furnish:</Form.Label>
            <div className="w-75  mt-1 p-1 shadow-sm rounded">
                {propertyProps.propertyData.furnish}{"FURNISHED"}
            </div>
          </Col>
          {/* Floor type */}
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Surveillance</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData.survillance ? "Yes": "No"}
            </div>
          </Col>

          {/* No. of windows */}
        </Row>
        <Row>
          <Col sm={6} className="mt-3 ">
            <Form.Label className="req-form fw-bold ">Basement:</Form.Label>
            <div className="w-75  mt-1 p-1 mb-3 shadow-sm rounded">
              {propertyProps.propertyData?.has_basement == 1 ? "Yes" : "No"}
            </div>
          </Col>
          {/* Parking Floors */}
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Swimming Pool:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData?.pool == 1 ? "Yes" : "No"}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={6} className="mt-3 ">
            <Form.Label className="req-form fw-bold ">Flooring</Form.Label>
            <div className="w-75  mt-1 p-1 mb-3 shadow-sm rounded">
              {propertyProps.propertyData?.floor_type}
            </div>
          </Col>
          { propertyProps.propertyData.listed_for ==="SALE" ? <Col sm={6} className="mt-3 ">
            <Form.Label className="req-form fw-bold ">Occupancy</Form.Label>
            <div className="w-75  mt-1 p-1 mb-3 shadow-sm rounded">
              {propertyProps.propertyData?.occupancy ? "Yes": "No"}
            </div>
          </Col>
          :<>
          <Col sm={6} className="mt-3 ">
            <Form.Label className="req-form fw-bold ">Non Veg allowed</Form.Label>
            <div className="w-75  mt-1 p-1 mb-3 shadow-sm rounded">
              {propertyProps.propertyData?.non_veg ? "Yes": "No"}
            </div>
          </Col>
          <Col sm={6} className="mt-3 ">
            <Form.Label className="req-form fw-bold ">Water supply</Form.Label>
            <div className="w-75  mt-1 p-1 mb-3 shadow-sm rounded">
              {propertyProps.propertyData?.water_supply ? "Yes": "No"}
            </div>
          </Col>
          <Col sm={6} className="mt-3 ">
            <Form.Label className="req-form fw-bold ">Gated security</Form.Label>
            <div className="w-75  mt-1 p-1 mb-3 shadow-sm rounded">
              {propertyProps.propertyData?.gated_security ? "Yes": "No"}
            </div>
          </Col>
          </>
          }
        </Row>
        <Row>
          <Col sm={6} className="mt-3">
            <Form.Label className="req-form fw-bold  ">Lift:</Form.Label>
            <div className="w-75  mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData?.lift? "Yes": "No"}
            </div>
          </Col>
          {/* Window type */}
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Wifi:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
            {propertyProps.propertyData?.wifi? "Yes": "No"}
            </div>
          </Col>
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Play area:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData.play_area ? "Yes": "No"}
            </div>
          </Col>
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Fire Safety:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData.fire_safety ? "Yes": "No"}
            </div>
          </Col>
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Park:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData.park ? "Yes": "No"}
            </div>
          </Col>
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Gas:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData.gas ? "Yes": "No"}
            </div>
          </Col>
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Sewage:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData.sewage ? "Yes": "No"}
            </div>
          </Col>
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Rain water:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData.rain_water ? "Yes": "No"}
            </div>
          </Col>
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Shopping:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData.shopping ? "Yes": "No"}
            </div>
          </Col>
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Garden:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData.garden ? "Yes": "No"}
            </div>
          </Col>
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Gym:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData.gym ? "Yes": "No"}
            </div>
          </Col>
          {/* No. of windows */}
        </Row>
        <Row>
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Listed On:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData &&
                propertyProps.propertyData?.listed_on?.slice(0, 10)}
            </div>
          </Col>
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Views:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData?.views}
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  </>
  )
}
