import React, { useState, useEffect, useContext } from "react";
import "./style.css";
import { favList } from "../../data/favorites";
// import Navbar from "../../layouts/Agent/V1/Components/Navbar"
import Footer from "../../layouts/Footer";
import { Results } from "../SearchResults/Properties/Components";
import { Layout } from "../../layouts";
import PropertyCard from "../SearchResultsV2/components/PropertyCard";
import getFavoritesList from "../../api/Wishlist/getFavoritesList";
import { AuthContext } from "../../context/AuthContext";

export default function Index() {
  const [favoriteList, setFavoriteList] = useState<any>([]);
  const { userInfo } = useContext(AuthContext);

  const handleSetFavoriteList = async () => {
    const responseList = await getFavoritesList(userInfo?.user_key);
    setFavoriteList(responseList[1]);
  };

  window.scrollTo(0,0)
  useEffect(() => {
    handleSetFavoriteList();
  }, []);
  return (
    <div className="h-25">
      <Layout />
      <div className=" favorite-head fs-2">Favorites</div>
      <div className="favorites-container pt-5">
        <div className="d-flex flex-column flex-wrap favorites-card">
          {/* <Results results={favList} /> */}
          {favoriteList &&
            favoriteList?.map((favorite) => {
              return (
                <PropertyCard key={favorite?.property_id} data={favorite} />
              );
            })}
        </div>
      </div>
      <div
        className=""
        style={{
          marginTop: "183px",
        }}
      >
        <Footer />
      </div>
    </div>
  );
}
