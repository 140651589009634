import React from "react";
import legalServices from "./assets/legalServices.svg";
import premiumProjects from "./assets/premiumProjects.svg";
import rentalGuidance from "./assets/rentalGuidance.svg";
import trustedAgents from "./assets/trustedAgents.svg";
import verifiedProperty from "./assets/verifiedProperty.svg";
import "./style.css";
export default function Index() {
  return (
    <div className="why-section-container">
      <div className="section-l">
        <div className="icons">
          <SvgContainer label="Trusted Agents" svg={trustedAgents} />
          <SvgContainer label="Verified Property" svg={verifiedProperty} />
          <SvgContainer label="Premium Projects" svg={premiumProjects} />
          <SvgContainer label="Rental Guidance" svg={rentalGuidance} />
          <SvgContainer label="Legal Services" svg={legalServices} />
        </div>
      </div>

      <div className="section-r">
        <div className="text">
          <div className="">Why Choose</div>
          <div className="truprops">TRUPROPS ?</div>
        </div>
      </div>
    </div>
  );
}

const SvgContainer = ({ svg, label }) => {
  return (
    <div className="t-icon-container">
      <div className="svg">
        <img src={svg} alt="svg" />
      </div>
      <div className="icon-text">{label}</div>
    </div>
  );
};
