import React from 'react'

const facebook = () => {
  return (
    <>
     <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_349_35)">
<path d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z" fill="#3B5998"/>
<path d="M31.2849 25.9787H26.824V42.3216H20.0653V25.9787H16.8508V20.2352H20.0653V16.5185C20.0653 13.8606 21.3278 9.69873 26.8842 9.69873L31.8906 9.71968V15.2947H28.2581C27.6623 15.2947 26.8244 15.5924 26.8244 16.8603V20.2405H31.8754L31.2849 25.9787V25.9787Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_349_35">
<rect width="50" height="50" fill="white"/>
</clipPath>
</defs>
</svg>
   
    </>
  )
}

export default facebook