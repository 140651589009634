import React, { useState } from "react";

const Index = ({ nextStep, handleFormData, prevStep, values }) => {
  const [error, setError] = useState("");

  const submitFormData = (e) => {
    e.preventDefault();
    if(values.TfullName.length===0 && values.TfatherName.length===0 && values.Tadress.length===0){
      return(setError("* All Fields are Required"))
    }
    if(values.Tphone.length <10 || values.Tphone.length >10){
      return(setError("* Phone number must be 10 digits"))
    }
    if (values.Temail.indexOf("@") == -1 || values.Temail.indexOf(".") == -1) {
      return(setError("* Enter valid email id"))
    }
    else{
      setError("")
      nextStep();
    }
  };

  console.log("error",setError)
  return (
    <>
      <div className="agree-form-header">Tenant Details</div>
      <form action="" className="agree-form" onSubmit={submitFormData}>
        <div className="agree-form-field">
          <label htmlFor="fullname">Full Name <span className="required"> *</span></label>
          <input type="text" id="fullname" placeholder="Full Name" onChange={handleFormData("TfullName")} defaultValue={values.TfullName} required />
        </div>
        <div className="agree-form-field">
          <label htmlFor="fathername">Father/ Mother's Name <span className="required"> *</span></label>
          <input type="text" id="fathername" placeholder="Father/ Mother's Name"  onChange={handleFormData("TfatherName")} defaultValue={values.TfatherName} required/>
        </div>
        <div className="agree-form-field">
          <label htmlFor="phone">Phone Number <span className="required"> *</span></label>
          <input type="number" id="phone"placeholder="Phone Number" defaultValue={values.Tphone}  onChange={handleFormData("Tphone")}  required/>
        </div>
        <div className="agree-form-field">
          <label htmlFor="email">Email <span className="required"> *</span></label>
          <input type="email" id="email"placeholder="Email" defaultValue={values.Temail}  onChange={handleFormData("Temail")}  required/>
        </div>
        <div className="agree-form-field">
          <label htmlFor="address">Permanant Address <span className="required"> *</span></label>
          <input type="text" id="address" placeholder="Permanant Address" defaultValue={values.Tadress}  onChange={handleFormData("Tadress")} required/>
        </div>
        <div className="agree-form-field">
          <label htmlFor="Pan">PAN (optional)</label>
          <input type="text" id="Pan" placeholder="PAN (optional)" defaultValue={values.Tpan} onChange={handleFormData("Tpan")}/>
        </div>
      </form>
      <div className="agree-btn-container">
      {error && <p className="error"><strong>{error}</strong></p>}
          <button className="agree-btn" onClick={prevStep}>
            Previous
          </button>
          <button className="agree-btn" type="submit" onClick={submitFormData}>
            Next
          </button>
        </div>
    </>
  );
};

export default Index;
