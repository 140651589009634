import { PATCH } from "../functions";

const Index = async (agent_key, data) => {
    const path = `/agents/${agent_key}`;
    return await PATCH({apiUri:path, data:data} )
      .then((result) => {
        return result;
      })
      .catch((err) => {
        console.log("error on prop types from db > ", err);
        return err;
      });
  };
  export default Index;