const index = (response) => {
  const price = () => {
    return `${response?.currency_symbol || ""} ${response?.price}`;
  };
  return {
    providerName: "",
    propId: response?.property_key,
    imgSrc: response?.img_src,
    price: price(),
    bed: response?.bed,
    bath: response?.bath,
    listedFor: response?.listed_for,
    openTour: response?.open_tour,
    title: response?.title,
    description: response?.description,
    area: response?.built_area,
    lat: response?.lat,
    lon: response?.lon,
    currencySymbol: response?.currency_symbol,
    areaUnit: response?.area_unit,
    propertyType: response?.property_type_name,
    status: response?.status,
    isApproved: response?.is_approved
  };
};
export default index;
