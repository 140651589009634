import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export { useQuery };

export { default as useAuth } from "./useAuth";
export { default as useApi } from "./useApi";
export {default as useUserRole} from './useUserRole'
