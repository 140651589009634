import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  ButtonGroup,
  Form,
  Button,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import { Controller } from "react-hook-form";
import ChipV1 from "../../../../components/Inputs/Chip/V1/index";
import "./style.css";

interface House {
  title: string;
  description: string;
  price: string;
  bed: string;
  bath: string;
  kitchen: string;
  parking: string;
  built_area: string;
  super_builtup: string;
  address_line_1: string;
  address_line_2: string;
  facing: string;
  landmark: string;
  floor_type: string;
  furnish: string;
  available_from: string;
  has_basement: boolean;
  open_tour: string;
  survillance: string;
  power: string;
  play: string;
  fire_safety: string;
  park: string;
  servant_room: string;
  gas: string;
  sewage: string;
  house_keeping: string;
  rain_water: string;
  pool: string;
  garden: string;
}
export default function Index({
  register,
  errors,
  handleFormData,
  control,
  getValues,
}) {
  const chipList = {
    prefOption: [
      {
        label: "Yes",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
    pricesIn: [
      {
        label: "USD",
        value: 2,
      },
      {
        label: "INR",
        value: 1,
      },
    ],
    prefParkingList: [
      {
        label: "Car",
        value: "CAR",
      },
      {
        label: "Bike",
        value: "BIKE",
      },
      {
        label: "Both",
        value: "BOTH",
      },
      {
        label: "None",
        value: "NONE",
      },
    ],
    prefBedList: [
      {
        label: "1",
        value: 1,
      },
      {
        label: "2",
        value: 2,
      },
      {
        label: "3",
        value: 3,
      },
      {
        label: "4",
        value: 4,
      },
      {
        label: "5",
        value: 5,
      },
      {
        label: "6",
        value: 6,
      },
    ],
    prefBathList: [
      {
        label: "1",
        value: 1,
      },
      {
        label: "2",
        value: 2,
      },
      {
        label: "3",
        value: 3,
      },
      {
        label: "4",
        value: 4,
      },
      {
        label: "5",
        value: 5,
      },
      {
        label: "6",
        value: 6,
      },
    ],
    prefKitchenList: [
      {
        label: "1",
        value: 1,
      },
      {
        label: "2",
        value: 2,
      },
      {
        label: "3+",
        value: 3,
      },
    ],
    prefFacingList: [
      {
        label: "North",
        value: "NORTH",
      },
      {
        label: "South",
        value: "SOUTH",
      },
      {
        label: "East",
        value: "EAST",
      },
      {
        label: "West",
        value: "WEST",
      },
      {
        label: "North-East",
        value: "NORTHEAST",
      },
      {
        label: "South-west",
        value: "SOUTHWEST",
      },
      {
        label: "South-East",
        value: "SOUTHEAST",
      },
      {
        label: "North-West",
        value: "NORTHWEST",
      },
    ],
    prefFloorTypeList: [
      {
        label: "Tiles",
        value: "TILES",
      },
      {
        label: "Marbles",
        value: "MARBLES",
      },
      {
        label: "Granite",
        value: "GRANITE",
      },
      {
        label: "Cement",
        value: "CEMENT",
      },
      {
        label: "Mosaic",
        value: "MOSAIC",
      },
    ],
    prefFurnishList: [
      {
        label: "Fully Furnished",
        value: "FULL",
      },
      {
        label: "Semi-Furnished",
        value: "SEMI",
      },
      {
        label: "Unfurnished",
        value: "UN",
      },
    ],
  };
  const aminitiesList = [
    { label: "Garden", value: "garden" },
    { label: "Park", value: "park" },
    { label: "Gym", value: "gym" },
    { label: "Lift", value: "lift" },
    { label: "Wifi", value: "wifi" },
    { label: "Play Area", value: "play_area" },
    { label: "Fire Safety", value: "fire_safety" },
    { label: "Sewage Treatment", value: "sewage" },
    { label: "Swimming Pool", value: "pool" },
    { label: "Power Backup", value: "power" },
    { label: "Rain Water Harvesting", value: "rain_water" },
    { label: "Servant Room", value: "servant_room" },
    { label: "House Keeping", value: "house_keeping" },
  ];
  return (
    <>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Title <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="formdata"
              type="text"
              placeholder="Title"
              {...register("title", {
                required: true,
              })}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">Description </Form.Label>
            <Form.Control
              className="formdata"
              type="text"
              placeholder="Description"
              {...register("description", {
                required: false,
              })}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Address Line 1<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="formdata"
              type="text"
              placeholder="Address Line 1"
              {...register("address_line_1", {
                required: true,
              })}
            />
            {/* {errors?.address_line_2?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )} */}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Address Line 2<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="formdata"
              type="text"
              placeholder="Address Line 2"
              {...register("address_line_2", {
                required: true,
              })}
            />
            {/* {errors?.address_line_2?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )} */}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Landmark
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="formdata"
              type="text"
              placeholder="Landmark"
              {...register("landmark", {
                required: true,
              })}
            />
            {errors?.landmark?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Bedroom
              <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="bed"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefBedList}
                  value={value}
                />
              )}
              rules={{required: true}}
            />
            {errors?.bed?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Bathroom
              <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="bath"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefBathList}
                  value={value}
                />
              )}
              rules={{required: true}}
            />
            {errors?.bath?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Kitchen
              <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="kitchen"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefKitchenList}
                  value={value}
                />
              )}
              rules={{required: true}}
            />
            {errors?.kitchen?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="w-100">
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Builtup Area
              <span className="text-danger">*</span>
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                className="formdata"
                type="number"
                placeholder="Builtup"
                {...register("built_area", {
                  required: true,
                })}
              />
              <InputGroup.Text id="basic-addon2">Sq.Ft.</InputGroup.Text>
            </InputGroup>
            {errors?.built_area?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Super Builtup Area
              <span className="text-danger">*</span>
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                className="formdata"
                type="number"
                placeholder="Super Builtup"
                {...register("super_builtup", {
                  required: true,
                })}
              />
              <InputGroup.Text id="basic-addon2">Sq.Ft.</InputGroup.Text>
            </InputGroup>
            {errors?.super_builtup?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3 ">
            <Form.Label className="lable">Facing</Form.Label>
            <Controller
              control={control}
              name="facing"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefFacingList}
                  value={value}
                />
              )}
            />
            {errors?.facing?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3 ">
            <Form.Label className="lable">
              Parking
              <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="parking"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefParkingList}
                  value={value}
                />
              )}
              {...register("parking")}
              rules={{required: true}}
            />
            {errors?.parking?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3 ">
            <Form.Label className="lable">Floor Type
            <span className="text-danger">*</span></Form.Label>
            <Controller
              control={control}
              name="floor_type"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefFloorTypeList}
                  value={value}
                />
              )}
              rules={{ required: true }}
            />
            {errors?.floor_type?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3 ">
            <Form.Label className="lable">Furnishing
            <span className="text-danger">*</span></Form.Label>
            <Controller
              control={control}
              name="furnish"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefFurnishList}
                  value={value}
                />
              )}
              rules={{required: true}}
            />
            {errors?.furnish?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Available From
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="formdata"
              type="date"
              {...register("available_from", {
                required: true,
              })}
              value={getValues("available_from")?.slice(0, 10)}
            />
            {errors?.available_from?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">Has Basement?</Form.Label>
            <Controller
              control={control}
              name="has_basement"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefOption}
                  value={value}
                />
              )}
            />
            {/* {errors?.has_basement?.type === "required" && (
            <span className="text-danger">This field is required</span>
          )} */}
          </Form.Group>
        </Col>

        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable" require>
              Is Surveillance available?
              <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="survillance"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefOption}
                  value={value}
                />
              )}
              rules={{required: true}}
            />
            {errors?.survillance?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <div className="mt-4">
        <span className="text-center">
          <h2 className="lable">Amenities</h2>
        </span>
      </div>

      <h5 className="lable">Select the available amenities</h5>

      {/* <div className=" prop-type-aminities-container d-flex flex-row flex-wrap"> */}
      <div className="mx-5">
        <Row>
          <Col >
            <div className="checkbox">
              <label>
                {" "}
                <input
                  {...register("lift", {
                    required: false,
                  })}
                  type="checkbox"
                  name="lift"
                />{" "}
                Lift
              </label>
            </div>
          </Col>
          <Col >
            <div className="checkbox">
              <label>
                {" "}
                <input
                  {...register("wifi", {
                    required: false,
                  })}
                  type="checkbox"
                  name="wifi"
                />{" "}
                Wi-Fi
              </label>
            </div>
          </Col>

          <Col >
            <div className="checkbox">
              <label>
                {" "}
                <input
                  {...register("ac", {
                    required: false,
                  })}
                  type="checkbox"
                  name="ac"
                />{" "}
                Air Conditioner
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col >
            <div className="checkbox">
              <label>
                {" "}
                <input
                  {...register("power", {
                    required: false,
                  })}
                  type="checkbox"
                  name="power"
                />{" "}
                Power Backup
              </label>
            </div>
          </Col>
          <Col >
            <div className="checkbox">
              <label>
                {" "}
                <input
                  {...register("play_area", {
                    required: false,
                  })}
                  type="checkbox"
                  name="play_area"
                />{" "}
                Children Play Area
              </label>
            </div>
          </Col>
          <Col >
            <div className="checkbox">
              <label>
                {" "}
                <input
                  {...register("fire_safety", {
                    required: false,
                  })}
                  type="checkbox"
                  name="fire_safety"
                />{" "}
                Fire Safety
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col >
            <div className="checkbox">
              <label>
                {" "}
                <input
                  {...register("park", {
                    required: false,
                  })}
                  type="checkbox"
                  name="park"
                />{" "}
                Park
              </label>
            </div>
          </Col>
          <Col >
            <div className="checkbox">
              <label>
                {" "}
                <input
                  {...register("servant_room", {
                    required: false,
                  })}
                  type="checkbox"
                  name="servant_room"
                />{" "}
                Servant Room
              </label>
            </div>
          </Col>
          <Col >
            <div className="checkbox">
              <label>
                {" "}
                <input
                  {...register("gas", {
                    required: false,
                  })}
                  type="checkbox"
                  name="gas"
                />{" "}
                Gas Pipeline
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col >
            <div className="checkbox">
              <label>
                {" "}
                <input
                  {...register("sewage", {
                    required: false,
                  })}
                  type="checkbox"
                  name="sewage"
                />{" "}
                Sewage Treatment Plan
              </label>
            </div>
          </Col>

          <Col >
            <div className="checkbox">
              <label>
                {" "}
                <input
                  {...register("house_keeping", {
                    required: false,
                  })}
                  type="checkbox"
                  name="house_keeping"
                />{" "}
                House Keeping
              </label>
            </div>
          </Col>
          <Col >
            <div className="checkbox">
              <label>
                {" "}
                <input
                  {...register("rain_water", {
                    required: false,
                  })}
                  type="checkbox"
                  name="rain_water"
                />{" "}
                Rain Water Harvesting
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col >
            <div className="checkbox">
              <label>
                {" "}
                <input
                  {...register("pool", {
                    required: false,
                  })}
                  type="checkbox"
                  name="pool"
                />{" "}
                Swimming Pool
              </label>
            </div>
          </Col>
          <Col >
            <div className="checkbox">
              <label>
                {" "}
                <input
                  {...register("shopping", {
                    required: false,
                  })}
                  type="checkbox"
                  name="shopping"
                />{" "}
                Shopping Center
              </label>
            </div>
          </Col>
          <Col  >
            <div className="checkbox">
              <label>
                {" "}
                <input
                  {...register("garden", {
                    required: false,
                  })}
                  type="checkbox"
                  name="garden"
                />{" "}
                Garden
              </label>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
