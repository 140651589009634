import React from "react";

const Index = ({ formControl, error }) => {
  return (
    <>
      <input
        className="form-control"
        type="text"
        {...formControl}
        // className={`form-control ${errors.name ? "border-danger" : ""}`}
      />
      {error?.message && (
        <p>
          <span className="text-danger">{error?.message}</span>
        </p>
      )}
    </>
  );
};

export default Index;
