import React from "react";
import { GET } from "../../api/functions";

interface getFuncProps {
  path: string;
  callback?: (e: any) => void;
  statusCallback?: (e: any) => void;
}
interface ApiContextProps {
  get: (a: getFuncProps) => void;
}

const initialContext = {
  get: () => {},
};

export const ApiContext = React.createContext<ApiContextProps>(initialContext);
export function ApiProvider({ children }) {
  const get = async ({ path, callback, statusCallback }: getFuncProps) => {
    statusCallback(true);
    await GET(path)
      .then(([statusCode, response]) => {
        if (statusCode === 200) {
          let tempResult = response;
          // let { additional_data, ...data } = response;
          // tempResult = { ...JSON.parse(additional_data), ...data };
          callback(tempResult);
        }
      })
      .catch((err) => {
        console.log("error > ", err);
        return err;
      });
    statusCallback(false);
  };

  return <ApiContext.Provider value={{ get }}>{children}</ApiContext.Provider>;
}
