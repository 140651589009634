import React, { useEffect, useState } from "react";
import { calculateDistance } from "../../../utils";
import { useHistory } from "react-router-dom";
import VerifyBtn from "../Components/VerifyBtn";
import PropertyCard from "../Components/PropCard";
import getPropNearby from "../../../api/Agent/getPropNearby";
import addPropToBucket from "../../../api/Agent/addPropToBucket";
import NoProp from "../../../assets/svg/norecord";


function PropertyList({ agentData, fetchData }) {
  // console.log(agentData, "ad");
  const agent_key = agentData?.agent_id;
  const history = useHistory();
  const [properties, setProperties] = useState([]);
  const [range, SetRange] = useState(10);
  // console.log("ak", agent_key);
  function handleRangeChange(event) {
    const ranges = event.target.value;
    const newValue = Math.round(event.target.value / 5) * 5;
    SetRange(newValue);
    console.log(newValue);
  }
  const fetchPropData = async () => {
    try {
      console.log("savsfavgfda", agent_key);
      const res = await getPropNearby(agent_key, range);
      setProperties(res[1]);
      // console.log("response", properties);
    } catch (error) {
      console.error(error);
    }
  };
  const handleAddPropToBucket = async (property_key: String | Number) => {
    console.log("ksjdanvkjsd");
    await addPropToBucket({ property_key }, agent_key);
    alert("Property Added")
    fetchPropData();
    fetchData();

  };
  const limit = agentData?.property_limit;

  useEffect(() => {
    fetchPropData();
    fetchData();
    console.log(properties, "props");
  }, [range, agent_key]);
  // console.log(range);
  console.log("propout", properties);
  return (
    <>
      <div className="agt-prop-list">
        <div className="nearby-header">
          <p>
            <strong>
              No of Properties Available in Your Location: {properties.length}
            </strong>
          </p>
          <div className="range-selecter">
            <label htmlFor="range">Select Your Radius</label>
            <input
              type="range"
              min="1"
              max="20"
              value={range}
              onChange={handleRangeChange}
            />
            <span id="slider-value">Around {range} KM</span>
          </div>
        </div>
        {properties.length>0? (
          <>
          {properties?.map((property, idx) => (
          <PropertyCard
            idx={idx}
            property={property}
            limit={limit}
            onView={() =>
              console.log(`Viewing property ${property.property_key}`)
            }
            onAdd={() => handleAddPropToBucket(property.property_key)}
          />
        ))}
          </>
        ):(<>
        <div className="agt-no-rcrd">
        <p><strong>No Properties found in Selected Range</strong></p>
        <NoProp/>
        </div>
        </>)}
      </div>
    </>
  );
}
export default PropertyList;
