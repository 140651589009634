import React from "react";
import { useHistory } from "react-router-dom";
import VerifyBtn from "../VerifyBtn";
import { openMap } from "../../../../utils";
import DelPropBtn from "../DelPropBtn";

export default function Index(props) {
  const {
    property,
    onView,
    onAdd,
    onVerify,
    onNavigate,
    limit,
    onDelete,
    idx,
    agentKey
  } = props;
  const history = useHistory();

  return (
    <>
      <div className="property-card" key={idx}>
        <img src={property.img_src} alt="" className="property-image" />
        <div className="property-details">
          <h3 className="property-name">{property.title?.slice(0, 30)}</h3>
          <p className="property-description">
            {property.description?.slice(0, 40)}.....
          </p>
          <p className="property-price">{property.price}</p>
          <div className="agent-btn-container">
            {onView && (
              <button
                className="property-button"
                disabled={limit <= 0}
                onClick={() =>
                  history.push(`/properties/${property?.property_key}`)
                }
              >
                View
              </button>
            )}
            {onAdd && (
              <button
                className="property-button"
                disabled={limit <= 0}
                onClick={() => onAdd(property?.property_key)}
              >
                Add
              </button>
            )}
            {onVerify && (
              <VerifyBtn
                propertyLocation={{ lat: property.lat, lon: property.lon }}
                propertyKey={property?.property_key}
                agentKey={agentKey}
              />
            )}
            {onNavigate && (
              <button
                className="navigate-btn"
                onClick={() => openMap(property.lat, property.lon)}
              >
                Navigate
              </button>
            )}
            {onDelete && (
              <DelPropBtn onDelete={() => onDelete(property?.property_key)} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
