import React from 'react'

const tasks = () => {
  return (
    <>
     <svg width="31" height="39" viewBox="0 0 31 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.5312 30.75L24.125 20.1563L21.4062 17.4375L13.4844 25.3594L9.54687 21.4219L6.875 24.0937L13.5312 30.75ZM4.25 38.25C3.21875 38.25 2.33625 37.8831 1.6025 37.1494C0.8675 36.4144 0.5 35.5313 0.5 34.5V4.5C0.5 3.46875 0.8675 2.58562 1.6025 1.85062C2.33625 1.11687 3.21875 0.75 4.25 0.75H19.25L30.5 12V34.5C30.5 35.5313 30.1331 36.4144 29.3994 37.1494C28.6644 37.8831 27.7813 38.25 26.75 38.25H4.25ZM17.375 13.875V4.5H4.25V34.5H26.75V13.875H17.375Z" fill=""/>
</svg>
   
    </>
  )

}

export default tasks