import React from 'react'

export default function Gplay() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="80pt" height="70pt" viewBox="0 0 833.000000 299.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,299.000000) scale(0.100000,-0.100000)" fill="#ffffff" stroke="none">
<path d="M543 2550 c-18 -10 -40 -34 -50 -52 -17 -31 -18 -88 -18 -1008 0 -1063 -2 -1025 58 -1076 28 -23 38 -26 92 -22 55 3 75 12 235 104 96 56 344 198 550 316 850 489 945 545 973 581 55 67 42 149 -33 208 -39 31 -1606 934 -1657 955 -47 20 -112 17 -150 -6z m696 -377 c311 -179 572 -331 579 -337 11 -10 -15 -39 -134 -152 l-146 -139 -464 470 c-255 259 -463 476 -464 483 0 10 8 12 31 7 17 -4 286 -153 598 -332z m-210 -199 c260 -262 470 -480 469 -484 -7 -18 -946 -958 -952 -953 -10 10 -7 1913 3 1913 5 0 221 -214 480 -476z m1079 -301 c202 -116 242 -147 242 -188 0 -57 1 -56 -263 -209 l-168 -97 -159 153 c-88 84 -160 156 -160 161 0 4 62 71 138 147 97 99 144 140 160 140 12 0 107 -48 210 -107z m-268 -533 c0 -3 -39 -28 -87 -56 -49 -28 -305 -175 -570 -328 -444 -255 -573 -323 -587 -308 -3 3 -6 12 -6 21 0 9 215 231 477 493 l478 478 147 -147 c82 -81 148 -150 148 -153z" fill="#ffffff"/>
<path d="M2681 2114 c-63 -32 -96 -78 -112 -155 -15 -77 1 -131 55 -185 42 -42 94 -64 151 -64 45 1 118 20 127 33 13 21 9 184 -4 189 -11 4 -88 3 -110 -1 -5 0 -8 -8 -8 -16 0 -10 14 -17 40 -21 l40 -7 0 -46 c0 -69 -17 -86 -82 -86 -101 1 -164 77 -156 187 5 70 27 104 89 136 38 20 53 22 112 17 71 -7 84 2 45 29 -36 26 -125 21 -187 -10z" fill="#ffffff"/>
<path d="M3005 2132 c-3 -3 -5 -97 -5 -209 l0 -203 104 0 c66 0 107 4 112 11 12 19 -19 29 -93 29 l-73 0 0 75 0 75 53 0 c65 0 97 9 97 25 0 16 -32 25 -97 25 l-53 0 0 65 0 65 85 0 c67 0 85 3 85 14 0 8 -5 18 -12 22 -14 9 -195 14 -203 6z" fill="#ffffff"/>
<path d="M3283 2125 c-15 -11 -15 -13 3 -23 23 -12 23 -12 74 -12 l40 0 0 -186 0 -185 23 3 c22 3 22 6 27 176 5 186 7 192 61 192 35 0 67 24 48 36 -22 13 -257 13 -276 -1z" fill="#ffffff"/>
<path d="M3910 2133 c-45 -13 -46 -14 -26 -29 11 -8 39 -14 63 -14 l43 0 2 -182 c3 -181 3 -183 25 -186 23 -3 23 -3 25 180 l3 183 49 7 c69 10 82 16 67 31 -11 11 -216 19 -251 10z" fill="#ffffff"/>
<path d="M4437 2119 c-47 -25 -84 -81 -99 -150 -22 -108 36 -221 129 -249 52 -16 121 -6 165 23 82 56 110 200 58 302 -46 88 -162 122 -253 74z m153 -34 c44 -23 73 -87 73 -161 -1 -69 -18 -110 -65 -149 -28 -23 -39 -27 -80 -23 -83 8 -128 70 -128 172 0 73 25 133 64 157 37 23 96 24 136 4z" fill="#ffffff"/>
<path d="M3772 2118 c-13 -13 -18 -388 -6 -401 4 -3 15 -2 26 4 17 9 18 22 16 206 -3 194 -8 219 -36 191z" fill="#ffffff"/>
<path d="M4799 1943 c-2 -237 -2 -236 26 -213 12 10 15 40 15 161 0 82 3 149 8 149 4 0 52 -72 106 -161 90 -146 101 -160 125 -157 l26 3 3 183 c3 189 -5 236 -33 212 -11 -9 -15 -48 -17 -159 l-3 -146 -60 93 c-33 51 -78 121 -100 155 -31 48 -46 63 -68 65 l-27 3 -1 -188z" fill="#ffffff"/>
<path d="M5070 1170 l0 -360 55 0 55 0 0 360 0 360 -55 0 -55 0 0 -360z" fill="#ffffff"/>
<path d="M6659 1526 c-2 -2 -3 -164 -1 -360 l2 -356 55 0 55 0 0 360 0 360 -53 0 c-30 0 -56 -2 -58 -4z" fill="#ffffff"/>
<path d="M2880 1499 c-120 -21 -222 -94 -270 -194 -17 -36 -25 -72 -28 -131 -12 -225 107 -357 333 -371 83 -5 214 16 241 38 11 9 14 48 14 175 l0 164 -120 0 -120 0 0 -45 0 -45 60 0 60 0 0 -75 c0 -96 -15 -115 -92 -115 -75 0 -124 16 -168 56 -96 87 -109 253 -29 366 12 17 47 42 77 56 52 23 62 25 169 19 l114 -6 10 26 c5 15 8 34 7 42 -7 34 -157 58 -258 40z" fill="#ffffff"/>
<path d="M6096 1484 c-14 -14 -16 -55 -14 -342 l3 -327 57 -3 57 -3 3 123 3 123 85 12 c46 7 104 19 127 28 94 36 145 139 122 248 -9 41 -21 61 -54 93 -54 52 -103 64 -260 64 -90 0 -117 -3 -129 -16z m264 -89 c42 -22 60 -53 60 -106 0 -52 -23 -90 -70 -114 -35 -18 -121 -20 -138 -3 -8 8 -12 48 -12 113 0 65 4 105 12 113 17 17 113 15 148 -3z" fill="#ffffff"/>
<path d="M3469 1314 c-57 -10 -131 -67 -163 -124 -27 -49 -31 -64 -31 -135 0 -71 4 -86 31 -135 44 -78 100 -111 199 -118 95 -6 149 13 204 73 42 46 71 121 71 185 0 96 -54 191 -131 231 -39 19 -131 31 -180 23z m117 -103 c42 -26 64 -77 64 -150 0 -110 -47 -176 -125 -176 -78 0 -125 67 -125 179 0 125 96 202 186 147z" fill="#ffffff"/>
<path d="M4022 1302 c-67 -24 -126 -81 -147 -140 -21 -63 -19 -177 4 -227 23 -51 89 -111 138 -124 70 -20 154 -14 211 15 88 44 132 124 132 239 0 52 -6 75 -32 126 -27 50 -42 67 -84 90 -68 39 -150 46 -222 21z m147 -99 c45 -33 73 -100 68 -159 -13 -129 -98 -197 -184 -144 -87 52 -95 225 -15 305 23 23 33 26 66 22 22 -3 51 -14 65 -24z" fill="#ffffff"/>
<path d="M4599 1305 c-51 -16 -108 -68 -136 -124 -26 -51 -25 -202 1 -251 30 -57 67 -90 120 -105 59 -18 101 -12 168 21 54 28 65 20 47 -34 -21 -67 -47 -100 -94 -118 -42 -15 -48 -15 -116 1 -70 17 -73 17 -86 0 -7 -11 -13 -31 -13 -47 0 -24 6 -29 43 -39 66 -19 194 -17 249 4 64 25 113 81 127 146 6 30 11 155 11 295 l0 244 -37 7 c-33 6 -40 4 -60 -21 -21 -27 -23 -27 -42 -10 -23 21 -86 45 -116 45 -11 0 -41 -7 -66 -14z m136 -91 c46 -21 65 -66 65 -152 0 -88 -11 -117 -52 -142 -39 -24 -78 -25 -118 -5 -44 23 -64 69 -65 145 0 78 24 126 75 152 43 22 53 22 95 2z" fill="#ffffff"/>
<path d="M5480 1311 c-117 -36 -180 -127 -180 -261 0 -88 20 -139 71 -186 66 -60 175 -80 299 -53 45 10 50 13 50 37 0 48 -9 51 -110 50 -111 -1 -143 9 -170 54 -40 66 -39 67 143 70 l162 3 3 60 c4 76 -18 133 -68 179 -55 49 -132 67 -200 47z m95 -85 c39 -16 55 -42 55 -85 l0 -31 -105 0 c-96 0 -105 2 -105 19 0 26 41 83 70 98 32 16 43 16 85 -1z" fill="#ffffff"/>
<path d="M7007 1306 c-73 -16 -92 -32 -77 -70 9 -25 14 -28 43 -22 137 25 174 17 195 -43 10 -28 9 -31 -11 -35 -104 -20 -139 -30 -186 -54 -82 -41 -111 -115 -77 -195 34 -81 110 -105 224 -69 63 20 64 20 83 1 16 -16 27 -18 57 -13 20 4 41 9 45 11 14 8 -4 354 -20 393 -21 52 -51 79 -107 95 -58 18 -90 18 -169 1z m173 -302 c0 -66 -10 -89 -50 -109 -42 -22 -77 -19 -105 10 -26 25 -32 63 -15 95 12 23 96 55 153 59 14 1 17 -8 17 -55z" fill="#ffffff"/>
<path d="M7386 1301 c-4 -6 34 -115 84 -241 66 -166 90 -238 86 -255 -4 -14 -35 -48 -72 -78 -64 -51 -65 -53 -59 -92 3 -22 10 -44 14 -48 4 -4 27 -1 49 9 59 23 148 123 187 209 52 113 189 484 182 491 -3 3 -26 9 -50 11 -61 7 -73 -11 -126 -195 -25 -84 -47 -152 -50 -152 -4 0 -31 72 -61 161 -30 88 -60 165 -66 170 -19 15 -110 23 -118 10z" fill="#ffffff"/>
</g>
</svg>

  )
}
