import {GET} from "../functions"

const index = async (agent_key, range) => {
  const path = `/agents/${agent_key}/nearbyproperties?distance_limit=${range}`;
  return await GET(path)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log("error on prop types from db > ", err);
      return err;
    });
};
export default index;