import React, { useContext, useEffect, useState } from "react";
import { LayoutSidebar } from "../../../../components";
import { useApi } from "../../../../hooks";
import { AuthContext } from "../../../../context/AuthContext";
import PropertyHistoryCard from "./components/propertyHistoryCard";
import "./style.css";
import { useHistory } from "react-router-dom";

const Index = () => {
  const [isFetching, setIsFetching] = useState(false);
  const { userInfo } = useContext(AuthContext);
  const api = useApi();
  const [propertyList, setPropertyList] = useState<any>(null);
  const [approvedValue, setApprovedValue] = useState(null);
  const user_key = userInfo?.user_key;
  const history = useHistory();

  const fetchProperties = async () => {
    const path = `/properties?user_key=${user_key}`;

    await api.get({
      path,

      callback: setPropertyList,

      statusCallback: setIsFetching,
    });
  };
  useEffect(() => {
    fetchProperties();
  }, [AuthContext, approvedValue]);
  console.log(propertyList);
  return (
    <div>
      <div>
        <LayoutSidebar />
      </div>
      <header>
        <h2 className="header-h2">Activities</h2>
      </header>
      <div className="main-con">
        <div className="history-card-container">
          {propertyList &&
            propertyList.map((property) => {
              let date = new Date(property?.listed_on);
              let dateString = date.toLocaleDateString();
              return (
                <PropertyHistoryCard
                  key={property.property_key}
                  propertyName={property?.title}
                  postedDate={dateString}
                  onClick={() =>
                    history.push(`/properties/${property.property_key}`)
                  }
                  startDate = {property.start_date?new Date(property.start_date).toLocaleString("en-in").split(",")[0]:"Not Provided"}
                  endDate = {property.end_date?new Date(property.end_date).toLocaleDateString():"Not Provided"}
                  isSubscribed = {property.is_subscribed}
                  isActive = {property.is_active}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
//
export default Index;
