import React from 'react'
import Apartment from './apartment';
import House from './house';
import Office from './office';
import Shop from './shop';


export default function PropDetails(property:any) {
    const [show, setShow] = React.useState(false);
    const handleShow = () => {
        // setIndividualSiteDetail(data)
        setShow(!show);
      };
      const propertyData = property?.propertyData
      console.log(propertyData.name)


      const propertyTypeDetails = () => {
        if (propertyData){
        switch (propertyData.name) {
          case "Apartment":
            return <Apartment propertyData={propertyData} handleModelShow={handleShow} show={show}/>;
          case "House":
            return <House propertyData={propertyData} handleModelShow={handleShow} show={show}/>;
          case "Office":
            return <Office propertyData={propertyData} handleModelShow={handleShow} show={show}/>;
          case "Shop":
            return <Shop propertyData={propertyData} handleModelShow={handleShow} show={show}/>;
        }}
        else return <>No Data found</>
      };


  return (
    <>
    <div className=" prop-description">
      {propertyTypeDetails()}
    </div>
  </>
  )
}
