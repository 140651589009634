const buildQueryFromObj = (obj: any) => {
  let str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

export default buildQueryFromObj;

// bank account (GST, pan card, udhiyog aadhaar)
//   company reg
//   other plans
//     - we can go with XBI4's GST
// launching plan

// Terms and conditions for truprops
// - xompany's own terms and conditionß
