export const pricesIn = [
  {
    label: "USD",
    value: 2,
    symbol: "$",
  },
  {
    label: "INR",
    value: 1,
    symbol: "₹",
  },
];
