import React from "react";
import Footer from "../../layouts/Footer";
import "./style.css";
import building from "../../assets/building.jpg";
import tools from "../../assets/tools.jpg";
import pulb from "../../assets/pulb.png";
import member from "../../assets/members1.png";
import chip from "../../assets/chip.png";
import { useHistory } from "react-router-dom";
import Banner from "../../components/BannerEmpty"

// import Home from "../../assets/svg/home";

export default function Index() {
  const history = useHistory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      
      <div className="bnr-bg">
              {/* <div className="home-icon"><Home/></div> */}
              <div className="bnr-content">
                <img onClick={() => history.push("./")}
                  className="brand-logo"
                  src="https://truprops.com/static/media/brandlogo.106f7c99d4a8ffbd67c4.png"
                  alt="truprops"
                />
              
              <button className="list-your-prop">About and Why</button>
              </div>
            </div>
      <div className="heading-1">About Truprops</div>
      <div className="d-flex about-truprops">
        <div className="aboutus-left">
          {/* <div className="underline"></div> */}

          <img src={building} className="building-image "></img>
        </div>

        <div className="aboutus-right">
          <li>
            Launched in 2022, Truprops, India’s property portal, deals with
            every aspect of the consumers’ needs in the real estate industry. It
            is an online forum where buyers, sellers and brokers/agents can
            exchange information about real estate properties quickly,
            effectively and inexpensively.
          </li>

          <li>
            At truprops, you can advertise a property, search for a property,
            browse through properties, build your own property microsite, and
            keep yourself updated with the latest news and trends making
            headlines in the realty sector.{" "}
          </li>

          <li>
            Our core value is to involve technologies and develop
            industry-focused cutting-edge solutions understanding the ongoing
            demands of your organization.
          </li>
        </div>
      </div>
      <div className="heading-1">Why Truprops?</div>
      <div className="d-flex about-truprops">
        <div className="aboutus-left">
          {/* <div className="underline"></div> */}

          <img src={tools} className="building-image "></img>
        </div>

        <div className="aboutus-right">
          <li>
            At present, Truprops prides itself for having trusted &
            unique property listings across India. A reliable marketplace where you can find genuine buyers and sellers. 
          </li>
          <li>
            Verifying properties posted on an online platform is not everyone's cup of tea, here in Truprops we take care of that. Our agents verify every property posted on our site including the contact details of the property owners thereby making us the only platform to introduce this trustworthy feature.
          </li>
          <li>
            Of all, the website having paid/free listings. In addition to
            providing an online platform to real estate developers, brokers and
            property owners for listing their property for sale, purchase or
            rent, Truprops offers advertisement stints such as microsites,
            banners, home page links and project pages to the clients for better
            visibility and branding in the market.
          </li>
          <li>
            With the ever-evolving online search behaviour, Truprops shares
            updated information pertinent to real estate activities, assisting
            prospective buyers to make informed buying decision. We make online
            property search easier, quicker and smarter!
          </li>
        </div>
        {/* <div className="contents-2">
          <div className="d-flex icons-allcontents">
            <div className="icons-1">
              <img src={member} className=""></img>
            </div>
            <div className="icon-contents1">
              <div className="customer-centricity">Customer Certainity</div>
              <div className="customer-content">
                Understanding customer needs to ensure we meet all their
                requirements
              </div>
            </div>
          </div>
          <div className="d-flex icons-contents">
            <div className="icons-1">
              <img src={chip} className=""></img>
            </div>
            <div className="icon-contents1">
              <div className="customer-centricity">
                State Of The Art Technology
              </div>
              <div className="customer-content">
                Developing leading edge technology and innovative designs with
                excellence in reliability and product quality
              </div>
            </div>
          </div>
          <div className="d-flex icons-contents">
            <div className="icons-1">
              <img src={pulb} className=""></img>
            </div>
            <div className="icon-contents1">
              <div className="customer-centricity">Seamless Execution</div>
              <div className="customer-content">
                Optimising the effectiveness of all our process to deliver best
                values to our customers and other stakeholders
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <Footer />
    </>
  );
}
