import { GET } from "../functions";

const getPlanDetails = async (category_id: string | number) => {
  const path = `/plans/plan_category/${category_id}`;
  return await GET(path)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      // console.log("getPlanDetails from db> ", result);
      return err;
    });
};

export default getPlanDetails;
