import React, { useContext, useEffect, useState } from "react";
import RadioBtn from "../RadioBtn";
import SearchBar from "../SearchBar";
import { useHistory } from "react-router-dom";
import { Search } from "react-bootstrap-icons";
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Form,
  ToggleButton
} from "react-bootstrap";
import countryList from "../../../../data/countries";
import { StateContext } from "../../../../context/StateContext";

export default function Index({ onChange, data }) {
  const { handleGlobalParams } = useContext(StateContext);
  const [country, setCountry] = useState<any>("in");
  const [showCountryOption, setShowCountryOption] = useState(false);

  const history = useHistory();
  const [searchBarData, setSearchBarData] = useState<any>(null);
  const initValues = {
    lookingFor: data.get("looking_for"),
    location: data.get("loc"),
    bed: "any",
    bath: "any",
    text: data.get("text"),
    map_bounds: data.get("map_bounds")
  };
  const [values, setValues] = useState<any>({ ...initValues });
  const handleChange = (key) => (value) => {
    setValues({ ...values, [key]: value });
  };

  const handleSubmit = () => {
    onChange({ ...values, ...searchBarData });
  };

  return (
    <div className="d-flex flex-row w-100 flex-wrap align-items-center justify-content-center " >
      {/* // ? search bar */}
      <div className="m-1 d-flex flex-row align-items-center " style={{borderRadius:"5px"}}>
        {/* //*country */}
        <div className="ms-2 me-2 " 
        style={{
        fontWeight:"bolder",
        textDecoration:"underline",
        textShadow:"0px 0px 0px orange",
        color:"#0000"

       }}>
          <div
            className="text-muted cursor-p "
            onClick={() => setShowCountryOption(!showCountryOption)}
          >
            {countryList?.find((c) => c.value === country)?.label}
          </div>

          {showCountryOption && (
            <div
              className="d-flex flex-column mt-3 bg-light rounded  shadow"
              style={{
                zIndex: 8,
                position: "absolute",
                display: showCountryOption ? "block" : "none"
              }}
            >
              {countryList?.map((c, idx) => (
                <div
                  className="m-1 px-1 cursor-p "
                  key={idx}
                  onClick={() => {
                    setCountry(c?.value);
                    setShowCountryOption(false);
                    handleGlobalParams({ country: c?.value });
                  }}
                >
                  {c?.label}
                </div>
              ))}
            </div>
          )}
        </div>

        <SearchBar
          onClick={setSearchBarData}
          initialSearchText={values?.text}
        />

        <div className="m-1 ">
          <Button
            style={{ backgroundColor: "var(--primary-color)", border: "none" }}
            onClick={handleSubmit}
            disabled={!values?.location}
          >
            <Search />
          </Button>
        </div>
      </div>

      <div className="d-flex flex-wrap flex-row" style={{borderRadius:"5px"}}>
        <div className="m-1" style={{backgroundColor:"#fa983a"}}>
          <DropdownButton size="sm" title="Looking For" >
            <div className="container " >
              <RadioBtn
                // title="Looking For"
                btns={[
                  { name: "Rent", value: "rent" },
                  { name: "Buy", value: "buy" }
                ]}
                value={values?.lookingFor || ""}
                onChange={handleChange("lookingFor")}
                // onChange={(e) => {
                // setValues({ ...values, bath: e });
                // }}
              />
            </div>
          </DropdownButton>
        </div>
        {/* <div className="m-1">
        <DropdownButton size="sm" title="Price">
          <div className="container">
            <div className="f-b mb-1 text-muted">Min</div>

            <Form.Range
              id="price-range-min"
              onChange={(_) => handleChange("price_range")(_?.target?.value)}
              min={2000}
              max={500000}
              value={values?.price_range}
            />
          </div>
          <div className="container">
            <div className="f-b mb-1 text-muted">Max</div>

            <Form.Range
              id="price-range-max"
              onChange={(_) => handleChange("price_range")(_?.target?.value)}
              min={2000}
              max={500000}
              value={values?.price_range}
            />
          </div>
        </DropdownButton>
      </div> */}

        {/* looking for */}
        <div className="m-1">
          <DropdownButton size="sm" title="Beds & Baths">
            <div className="container">
              <RadioBtn
                title="Bathrooms"
                btns={[
                  { name: "Any", value: "any" },
                  { name: "1+", value: "1" },
                  { name: "2+", value: "2" },
                  { name: "3+", value: "3" },
                  { name: "4+", value: "4" },
                  { name: "5+", value: "5+" }
                ]}
                value={values?.bath || ""}
                onChange={handleChange("bath")}
                // onChange={(e) => {
                // setValues({ ...values, bath: e });
                // }}
              />
              <RadioBtn
                title="Bedrooms"
                btns={[
                  { name: "Any", value: "any" },
                  { name: "1+", value: "1" },
                  { name: "2+", value: "2" },
                  { name: "3+", value: "3" },
                  { name: "4+", value: "4" },
                  { name: "5+", value: "5+" }
                ]}
                value={values?.bed || ""}
                onChange={(e) => {
                  handleChange("bed")(e);
                  // setValues({ ...values, bed: e });
                }}
              />
            </div>
          </DropdownButton>
        </div>
      </div>
    </div>
  );
}
