// import { GOOGLE_MAP_TOKEN } from "./const";
// import React, { useState, useEffect } from 'react';
// import { GoogleMap, Marker } from '@react-google-maps/api';

// const containerStyle = {
//   width: '100%',
//   height: '400px'
// };

// function MapPicker(props) {
//   const { loc, onChange } = props;
//   const [defaultLocation, setDefaultLocation] = useState(loc);
//   const [center, setCenter] = useState(defaultLocation);
//   const [marker, setMarker] = useState(defaultLocation);
//   const [apiLoaded, setApiLoaded] = useState(false);

//   useEffect(() => {
//     // Check if the Google Maps API is already loaded
//     if (!window.google) {
//       const script = document.createElement('script');
//       script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_TOKEN}`;
//       script.onload = () => setApiLoaded(true);
//       document.body.appendChild(script);
//     } else {
//       setApiLoaded(true);
//     }
//   }, [GOOGLE_MAP_TOKEN]);

//   const handleMapClick = event => {
//     const { latLng } = event;
//     const lat = latLng.lat();
//     const lng = latLng.lng();
//     setCenter({ lat, lng });
//     setMarker({ lat, lng });
//     console.log({ lat, lng }); // log the value of the location
//   };

//   const handleMarkerDragEnd = event => {
//     const { latLng } = event;
//     const lat = latLng.lat();
//     const lng = latLng.lng();
//     setMarker({ lat, lng });
//     onChange({ lat: lat, lon: lng });
//     console.log({ lat, lng }); // log the value of the location
//   };

//   return (
//     apiLoaded && (
//       <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10} onClick={handleMapClick}>
//         {marker && <Marker position={marker} draggable={true} onDragEnd={handleMarkerDragEnd} />}
//       </GoogleMap>
//     )
//   );
// }

// export default MapPicker;


// import React, { useState } from 'react'

// function Index() {
//   const [showMenu, setShowMenu] = useState(false)
//   const handleClose = ()=>{
//     setShowMenu(false);
//     console.log("Menu", setShowMenu)
//   }
//   const handleOpen = ()=>{
//     setShowMenu(true);
//     console.log("Menu", setShowMenu)
//   }
  

//   return (
//     <>
//     {showMenu ? (
//       <MenuModal onCloseMenu={handleClose}/>
//     ):(
//       <button onClick={handleOpen}>Menu</button>
//     )}
//     </>
//   )
// }

// function MenuModal({onCloseMenu}){
//   return(
//   <>
//   <div className="agnt-menu-mdl">
//    <a href="">Home</a>
//    <a href="">Home</a>
//    <a href="">Home</a>
//    <a href="">Home</a>
//    <button className="btn btn-secondary" onClick={onCloseMenu}>
//         No
//       </button>
//   </div>
//   </>
//   )
// }
// export default Index;

// import React, { useContext, useEffect, useState } from 'react'
// import {AuthContext} from "./context/AuthContext"
// import getAgentLimit  from "./api/Agent/getAgentLimit"



// export default function Test() {
//   const [data, setData] = useState(null);
//   const {userInfo } = useContext(AuthContext);
//   const user_key = userInfo?.user_key;
//   const fetchData = async () => {
//     try {
//       const res = await getAgentLimit(user_key);
//       console.log("res", res);
//       setData(res[1][0]);
//       console.log(data, "datas");
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     if (user_key) {
//       fetchData();
//       console.log(data, "daaaaaaaa");

//     }
//   }, [user_key]);
  


//   return (
//     <>{data &&
//     <div>{data.agent_id}</div>
//     }
//     </>
 
//   )
// }

import { useContext, useEffect, useState } from 'react';
import {AuthContext} from "./context/AuthContext"
import getAgentLimit  from "./api/Agent/getAgentLimit"
import ModalAgnt from "./pages/AgentDashboard/Components/EditAgtProfile"
export default function MyComponent() {
  
  const { agentData } = useContext(AuthContext);
  // const [data] = agentData;

console.log(agentData, "AgInfo")
  return (
    <div>
      {agentData ? (
        <div>
          <p>{agentData.Name}</p>
          <p>{agentData.email}</p>
          <p>{agentData.agent_id}</p>
          Display other properties of the data object
        </div>
      ) : (
        <p>Loading...</p>
      )}
      <ModalAgnt/>
    </div>
  );
}

// function useAgentLimit() {
//   const [data, setData] = useState(null);
//   const { userInfo } = useContext(AuthContext);
//   const user_key = userInfo?.user_key;

//   const fetchData = async () => {
//     try {
//       const res = await getAgentLimit(user_key);
//       setData(res[1][0]);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     if (user_key) {
//       fetchData();
//     }
//   }, [user_key]);

//   return [data];
// }



