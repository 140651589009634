import styled from "styled-components";

const SubscriptionPlansSc = styled.div`
  .plans_detail_con {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    gap: 2em;
  }
  .btn_con {
    margin: 2em 0;
    display: flex;
    justify-content: center;
  }
  .checkout_btn {
    border: 1px solid;
    background-color: white;
    padding: 0.5em 1em;
    transition: all 300ms;
  }
  .checkout_btn:hover {
    background-color: var(--primary-color);
    color: white;
  }
  .category_card_con {
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    .plans_detail_con{
      flex-direction: column;
    }
    .category_card_con{
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
`;

export default SubscriptionPlansSc;
