import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  ButtonGroup,
  Form,
  Button,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import { Controller } from "react-hook-form";
import ChipV1 from "../../../../components/Inputs/Chip/V1/index";
import CheckBoxV1 from "../../../../components/Inputs/CheckBox/V1/index";

interface Shop {
  title: string;
  address_line_1: string;
  address_line_2: string;
  landzone: string;
  landmark: string;
  wash: string;
  personal_wash: string;
  cafe: string;
  built_area: string;
  possesion: string;
  is_active: string;
  floor: string;
  total_floor: string;
  furnish: string;
  age: string;
  ac: string;
  power: string;
  fire_safety: string;
  corner: boolean;
  main: boolean;
}
export default function Index({ register, errors, handleFormData, control, getValues }) {
  const chipList = {
    prefOption: [
      {
        label: "Yes",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
    prefLandZone: [
      {
        label: "Industrial",
        value: "INDUSTRIAL",
      },
      {
        label: "Commercial",
        value: "COMMERCIAL",
      },
      {
        label: "Residential",
        value: "RESIDENTIAL",
      },
    ],
    prefWashList: [
      {
        label: "1",
        value: 1,
      },
      {
        label: "2",
        value: 2,
      },
      {
        label: "3",
        value: 3,
      },
      {
        label: "4",
        value: 4,
      },
      {
        label: "5",
        value: 5,
      },
      {
        label: "6",
        value: 6,
      },
    ],
    prefBathList: [
      {
        label: "1",
        value: 1,
      },
      {
        label: "2",
        value: 2,
      },
      {
        label: "3",
        value: 3,
      },
      {
        label: "4",
        value: 4,
      },
      {
        label: "5",
        value: 5,
      },
      {
        label: "6",
        value: 6,
      },
    ],
    prefParkingList: [
      {
        label: "Car",
        value: "CAR",
      },
      {
        label: "Bike",
        value: "BIKE",
      },
      {
        label: "Both",
        value: "BOTH",
      },
      {
        label: "None",
        value: "NONE",
      },
    ],
    floorNo: [
      {
        label: "Ground Floor",
        value: "Ground",
      },
      {
        label: "1",
        value: "1",
      },
      {
        label: "2",
        value: "2",
      },
      {
        label: "3",
        value: "3",
      },
      {
        label: "4",
        value: "4",
      },
      {
        label: "5",
        value: "5",
      },
      {
        label: "6+",
        value: "6+",
      },
    ],
    totalFloorNo: [
      {
        label: "Ground Floor Only",
        value: "Ground",
      },
      {
        label: "1",
        value: "1",
      },
      {
        label: "2",
        value: "2",
      },
      {
        label: "3",
        value: "3",
      },
      {
        label: "4",
        value: "4",
      },
      {
        label: "5",
        value: "5",
      },
      {
        label: "6+",
        value: "6+",
      },
    ],
    prefFurnishList: [
      {
        label: "Fully Furnished",
        value: "FULL",
      },
      {
        label: "Semi-Furnished",
        value: "SEMI",
      },
      {
        label: "Unfurnished",
        value: "UN",
      },
    ],
    ageOfProp: [
      {
        label: "Less than a year",
        value: "new",
      },
      {
        label: "1-3 Years",
        value: "1-3",
      },
      {
        label: "3-5 Years",
        value: "3-5",
      },
      {
        label: "5-10 Years",
        value: "5-10",
      },
      {
        label: "More than 10 Years",
        value: "10+",
      },
    ],
  };
  const aminitiesList = [
    { label: "Fire Safety", value: "fire_safety" },
    { label: "Power Backup", value: "power" },
    { label: "Air Conditioner", value: "ac" },
  ];
  return (
    <>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">Title
            <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="formdata"
              type="text"
              placeholder="Title"
              {...register("title", {
                required: true,
              })}
            />
            {errors?.title?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">Description </Form.Label>
            <Form.Control
              className="formdata"
              name="description"
              type="text"
              placeholder="Description"
              {...register("description", {
                required: false,
              })}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Address Line 1<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="formdata"
              type="text"
              placeholder="Address Line 1"
              {...register("address_line_1", {
                required: true,
              })}
            />
            {/* {errors?.address_line_2?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )} */}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Address Line 2<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="formdata"
              type="text"
              placeholder="Address Line 2"
              {...register("address_line_2", {
                required: true,
              })}
            />
            {/* {errors?.address_line_2?.type === "required" && (
                  <span className="text-danger">This field is required</span>
                )} */}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="w-100">
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Builtup Area
              <span className="text-danger">*</span>
            </Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                className="formdata"
                type="number"
                placeholder="Builtup"
                {...register("built_area", {
                  required: true,
                })}
              />
              <InputGroup.Text id="basic-addon2">Sq.Ft.</InputGroup.Text>
            </InputGroup>
            {errors?.built_area?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">Select Land Zone
            <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="landzone"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefLandZone}
                  value={value}
                />
              )}
              rules={{required: true}}
            />
            {errors?.landzone?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>

        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Landmark
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="formdata"
              type="text"
              placeholder="Landmark"
              {...register("landmark", {
                required: true,
              })}
            />
            {errors?.landmark?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">Washroom
            <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="wash"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefWashList}
                  value={value}
                />
              )}
              rules={{required: true}}
            />
            {errors?.wash?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">Floor No
            <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="floor"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.floorNo}
                  value={value}
                />
              )}
              rules={{required: true}}
            />
            {errors?.floor?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">Total Floor
            <span className="text-danger">*</span></Form.Label>
            <Controller
              control={control}
              name="total_floor"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.totalFloorNo}
                  value={value}
                />
              )}
              rules={{required: true}}
            />
            {errors?.total_floor?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">Is this corner shop?
            <span className="text-danger">*</span></Form.Label>
            <Controller
              control={control}
              name="corner"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefOption}
                  value={value}
                />
              )}
              rules={{required: true}}
            />
            {errors?.corner?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">Situated in Main Road?
            <span className="text-danger">*</span></Form.Label>
            <Controller
              control={control}
              name="main"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefOption}
                  value={value}
                />
              )}
              rules={{required: true}}
            />
            {errors?.main?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3 ">
            <Form.Label className="lable">
              Age of Property
              <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="age"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.ageOfProp}
                  value={value}
                />
              )}
              rules={{required: true}}
            />
            {errors?.age?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
          <Form.Group className="mb-3 ">
            <Form.Label className="lable">
              Parking
              <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="parking"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefParkingList}
                  value={value}
                />
              )}
              {...register("parking")}
              rules={{required: true}}
            />
            {errors?.parking?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label className="lable">
              Available From
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              className="formdata"
              type="date"
              {...register("available_from", {
                required: true,
              })}
              value={getValues("available_from")?.slice(0, 10)}
            />
            {errors?.available_from?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3 ">
            <Form.Label className="lable">Furnishing
            <span className="text-danger">*</span></Form.Label>
            <Controller
              control={control}
              name="furnish"
              render={({ field: { onChange, value } }) => (
                <ChipV1
                  onClick={(e) => {
                    onChange(e);
                  }}
                  chipList={chipList?.prefFurnishList}
                  value={value}
                />
              )}
              rules={{required:true}}
            />
            {errors?.furnish?.type === "required" && (
              <span className="text-danger">This field is required</span>
            )}
          </Form.Group>
        </Col>
      </Row>
      <div className="mt-4">
        <span className="text-center">
          <h2 className="lable">Amenities</h2>
        </span>
      </div>

      <h5 className="lable">Select the available amenities</h5>

      <div className="mx-5">
      <Row>
        {aminitiesList.map((amenity) => (
          <Col key={amenity.value} >
            <div className="checkbox">
              <label>
                <input
                  {...register(amenity.value, {
                    required: false,
                  })}
                  type="checkbox"
                  name={amenity.value}
                />{" "}
                {amenity.label}
              </label>
            </div>
          </Col>
        ))}
      </Row>
      </div>
    </>
  );
}
