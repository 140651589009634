import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { useApi } from "../../../../hooks";
import { LayoutSidebar } from "../../../../components";
import "./style.css";
import { useHistory } from "react-router-dom";
import getUserPlan from "../../../../api/User Plan/getUserPlan";
import { Button } from "react-bootstrap";
interface StatsCardProps {
  title: string;
  count: number;
  onClick: () => void;
}

const Plans = () => {
  const [userPlans, setUserPlans] = useState([]);
  const { userInfo } = useContext(AuthContext);
  const handleGetUserPlans = async () => {
    const res = await getUserPlan(userInfo?.user_key);
    setUserPlans(res[1]);
  };
  useEffect(() => {
    handleGetUserPlans();
  }, []);
  return (
    <div className="plan_container">
      <header className="header_plans">Your Plans</header>
      <div className="plan_hero_div">
        {userPlans?.map((plan) => {
          return (
            <>
              <h3 className="plan_name">{plan?.plan_for}</h3>
              <div
                className={`plans ${plan?.current_plan_name?.toLocaleLowerCase()}`}
              >
                <p className="plan_type">{plan?.current_plan_name}</p>
                <p className="price_plan">Price: {plan?.price}</p>
                <p className="start_date">
                  Purchased Date: {plan?.purchased_on}
                </p>
                <p className="total_limit">
                  Contact Limit: {plan?.total_limit}
                </p>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

const Stats = ({ title, count, onClick }: StatsCardProps) => {
  return (
    <div className="dashboard-stats-card">
      <p className="title">{title}</p>

      <div className="stat-container">
        <div className="stat">{count}</div>
      </div>

      <div className="stat-card-link">
        <span className="link-text" onClick={onClick}>
          View
        </span>
      </div>
    </div>
  );
};

const Index = () => {
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(false);
  const { userInfo } = useContext(AuthContext);
  console.log(userInfo);
  const api = useApi();
  const [propertyList, setPropertyList] = useState<any>(null);
  const [approvedValue, setApprovedValue] = useState(null);
  const user_key = userInfo?.user_key;

  const fetchProperties = async () => {
    const path = `/properties?user_key=${user_key}${
      approvedValue === null ? "" : `&is_approved=${approvedValue}`
    }`;

    await api.get({
      path,

      callback: setPropertyList,

      statusCallback: setIsFetching,
    });
  };
  const setActiveProperties = () => {
    let activeProperties = [];
    propertyList &&
      propertyList.map((property) => {
        if (property.is_active) {
          activeProperties.push(property);
        }
      });
    return activeProperties;
  };
  const setApprovedProperties = () => {
    let approvedProperties = [];
    propertyList &&
      propertyList.map((property) => {
        if (property.is_approved) {
          approvedProperties.push(property);
        }
      });
    return approvedProperties;
  };
  useEffect(() => {
    fetchProperties();
    setActiveProperties();
    setApprovedProperties();
  }, [AuthContext, approvedValue]);

  return (
    <div>
      <div>
        <LayoutSidebar />
      </div>
      {!userInfo?.is_aadhar_verified && (
        <div className="verify_adhaar_con">
          <Button
            variant="success"
            onClick={() => history.push(`/verification`)}
          >
            Verify Aadhaar
          </Button>
        </div>
      )}

      <div className="dashboard-stats-card-container">
        <Stats
          count={propertyList?.length}
          title="Total properties Posted"
          onClick={() => history.push(`my-listing`)}
        />
        <Stats
          count={setActiveProperties() && setActiveProperties()?.length}
          title="Total properties Active"
          onClick={() => history.push(`my-listing`)}
        />
        <Stats
          count={setApprovedProperties() && setApprovedProperties()?.length}
          title="Total properties Approved"
          onClick={() => history.push(`my-listing`)}
        />
      </div>
      <Plans />
    </div>
  );
};

export default Index;
