import styled from "styled-components";

export const SubscriptionCategoryCardSc = styled.div`
  margin: 2rem 0;
  .card-container {
    cursor: pointer;
    display: flex;
    /* border: 2px solid #6c6cfd; */
    border-radius: 10px;
    padding: 0.5em 1em;
    margin: 0 3em;
    transition: all 300ms;
  }
  .selected_bg_color {
    background-color: #fa983a;
    color: white;
    border: 2px solid #fa983a;
  }
  .category_title {
    font-size: 1.3rem;
    margin: 0;
    padding: 0;
  }
  .card-container:hover {
    background-color: #6c6cfd;

    color: white;
  }

  @media (max-width: 768px) {
    .card-container{
      padding: 0 0.5rem;
      margin: 0 0.5rem;
    }
  }
`;
