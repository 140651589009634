import React from "react";
import { GeoFill } from "react-bootstrap-icons";
import { Marker } from "react-map-gl";

export default function Index({
  icon = <GeoFill />,
  lat,
  lon,
  offsetTop,
  offsetLeft,
  onClick = () => {}
}) {
  return (
    <Marker
      longitude={lon}
      latitude={lat}
      offsetTop={offsetTop}
      offsetLeft={offsetLeft}
      onClick={onClick}
    >
      <div className="marker temporary-marker cursor-p">{icon}</div>
    </Marker>
  );
}
