import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useHistory } from "react-router-dom";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { InputFieldV1 } from "../../../components/Inputs";
import loginVecotor from "../../../assets/loginPageVector.png";
import backArrow from "../../../assets/arrow_left_circle.png";

export default function Index() {
  const history = useHistory();
  const initValues = {
    cred: {
      password: "",
      username: "",
      confirmPassword: "",
    },
    attributes: {
      "custom:first_name": "",
      "custom:last_name": "",
      "custom:phone_number": "",
    },
  };
  const [cred, setCred] = useState<any>(initValues?.cred);
  const [attributes, setAttributes] = useState<any>(initValues?.attributes);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const { signUp } = useContext(AuthContext);
  const handleAttribute = (key) => (value) => {
    if (key === "custom:phone_number") {
      const isValid = validateNumber(value);
      const isLengthValid = value.length === 10;
      setPhoneNumberError(!isValid || !isLengthValid);
    }
  
    setAttributes({ ...attributes, [key]: value });
  };
  
  
  const validateEmail = (email) => {
    // Email validation logic
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
 
  const [showPassword, setShowPassword] = useState(false);

  // * validation * //
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const initError = {
    confirmPassword: {
      error: false,
      message: "",
    },
    email: {
      error: false,
      message: "",
    },
  };
  const [error, setError] = useState(initError);
  const handleCred = (key) => (value) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/;
    if (key === "password" && !passwordRegex.test(value)) {
      setError({
        ...error,
        confirmPassword: {
          error: true,
          message:
            "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, one digit, and one special character.",
        },
      });
      return;
    }
    else if (key === "username" && !validateEmail(value)) {
      setError((prevError) => ({
        ...prevError,
        email: {
          error: true,
          message: "Invalid email address",
        },
      }));
    }else {
      setError((prevError) => ({
        ...prevError,
        confirmPassword: {
          error: false,
          message: "",
        },
        email: {
          error: false,
          message: "",
        },
      }));
    }
    setCred({ ...cred, [key]: value });
  };

  const handleError =
    (fieldName) =>
      ({ error, message }) => {
        setError({ ...error, [fieldName]: { error, message } });
      };

  const isPasswordValid = () => {
    // ! password validation
    if (cred?.password === cred?.confirmPassword && cred?.password) {
      setError(initError);
      return true;
    }

    if (cred?.password !== cred?.confirmPassword) {
      handleError("confirmPassword")({
        error: true,
        message: "Password is not matched",
      });
    }
    return false;
  };
  const validateNumber = (number) => {
    // Number validation logic
    const numberRegex = /^\d+$/;
    return numberRegex.test(number);
  };
  
  const validate = () => {
    return (
      isPasswordValid() &&
      attributes?.["custom:first_name"] !== "" &&
      attributes?.["custom:last_name"] !== "" &&
      attributes?.["custom:phone_number"] !== "" &&
      cred?.username !== "" &&
      validateEmail(cred?.username) 
    );
  };

  useEffect(() => {
    setError(initError); //? reset the error
  }, []);
  useEffect(() => {
    setIsBtnDisabled(!validate());
  }, [cred, attributes]);

  // * form submit handler * //
  const handleSubmit = async () => {
    console.log(cred?.username, cred?.password, attributes);
    await signUp(cred?.username, cred?.password, attributes)
      .then((result) => {
        console.log(result);
        if (result?.statusCode === 201) {
          history.push(result?.redirectTo);
        }
      })
      .catch((err) => {
        console.log("error > ", err);
      });
  };
  
  return (
    <div>
      <div className="d-flex justify-content-center vh-100 align-items-center ">
        <div className="">
          <div>
            <img
              className=""
              onClick={() => history.goBack()}
              style={{ cursor: "pointer" }}
              src={backArrow}
            />
          </div>
          <div className="pt-4 pb-0 px-4 py-4 m-2 d-flex flex-column align-items-center border border-secondary shadow-lg login-box">
            <div className="fs-3 fw-semibold login-index">Register</div>

            {/* 
// input container
 */}
            <div className="">
              <div className="d-flex flex-row justify-content-between flex-wrap">
                <InputFieldV1
                  placeholder="First Name"
                  onChange={(e) => handleAttribute("custom:first_name")(e)}
                  containerClassName="me-3 mt-3  col-12 col-sm-12 col-xs-12 col-md-5 col-lg-5 w-100 "
                />
                <InputFieldV1
                  placeholder="Last Name"
                  onChange={(e) => handleAttribute("custom:last_name")(e)}
                  containerClassName="mt-3  col-12 col-sm-12 col-xs-12 col-md-5 col-lg-5 w-100"
                />
              </div>

              <div className="mt-3">
                <InputFieldV1
               
                 placeholder="Phone Number"
                 onChange={(e) => handleAttribute("custom:phone_number")(e)}
                 error={phoneNumberError}
                 errorMsg={
                   phoneNumberError
                     ? "Phone number must be a 10-digit number"
                     : ""
                 }

                />
              </div>

              <div className="mt-3">
                <InputFieldV1
                  placeholder="Email"
                  onChange={(e) => handleCred("username")(e)}
                  error={error?.email?.error}
                  errorMsg={error?.email?.message}
                />
              </div>

              <InputFieldV1
                placeholder="New Password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => handleCred("password")(e)}
                containerClassName="mt-3"
              />
              <InputFieldV1
                placeholder="Confirm New Password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => handleCred("confirmPassword")(e)}
                error={error?.confirmPassword?.error}
                errorMsg={error?.confirmPassword?.message}
                containerClassName="mt-3"
                left={
                  <div className="d-flex flex-row">
                    <div
                      className=""
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {!showPassword ? <Eye /> : <EyeSlash />}
                    </div>
                  </div>
                }
              />
            </div>

            {/* <div className="mt-3 ps-2 w-75 text-muted">
            Already me
            <span
              className="f-b text-dark cursor-p"
              onClick={() => history.push("/logIn")}
            >
              {" "}
              click here{" "}
            </span>{" "}
            to Log In.
          </div> */}
            <div className="mt-4 w-100 d-flex justify-content-between">
              <div
                className="login-text"
                onClick={() => history.push("/logIn")}
              >
                Login Instead
              </div>
              <button
                className={`btn-login`}
                disabled={isBtnDisabled}
                onClick={handleSubmit}
              >
                Register
              </button>
            </div>
            <div className="login-image ">
              <img className="login-vector" src={loginVecotor} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
