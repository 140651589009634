import React, { useEffect, useState } from "react";

import useAuth from "../useAuth";

type UserRoleType = "user" | "agent" | "property-owner";

interface Props {
  propertyData?: any;
}

interface RoleGroupProps {
  roles: UserRoleType[];

  group: string;
}

const Index = (props?: Props) => {
  const [roles, setRoles] = useState<UserRoleType[]>([]);

  const auth = useAuth();

  const roleGroup: RoleGroupProps[] = [
    {
      group: "user",

      roles: ["user"],
    },

    {
      group: "agent",

      roles: ["agent", "user"],
    },

    {
      group: "property-owner",

      roles: ["property-owner", "user"],
    },

    {
      group: "all",

      roles: ["property-owner", "user", "agent"],
    },
  ];

  const getRoleGroupRoles = (e: string) => {
    return (
      roleGroup.find((role) => role.group === e) ||
      roleGroup.find((role) => role.group === "user")
    ).roles;
  };

  const isPropertyOwner = (propertyData:any) => {
    // console.log('>>>', props?.propertyData?.user_key,auth?.userInfo?.user_key,'<<<<')
    return propertyData?.user_key === auth?.userInfo?.user_key;
  };

  useEffect(() => {
    if (auth?.isAuthenticated) {
      if (auth?.userInfo?.is_agent) {
        setRoles(getRoleGroupRoles("agent"));
        // if (auth?.user?.attributes?.sub === props?.propertyUserKey) {
        //   setRoles(getRoleGroupRoles("agent"));
        // } else {
        //   setRoles(getRoleGroupRoles("all"));
        // }
      } else {
        setRoles(getRoleGroupRoles("user"));
      }
    } else {
      setRoles([]);
    }
  }, [auth?.userInfo, auth?.user, props, auth?.isAuthenticated]);

  return { roles, isPropertyOwner };
};

export default Index;
