import React, { useContext, useState, useEffect } from "react";
import SideBar from "../Components/SideIconBar";
import paymentbg from "../../../assets/paymentbg.png";
import { Container, Table } from "react-bootstrap";
import { GET } from "../../../api/functions";
import { AuthContext } from "../../../context/AuthContext";

export type PaymentHistoryType = {
  approved_count: number;
  payments_history: {
    invoice_data: Array<{
      invoice_id: number;
      payment_amt: string;
      payment_status: number;
      task_count: number;
    }> | null;
  };
  total_earnings: number;
};

export default function Index() {
  const [paymentHistoryData, setPaymentHistoryData] =
    useState<PaymentHistoryType>();
  const { agentData } = useContext(AuthContext);

  const getPaymentHistory = async () => {
    try {
      const response = await GET(`/agents/${agentData.agent_id}/payments`);
      console.log(response);
      if (response[0] === 200) {
        console.log(response[1][0].payments_history.invoice_data);
        setPaymentHistoryData(response[1][0]);
        return response;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPaymentHistory();
  }, []);

  return (
    <>
      <div className="dashboard-container w-max-content">
        <div className="sidebar">
          <SideBar />
        </div>
        <div className="right-column">
          <div
            style={{
              backgroundImage: `url(${paymentbg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              display: "flex",
              height: "auto",
            }}
          >
            {paymentHistoryData ? (
              <p className="d-flex justify-content-end align-items-center fw-400 m-2">
                Total earnings:{" "}
                <span
                  style={{
                    fontFamily: "Roboto",
                    color: "#fff",
                    fontWeight: "bolder",
                  }}
                >
                  &#8377; {paymentHistoryData.total_earnings}{" "}
                </span>
              </p>
            ) : (
              <p> No earnings</p>
            )}
          </div>
          <div className="mt-5">
            <Container fluid>
              {paymentHistoryData &&
              paymentHistoryData.payments_history &&
              paymentHistoryData.payments_history.invoice_data ? (
                <Table bordered hover>
                  <thead>
                    <tr className="bg-warning text-center">
                      <th>Invoice Id</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>For Approved Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentHistoryData.payments_history.invoice_data.map(
                      (data, index) => (
                        <tr key={index} className="bg-light text-center">
                          <td style={{ width: "10%" }}>{data.invoice_id}</td>
                          <td style={{ width: "30%" }}>{data.payment_amt}</td>
                          <td style={{ width: "30%" }}>
                            {data.payment_status ? "Paid" : "Not paid"}
                          </td>
                          <td style={{ width: "30%" }}>{data.task_count}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              ) : (
                <p className="text-center">No Records found</p>
              )}
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
