import React from 'react'

export default function Home() {
  return (
    <svg width="44" height="42" viewBox="0 0 44 42" fill="#3247D1" xmlns="http://www.w3.org/2000/svg">
<path d="M0 41.4773V13.8258L21.7742 0L43.5484 13.8258V41.4773H27.2177V25.3472H16.3306V41.4773H0ZM5.44355 36.8687H10.8871V20.7386H32.6613V36.8687H38.1048V16.1301L21.7742 5.76073L5.44355 16.1301V36.8687Z" fill=""/>
</svg>

  )
}
