import React, { useContext, useEffect, useState } from "react";
import { FormControl, InputGroup, Button } from "react-bootstrap";
import { GeoAltFill, Search } from "react-bootstrap-icons";
import { geoCordApi } from "../../../../api";
import { StateContext } from "../../../../context/StateContext";
import { parseMapboxApiResponse } from "../../../../helpers/parseMapboxApi";

export default function Index({ initialSearchText, onClick }) {
  const { globalParams } = useContext(StateContext);
  const [searchText, setSearchText] = useState(initialSearchText);
  const [results, setResults] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [resultDisplay, setResultDisplay] = useState(false);
  const handleChange = (value) => {
    setSearchText(value);
    setResultDisplay(true);
  };

  const fetchLocation = async () => {
    const res = await geoCordApi({
      query: searchText,
      countryList: globalParams?.country
    });
    if (res["status"] === 200) {
      setResults(res["data"]?.features);
    } else {
      alert("error in mapbox geocoord");
    }
  };

  // useEffect(() => {
  //   if (!searchText) {
  //     setResultDisplay(false);
  //   }
  //   if (resultDisplay) {
  //     document.getElementById("root").addEventListener("click", () => {
  //       setResultDisplay(false);
  //     });
  //   } else {
  //     document.getElementById("root").removeEventListener("click", () => {});
  //   }
  // }, [resultDisplay, searchText]);

  useEffect(() => {
    if (searchText) {
      fetchLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, globalParams?.country]);
  return (
    <div>
      <input
        type="text"
        name="location"
        onChange={(e) => handleChange(e.target.value)}
        value={searchText}
      />

      {/* <InputGroup className="">
        <FormControl
          placeholder="Search by location"
          onChange={(e) => handleChange(e.target.value)}
          value={searchText}
          style={{
           borderRadius: "20px"
          }}
        /> */}
        {/* <Button variant="outline-secondary" id="button-addon2" onClick={()=>onClick(selectedLocation)}>
          <Search/>
        </Button> */}
      {/* </InputGroup> */}
      <div
        style={{
          zIndex: 8,
          position: "absolute",
          display:
            resultDisplay && searchText && results?.length > 0
              ? "block"
              : "none",
          maxHeight: "40%",
          overflowY: "scroll"
        }}
        className="bg-light rounded shadow p-3"
      >
        {results?.map((result) => (
          <div
            className="cursor-p mb-3"
            key={result?.id}
            onClick={async () => {
              console.log("result > ", result);
              setSelectedLocation(result?.center);
              setSearchText(result?.place_name);
              setResultDisplay(false);
              onClick(await parseMapboxApiResponse(result));
            }}
          >
            <div>
              <GeoAltFill color="#aaa" size={20} />
              {result?.place_name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
