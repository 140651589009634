import styled from "styled-components";

export const Card = styled.div`
  &:hover {
    background-color: #f1f1f1;
    transform: scale(1.01);
  }
  .prop-card,
  .area-and-price {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .prop-card {
    border-bottom: 1px solid #b8b4b4;
  }
  .image-section,
  .prop-card-right {
    display: flex;
    flex-direction: column;
    /* width: 50%; */
    padding: 10px;
  }

  .image-section {
    /* width: 40%; */

    align-items: center;
    justify-content: center;
  }
  .prop-card-right {
    width: 60%;
    position: relative;
  }
  .prop-image-container {
    position: relative;
  }

  .prop-image-container > img {
    width: 270px;
    height: 150px;
    border-radius: 5px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .price {
    color: var(--secondary-color);
    font-weight: bold;
    font-size: 24px;
    opacity: 0.8;
    letter-spacing: 1px;
  }

  .details {
    color: #000;
    width: 90%;
    padding: 16px;
  }

  .area {
    font-weight: 500;
  }

  .area-and-price {
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 0px;
  }

  .action-btns {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 16px;
    right: 8px;
  }

  .btn-container {
    position: absolute;
    bottom: 8px;
    right: 8px;
  }

  .type-and-location {
    border-bottom: 1px solid #f1f1f1;
    padding: 8px 0;
  }

  .type,
  .area {
    font-size: 16px;
    font-weight: bold;
  }

  .location,
  .prop-desc {
    font-size: 14px;
    color: #a1a1a1;
    width: full;
  }

  .configs {
    display: flex;
    flex-direction: row;
    color: var(--primary-color);
    padding: 8px 0;
  }
  .configs > div {
    // margin: 8px;
    font-size: 16px;
  }

  .new {
    background-color: var(--primary-color);
    color: #fff;
    padding: 2px 8px;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    font-weight: bold;
  }

  .verified {
    position: absolute;
    z-index: 2;
    top: 12px;
    right: 12px;
  }

  .more-images-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .more-images {
    display: flex;
    flex-direction: row;
  }

  .more-image {
    width: 50px;
    height: 50px;
    background-color: #aaa;
    margin-left: 16px;
  }

  .view-more {
    font-size: 12px;
    margin-left: 16px;
  }
  .property-location {
    font-size: 12px;
    color: #a1a1a1;
  }
  .prop-card-btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
  }
  .prop-card-btns > a {
    background-color: #4caf50;
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  .listing-type-card-prop {
    right: 10px;
    top: 65px;
    position: absolute;
    color: var(--primary-color);
    padding: 5px;
    /* border-radius: 5px; */
  }
  margin: 16px;
  /* min-width: 500px;
  width:700px; */
  //  max-width: 600px;
  /* display:flex;
  flex-direction: row;
  overflow:hidden;

  .image-section{
    display: flex;
    // flex:1;
    width: 400px;
  }

  .image-section{
    display: flex;
    flex-direction: column;
    width: 350px;
    min-width:300px;
  }

  .image-details{
    // background-color: red;
    flex:1;
    padding: 8px;
    
  }

  .badges{
    position: relative;
  }

  .image-container{
    padding: 8px;
    width: 100%;
  }

  .image-container > img{
    width: 100%;
  }


  .content-container{
    display:flex;
    flex-direction: column;
    position:relative;
    min-width: 400px;
    width:700px;
  }
  
 */

  @media only screen and (max-width: 768px) {
    /* margin: 16px;
  min-width: 500px;
  width:700px; */
    //  max-width: 600px;
    .prop-card {
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      border: solid 1px #c9c8c8;
      /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.24); */
      margin-bottom: 20px;
      width:300px
    }
    .prop-image-container> img {
    width: 270px;
    height: 150px;
    border-radius: 5px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  }
  .image-section,
  .prop-card-right {
    width: 100%;
  }
  @media only screen and (max-width: 1200px) {
    /* margin: 16px;
  min-width: 500px;
  width:700px; */
    //  max-width: 600px;
    .prop-card {
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      border: solid 1px #c9c8c8;
      /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.24); */
      margin-bottom: 20px;
      width:300px
    }
  }
  .image-section,
  .prop-card-right {
    width: 100%;
  }
`;
