import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { XCircleFill } from "react-bootstrap-icons";

function getDates(noOfDay = 5) {
  let dates = new Array(noOfDay).fill(null);
  dates.map((_, index) => {
    let t = new Date();
    let temp = new Date(t.setDate(t.getDate() + (index + 1)));
    dates[index] = `${temp.getFullYear()}/${
      temp.getMonth() + 1
    }/${temp.getDate()}`;
  });
  console.log(dates);
  return dates;
}
export default function Index() {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    console.log("selected date:", selectedDate);
  };

  const handleShow = () => setShow(true);
  const [dateList, setDateList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [timePicker, setTimePicker] = useState(new Date());
  const [selectedTiming, setSelectedTiming] = useState("00:00");
  useEffect(() => {
    setDateList(getDates());
  }, []);
  // useEffect(() => {
  //   handleTiming(propId, `${selectedDate} ${selectedTiming}`)
  //   console.log(`${selectedDate} ${selectedTiming}`)
  // }, [selectedDate, selectedTiming])
  return (
    <>
      {" "}
      <div>
        <Button
          className="fs-5 px-3 mt-2 d-flex flex-column flex-wrap mx-auto"
          onClick={() => {
            handleShow();
          }}
        >
          Schedule Tour
        </Button>
      </div>
      <Modal
        show={show}
        onClose={handleClose}
        centered
        size="lg"
        className="mb-5"
      >
        <>
          <div className="p-3">
            <div className="d-flex flex-row justify-content-between">
              <div className="fs-5 f-b">Schedule Tour</div>
              <XCircleFill size={30} color="#aaa" onClick={handleClose} />
            </div>

            <hr className="mb-4 mt-2" />
            <div className="delivery-schedule-container column">
              <div className="column">
                <div className=" delivery-schedule-slot-container d-flex flex-row flex-wrap">
                  {dateList?.length > 0 &&
                    dateList?.map((slot, index) => (
                      <div
                        className={`delivery-schedule-slot btn btn-primary p-1 px-2 ms-2 cursor-p btn-${
                          slot === selectedDate ? "primary f-b" : "light"
                        }`}
                        key={index}
                        onClick={() => {
                          setSelectedDate(slot);
                        }}
                      >
                        {slot}
                      </div>
                    ))}
                </div>
                {dateList?.length > 0 && (
                  <div>
                    <div className="title mt-1 f-b bold">
                      Timing
                      <hr className="divider-line" />
                    </div>
                    <div className="delivery-schedule-timing-container row mt-2">
                      <Form.Group>
                        <select id="time" className={`form-select`}>
                          <option value="Select Time">Select Time</option>
                          <option value="9">9:00AM</option>
                          <option value="10">10:00AM</option>
                          <option value="11">11:00AM</option>
                          <option value="12">12:00PM</option>
                          <option value="1">1:00PM</option>
                          <option value="2">2:00PM</option>
                          <option value="3">3:00PM</option>
                          <option value="4">4:00PM</option>
                          <option value="5">5:00PM</option>
                          <option value="6">6:00PM</option>
                        </select>
                      </Form.Group>
                    </div>
                  </div>
                )}
              </div>
              <button className="btn btn-primary mt-3" onClick={handleClose}>
                Ok
              </button>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}
