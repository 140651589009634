import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import "./style.css";
import Agree from "../../assets/svg/agree";
import {
  BasicForm,
  OwnerForm,
  TenantForm,
  PropertyDetails,
  ContractForm,
  ItemList,
  Preview,
} from "../AgreementsV2/Components/Forms";
import { PostPropertyForm } from "..";
import Logo from "../../assets/brandLogoBlack.png"
import {useHistory} from 'react-router-dom'

function Index() {
  //state for steps
  const [step, setStep] = useState(1);
  const history = useHistory()
  //state for form data
  const [formData, setFormData] = useState({
    userType: "",
    propCity:"",
    OfullName: "",
    OfatherName: "",
    Ophone: "",
    Oadress: "",
    Oemail: "",
    TfullName: "",
    TfatherName: "",
    Tphone: "",
    Tadress: "",
    Temail: "",
    floorNo: "",
    propType: "",
    bhkType: "",
    houseNo: "",
    houseaddr: "",
    pincode: "",
    agreeDate: "",
    monthlyRent: "",
    rentDate: "",
    secAmt: "",
    noticePeriod: "",
    // Tpan: "",
  });
  // console.log(formData);

  const nextStep = () => {
    setStep((e) => e + 1);
  };

  const prevStep = () => {
    setStep((e) => e - 1);
  };

  const handleInputData = (input) => (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  const AgreeForm = (step) => {
    switch (step) {
      // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
      case 1:
        return (
          <>
            <BasicForm
              nextStep={nextStep}
              handleFormData={handleInputData}
              values={formData}
              // errors={error}
            />
          </>
        );
      case 2:
        return (
          <>
            <OwnerForm
              nextStep={nextStep}
              prevStep={prevStep}
              handleFormData={handleInputData}
              values={formData}
            />
          </>
        );
      case 3:
        return (
          <>
            <TenantForm
              nextStep={nextStep}
              prevStep={prevStep}
              handleFormData={handleInputData}
              values={formData}
            />
          </>
        );
      case 4:
        return (
          <>
            <PropertyDetails
              nextStep={nextStep}
              prevStep={prevStep}
              handleFormData={handleInputData}
              values={formData}
            />
          </>
        );
      case 5:
        return (
          <>
            <ContractForm
              nextStep={nextStep}
              prevStep={prevStep}
              handleFormData={handleInputData}
              values={formData}
            />
          </>
        );
      case 6:
        return (
          <>
            <ItemList
              nextStep={nextStep}
              prevStep={prevStep}
              handleFormData={handleInputData}
              values={formData}
            />
          </>
        );
      case 7:
        return (
          <>
            <Preview prevStep={prevStep} values={formData} />
          </>
        );

      // default:
      //   return <div className="App"></div>;
    }
  };
  return (
    <>
    <div className="navigation" style={{ display:"flex",  backgroundColor: "#f1f1f9" }}>
          <img
            className="brand-logo-lay"
            src={Logo}
            alt="truprops"
            style={{
              height: "60px",
              cursor: "pointer",
              // backgroundColor: "#FF8504 "
            }}
            onClick={() => history.push("/")}
          />
        </div>
      <div className="agree-main">
        <div className="agree-left">
          <Agree />
        </div>
        <div className="agree-form-container">{AgreeForm(step)}</div>
      </div>
    </>
  );
}

export default Index;

{
  /* <Final values={formData}  /> */
}
