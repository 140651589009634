import React, { useContext, useEffect, useState } from "react";
import SideBar from "../Components/SideIconBar";
import { calculateDistance } from "../../../utils";
import PropertyCard from "../Components/PropCard";
import { AuthContext } from "../../../context/AuthContext";
import getAgentBucket from "../../../api/Agent/getAgentBucket";
import deletePropFromBucket from "../../../api/Agent/deletePropFromBucket";
import NoProp from "../../../assets/svg/norecord";

export default function Index() {
  const { agentData } = useContext(AuthContext);
  const { fetchData } = useContext(AuthContext);
  // const agentLocation = { lat: 9.016216, lon: 77.2508838};

  const [properties, setProperties] = useState([]);
  const [aprvdProp, setAprvdProp] = useState([])
  const handleReadAgentBucket = async () => {
    const bucketData = await getAgentBucket(agentData?.agent_id);
    setProperties(bucketData[1][0]?.assigned_property);
    setAprvdProp(bucketData[1][0]?.approved_property)
    
  };
  const handleDeleteProp = async (property) => {
    await deletePropFromBucket(agentData?.agent_id, property);
    handleReadAgentBucket();
  };

  useEffect(() => {
    if (!agentData?.agent_id || !properties) return;
    handleReadAgentBucket();
    fetchData();
  }, []);
  useEffect(() => {
    console.log(properties, "frefdbdddfdgfg");
    console.log(aprvdProp, "frefdbdddfdgfg");
  }, [aprvdProp,properties])
  
  return (
    <>
      <div className="dashboard-container">
        <div className="sidebar">
          <SideBar />
        </div>
        {agentData && (
          <div className="right-column">
            {properties ? (
              <>
              {
              properties?.map((property, idx) => (
                <PropertyCard
                  idx={idx}
                  property={property}
                  onView={() => console.log(`Viewing property ${property.id}`)}
                  // onAdd={() => console.log(`Adding property ${property.id}`)}
                  onVerify={() => console.log(`Verify property ${property.id}`)}
                  onNavigate={{}}
                  onDelete={() => handleDeleteProp(property.property_key)}
                  agentKey={agentData?.agent_id}
                />
              ))}
              </>
            ) : (
              <>
                <div className="agt-no-rcrd">
                  <p>
                    <strong>No Properties found in Your Bucket</strong>
                  </p>
                  <NoProp />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}
