import React from "react";
import Footer from "../../layouts/Footer";
import { useHistory } from "react-router-dom";

export default function Indes() {
  const history = useHistory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bnr-bg">
        {/* <div className="home-icon"><Home/></div> */}
        <div className="bnr-content">
          <img
            onClick={() => history.push("./")}
            className="brand-logo"
            src="https://truprops.com/static/media/brandlogo.106f7c99d4a8ffbd67c4.png"
            alt="truprops"
          />

          <button className="list-your-prop">Buyer/Seller Guidelines</button>
          <div>
            Last Updated :{" "}
            <span style={{ color: "white" }}>August 30, 2023</span>
          </div>
        </div>
      </div>
      <div className="d-flex w-100 justify-content-center align-items-center ">
        <div className="aboutus-heading  w-75 m-0">
          <br />
          <div className="interprtation">
            Truprops Listing! Buyer/Seller Guidelines
          </div>
          <br />
          <div className="para-1">
            Welcome to the world of{" "}
            <span className="interprtation-content">Truprops</span> online real
            estate listing! Whether you're buying or selling a property, these
            guidelines will help you navigate the process smoothly. Don't worry
            if you're new to real estate – we've got you covered with
            easy-to-understand tips.
          </div>
          <br />
          <div className="interprtation-content">For Buyers:</div>
          <br />
          <div className="interprtation">Research:</div>
          <div className="para-1">
            Take your time to explore different online real estate platforms
            like TRUPROPS. Look for properties in your desired location, within
            your budget, and with the features you need.
          </div>
          <br />
          <div className="interprtation">Set Priorities: </div>
          <div className="para-1">
            Make a list of your "must-have" and "nice-to-have" features. This
            will help you narrow down your choices and find a property that fits
            your needs.
          </div>
          <br />
          <div className="interprtation">Check Details: </div>
          <div className="para-1">
            Read the property descriptions, view photos, and check virtual tours
            if available. Look for information about the number of bedrooms,
            bathrooms, and any special features.
          </div>
          <br />
          <div className="interprtation">Location Matters: </div>
          <div className="para-1">
            Consider the property's proximity to your workplace, schools,
            transportation, and other important amenities. Research the
            neighborhood to ensure it aligns with your lifestyle.
          </div>
          <br />
          <div className="interprtation">Connect with Agents: </div>
          <div className="para-1">
            Online listings often have real estate agents' contact information.
            Reach out to them with any questions you have about the property or
            the buying process.
          </div>
          <br />
          <div className="interprtation">Arrange Visits:</div>
          <div className="para-1">
            If you're interested in a property, schedule a visit if possible.
            This gives you a better sense of the property's condition and
            layout.
          </div>
          <br />
          <div className="interprtation">Research:</div>
          <div className="para-1">
            Take your time to explore different online real estate platforms
            like TRUPROPS. Look for properties in your desired location, within
            your budget, and with the features you need.
          </div>
          <br />
          <div className="interprtation">Financing: </div>
          <div className="para-1">
            Sort out your financing options before making an offer. Get
            pre-approved for a mortgage to know your budget and strengthen your
            offer's credibility.
          </div>
          <br />
          <div className="interprtation">Negotiate Wisely: </div>
          <div className="para-1">
            If you're interested in a property, negotiate the price and terms
            with the seller. Don't be afraid to ask for reasonable adjustments
            based on the property's condition.
          </div>
          <br />
          <div className="interprtation">Inspect Thoroughly: </div>
          <div className="para-1">
            Once your offer is accepted, hire a professional home inspector to
            check the property for any issues. This helps you avoid unexpected
            problems down the line.
          </div>
          <br />
          <div className="interprtation">Finalize the Deal: </div>
          <div className="para-1">
            If everything looks good after the inspection, work with your real
            estate agent and lawyer to finalize the paperwork and close the
            deal.
          </div>
          <br />
          <br />
          <div className="interprtation-content">For Sellers </div>
          <br />
          <div className="interprtation">Property Information: </div>
          <div className="para-1">
            Provide accurate and detailed information about your property.
            Include the number of bedrooms, bathrooms, square footage, and any
            special features.
          </div>
          <br />
          <div className="interprtation">Property Information: </div>
          <div className="para-1">
            Provide accurate and detailed information about your property.
            Include the number of bedrooms, bathrooms, square footage, and any
            special features.
          </div>
          <br />
          <div className="interprtation">Quality Photos: </div>
          <div className="para-1">
            Take clear and well-lit photos of your property. Highlight its best
            features and provide a virtual tour if possible. Clean and declutter
            the space before taking photos.
          </div>
          <br />
          <div className="interprtation">Set the Right Price: </div>
          <div className="para-1">
            Research similar properties in your area to determine a competitive
            price. Avoid overpricing, as it can deter potential buyers.
          </div>
          <br />
          <div className="interprtation">Highlight the Neighborhood: </div>
          <div className="para-1">
            Describe the benefits of your neighborhood, such as nearby schools,
            parks, shopping centers, and public transportation.
          </div>
          <br />
          <div className="interprtation">Be Responsive: </div>
          <div className="para-1">
            Respond promptly to inquiries from potential buyers. Being available
            to answer questions can help build trust and encourage serious
            buyers.
          </div>
          <br />
          <div className="interprtation">Consider Professional Help: </div>
          <div className="para-1">
            If you're not familiar with the selling process, consider to have
            local real estate agent. They can help you navigate negotiations and
            paperwork.
          </div>
          <br />
          <div className="interprtation">Negotiation: </div>
          <div className="para-1">
            Be open to negotiating with buyers. Consider their offers and be
            willing to make reasonable adjustments if needed.
          </div>
          <br />
          <div className="interprtation">Disclose Information: </div>
          <div className="para-1">
            Be transparent about any known issues with the property. This builds
            trust with potential buyers and can prevent legal problems later.
          </div>
          <br />
          <div className="interprtation">Show the Property: </div>
          <div className="para-1">
            Schedule viewings or virtual tours for interested buyers. Make sure
            the property is clean and presentable.
          </div>
          <br />
          <div className="interprtation">Closing Process: </div>
          <div className="para-1">
            Once you've accepted an offer, work with your real estate agent and
            lawyer to navigate the closing process. Ensure all necessary
            paperwork is completed accurately.
          </div>
          <br />
          <br />
          <div className="para-1">
            Remember, both buyers and sellers can benefit from researching and
            seeking professional advice when needed. Truprops offer a convenient
            way to connect, explore, and make informed decisions in the world of
            real estate. Good luck with your buying or selling journey!
          </div>
          <br/><br/>
        </div>
      </div>
      <Footer />
    </>
  );
}
