import { GET } from "../functions";

const getWishListId = async (userId, property_id) => {
  const path = `/users/${userId}/wishlist/property/${property_id}`;
  return await GET(path)
    .then((result) => {
      console.log("addAgent in db > ", result);
      return result;
    })
    .catch((err) => {
      return err;
    });
};

export default getWishListId;
