import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { Search, GeoAltFill } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import { ButtonGroup, FormCheck, ToggleButton } from "react-bootstrap";
import "mapbox-gl/dist/mapbox-gl.css";
// import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { geoCordApi } from "../../../../api";
import { parseMapboxApiResponse } from "../../../../helpers/parseMapboxApi";
import { propQueryBuilder } from "../../../../helpers/queryBuilder";
import { StateContext } from "../../../../context/StateContext";
import countryList from "../../../../data/countries";
import { props } from "../../../../data/props";

export default function Index(props) {
  const history = useHistory();
  const [searchData, setSearchData] = useState<any>(null);

  const handleSearch = () => {
    console.log(searchData);
    if (searchData) {
      const query = propQueryBuilder({
        ...searchData,
        map_bounds: searchData?.map_bounds,
        looking_for: searchData?.lookingFor,
      });
      history.push(`/results?${query}`);
    } else {
      alert("Choose a location");
    }
    // console.log(searchData);
  };

  return (
    <div className="w-75 d-flex flex-column justify-content-center">
      <SearchBar handleValue={setSearchData} onClick={handleSearch} />
      {/* <div
        className="d-flex align-self-end bg-orange col-3 col-xs-3 col-md-3 col-lg-1 mt-3"
        style={{ marginRight: "10rem" }}
      >
        <div className="mb-5"> */}
          {/* <div className="btn btn-dark" aria-current="page" color="orange">Buy</div>
        <div onClick={() => history.push("/rentProperties")} className="btn btn-dark">Rent</div> */}
          {/* <ButtonGroup className="mb-2 ">
            {[
              { name: "Rent", value: "rent" },
              { name: "Buy", value: "buy" }
            ].map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={lookingFor === radio.value ? "dark" : "secondary"}
                name="radio"
                value={radio.value}
                checked={lookingFor === radio.value}
                onChange={(e) => setLookingFor(e.currentTarget.value)}
                style={{
                  width: "4rem",
                  opacity: lookingFor === radio.value ? 1 : 0.7
                }}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup> */}
        </div>
    //   </div>
    // </div>
  );
}

const SearchBar = ({ handleValue, onClick }) => {
  const { handleGlobalParams } = useContext(StateContext);
  const [lookingFor, setLookingFor] = useState<any>("buy");

  const [searchText, setSearchText] = useState("");
  const [results, setResults] = useState([]);
  const [resultDisplay, setResultDisplay] = useState(true);
  const handleChange = (value) => {
    setSearchText(value);
    setResultDisplay(true);
  };
  const [tab, setTab] = useState("button1");
  // const [currentButton, setCurrentButton] = useState("button1");
  const unSelectedClass = "";
  const selectedClass = "selected-tab";
  // const setNavbarCurrentButton = props.setNavbarCurrentButton


  // *
  // useEffect(() => {
  //   if (!searchText) {
  //     setResultDisplay(false);
  //   }
  //   if (resultDisplay) {
  //     document.getElementById("root").addEventListener("click", () => {
  //       setResultDisplay(false);
  //     });
  //   } else {
  //     document.getElementById("root").removeEventListener("click", () => {});
  //   }
  // }, [resultDisplay]);

  // *country selector
  const [country, setCountry] = useState<any>("in"||"us");
  const [showCountryOption, setShowCountryOption] = useState(false);
  

  const fetchLocation = async () => {
    const res = await geoCordApi({ query: searchText, countryList: country });
    console.log("---->", country, res);

    if (res["status"] === 200) {
      setResults(res["data"]?.features);
    } else {
      alert("error in mapbox geocoord");
    }
  };
  useEffect(() => {
    console.log(searchText);

    if (searchText) {
      fetchLocation();
    } else {
      handleValue(null);
    }
  }, [searchText, country]);

  return (
    <div className="w-100">
      {/* <div className=""> */}
    {/* <div className="d-flex flex-column justify-content-center align-content-center"> */}
      <div className="radio-button-search-bar d-flex flex-row align-self-center w-100 ">
        {/* <div className="  form-check"> */}
        <div 
      

          className={` form-check1  ml-4 ${
            tab == "button1" ? selectedClass : unSelectedClass
          }`}
          onClick={() => {
            // setCurrentButton("button1");
            setLookingFor("buy");
            setTab("button1");
            // setNavbarCurrentButton('button1');
            
          }}
          id="button1"
        >
          Buy
        </div>
        <div
          className={` form-check2  ml-4 ${
            tab == "button2" ? selectedClass : unSelectedClass
          }`}
          onClick={() => {
            // setCurrentButton("button2");
            setLookingFor("rent");
            setTab("button2");
            
          }}
          id="button2"
        >
          Rent
        </div>
      </div>

      {/* <input className="form-check-input" type="radio" name="flexRadioDefault" id="rent" onClick={() => setLookingFor("rent")}/>
        <label className="form-check-label"  >
          Rent
        </label> */}
      {/* </div> */}
      {/* <div className=" ms-5 form-check">
        <input className="form-check-input" type="radio" name="flexRadioDefault" id="buy" onClick={() => setLookingFor("buy")}/>
        <label className="form-check-label" >
          Buy
        </label>
      </div> */}
      <div className="re-home-search-bar d-flex flex-row align-items-center shadow-lg w-100">
        <div className="location-icon d-flex flex-row align-items-center">
          <GeoAltFill color="#aaf" size={20} />
        </div>

        {/* //*country */}
        <div className=" ">
          <div
            className="text-muted cursor-p"
            onClick={() => setShowCountryOption(!showCountryOption)}
          >
            {countryList?.find((c) => c.value === country)?.label}
          </div>

          {showCountryOption && (
            <div
              className="map-lists d-flex flex-column mt-3 bg-light rounded shadow"
              style={{
                display: showCountryOption ? "block" : "none",
              }}
            >
              {countryList?.map((c, idx) => (
                <div
                  className="m-1 px-1 cursor-p "
                  key={idx}
                  onClick={() => {
                    setCountry(c?.value);
                    setShowCountryOption(false);
                    handleGlobalParams({ country: c?.value });
                  }}
                >
                  {c?.label}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* //*looking for */}
        {/* <div className=" ">
          <div
            className="text-muted cursor-p"
            onClick={() => setLookingFor(!lookingFor)}
          >
            {lookingforprop?.find((c) => c.value === "rent")}
          </div>

          {lookingFor && (
            <div
              className="d-flex flex-column mt-3 bg-light rounded shadow"
              style={{
                zIndex: 8,
                position: "absolute",
                display: lookingFor ? "block" : "none"
              }}
            >
              {[lookingforprop]?.map((c, idx) => (
                <div
                  className="m-1 px-1 cursor-p "
                  key={idx}
                  onClick={() => {
                    setLookingFor(c)
                  }}
                >
                  {c}
                </div>
              ))}
            </div>
          )}
        </div> */}
<h3 className="text-muted m-1">|</h3>
        <div className="home-search-input">
          
          <input
            type="text"
            name="search bar"
            onChange={(_) => handleChange(_.target.value)}
            placeholder="Search by location"
            list="location"
            value={searchText}
          />
        </div>
        <button
          className="home-search-icon d-flex flex-row align-items-center"
          onClick={() => onClick()}
        >
          
          <Search
            style={{
              marginLeft: "10px",
            }}
            color="#fff"
            size={20}
          />
        </button>
      </div>
      {/* location list */}
      <div
       className="bg-light rounded shadow search-location "
        style={{
        
          display:
            resultDisplay && searchText && results?.length > 0
              ? "block"
              : "none",
          
        }}
       
      >
        {results?.map((result) => (
          <div
            className="cursor-p  p-3 location-search-list"
            key={result?.id}
            onClick={async () => {
              handleValue({
                ...(await parseMapboxApiResponse(result)),
                lookingFor,
              });
              setSearchText(result?.place_name);
              setResultDisplay(false);
            }}
          >
            <div>
              <GeoAltFill color="#aaf" size={20} />
              {result?.place_name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
// _0e5d7f65ce863b46fe9d50d7be193c1f    CNAME   _95fd4b861fb4deb58d3383e7a0320a71.bnpptgxfyj.acm-validations.aws.
// realestate    CNAME    d2fd2hty6rp11.cloudfront.net
