import React from 'react'
import rentalmockup from "../../assets/svg/objects.svg" 

export default function agree() {
  return (
    <>
    <img src={rentalmockup} style={{width: "90%"}}/>
    </>
  )
}
