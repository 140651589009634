import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import { FavBtnV1, ReadMore } from "../../../../../../components";
import { currencySymbols } from "../../../../../../data/currency";
import getImageUrls from "../../helpers/getImages";
import { useHistory } from "react-router-dom";
import { Button, Row } from "react-bootstrap";
import ScheduledTour from "../../../../../ScheduledTours";
import ContactAgent from "../../../../../../components/Modal/ContactAgent"
export default function Index({ detail }) {
  const history = useHistory();
  const [imageUrls, setImageUrls] = useState<any>([]);
  const parseImageFromDetail = async () =>
    setImageUrls(await getImageUrls(detail));
  useEffect(() => {
    parseImageFromDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);

  return (
    <div>
      <div className="fs-5 px-3 mt-2 d-flex container  flex-column flex-wrap">
        <div className="d-flex flex-row align-items-center justify-content-between container">
          <div className="">
            <Price data={detail} />
          </div>
          <div className="">
            <FavBtnV1 />
          </div>
        </div>
        <div className="container">
          <Address address={detail?.address} />
        </div>
        <div></div>
      </div>

      {/* //! image container */}
      <div
        className=" mt-4 text-center d-flex justify-content-center w-100 img-fluid img-conf"
        style={{ overflow: "hidden" }}
      >
        <ImageGallery items={imageUrls} thumbnailPosition="left" />
      </div>

      {/* image down infos */}
      <div className="w-100">
        <div className=" mt-3 m-auto  w-auto">
          <div className="d-flex flex-row justify-content-center flex-wrap">
            {/* size */}
            <InfoContainer
              title="Area"
              value={
                detail?.resoFacts?.livingArea || detail?.resoFacts?.lotSize
              }
            />

            {/* beds */}
            {detail?.bedrooms && (
              <InfoContainer title="Beds" value={detail?.bedrooms} unit="" />
            )}

            {/* baths */}
            {detail?.bathrooms && (
              <InfoContainer title="Baths" value={detail?.bathrooms} unit="" />
            )}

            {/* property age */}
            {detail?.yearBuilt && (
              <InfoContainer
                title="Age of Property"
                value={new Date().getFullYear() - detail?.yearBuilt + ""}
                unit={`Year${
                  new Date().getFullYear() - detail?.yearBuilt > 1 && "s"
                }`}
              />
            )}
          </div>
        </div>
      </div>

      {/* Description */}
      {detail?.description && (
        <div className="container">
          <div className="fs-4 f-b ">Description</div>
          <ReadMore>{detail?.description}</ReadMore>
        </div>
      )}
      <Row>
      <div className="col-sm-12">
        <ContactAgent/>
      
        <ScheduledTour />
      </div>
      </Row>
      
    </div>
  );
}

const InfoContainer = ({ title, value, unit = "" }) => (
  <div className="d-flex flex-column align-items-center fs-6 col-6 col-sm-4 col-md-3 col-lg-3 col-xl-2 my-2 ">
    <div className="">{title}</div>
    <div className="fs-3 primary-text text-align-center">
      {value}
      <span className="fs-6 "> {unit}</span>
    </div>
  </div>
);

const Address = ({ address }) => {
  return (
    <div className="f-16 text-muted">
      <div>
        {address?.streetAddress}, {address?.city}, {address?.state},{" "}
        {address?.zipcode}
      </div>
    </div>
  );
};

const Price = ({ data }) => {
  const [status, setStatus] = useState<any>(null);
  const listingFor = (homeStatus) => {
    switch (homeStatus) {
      case "FOR_RENT":
        setStatus({ text: "RENT", bg: "primary" });
        break;
      case "FOR_SALE":
        setStatus({ text: "SALE", bg: "success" });
        break;
      case "SOLD":
        setStatus({ text: "SOLD", bg: "warning" });
        break;
      case "OTHER":
        setStatus({ text: "OFF MARKET", bg: "danger" });
        break;
      default:
        setStatus(null);
        return;
    }
  };

  const [homeType, setHomeType] = useState<any>(null);
  const getHomeType = (type) => {
    switch (type) {
      case "LOT":
        setHomeType({ text: "Lot/Land" });
        break;
      case "SINGLE_FAMILY":
        setHomeType({ text: "Single Family" });
        break;
      case "APARTMENT":
        setHomeType({ text: "Apartment" });
        break;

      default:
        setHomeType(null);
        break;
    }
  };
  useEffect(() => {
    listingFor(data?.homeStatus);
    getHomeType(data?.homeType);
  }, [data]);
  return (
    <div className="fs-2 ">
      {status && (
        <span className={`text-light bg-${status?.bg} p-1 px-4 ls-2 f-14`}>
          {status?.text}
        </span>
      )}

      <div className="f-b">
        {data?.price ? (
          <div>
            {currencySymbols[data?.currency]}
            {data?.price}{" "}
            <span className="f-12">
              {data?.homeStatus === "FOR_RENT" ? " /mo" : ""}
            </span>
          </div>
        ) : null}
      </div>

      {homeType && (
        <div className="f-14">
          Type - <span className="f-b">{homeType?.text}</span>
        </div>
      )}
    </div>
  );
};
