import { GET } from "../functions";

const getFavoritesList = async (userId) => {
  const path = `/users/${userId}/wishlist`;
  return await GET(path)
    .then((result) => {
      console.log("get favorite list > ", result);
      return result;
    })
    .catch((err) => {
      return err;
    });
};

export default getFavoritesList;
