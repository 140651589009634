/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Rent from "./Forms/ListingType/Rent";
import Sell from "./Forms/ListingType/Sell";
// import * as yup from "yup";
import { Form, Button, Row, Col } from "react-bootstrap";
import GoogleMapPicker from "../../components/Map/GMapUptd";
// import GoogleMapPicker from "../../test";
import { PropTypeModal } from "./components";
import Apartment from "./Forms/PropertyType/Apartment";
import House from "./Forms/PropertyType/House";
import Office from "./Forms/PropertyType/Office";
import Shop from "./Forms/PropertyType/Shop";
import { addProperty, updateProperty } from "../../api/property";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { v4 as uuidv4 } from "uuid";
import { uploadImage } from "../../api/uploadImage";
import "./style.css";
// import { setTimeout } from "timers";
import { LoadingOverlayV1, LoadingV1 } from "../../components/Loading";
//import { Watch } from "react-bootstrap-icons";
import { pricesIn } from "./data";
import { useApi } from "../../hooks";
import { Layout } from "../../layouts";

interface Props {
  propertyId?: string;
}

export default function Index({ propertyId }: Props) {
  const [isEdit, setIsEdit] = useState(Boolean(propertyId));
  const [propertyTypeData, setPropertyTypeData] = useState<any>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [propertyData, setPropertyData] = useState<any>([null]);
  const [apiPropData, setApiPropData] = useState<any>();
  const api = useApi();
  const [loc, setLoc] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);

  const getPropData = async () => {
    console.log("api start");
    await api.get({
      path: `/properties/${propertyId}`,
      callback: setApiPropData,
      statusCallback: setIsFetching,
    });
    console.log("api end");
  };
useEffect(() => {
  if (apiPropData) {
    let temp = apiPropData
    let { additional_data, ...data } = apiPropData;
    temp = { ...JSON.parse(additional_data), ...data };
    setPropertyData(temp)
    console.log("api end");
  } else {
    console.log("apiPropData is null");
  }
}, [apiPropData])


  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // *image
  const [imageList, setImageList] = useState([]);
  const [dbImageList, setDbImageList] = useState([]);

  // const addImage = (image) => {
  //   const uuid = uuidv4();
  //   setImageList([...imageList, { file: image, name: uuid }]);
  // };

  const addImage = (images) => {
    const newImages = [];
    for (let i = 0; i < images.length; i++) {
      const uuid = uuidv4();
      newImages.push({ file: images[i], name: uuid });
    }
    setImageList([...imageList, ...newImages]);
  };

  const removeImage = (image_name) => {
    setImageList(imageList?.filter((img) => img?.name !== image_name));
  };
  const dbRemoveImage = (image_name) => {
    setDbImageList(dbImageList?.filter((img) => img?.img_src !== image_name));
  };

  const history = useHistory();
  const { userInfo } = useContext(AuthContext);

  const initialFormData = {
    listed_for: null,
  };
  const [formData, setFormData] = useState(initialFormData);
  const handleFormData = (key) => (value) => {
    setFormData({ ...formData, [key]: value })
    setValue(key, formData?.listed_for);
  }

  const fetchProprtyData = (propData) => {
    console.table("fetchdatafn with property data", propData.title);
    const apiPropertyTypeData = {
      listingType: {
        label: propData.listed_for,
        value: propData.listed_for,
      },
      propertyType: {
        label: propData.name,
        value: propData.name,
      },
    };
    console.table(apiPropertyTypeData);
    setPropertyTypeData(apiPropertyTypeData);
  };

  useEffect(() => {
    getPropData();
    console.log("propertyid :", propertyId);
    console.log("Edit:", isEdit);
    if (isEdit) {
      setIsLoading(false);
      console.log("adsaydsdsdudhuwdhu",propertyTypeData);
    }
    if (!isEdit) {
      setShowModal(true);
      console.log("propertytypedata is " + propertyTypeData);
      console.log("isedit is " + isEdit);
    }
  }, [isEdit, propertyId]);

  useEffect(() => {
    console.log(propertyData);

    
    fetchProprtyData(propertyData);
    if (apiPropData) {
      // setValue("3d_tour", propertyData?.3d_tour);
      setValue("description", propertyData?.description);
      setValue("bed", propertyData?.bed);
      setValue("address_line_1", propertyData?.address_line_1);
      setValue("address_line_2", propertyData?.address_line_2);
      setValue("available_from", propertyData?.available_from);
      setValue("bath", propertyData?.bath);
      setValue("built_area", propertyData?.built_area);
      setValue("deposit", propertyData?.deposit);
      setValue("description", propertyData?.description);
      setValue("facing", propertyData?.facing);
      setValue("furnish", propertyData?.furnish);
      setValue("has_basement", propertyData?.has_basement);
      setValue("pool", propertyData?.pool);
      setValue("img_src", propertyData?.img_src);
      setValue("floor_type", propertyData?.floor_type);
      setValue("is_pet_allowed", propertyData?.is_pet_allowed);
      setValue("kitchen", propertyData?.kitchen);
      setValue("lat", propertyData?.lat);
      setValue("listed_for", propertyData?.listed_for);
      setValue("landmark", propertyData?.landmark)
      setValue("open_tour", propertyData?.open_tour);
      setValue("parking", propertyData?.parking);
      setValue("pref_tenant", propertyData?.pref_tenant);
      setValue("price", propertyData?.price);
      setValue("maintenance_charge", propertyData?.maintenance_charge);
      setValue("expec_deposit", propertyData?.expec_deposit);
      setValue("super_builtup", propertyData?.super_builtup);
      setValue("survillance", propertyData?.survillance);
      setValue("property_type_id", propertyData?.property_type_id);
      setValue("title", propertyData?.title);
      setValue("token_amt", propertyData?.token_amt);
      setValue("user_key", propertyData?.user_key);
      setValue("ac", propertyData?.ac);
      setValue("gas", propertyData?.gas);
      setValue("lift", propertyData?.lift);
      setValue("wifi", propertyData?.wifi);
      setValue("power", propertyData?.power);
      setValue("play_area", propertyData?.play_area);
      setValue("fire_safety", propertyData?.fire_safety);
      setValue("park", propertyData?.park);
      setValue("servant_room", propertyData?.servant_room);
      setValue("sewage", propertyData?.sewage);
      setValue("house_keeping", propertyData?.house_keeping);
      setValue("rain_water", propertyData?.rain_water);
      setValue("pool", propertyData?.pool);
      setValue("shopping", propertyData?.shopping);
      setValue("garden", propertyData?.garden);
      setValue("landzone", propertyData?.landzone);
      setValue("wash", propertyData?.wash);
      setValue("personal_wash", propertyData?.personal_wash);
      setValue("floor", propertyData?.floor);
      setValue("total_floor", propertyData?.total_floor);
      setValue("age", propertyData?.age);
      setValue("cafe", propertyData?.cafe);
      setValue("corner", propertyData?.corner);
      setValue("main", propertyData?.main);
      setValue("paid_tax", propertyData?.paid_tax);
      setValue("lease", propertyData?.lease);
      setValue("occupancy", propertyData?.occupancy);
      setValue("gym", propertyData?.gym);
      setValue("security", propertyData?.security);
      setValue("non_veg", propertyData?.non_veg);
      setValue("water_supply", propertyData?.water_supply);
      setValue("gated_security", propertyData?.gated_security);
      setDbImageList(propertyData?.images);
    }
  }, [propertyData]);

  useEffect(() => {
    if (propertyTypeData?.locationData){
      setLoc({
        lat: propertyTypeData?.locationData?.lat,
        lon: propertyTypeData?.locationData?.lon,
      });
    }
    else{
      setLoc({
        lat: propertyData?.lat,
        lon: propertyData?.lon
      })
    }
  }, [propertyTypeData]);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    control,
    watch,
    getValues,
  } = useForm({
    mode: "onBlur",
  });
  // resolver: yupResolver(schema),
  const handleHookFormProps = { register, errors, handleFormData, control, getValues, watch };

  const listingForm = (listingType, propertyType) => {
    switch (listingType?.value) {
      case "RENT":
        return (
          <Rent {...handleHookFormProps} propertyType={propertyType?.value} />
        );
      case "SALE":
        return (
          <Sell {...handleHookFormProps} propertyType={propertyType?.value} />
        );
    }
  };
  const propertyForm = (listingType, propertyType) => {
    switch (propertyType?.value) {
      case "Apartment":
        return <Apartment {...handleHookFormProps} />;
      case "House":
        return <House {...handleHookFormProps} />;
      case "Office":
        return <Office {...handleHookFormProps} />;
      case "Shop":
        return <Shop {...handleHookFormProps} />;
    }
  };

  const postProp = async (data) => {
    console.log("Post prop data" , data)
    await addProperty(data).then(([, res]) => {
      alert("Property Uploaded Successfully")
      history.push("/account/my-listing");
      console.log("property Uploaded ", res);
      // if (statusCode === 200) {
      // } else {
      //   console.log("error while posting prop > ", res);
      //   alert("Something Went Wrong");
      // }
    });
    
  };
  const updateProp = async (data) => {
    await updateProperty(data , propertyData?.property_key).then(([, res]) => {
      alert("Property updated Successfully")
      history.push(`/properties/${propertyData?.property_key}`);
      // console.log("property Uploaded ", res);
      // if (statusCode === 200) {
      // } else {
      //   console.log("error while posting prop > ", res);
      //   alert("Something Went Wrong");
      // }
    });
    // console.log("property data to edit", data)

  };

  const updatePropData = async (data) => {
    let links = [...dbImageList];
    imageList?.forEach((img) => {
      uploadImage(img?.name, img?.file, "path/").then((r) => {
        links = [...links, { img_src: r }];
      });
    });
    await setTimeout(async () => {
      await updateProp({
        ...data,
        img_src: links[0].img_src,
        images: links,
        location_data: propertyData?.location_data 
      }).then(() => {
        setIsLoading(false);
      });
    }, 5000); // ?wait 1000ms to update state
  }

  const validate = () => {
    return (
      (dbImageList?.length > 0) || (imageList?.length > 0) &&
      loc?.lat &&
      loc?.lon &&
      propertyTypeData?.propertyType?.value &&
      propertyTypeData?.listingType?.value
    );
  };

  useEffect(() => {
    setIsBtnDisabled(!validate());
  }, [imageList,dbImageList, loc, propertyTypeData]);

  const uploadPropData = async (data) => {
    let links = [];
    console.log("image list", imageList);
    imageList?.forEach((img) => {
      uploadImage(img?.name, img?.file, "path/").then((r) => {
        links = [...links, { img_src: r }];
      });
    });
    await setTimeout(async () => {
      console.log(links);
      await postProp({
        ...data,
        img_src: links[0].img_src,
        images: links,
        property_type_id: propertyTypeData?.propertyType?.id,
        location_data: propertyTypeData?.locationData,
      }).then(() => {
        setIsLoading(false);
      });
    }, 5000); // ?wait 1000ms to update state
  };

  const onSubmit = async (data:any) => {
    setIsLoading(true);
    const temp = {
      ...data,
      listed_for: propertyTypeData?.listingType?.value,
      ...loc,
      user_key: userInfo?.user_key,
    };
    console.log(temp);
    ( isEdit ? (await updatePropData(temp) ): (await uploadPropData(temp)) )
    
  };

  return (
    <>
      {isLoading && (
        <div className="loading-overlay0">
          <LoadingV1 />
          <div className="text-light fs-4">Uploading your property</div>
        </div>
      )}

      <Layout />
      {/* {isEdit && handleEdit()} */}
      <PropTypeModal
        show={showModal}
        onClose={(e) => {
          setPropertyTypeData(e);
          console.table(e);
        }}
        handleClose={() => {setShowModal(false); }}
      />
      {isEdit == true ? (
        <div className="fs-3 f-b my-2 text-center">Edit Your Property</div>
      ) : null}

      <div className="d-flex justify-content-center">
        {/* <div className="container prop-listing-box"> */}
        {/* {console.log(propertyTypeData ? "t" : "f")} */}
        <div className="listing-form">
          {propertyTypeData && (
            <Form onSubmit={handleSubmit(onSubmit)} className="">
              <div className="fs-3 f-b my-2 text-center listing-heading">
                {propertyTypeData?.propertyType?.label} for{" "}
                {propertyTypeData?.listingType?.label}
              </div>
              <div className="fs-4 text-muted my-2">
                {propertyTypeData?.locationData?.placeName}
              </div>
              {console.log(
                propertyTypeData?.listingType,
                propertyTypeData?.propertyType,
                loc?.lat,
                loc?.lon
              )}

              {propertyTypeData?.listingType &&
                propertyTypeData?.propertyType &&
                loc?.lat &&
                loc?.lon && (
                  <>
                    <div className="listing-container w-100">
                      {propertyForm(
                        propertyTypeData?.listingType,
                        propertyTypeData?.propertyType
                      )}
                    </div>
                    <div className="listing-container w-100">
                      {listingForm(
                        propertyTypeData?.listingType,
                        propertyTypeData?.propertyType
                      )}
                    </div>
                    <div className="my-3 w-100">
                      <div className="my-2 f-b fs-3 text-center listing-heading">
                        Choose Your Property on Map
                      </div>
                      {console.log("loc >", loc)}
                      <GoogleMapPicker
                        loc={{
                          lng: loc?.lon,
                          lat: loc?.lat,
                        }}
                        onChange={(e) => setLoc(e)}
                      />
                    </div>
                  </>
                )}
              {/* //*choose image */}
              <div className="mt-4 mb-2 w-100">
                <div className="f-b fs-3 text-align listing-heading">
                  Choose Images
                </div>
                <span className="text-muted">Click image to remove</span>
                <div className="my-2 d-flex flex-row flex-wrap w-100 listing-container">
                {dbImageList?.map((img, idx) => (
                    <div
                      onClick={() => dbRemoveImage(img?.img_src)}
                      className="image-container m-2"
                      key={idx}
                    >
                      <img
                        src={img?.img_src}
                        alt="property"
                        width="100%"
                      />
                    </div>
                  ))}{
                    imageList?.map((img, idx) => (
                      <div
                        onClick={() => removeImage(img?.name)}
                        className="image-container m-2"
                        key={idx}
                      >
                        <img
                          src={URL.createObjectURL(img?.file)}
                          alt="property"
                          width="100%"
                        />
                      </div>
                    ))
                  }

                  <label
                    htmlFor="image-input"
                    className="f-b custom-file-input-label ms-3"
                  >
                    <div>
                      <div>Click to add image</div>
                    </div>
                  </label>
                  <input
                    name="image-input"
                    id="image-input"
                    type="file"
                    accept="image/*"
                    onChange={({ target }) => {
                      // handleImageChange(target.files);
                      // addImage(target.files[0]);
                      addImage(target.files);
                    }}
                    className="custom-file-input"
                    multiple
                  />
                </div>
              </div>

              {/* { {if(isEdit)} && ( */}
              <div className="w-100 d-flex justify-content-center my-4">
                <button
                  className="list-your-prop"
                  type="submit"
                  disabled={isBtnDisabled}
                >
                  {isEdit ? "Update" : "Post"} Your Property
                </button>
              </div>
            </Form>
          )}
        </div>
        {/* </div> */}

        {/* temp code */}
        <div
          // style={{ height: "400px" }}
          className=" prev-container-right p-5 mt-5 "
        >
          <h6 className="mx-5">Property Preview</h6>

          <div>
          {dbImageList?.length > 0 ? (
              <div>
                <img
                  className="shadow bg-white rounded"
                  src={dbImageList[0]?.img_src}
                  alt="property"
                  width="100%"
                />
              </div>
            ) : imageList?.length > 0 && (
              <div>
                <img
                  className="shadow bg-white rounded"
                  src={URL.createObjectURL(imageList[0]?.file)}
                  alt="property"
                  width="100%"
                />
                </div> ) || 
               ( <div>
                <img
                  className="shadow bg-white rounded"
                  src=""
                  width={200}
                  height={150}
                ></img>
              </div>
            )}
          </div>

          <h6 className="mt-2">
            Type:{" "}
            {propertyTypeData?.propertyType?.label === "House" || propertyTypeData?.propertyType?.label === "Apartment"
              ? `${watch("bed")} BHK`
              : propertyTypeData?.propertyType?.label}{" "}
              
              {console.log("porpoeproeproeproeproer", propertyTypeData)}
            
          </h6>
          <h6 className="mt-2">
            Price:{" "}
            {/* {watch("currency_id")} */}
            {pricesIn.find((e) => e.value === watch("currency_id")?.label)}
            {watch("price")}{" "}
          </h6>

          <div>
            <span style={{ backgroundColor: "#4caf50", color: "white" }}>
              Available From:
            </span>
            <span> {watch("available_from")?.slice(0, 10)}</span>
          </div>

          <div className="cust-location">
            <svg
              width="15"
              height="20"
              viewBox="0 0 20 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 10.2667C19 17.4741 10 23.6519 10 23.6519C10 23.6519 1 17.4741 1 10.2667C1 7.80899 1.94821 5.45198 3.63604 3.71414C5.32387 1.97631 7.61305 1 10 1C12.3869 1 14.6761 1.97631 16.364 3.71414C18.0518 5.45198 19 7.80899 19 10.2667Z"
                stroke="#6C6CFD"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 13.3555C11.6569 13.3555 13 11.9726 13 10.2667C13 8.56071 11.6569 7.17776 10 7.17776C8.34315 7.17776 7 8.56071 7 10.2667C7 11.9726 8.34315 13.3555 10 13.3555Z"
                stroke="#6C6CFD"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span className="span">
              {watch("address_line_1")},{watch("address_line_2")},
              {!isEdit ? (propertyTypeData?.locationData?.placeName) : (propertyData?.location_data?.placeName)}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
