import React from "react";
import "./style.css";
import Footer from "../../layouts/Footer";

import { useHistory } from "react-router-dom";

export default function Index() {
  const history = useHistory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bnr-bg">
        {/* <div className="home-icon"><Home/></div> */}
        <div className="bnr-content">
          <img
            onClick={() => history.push("./")}
            className="brand-logo"
            src="https://truprops.com/static/media/brandlogo.106f7c99d4a8ffbd67c4.png"
            alt="truprops"
          />

          <button className="list-your-prop">Refund Policy</button>
          <div>
            Last Updated :{" "}
            <span style={{ color: "white" }}>October 26, 2022</span>
          </div>
        </div>
      </div>
      {/* <Footer/> */}
      <div className="d-flex w-100 justify-content-center align-items-center ">
        <div className="refund-header w-75 m-0">
          <br />
          <div className="interprtation-content">Refund Policy</div>
          <div>
            Truprops provides a comprehensive online listing service for real
            estate properties. We strive to offer a platform that is easy to
            use, user-friendly and accessible to all. We understand that there
            may be times when a user may wish to request a refund and,
            therefore, we have developed this policy to provide clarity and
            transparency regarding our stance on refunds.
          </div>
          <br />
          <div>
            Please note that our refund policy is non-negotiable and applies to
            all transactions made on truprops. By using truprops and purchasing
            a listing, you acknowledge and agree to the terms of this refund
            policy.
          </div>
          <br/>
          <div className="interprtation-content">
            No Refunds Under Any Circumstances
          </div>
          <div>
            We do not provide any refunds under any circumstances. This means
            that all fees paid for listings on truprops are non-refundable and
            non-transferable, regardless of the reason for the refund request.
            This policy applies to all users of truprops and is a fundamental
            aspect of the terms of use agreement.
          </div>
          <div>
            The listing fee is a one-time payment for the use of truprops to
            list a property. This fee covers the cost of providing the platform,
            hosting the listing, and making it available to potential buyers. As
            a result, we do not provide refunds for any reason, including, but
            not limited to:
          </div>
          <br/>
          <div className="interprtation-content">
            Technical difficulties encountered while using truprops
          </div>
          <div>
            Changes in personal circumstances, such as a change in the status of
            the property or a change in the owner's plans to sell Issues with
            the property listed, such as inaccuracies in the listing information
            or difficulty in completing the sale Unsatisfactory results from the
            listing, such as a lack of interest or offers
          </div>
          <div>
            It is the responsibility of the user to ensure that the information
            provided in the listing is accurate and up-to-date. truprops cannot
            be held responsible for any inaccuracies or errors in the listing
            information.
          </div>
          <br/>
          <div className="interprtation-content">Non-Refundable Fees</div>
          <div>
            The listing fee paid to use truprops is non-refundable. This fee
            covers the cost of providing the platform, hosting the listing, and
            making it available to potential buyers. truprops does not provide
            refunds for this fee under any circumstances.
          </div>
          <div>
            In the event that a user cancels their listing, the listing fee is
            still non-refundable. This fee is charged for the use of our
            platform and, as such, is non-refundable regardless of the length of
            time that the listing is active.
          </div>
          <br />
          <div>
            If a user encounters any technical difficulties while using our
            platform, they should contact our support team for assistance. Our
            support team will do their best to resolve any issues as quickly as
            possible, but we do not provide refunds for technical difficulties.
          </div>
          <div>
            It is important to note that truprops does not provide refunds for
            listings that have already been published. Once a listing has been
            published, it is considered live and active, and the listing fee is
            non-refundable.
          </div>
          <br />
          <div className="interprtation-content">Conclusion</div>

          <div>
            Our refund policy is clear and straightforward. All fees paid for
            listings on truprops are non-refundable and non-transferable,
            regardless of the reason for the refund request. This policy applies
            to all users of truprops and is a fundamental aspect of the terms of
            use agreement.
          </div>
          <div>
            By using truprops and purchasing a listing, you acknowledge and
            agree to the terms of this refund policy. If you have any questions
            or concerns about this policy, please contact us before making a
            purchase.
          </div>
          <div>
            We thank you for choosing truprops for your real estate listing
            needs and we look forward to serving you. Our goal is to provide a
            platform that is easy to use, user-friendly, and accessible to all,
            and we believe that our refund policy reflects this commitment.
          </div>
        </div>
      </div>
      <br />
      <Footer />
    </>
  );
}
