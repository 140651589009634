import React, { useEffect, useState } from "react";

export default function Index({ detail }) {
  const [schools, setSchools] = useState<any>([]);
  useEffect(() => {
    setSchools(detail?.schools);
  }, [detail]);
  return (
    <div className="container">
      <div className="f-b fs-4 mb-2">Schools</div>
      <div className="ps-3">
        {schools?.map((school) => (
          <div className="my-3">
            <div className="d-flex flex-row align-items-center">
              <div
                className="rounded-circle bg-secondary f-12 text-light d-flex align-content-center justify-content-center align-items-center"
                style={{ height: "40px", width: "40px" }}
              >
                <span className="f-b f-14">{school?.rating}</span>
                /10
              </div>

              <div className="d-flex flex-column ms-3">
                <div>
                  <a
                    href={school?.link}
                    target="_blank"
                    className="f-b text-primary"
                    rel="noreferrer"
                  >
                    {school?.name}
                  </a>
                </div>

                <div>
                  Grades: <span className="f-b">{school?.grades} </span>
                  Distance: <span className="f-b">{school?.distance} </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
