import React, { useState } from "react";

const Index = ({ nextStep, handleFormData, prevStep, values }) => {

  const [error, setError] = useState("");
  const submitFormData = (e) => {
    e.preventDefault();
    if(values.OfullName.length===0 && values.OfatherName.length===0 && values.Oadress.length===0){
      return(setError("* All Fields are Required"))
    }
    if(values.Ophone.length <10 || values.Ophone.length >10) {
      return(setError("* Phone number must be 10 digits"))
    }
    if (values.Oemail.indexOf("@") == -1 || values.Oemail.indexOf(".") == -1) {
      return(setError("* Enter valid email id"))
    }
    else{
      setError("")
      nextStep();
    }
    
  };

  // console.log(values.Oemail.indexOf("@"))
  return (
    <>
      <div className="agree-form-header">Owner Details</div>
      <form action="" className="agree-form">
        <div className="agree-form-field">
          <label htmlFor="fullname">Full Name <span className="required"> *</span></label>
          <input type="text" id="fullname" placeholder="Full Name" onChange={handleFormData("OfullName")} defaultValue={values.OfullName} required />
        </div>
        <div className="agree-form-field">
          <label htmlFor="fathername">Father/ Mother's Name <span className="required"> *</span></label>
          <input type="text" id="fathername" placeholder="Father/ Mother's Name"  onChange={handleFormData("OfatherName")} defaultValue={values.OfatherName} required/>
        </div>
        <div className="agree-form-field">
          <label htmlFor="phone">Phone Number <span className="required"> *</span></label>
          <input type="number" id="phone"placeholder="Phone Number" defaultValue={values.Ophone}  onChange={handleFormData("Ophone")}  required/>
        </div>
        <div className="agree-form-field">
          <label htmlFor="email">Email <span className="required"> *</span></label>
          <input type="email" id="email"placeholder="Email" defaultValue={values.Oemail}  onChange={handleFormData("Oemail")}  required/>
        </div>
        <div className="agree-form-field">
          <label htmlFor="address">Permanant Address <span className="required"> *</span></label>
          <input type="text" id="address" placeholder="Permanant Address" defaultValue={values.Oadress}  onChange={handleFormData("Oadress")} required/>
        </div>
        <div className="agree-form-field">
          <label htmlFor="Pan">PAN (optional)</label>
          <input type="text" id="Pan" placeholder="PAN (optional)" defaultValue={values.Opan} onChange={handleFormData("Opan")}/>
        </div>
      
      </form>
      <div className="agree-btn-container">
      {error && <p className="error"><strong>{error}</strong></p>}
          <button className="agree-btn" onClick={prevStep}>
            Previous
          </button>
          <button className="agree-btn" type="submit" onClick={submitFormData}>
            Next
          </button>
        </div>
    </>
  );
};

export default Index;
