import React, { useEffect } from "react";
import "./style.css";
import Facebook from "../../assets/svg/facebook";
import Youtube from "../../assets/svg/youtube";
import Instagram from "../../assets/svg/instagram";
import Twiter from "../../assets/svg/twiter";
import { useHistory } from "react-router-dom";
import Gplaybg from "../../assets/Footer/gplay"

export default function Index() {
  const history = useHistory();
 

  return (
    <div className="footer">
      <div className="footer-row">
        {/* <div className="menu-column"> */}
        <div className="footer-column1">
          <div className="fw-bold mb-2 h5">Company</div>
          <div
            className="footer-link-items"
            onClick={() => history.push("/aboutus")}
          >
            About Truprops
          </div>
          <div
            className="footer-link-items"
            onClick={() => history.push("/contact")}
          >
            Contact Us
          </div>
          <div
            className="footer-link-items"
            onClick={() => history.push("/subscriptionPlans")}
          >
            Subscription Plans{" "}
          </div>
          <div
            className="footer-link-items"
            onClick={() => history.push("/policy")}
          >
            Privacy Policy
          </div>
          <div
            className="footer-link-items"
            onClick={() => history.push("/termscondition")}
          >
            Terms & conditions
          </div>
          <div
            className="footer-link-items"
            onClick={() => history.push("/refund")}
          >
            Cancellation/Refund Policy
          </div>
        </div>

        <div className="footer-column2">
          <div className="fw-bold mb-2 h5">Quick links</div>
          <div
            className="footer-link-items"
            onClick={() => history.push("/agent/dashboard")}
          >
            Agent Dashboard
          </div>
          <div
            className="footer-link-items"
            onClick={() => history.push("/my-favorites")}
          >
            Favorites
          </div>
          <div
            className="footer-link-items"
            onClick={() => history.push("/apply")}
          >
            Apply{" "}
          </div>
          <div
            className="footer-link-items"
            onClick={() => history.push("/account/my-listing")}
          >
            My Listing
          </div>
          <div
            className="footer-link-items"
            onClick={() => history.push("/post-your-property")}
          >
            List Property
          </div>
          <div
            className="footer-link-items"
            onClick={() => history.push("/agreements")}
          >
            Agreements
          </div>
          <div
            className="footer-link-items"
            onClick={() => history.push("/FAQ")}
          >
            FAQs
          </div>
        </div>
        <div className="footer-column3">
          <div className="contact-us-head fw-bold mb-2 h5">
            Contact Information
          </div>

          {/* <div className="toll-free">Toll Free - 1800-000-0000</div> */}
          <div className="email">Email - info@truprops.com</div>
        </div>
        {/* </div> */}
        <div className="footer-bottom">
          <div>
          <a
              className="gplay"
              href="https://play.google.com/store/apps/details?id=com.xbi4.truprops"
              target="blank"
            >
              <Gplaybg />
            </a>
          </div>
          <div className="sm-icons">
            <a
              className="facebook"
              href="https://www.facebook.com/profile.php?id=100086305639493"
              target="blank"
            >
              <Facebook />
            </a>
            <a className="youtube" href="https://www.youtube.com/@Product_Truprops" target="blank">
              <Youtube />
            </a>
            <a
              className="insta"
              href="https://www.instagram.com/truprops/"
              target="blank"
            >
              <Instagram />
            </a>
            <a
              className="twit"
              href="https://twitter.com/Truprops1"
              target="blank"
            >
              <Twiter />
            </a>
          </div>
          <div className="copyrights">© 2020 Copyright: Truprops</div>
          <div className="col-12 d-flex align-items-center justify-content-end ">
            <div className="developed">
              Developed by
              <a href="https://www.xbi4.com/" target="_blank">
                <img
                  src={require("../../assets/Footer/xbi4.png")}
                  width="60px"
                  height="24px"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
