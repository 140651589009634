const getImageUrls = (response) => {
  let imageUrls = [];
  const photos = response?.photos;
  // eslint-disable-next-line array-callback-return
  photos?.map((ms, idx) => {
    imageUrls[idx] = [];
    // eslint-disable-next-line array-callback-return
    ms?.mixedSources?.jpeg?.filter((img) => {
      if (img?.width === 384 || img?.width === 1344) {
        imageUrls[idx] = {
          ...imageUrls[idx],
          [img?.width === 384 ? "thumbnail" : "original"]: img?.url
        };
      }
    });
  });
  return imageUrls;
};

export default getImageUrls;
