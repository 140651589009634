import React from 'react'
import { Col, Form, Modal, Row } from "react-bootstrap";

type ShopDataType = {
  propertyData: any;
  handleModelShow: () => void;
  show: boolean;
};
export default function Shop(propertyProps: ShopDataType) {
  return (
    <>
    <div className="interprtation-content">Listed specifications</div>
    <div className="prop-details-row">
      {propertyProps.propertyData.listed_for === "RENT" ? (
        <>
          <div className="left-prop-details">
            <table className="w-100">
              <tr>
                <td>Maintenance charge</td>
                <td>{propertyProps.propertyData?.currency_symbol}{propertyProps.propertyData?.maintenance_charge.toLocaleString("en-IN")}</td>
              </tr>
            </table>
          </div>
          <div className="right-prop-details">
            <table className="w-100">
              <tr>
                <td>Expected Deposit</td>
                <td>{propertyProps.propertyData?.currency_symbol}{propertyProps.propertyData?.expec_deposit.toLocaleString("en-IN")}</td>
              </tr>
            </table>
          </div>
        </>
      ) : (
        <>
          <div className="left-prop-details">
            <table className="w-100">
              <tr>
                <td>Token Amount</td>
                <td>{propertyProps.propertyData?.currency_symbol}{propertyProps.propertyData?.token_amt.toLocaleString("en-IN")}</td>
              </tr>
              <tr>
                <td>Currently in lease</td>
                <td>{propertyProps.propertyData?.lease ? "Yes" : "No"}</td>
              </tr>
            </table>
          </div>
          <div className="right-prop-details">
            <table className="w-100">
              <tr>
                <td>Paid Tax</td>
                <td>{propertyProps.propertyData?.paid_tax ? "Yes" : "No"}</td>
              </tr>
            </table>
          </div>
        </>
      )}
    </div>

    <div className="interprtation-content">Listed features</div>
    <div className="prop-details-row">
      <div className="left-prop-details">
        <table className="w-100">
          <tr>
            <td>Buildup area</td>
            <td>{propertyProps.propertyData?.built_area} sqft</td>
          </tr>
          <tr>
            <td>Situated in</td>
            <td>{propertyProps.propertyData?.floor +" "+ "Floor"}</td>
          </tr>
          <tr>
            <td>Total Floor</td>
            <td>{propertyProps.propertyData?.total_floor+" "+ "Floor"}</td>
          </tr>
          <tr>
            <td>Age of property</td>
            <td>{propertyProps.propertyData?.age}</td>
          </tr>
        </table>
      </div>
      <div className="right-prop-details">
        <table className="w-100">
          <tr>
            <td>Furnish</td>
            <td>{propertyProps.propertyData?.furnish 
            + "FURNISHED"}</td>
          </tr>

          <tr>
            <td>Landzone</td>
            <td>{propertyProps.propertyData?.landzone}</td>
          </tr>
          <tr>
            <td>Washroom</td>
            <td>{propertyProps.propertyData?.wash}</td>
          </tr>
          <tr>
            <td>Available from</td>
            <td>
              {new Date(
                propertyProps.propertyData?.available_from
              ).toLocaleDateString()}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div className=" ">
      <button className="btn-details" onClick={propertyProps.handleModelShow}>
        More Details
      </button>
    </div>
    <Modal
      className=""
      size="lg"
      show={propertyProps.show}
      onHide={propertyProps.handleModelShow}
      scrollable={true}
    >
      <Modal.Header closeButton>
        <div className=" fw-bold mt-3 ">Additional Details</div>
      </Modal.Header>
      <Modal.Body
        className="completed-modal-body jsutify-content-center align-items-center"
        style={{ overflow: "scroll" }}
      >
        <Row>
          <Col sm={6} className="mt-3">
            <Form.Label className="req-form fw-bold  ">Address:</Form.Label>
            <div className="w-75  mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData?.address_line_1 +
                " " +
                propertyProps.propertyData?.address_line_2}
            </div>
          </Col>
          {/* Window type */}
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Landmark:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData?.landmark}
            </div>
          </Col>
        </Row>
        {/* <Row>
          <Col sm={6} className="mt-3 ">
            <Form.Label className="req-form fw-bold ">Flooring</Form.Label>
            <div className="w-75  mt-1 p-1 mb-3 shadow-sm rounded">
              {propertyProps.propertyData?.floor_type}
            </div>
          </Col>
          { propertyProps.propertyData.listed_for ==="SALE" ? <Col sm={6} className="mt-3 ">
            <Form.Label className="req-form fw-bold ">Occupancy</Form.Label>
            <div className="w-75  mt-1 p-1 mb-3 shadow-sm rounded">
              {propertyProps.propertyData?.occupancy ? "Yes": "No"}
            </div>
          </Col>
          :<>
          <Col sm={6} className="mt-3 ">
            <Form.Label className="req-form fw-bold ">Non Veg allowed</Form.Label>
            <div className="w-75  mt-1 p-1 mb-3 shadow-sm rounded">
              {propertyProps.propertyData?.non_veg ? "Yes": "No"}
            </div>
          </Col>
          <Col sm={6} className="mt-3 ">
            <Form.Label className="req-form fw-bold ">Water supply</Form.Label>
            <div className="w-75  mt-1 p-1 mb-3 shadow-sm rounded">
              {propertyProps.propertyData?.water_supply ? "Yes": "No"}
            </div>
          </Col>
          <Col sm={6} className="mt-3 ">
            <Form.Label className="req-form fw-bold ">Gated security</Form.Label>
            <div className="w-75  mt-1 p-1 mb-3 shadow-sm rounded">
              {propertyProps.propertyData?.gated_security ? "Yes": "No"}
            </div>
          </Col>
          </>
          }
        </Row> */}
        <Row>
          <Col sm={6} className="mt-3">
            <Form.Label className="req-form fw-bold ">AC:</Form.Label>
            <div className="w-75  mt-1 p-1 shadow-sm rounded">
            {propertyProps.propertyData?.ac? "Yes": "No"}
            </div>
          </Col>
          {/* Floor type */}
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Power Backup:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData.power ? "Yes": "No"}
            </div>
          </Col>
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Fire Safety:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData.fire_safety ? "Yes": "No"}
            </div>
          </Col>
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Parking:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData.parking}
            </div>
          </Col>
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Corner area:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData.corner ? "Yes": "No"}
            </div>
          </Col>
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Main road:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData.main ? "Yes": "No"}
            </div>
          </Col>
          {/* No. of windows */}
        </Row>
        <Row>
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Listed On:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData &&
                propertyProps.propertyData?.listed_on?.slice(0, 10)}
            </div>
          </Col>
          <Col sm={6} className=" mt-3">
            <Form.Label className="req-form fw-bold">Views:</Form.Label>
            <div className="w-75 mt-1 p-1 shadow-sm rounded">
              {propertyProps.propertyData?.views}
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  </>
  )
}
