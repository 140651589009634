const printDoc = (divName) => {
  const divToPrint = document.getElementById(divName);
  const newWin = window.open("", "_blank");
  newWin.document.open();
  newWin.document.write(
    `<html>
    <head>
    <style>
    
    ol li{list-style: numeric; padding-bottom: 10px;}
    .prev-header, .center-text{text-align: center; margin: 10px 0px;}
    .agree-whitespace{display: block !important; margin-top: 600px;}
    .stamp-header, .agree-header, .agree-left, .agree-btn-container {display: none;} 
    .annex-tab table, .annex-tab th, .annex-tab td{
      border: 1px solid black;
      border-collapse: collapse;
      text-align: center;
    }
    .annex-tab{
      margin: 0px 70px;
      padding:0px 5px;
    }
    table{ width: 100%;
      border-spacing: 5px;}
    @media print{
      @page{margin-top: 0; margin-bottom: 0;} 
      body{margin-top: 1.6cm; margin-bottom: 1.6cm;}
    }
    </style>
    </head>
    <body onload="window.print()">` +
      divToPrint.innerHTML +
      "</body></html>"
  );
  newWin.document.close();
  setTimeout(function () {
    newWin.close();
  }, 5000);
}

export default printDoc




