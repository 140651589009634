import React from 'react'

const twitter = () => {
  return (
    <>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_349_32)">
<path d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z" fill="#2DAAE1"/>
<path d="M41.0297 15.0553C39.8505 15.5786 38.584 15.9323 37.2523 16.0913C38.6114 15.2775 39.6525 13.9878 40.1441 12.453C38.8531 13.2197 37.4402 13.7596 35.9668 14.049C34.7677 12.7709 33.0579 11.9729 31.1662 11.9729C27.5352 11.9729 24.5905 14.9164 24.5905 18.5496C24.5905 19.0643 24.6485 19.5665 24.7599 20.0487C19.294 19.774 14.448 17.1558 11.2044 13.1773C10.638 14.149 10.3138 15.2787 10.3138 16.484C10.3138 18.7654 11.475 20.7783 13.2395 21.9575C12.195 21.9251 11.1734 21.6431 10.2601 21.1352V21.2183C10.2601 24.4051 12.5267 27.0623 15.5356 27.6666C14.9829 27.8181 14.4028 27.8972 13.8027 27.8972C13.3784 27.8972 12.9668 27.857 12.5647 27.7814C13.4016 30.3934 15.8305 32.2946 18.7076 32.3484C16.4567 34.1128 13.6205 35.1646 10.5402 35.1646C10.0096 35.1646 9.48523 35.1329 8.97046 35.0709C11.8814 36.9374 15.3377 38.0271 19.0519 38.0271C31.1483 38.0271 37.7639 28.0057 37.7639 19.315C37.7639 19.0297 37.7576 18.7465 37.7439 18.4643C39.0328 17.532 40.1455 16.3777 41.0297 15.0553V15.0553Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_349_32">
<rect width="50" height="50" fill="white"/>
</clipPath>
</defs>
</svg>

    </>

  )
}

export default twitter