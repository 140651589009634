import React, { useContext, useEffect, useState } from "react";
import SubscriptionPlanCardSc from "./index.sc";
import CheckoutModal from "../CheckoutModal";
import { title } from "process";
import { AuthContext } from "../../../context/AuthContext";
import createOrderId from "../../../api/Orders/createOrder";
import PropertyModal from "../PropertyModal";
import { useHistory } from "react-router-dom";
import { API_URL, API_DOMAIN } from "../../../api/const";
// import payment from "../SubscriptionPlanCard/payment"
interface SubscriptionPlanCardProps {
  planId?: String | Number;
  title?: String;
  price?: String | Number;
  description?: String;
  featuresList?: [String];
  showProperty?: boolean;
  selectedId?: Number;
}

const Index = (props: SubscriptionPlanCardProps) => {
  const history = useHistory();
  const [show, setShow] = useState<boolean>(false);
  const [showPropModal, setShowPropModal] = useState<boolean>(false);
  const { userInfo } = useContext(AuthContext);
  const [data, setData] = useState<any>();
  const [propData, setPropData] = useState<any>();
  const [orderData, setOrderData] = useState<any>({});
  const [pgFormData, setPgFormData] = useState<any>({});
  const [isLoaded, setIsLoaded] = useState(false);
  const handleBuyNowClick = async () => {
    if (userInfo) {
      await handleCreateOrderId(props?.planId, propData?.property_key);
    } else {
      alert("Not signed in!");
      history.push("/logIn");
    }
  };

  const handleCreateOrderId = async (planId, property_key = "") => {
    const data = {
      plan_id: planId,
      user_key: userInfo?.user_key,
      property_key: property_key,
    };
    const orderRequestData = await createOrderId(data);
    setPgFormData(orderRequestData[1][0]);
    setIsLoaded(true);
    setPropData("");
  };

  useEffect(() => {
    // console.debug("PG form data", pgFormData);
    setData({
      merchant_id: "2119797",
      order_id: pgFormData?.order_id,
      currency: "INR",
      // amount: pgFormData?.price,
      amount: "1.00",
      redirect_url: `${API_URL}/ccavResponseHandler`,
      cancel_url: `${API_URL}/ccavResponseHandler`,
      language: "EN",
      customer_identifier: `Hi! ${userInfo?.first_name} `,
      api_endpoint_fe: `${API_DOMAIN}`,
      api_endpoint_be: `${API_URL}`
    });

    setShow(true);
  }, [isLoaded]);
  const handlePropData = async (key) => {
    setPropData({ property_key: key });
  };
  const handlePropDataOrderReq = async () => {
    setShowPropModal(false);
    handleBuyNowClick();
  };

  const handlePaymentRequest = async () => {
    const form = document.forms["customerData"];
    form.submit();
  };
  // useEffect(() => {
  //   // console.log("yuagdsuqdiewhjdiewjdascuhewqjs", propData);
  // }, [propData]);

  return (
    <SubscriptionPlanCardSc>
      <div className="main_con">
        <div className="title_con">
          <p className="title">{props?.title}</p>
        </div>
        <div className="text_utility">
          <div className="price_tag_con">
            <p className="price_tag">
              <span style={{
              fontFamily: "Roboto"
            }}>&#8377;</span> {" "}
              {props?.price}
            </p>
          </div>
          <p className="description">{props?.description}</p>
        </div>

        <div className="feature_list text_utility">
          {props?.featuresList &&
            props?.featuresList.map((feature: String) => {
              return <p>{feature}</p>;
            })}
        </div>
      </div>
      {props?.showProperty ? (
        
        props.title !== "Free" ? <button
          className="buy_now_btn"
          onClick={() => setShowPropModal(true)}
          disabled={props?.title == "Free"}
        >
          Select Property
        </button> : null
      ) : (
        props.title !== "Free" ? <button
          className="buy_now_btn"
          onClick={handleBuyNowClick}
          disabled={props?.title == "Free"}
        >
          Buy Now
        </button> : null
      )}
      {props?.showProperty && (
        <PropertyModal
          props={userInfo?.user_key}
          show={showPropModal}
          handleClose={() => setShowPropModal(false)}
          handlePropData={handlePropData}
          handlePropDataOrderReq={handlePropDataOrderReq}
          selectedId={props?.selectedId}
        />
      )}
      <div>
        {isLoaded && (
          <CheckoutModal
            props={data}
            show={show}
            handleClose={() => (setShow(false), setIsLoaded(false))}
            handlePaymentRequest={handlePaymentRequest}
          />
        )}
      </div>
    </SubscriptionPlanCardSc>
  );
};

export default Index;
