import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
export default function Index({
  checked = false,
  onClick = () => {},
  isLoading = false
}) {
  const { isAuthenticated } = useContext(AuthContext);

  const [bg, setBg] = useState(checked ? "#cf2134" : "#ccc");
  const handleClick = () => {
    setClicked(true);
  };

  const [clicked, setClicked] = useState(false);
  useEffect(() => {
    if (clicked) {
      if (isLoading) return; //? skip below code if the state is loading
      setTimeout(() => {
        setBg((color) => (color === "#cf2134" ? "#ccc" : "#cf2134"));

        setClicked(false);
      }, 200);
    }
  }, [clicked, isLoading]);
  return isAuthenticated ? (
    <div
      className=" fav-btn-v1"
      style={{
        height: "30px",
        width: "30px",
        transition: "all ease-in .2s",
        transform: `scale(${clicked ? "1.2" : "1"})`
      }}
      onClick={handleClick}
    >
      <svg className="heart" style={{ fill: bg }} viewBox="0 0 32 29.6">
        <path
          d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
	c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
        />
      </svg>
    </div>
  ) : null;
}
