import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "../../../hooks";
import { useHistory } from "react-router-dom";
import { strDecrypt } from "../../../helpers/crypto";
import { AuthContext } from "../../../context/AuthContext";
import "./style.css";

export default function Index() {
  const query = useQuery();
  const history = useHistory();
  const { confirmSignUpCode, resendSignUpCode } = useContext(AuthContext);
  const [username, setUsername] = useState(query.get("user"));
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(null);

  // * validation * //
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const validate = () => {
    return !(otp?.length! >= 6);
  };
  useEffect(() => {
    setIsBtnDisabled(validate);
  }, [otp]);

  // * submit handler * //
  const handleSubmit = async () => {
    setError(null);
    await confirmSignUpCode(username, otp)
      .then((result) => {
        if (result?.statusCode === 200) {
          history.push(result?.redirectTo);
        } else {
          setError(result?.message?.info);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="d-flex justify-content-center vh-100 align-items-center ">
      <div className="p-5 m-2 d-flex flex-column align-items-center rounded-3 border border-secondary shadow-lg ">
        <div className="fs-3 f-b">Enter Otp</div>

        {/* error */}
        {error && <div className="my-1 text-danger">{error}</div>}

        <div className="mt-3 d-flex flex-column">
          <label htmlFor="username" className="text-muted mb-2">
            Username
          </label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="ps-3 pe-3 p-2 rounded-pill border border-secondary"
            disabled
          />
        </div>
        <div className="mt-3 d-flex flex-column">
          <label htmlFor="otp" className="text-muted mb-2">
            OTP
          </label>
          <input
            className="ps-3 pe-3 p-2 rounded-pill border border-secondary"
            id="otp"
            type="text"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        </div>
        <div className="mt-3 ps-2 w-75 text-muted">
          Didn't received any otp.
          <span
            className="f-b text-dark cursor-p"
            onClick={() => resendSignUpCode(username)}
          >
            {" "}
            click here{" "}
          </span>{" "}
          to resend.
        </div>
        <div className="mt-4 w-100">
          <button
            className="btn btn-warning w-100"
            disabled={isBtnDisabled}
            onClick={handleSubmit}
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  );
}
