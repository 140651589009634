import React, { useEffect, useState } from "react";
import "./style.css";
import NavBar from "../../../layouts/v1";
import { userActivities } from "../../../api/user";

const Index = ({ propKey }) => {
  const [proptimeline, setProptimeline] = useState<any[]>();
  const [formattedDate,setFormattedDate]=useState<string>()
  const getPropertyData = async () => {
    await userActivities(propKey)
      .then(([statusCode, res]) => {
        if (statusCode === 200) {
          setProptimeline(res);
          setFormattedDate(new Date(res[0].property.listed_on).toLocaleString("en-in", {
            timeZone: "Asia/Kolkata",
          }).split(",")[0])
        } else {
          alert(`Error - ${statusCode}`);
        }
      })
      .catch((err) => console.log(err));
  };
  // const formattedDate = 
  useEffect(() => {
    if (propKey) {
      getPropertyData();
    }
  }, [propKey]);

  return (
    <>
      <NavBar />
      <div>
        <div className="d-flex justify-content-center align-items-center p-5 flex-column"> 
        <div className="secondary-color" style={{
          fontSize:"18px"
        }}>
          Property History
        </div>
        <div className="d-flex flex-row justify-content-center align-items-center ">
        Property Title - <span style={{
          fontSize:"24px", textDecoration:"underline orange"
        }}>{" "}{proptimeline && proptimeline[0].property.title}</span>
        </div>
        {
          formattedDate && <div className="d-flex flex-row">
          Listed on - {formattedDate}
        </div>
        }
        </div>
        <div className="container">
          <div className="timeline">
            <ul>
              {proptimeline && proptimeline?.map((history) => {
                return (
                  <div>
                    {/* <div className="timeline-content"> */}
                      { history.activities?.map((data) => {
                      
                        return(<li>
                        <div className="timeline-content">
                        <h3 className="date">{data.user.full_name}</h3>
                        <h1> {data.name}</h1>
                        <p>{data.description}</p>
                        <p>{new Date(data.date).toLocaleString("en-in", {
            timeZone: "Asia/Kolkata",
          }).split(",")[0]}</p>
                        </div>
                        </li>)
                        
                      })

                      }
                      
                      {/* <h3 className="date">{history.activities[0].user.first_name}</h3>
                      <h1> {history.activities[0].name}</h1>
                      <p>{history.activities[0].description}</p>
                    </div> */}
                  </div>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
