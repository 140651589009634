import React, { useContext } from "react";
import { Eye, Pen } from "react-bootstrap-icons";
import { Initial } from "../../components";
import { AuthContext } from "../../context/AuthContext";
import { getInitialsFormUserAttr } from "../../helpers/getInitials";

export default function Index() {
  const { userAttr } = useContext(AuthContext);
  return (
    <div>
      <div className="container mt-2">
        {/* 
        // * image
        */}
        <div
          className="d-flex mt-5 p-3 flex-row align-items-center  shadow"
          style={{
            borderRadius: "10px"
          }}
        >
          <div className="">
            <Initial size="100px" textSize="30px" />
          </div>

          <div className="ms-3 fs-4">
            <div>
              {userAttr?.["custom:first_name"] +
                " " +
                userAttr?.["custom:last_name"]}
            </div>
          </div>
          <div className="ms-3 fs-4">
            <Pen color="#aaa" className="cursor-p" />
          </div>
        </div>
        {/*  */}

        {/* Properties */}
        {/* <div className="shadow-lg p-3 mt-3">
          <div className="fs-4">Properties</div>
          <div className="d-flex flex-row justify-content-around">
            <div className="m-2">
              <div className="fs-3">120</div>
              <div>Viewed</div>
            </div>
            <div className="m-2">
              <div className="fs-3 ">3</div>
              <div>Saved</div>
            </div>
            <div className="m-2">
              <div className="fs-3">0</div>
              <div>Posted</div>
            </div>
          </div>
        </div> */}

        {/* recently viewed */}
      </div>
    </div>
  );
}
