import React from 'react'

export default function norecord() {
  return (
    <>
    <svg width="251" height="186" viewBox="0 0 251 186" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M192.484 120.056L184.239 120.1L184.461 160.465L192.706 160.421L192.484 120.056Z" fill="#E0E0E0"/>
<path d="M134.155 120.164L112.802 120.278L112.925 142.561L134.277 142.447L134.155 120.164Z" fill="#EBEBEB"/>
<path d="M155.408 120.051L134.055 120.164L134.178 142.447L155.53 142.334L155.408 120.051Z" fill="#F5F5F5"/>
<path d="M112.827 126.444C127.023 126.56 141.226 126.496 155.435 126.251C155.495 126.251 155.552 126.227 155.595 126.185C155.637 126.144 155.661 126.087 155.661 126.028C155.661 125.969 155.637 125.912 155.595 125.871C155.552 125.829 155.495 125.805 155.435 125.805C141.245 125.728 127.017 125.805 112.827 126.067C112.784 126.076 112.745 126.1 112.717 126.135C112.689 126.169 112.674 126.212 112.674 126.255C112.674 126.299 112.689 126.342 112.717 126.376C112.745 126.41 112.784 126.434 112.827 126.444V126.444Z" fill="#E0E0E0"/>
<path d="M138.29 142.077L99.4912 142.283L99.5911 160.442L138.39 160.236L138.29 142.077Z" fill="#EBEBEB"/>
<path d="M176.9 141.871L138.102 142.078L138.202 160.237L177 160.03L176.9 141.871Z" fill="#F5F5F5"/>
<path d="M99.514 147.353C125.285 147.634 151.144 147.353 176.925 146.975C176.985 146.975 177.043 146.952 177.085 146.91C177.128 146.868 177.152 146.812 177.152 146.753C177.152 146.694 177.128 146.637 177.085 146.595C177.043 146.554 176.985 146.53 176.925 146.53C151.154 146.395 125.285 146.404 99.514 146.966C99.4618 146.966 99.4117 146.986 99.3748 147.022C99.3379 147.059 99.3171 147.108 99.3171 147.159C99.3171 147.211 99.3379 147.26 99.3748 147.296C99.4117 147.333 99.4618 147.353 99.514 147.353V147.353Z" fill="#E0E0E0"/>
<path d="M235.316 160.46H85V166.965H235.316V160.46Z" fill="#E0E0E0"/>
<path d="M115.711 163.586H109.61V185.783H115.711V163.586Z" fill="#E0E0E0"/>
<path d="M206.8 163.586H200.699V185.783H206.8V163.586Z" fill="#E0E0E0"/>
<path d="M200.529 105L192.283 105.044L192.589 160.558L200.835 160.514L200.529 105Z" fill="#EBEBEB"/>
<path d="M213.407 115.337L200.596 115.406L200.843 160.398L213.655 160.329L213.407 115.337Z" fill="#F5F5F5"/>
<path d="M213.323 124.024C209.065 124.32 204.79 124.32 200.531 124.024C204.791 123.741 209.064 123.741 213.323 124.024V124.024Z" fill="#E0E0E0"/>
<path d="M213.323 122.185C211.195 122.397 209.055 122.468 206.918 122.398C204.78 122.468 202.64 122.396 200.512 122.185C204.77 121.889 209.045 121.889 213.304 122.185H213.323Z" fill="#E0E0E0"/>
<path d="M213.323 153.577C209.065 153.873 204.79 153.873 200.531 153.577C204.791 153.294 209.064 153.294 213.323 153.577V153.577Z" fill="#E0E0E0"/>
<path d="M213.323 151.69C209.064 151.973 204.791 151.973 200.531 151.69C204.79 151.394 209.065 151.394 213.323 151.69V151.69Z" fill="#E0E0E0"/>
<path d="M207.124 147.566C207.006 147.566 206.908 143.026 206.908 137.431C206.908 131.836 207.006 127.286 207.124 127.286C207.242 127.286 207.351 131.826 207.351 137.431C207.351 143.036 207.252 147.566 207.124 147.566Z" fill="#E0E0E0"/>
<path d="M227.414 126.406H213.471V160.363H227.414V126.406Z" fill="#EBEBEB"/>
<path d="M196.28 116.832C197.59 116.832 198.652 115.788 198.652 114.499C198.652 113.211 197.59 112.166 196.28 112.166C194.971 112.166 193.909 113.211 193.909 114.499C193.909 115.788 194.971 116.832 196.28 116.832Z" fill="#E0E0E0"/>
<path d="M197.018 120.917H195.552V153.945H197.018V120.917Z" fill="#E0E0E0"/>
<path d="M61.4245 91.6617L44.1455 87.4799H44.2734C39.8118 90.2717 34.6527 91.796 29.3658 91.8843C26.2358 91.8983 23.1214 91.4513 20.126 90.5582C14.8598 88.8947 10.1877 85.783 6.66193 81.5907C3.13617 77.3984 0.9041 72.3007 0.229533 66.9002C-0.300269 62.8159 0.0897485 58.6669 1.37193 54.7474C2.65412 50.8279 4.79708 47.2338 7.64889 44.22C9.14801 42.6001 10.8282 41.1519 12.6575 39.9027C13.5824 39.3316 14.5074 38.7411 15.4717 38.2474L16.9379 37.5505L17.666 37.202L18.4335 36.931C22.3847 35.4243 26.6233 34.7866 30.8516 35.0627C36.5184 35.4247 41.9495 37.4288 46.4603 40.8223C50.9712 44.2159 54.3599 48.8471 56.1995 54.1323C57.2214 57.0889 57.7595 60.1863 57.7935 63.309C57.8182 68.4985 56.3845 73.5938 53.6509 78.0322V77.8774C58.4528 86.4054 60.8735 90.713 61.4147 91.6617C60.8538 90.6937 58.3446 86.4635 53.3754 78.0226V77.9451V77.8677C56.0109 73.4722 57.3721 68.4505 57.3114 63.3477C57.2657 60.275 56.7209 57.2293 55.6976 54.3259C53.8781 49.1488 50.5465 44.6148 46.1199 41.2916C41.6933 37.9684 36.3685 36.0037 30.8123 35.6435C26.6672 35.3778 22.5131 36.0054 18.6402 37.4827L17.8923 37.7441L17.174 38.0926L15.7374 38.7702C14.7534 39.2638 13.8875 39.835 12.9723 40.3964C11.1783 41.6121 9.5281 43.0212 8.05233 44.5975C5.25266 47.5561 3.14834 51.084 1.88833 54.9314C0.628313 58.7788 0.24342 62.8516 0.760894 66.8615C1.67316 74.2079 5.50452 80.9004 11.4176 85.4762C14.0612 87.5368 17.0614 89.1108 20.2736 90.1226C23.2152 91.0171 26.277 91.4707 29.3559 91.4681C34.5795 91.413 39.6865 89.9427 44.1159 87.2186H44.175H44.2832L61.4245 91.6617Z" fill="#263238"/>
<path d="M26.5712 50.8411L27.4469 65.0707C27.4819 65.632 27.7243 66.1613 28.1285 66.5589C28.5327 66.9565 29.0707 67.195 29.6413 67.2294C30.2416 67.2627 30.8321 67.0686 31.2915 66.687C31.7508 66.3053 32.0441 65.765 32.1111 65.1772L33.715 50.9186C33.743 50.6635 33.7154 50.4054 33.6343 50.1616C33.5532 49.9177 33.4203 49.6937 33.2444 49.5042C33.0685 49.3147 32.8537 49.1642 32.6142 49.0626C32.3748 48.9609 32.116 48.9106 31.8553 48.9148H28.3424C28.0959 48.922 27.8534 48.9782 27.6295 49.0799C27.4056 49.1816 27.205 49.3268 27.0395 49.5067C26.8741 49.6866 26.7474 49.8976 26.6669 50.1269C26.5864 50.3562 26.5538 50.5991 26.5712 50.8411V50.8411Z" fill="#FF8504"/>
<path d="M33.4592 75.1766C33.239 75.8267 32.8279 76.3979 32.2778 76.8181C31.7277 77.2383 31.0633 77.4887 30.3686 77.5376C29.6739 77.5865 28.9801 77.4318 28.3747 77.0929C27.7694 76.7539 27.2798 76.2461 26.9678 75.6336C26.6557 75.021 26.5353 74.3312 26.6217 73.6513C26.708 72.9715 26.9973 72.332 27.453 71.8138C27.9087 71.2956 28.5102 70.922 29.1817 70.74C29.8532 70.5581 30.5644 70.576 31.2256 70.7916C31.6651 70.9349 32.0717 71.1621 32.4219 71.4601C32.7721 71.7582 33.0592 72.1212 33.2666 72.5285C33.4741 72.9358 33.5979 73.3793 33.6309 73.8337C33.664 74.2881 33.6056 74.7444 33.4592 75.1766Z" fill="#FF8504"/>
<path d="M226.286 0.242065H88.3196V104.922H226.286V0.242065Z" fill="#6C6CFD"/>
<path d="M88.3196 0.242065L113.805 23.6289H249.43L226.286 0.242065H88.3196Z" fill="#6C6CFD"/>
<path d="M88.3196 0.242L226.326 0H226.434L226.513 0.0677523L249.42 23.2126L249.656 23.4546L250.079 23.8806H249.469L113.845 23.7741H113.786L88.3196 0.242L113.904 23.474H113.795L249.42 23.3772L249.243 23.8031L248.997 23.5611L226.099 0.416233L226.276 0.493675L88.3196 0.242Z" fill="#263238"/>
<path d="M129.766 48.5161C129.018 48.5428 128.311 48.857 127.797 49.3911C127.283 49.9253 127.002 50.6367 127.016 51.3723C127.029 52.1079 127.336 52.8089 127.87 53.3244C128.403 53.8399 129.121 54.1287 129.869 54.1287C130.617 54.1287 131.335 53.8399 131.868 53.3244C132.402 52.8089 132.709 52.1079 132.722 51.3723C132.736 50.6367 132.455 49.9253 131.941 49.3911C131.427 48.857 130.72 48.5428 129.972 48.5161" fill="white"/>
<path d="M162.366 48.5161C161.621 48.544 160.918 48.858 160.406 49.3907C159.895 49.9234 159.616 50.6322 159.631 51.3648C159.645 52.0974 159.951 52.7953 160.482 53.3085C161.014 53.8217 161.729 54.1091 162.474 54.1091C163.219 54.1091 163.934 53.8217 164.466 53.3085C164.997 52.7953 165.303 52.0974 165.317 51.3648C165.331 50.6322 165.053 49.9234 164.541 49.3907C164.03 48.858 163.326 48.544 162.582 48.5161" fill="white"/>
<path d="M162.553 73.568C161.984 71.4297 161.059 69.3985 159.817 67.5568C158.435 65.587 156.661 63.914 154.602 62.6393C152.118 61.0176 149.22 60.1151 146.24 60.0346C143.26 59.9542 140.317 60.6991 137.746 62.1843C135.623 63.3504 133.759 64.9239 132.265 66.8114C130.92 68.5852 129.885 70.5679 129.205 72.6775C129.212 72.5201 129.239 72.3641 129.284 72.2128C129.378 71.7767 129.499 71.3467 129.648 70.9254C130.187 69.3705 130.974 67.9099 131.98 66.5984C133.463 64.6226 135.35 62.974 137.52 61.7584C140.157 60.2098 143.187 59.4312 146.257 59.5135C149.327 59.5957 152.31 60.5355 154.858 62.2231C156.952 63.5475 158.742 65.2885 160.112 67.3341C161.047 68.6994 161.755 70.2025 162.208 71.7869C162.329 72.2166 162.425 72.653 162.494 73.0937C162.528 73.2498 162.547 73.4085 162.553 73.568V73.568Z" fill="white"/>
<path opacity="0.5" d="M88.6345 1.39392L113.038 31.8279H226.286V23.6289H113.805L88.6345 1.39392Z" fill="black"/>
<path d="M199.079 104.689C198.941 104.689 198.823 86.4811 198.823 64.0332C198.823 41.5853 198.941 23.3772 199.079 23.3772C199.216 23.3772 199.344 41.5756 199.344 64.0332C199.344 86.4908 199.226 104.689 199.079 104.689Z" fill="#263238"/>
<path d="M220.746 80.9539H208.584V94.4768H220.746V80.9539Z" fill="#FF8504"/>
<path d="M88.3195 0.242065L60 23.6289H88.3195V0.242065Z" fill="#6C6CFD"/>
<g opacity="0.4">
<path d="M88.3195 0.242065L60 23.6289H88.3195V0.242065Z" fill="black"/>
</g>
</svg>


    </>
  )
}
