import { DELETE } from "../functions";

const deletePropFromBucket = async (agent_key, property_key) => {
  const path = `/agents/${agent_key}?property_key=${property_key}`;
  return await DELETE(path)
    .then((result) => {
      console.log("addPropToBucket in db > ", result);
      return result;
    })
    .catch((err) => {
      return err;
    });
};

export default deletePropFromBucket;
