import React, { CSSProperties, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { getInitialsFormUserAttr } from "../../helpers/getInitials";

interface Props {
  size?: string;
  textSize?: string;
  textStyle?: CSSProperties;
  containerStyle?: CSSProperties;
  textClass?: string;
  containerClass?: string;
  onClick?: () => void;
}
export default function Index({
  size,
  textSize,
  textStyle,
  containerStyle,
  textClass,
  containerClass,
  onClick = () => {},
}: Props) {
  const { userAttr } = useContext(AuthContext);
  return (
    <div
      style={{
        width: size || "35px",
        height: size || "35px",
        ...containerStyle,
      }}
      onClick={onClick}
      className={` bg-danger  rounded-circle d-flex justify-content-center align-items-center cursor-p  ${containerClass}`}
    >
      <span
        className={`text-light p-2 ${textClass}`}
        style={{ fontSize: textSize || "15px", ...textStyle }}
      >
        {getInitialsFormUserAttr(userAttr)}
      </span>
    </div>
  );
}
