import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "../../hooks";
import { useHistory } from "react-router-dom";
import Filters from "./components/Filters";
import { MapModal } from "../../components/Modal";
import { getPropsFromOthers } from "../../api/propsFromOthers";
import { LoadingV1 } from "../../components/Loading";
import { propQueryBuilder } from "../../helpers/queryBuilder";
import { getProperties } from "../../api/property";
import { StateContext } from "../../context/StateContext";
import PropertyCard from "./components/PropertyCard";
import ResultsNavbar from "../../components/ResultsNavbar";
import "./style.css";
import { MapBox } from "../../components";
import NoProp from "../../assets/svg/norecord";
import Pagination from "../../components/Pagination";
export default function Index() {
  const { globalParams } = useContext(StateContext);
  const history = useHistory();
  const query = useQuery();
  const parseQuery = (query) => {
    let lat, lon;
    const loc = query.get("loc").split(",");
    lat = loc[0];
    lon = loc[1];
    const text = query.get("text");
    const looking_for = query.get("looking_for");
    const map_bounds = query.get("map_bounds");
    console.log("map > ", map_bounds);
    const bed = query.get("bed");
    const bath = query.get("bath");
    return { lat, lon, text, looking_for, map_bounds, bed, bath };
  };
  const [queryData, setQueryData] = useState(parseQuery(query));

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    getProps(queryData);
  }, []);
  useEffect(() => {
    setQueryData(parseQuery(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.get("loc"), query.get("text")]);

  // *property list

  const [isFetchingProps, setIsFetchingProps] = useState(false);
  const [zillowList, setZillowList] = useState<any>([]);
  const [realtorList, setRealtorList] = useState<any>([]);
  const [propertyList, setPropertyList] = useState<any>([]);

  // ? count total prop count
  const [propCount, setPropCount] = useState(0);
  useEffect(() => {
    setPropCount(
      zillowList?.length + realtorList?.length + propertyList?.length
    );
  }, [zillowList, realtorList, propertyList]);

  const getProps = async (data) => {
    setIsFetchingProps(true);
    setZillowList([]);
    setRealtorList([]);
    setPropertyList([]);

    const lookingFor = data?.lookingFor || query.get("looking_for");

    // ! own properties !//
    await getProperties(encodeURI(await propQueryBuilder(data)))
      .then(([statusCode, res]) => {
        if (statusCode === 200) {
          setPropertyList(res);
        }
      })
      .catch((err) => console.log(err));

    if (globalParams?.country === "us") {
      // // ! Zillow * //
      // * get properties from zillow
      setZillowList([]);
      // await getPropsFromOthers(
      //   `/zillow/${data?.text}?looking_for=${
      //     lookingFor === "buy" ? "for_sale" : "for_rent"
      //   }`
      // )
      //   .then(([statusCode, res]) => {
      //     if (statusCode === 200) {
      //       let d: any = res?.data;
      //       d = d?.filter((e) => !e?.zpid?.includes("*"));
      //       setZillowList(d);
      //     }
      //   })
      //   .catch((err) => console.log(err));
    }

    // // ! realtor * //
    // await getPropsFromOthers(`/realtor/${text}`)
    //   .then(([statusCode, res]) => {
    //     if (statusCode === 200) {
    //       setRealtorList(res?.data);
    //     }
    //   })
    //   .catch((err) => console.log(err));
    setIsFetchingProps(false);
  };

  const onFilterChange = async (data) => {
    console.log(">>", data);
    await history.push(`/results?${propQueryBuilder(data)}`);
    setQueryData(data);
    if (data?.text) {
      await getProps(data);
    }
  };
  // * Map modal * //
  const [showMap, setShowMap] = useState(false);
  const closeMap = () => setShowMap(false);
  const openMap = () => setShowMap(true);


  const getPropertyLocations = (propertyList) => {
    let temp = [];
    propertyList.forEach((property) => {
      temp = [
        ...temp,
        {
          id: property.property_key,
          markerColor: "red",
          lat: property.lat,
          lon: property.lon,
          link: `/properties/${property.property_key}`,
        },
      ];
    });
    return temp;
  };


  useEffect(() => {
    if (propertyList.length > 0) {
      setLocations(getPropertyLocations(propertyList));
    }
  }, [propertyList]);
  console.log(propertyList, "property list data");

  const onMouseEnterPropertyCard = (id) => {
    const temp = locations;
    temp.find((p) => p.id === id).markerColor = "blue";
    setLocations(temp);
  };
  const onMouseLeavePropertyCard = (id) => {
    const temp = locations;
    temp.find((p) => p.id === id).markerColor = "red";
    setLocations(temp);
    // console.log((locations.find((p) => p.id === id).markerColor = "red"));
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = propertyList.slice(startIndex, startIndex + itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <>
      <ResultsNavbar />
      <div className="ml-5 mt-2">
        <Filters onChange={onFilterChange} data={query} />
      </div>

      <div className="d-flex flex-row">
        <div className="properties">
          {propertyList?.length > 0 ? (
            <div className="prop-count">
              <span>{propertyList?.length}</span> Properties Found in Your
              Location
            </div>
          ) : null}
          {propertyList?.length > 0 ? (
            currentData?.map((data, idx) => {
              return (
                <PropertyCard
                  onMouseEnter={onMouseEnterPropertyCard}
                  onMouseLeave={onMouseLeavePropertyCard}
                  data={data}
                  key={idx}
                />
              );
            })
          ) : (
            <>
              <div className="no-prop-found">
                <div className="prop-count">
                  No Properties Found!!. Try with other Location
                </div>
                <div>
                  {" "}
                  <NoProp />
                </div>
              </div>
            </>
          )}
          { propertyList?.length > 0 ? 
            <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={propertyList.length}
            onPageChange={handlePageChange}
          /> : null
          }
        </div>

        <div className="properties-map ">
          {propertyList.length > 0 && <MapBox
            lat={parseFloat(locations[0]?.lat || queryData?.lat)}
            lon={parseFloat(locations[0]?.lon || queryData?.lon)}
            width="100%"
            height="70vh"
            zoom={11}
            locations={locations}
          />}
        </div>
      </div>
    </>
  );
}
