const propQueryBuilder = (queryObject) => {
  console.log(queryObject);
  let map_bounds =
    typeof queryObject.map_bounds === "string"
      ? queryObject.map_bounds
      : JSON.stringify(queryObject?.map_bounds || {});

  let queryList = [
    `looking_for=${queryObject.looking_for || queryObject.lookingFor}`,
    `text=${queryObject?.text || ""}`,
    `loc=${queryObject?.center || queryObject?.loc || queryObject?.location}`,
    `map_bounds=${map_bounds}`,
    `bed=${queryObject?.bed || "any"}`,
    `bath=${queryObject?.bath || "any"}`
  ];
  let query = queryList.join("&");
  console.log(">", query);
  return query;
};

export { propQueryBuilder };
