import React, { useEffect, useState } from "react";
import { PersonFill } from "react-bootstrap-icons";
import {
  useHistory,
  useLocation,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { useAuth } from "../../hooks";
// import { Layout } from "../../../layouts";
import {
  CustomerCare,
  PaymentHistory,
  MyAccDashboard,
  MyAccLeads,
  MyActivities,
  MyListing,
} from "../../pages/MyAccount/components";
import "./style.css";
// interface Props {
//   children: JSX.Element[] | JSX.Element;
// }
interface NavCardMenuProps {
  to: string;
  name: string;
}

const navMenus: NavCardMenuProps[] = [
  { name: "Dashboard", to: "/account/dashboard" },
  { name: "Leads", to: "/account/leads" },
  { name: "My Listing", to: "/account/my-listing" },
  { name: "My Activity", to: "/account/my-activity" },
  { name: "Payment History", to: "/account/payment-history" },
];

const routes = [
  {
    path: "/account/dashboard",
    component: MyAccDashboard,
  },
  {
    path: "/account/leads",
    component: MyAccLeads,
  },
  {
    path: "/account/my-listing",
    component: MyListing,
  },
  {
    path: "/account/my-activity",
    component: MyActivities,
  },
  {
    path: "/account/payment-history",
    component: PaymentHistory,
  },
];

const Index = () => {
  const history = useHistory();
  const { url, path } = useRouteMatch();
  console.log(">", path, url);
  const auth = useAuth();
  const [showNav, setShowNav] = useState(false);
  const togglenav = () => setShowNav(!showNav);
  const NavCardMenu = ({ name, to }: NavCardMenuProps) => {
    // const isActive = window.location.pathname === to;
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
      setIsActive(location.pathname.includes(to));
    }, [location, to]);

    return (
      <div
        className={`nav-menu-mob ${isActive ? "active" : ""}  py-2 px-2`}
        onClick={() => history.push(to)}
      >
        {name}
      </div>
    );
  };
  return (
    <div>
          <div className="my-acc-card-container-mob">
            <div className="my-acc-card-mob p-2">
            </div>
          </div>

          <ul className="agent-nav-links1-mob">
          <div className="agent-hamburger1" onClick={togglenav}>
            &#9776;
          </div>
          <div
            className="agent-menu1"
            style={{ display: showNav ? "block" : "", zIndex:20 }}
          >
            
              <div className="nav-menus-mob">
                {navMenus?.map((menu, idx) => (
                  <li className="">
                  <NavCardMenu {...menu} key={idx} />
                  </li>
                ))}
              </div>
            
           

          </div>
        </ul>
    </div>
  );
};

export default Index;
