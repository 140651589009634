import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import parseOwnPropertyResponse from "../../../helpers/parseOwnPropertyResponse";
import "../style.css";

export default function Index({ propData }) {
  const [prop, setProp] = useState<any>(null);
  useEffect(() => {
    setProp(parseOwnPropertyResponse(propData));
    return () => {
      setProp(null);
    };
  }, [propData]);
  const history = useHistory();
  return (
    <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 p-2">
      <div
        className="rounded shadow bg-light  pt-1 d-flex flex-row cursor-p common-prop-card"
        style={{
          height: "200px",
          overflow: "hidden",
          minWidth: "420px"
        }}
        onClick={() => history.push(`/properties/${prop?.propId}`)}
      >
        <div
          // style={{ margin: "1rem", maxWidth: "200px" }}
          className="d-flex align-items-center col-6"
        >
          <img
            src={propData?.img_src}
            alt="property"
            width="100%"
            height="100%"
          />
        </div>

        <div className="m-3 col-6 d-flex flex-column">
          <div className="f-b fs-3">{prop?.price}</div>

          {prop?.area ? (
            <div className="f-18 text-muted">Area : {prop?.area} Sq.ft</div>
          ) : null}

          {prop?.bed ? (
            <div className="f-18 text-muted">Beds : {prop?.bed}</div>
          ) : null}

          {prop?.bath ? (
            <div className="f-18 text-muted">Baths : {prop?.bath}</div>
          ) : null}

          {prop?.openTour ? (
            <div className="bg-primary f-12 rounded text-light w-50 d-flex justify-content-center align-items-center px-1 py-1">
              Open Tour
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
