import React from "react";
import { Button } from "react-bootstrap";
import { Download } from "react-bootstrap-icons";
import { useHistory } from "react-router-dom";
import { SearchBar } from "../../pages/Home/Components";
import "./style.css";
import Section from "./Section";
import Footer from "../../layouts/Footer";
import { ProfileDropDown } from "../../components/Dropdown";
import { useAuth } from "../../hooks";
import Dwnld from "../../assets/svg/dwnld";
import verified from "../../assets/svg/verifiedcopy.svg";
import premium from "../../assets/svg/premium.svg";
import agents from "../../assets/svg/agent.svg";
import logo from "../../assets/brandlogo.png";
// import WhyTruprops from "../Section/WhyTruprops/index";

export default function Index() {
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const openPlaystoreLink = () => {
    window.open('https://play.google.com/store/apps/details?id=com.xbi4.truprops', '_blank');
  };
  return (
    <>
      <div className="main-container1">
        <div className="banner_overlay1"></div>
        {/* <div className="img-container">
          <img
            src="https://images.unsplash.com/photo-1493663284031-b7e3aefcae8e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8c29mYXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60"
            alt="SOFA"
            style={{
              width: "100%",
              opacity: "0.5",
            }}
          />
        </div> */}
        <header className="header1 ">
          <div className="header-content1">
            <div className="list-property">
            <div className="free-btn">Free</div>
            <div
              className=""
              onClick={() => history.push("post-your-property")}
            >
              List Property
            </div>
            </div>
            <div className="download-app" onClick={openPlaystoreLink
}>
              Download App
            </div>
            <div className="menu ">
              {/* <Initial onClick={() => history.push("/account/dashboard")} />  */}
              {isAuthenticated ? (
                <ProfileDropDown />
              ) : (
                <button
                  className="re-navbar-menu light-bg no-border"
                  onClick={() => history.push("/logIn")}
                >
                  Log In
                </button>
              )}
            </div>
          </div>
        </header>
        <div className="banner-row1">
          <div className="left-bnr1">
            <div className="left-bnr-content1">
              <img
                className="brand-logo"
                src={logo}
                alt="truprops"
              />
              <div className="bnr-title">the key to your</div>
              <div className="bnr-title-happy">Happiness</div>
              <button className="list-your-prop" onClick={() => history.push("/post-your-property")}>List Your Property</button>
              <div className="icons-container">
              <SvgContainer label="Agents" svg={agents} />
              <SvgContainer label="Verified Properties" svg={verified} />
              <SvgContainer label="Premium Projects" svg={premium} />
              </div>
            </div>
          </div>
          <div className="right-bnr">
           
            <SearchBar />
          
          </div>
        </div>
      </div>

      <div>
        <div className="section">
          <Section />
        </div>
      </div>
      <div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </>
  );
}
const SvgContainer = ({ svg, label }) => {
  return (
    <div className="t-icon-container">
      <div className="svg bg-transparent">
        <img src={svg} alt="svg" />
      </div>
      <div className="icon-text">{label}</div>
    </div>
  );
};