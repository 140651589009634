import React, { useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import SideBar from "../Components/SideIconBar"
import {Profile} from "../../../assets/AgentDashboard"
import EditProfile from './editProfile'
import "./style.css"
import { useAuth } from '../../../hooks'
import verfied from "../../../assets/verified.png";



export default function Index() {
  const{agentData} = useContext(AuthContext);
  const auth = useAuth();
  const [show, setShow] = React.useState<boolean>(false);
  const close = () => setShow(!show);
  const modelHandler= ()=> {
    window.location.reload()
  }
  console.log(agentData, "AData");  
  return (
    <>
    <div className="dashboard-container">
        <div className="sidebar">
          <SideBar />
        </div>
        <div className="profile-right-column">
          {agentData && (     
  <div className="profile-contaier">
    <div className="pro-img-container d-flex justify-content-center">{agentData?.photo_src?(<img src={agentData.photo_src} alt="Profile-img" />):(<Profile/>)}
    {auth?.userInfo?.is_aadhar_verified == 1 ? (
                    <div className='d-flex flex'>
                      <img src={verfied} className="verified_icon" style={{
                        width:"2em", height:"2em"
                      }} />
                    </div>
                  ) : null}
    </div>
    {/* <p>{agentData.Name}</p> */}
    <p><span className="ad-tit">Agent Name </span><span className="ad-info"> : {agentData.Name}</span></p>
    <p><span className="ad-tit">Agent ID</span><span className="ad-info"> : {agentData.agent_id}</span></p>
    <p><span className="ad-tit">Address</span><span className="ad-info"> : {agentData.address}</span></p>
    <p><span className="ad-tit">City</span><span className="ad-info"> : {agentData.city}</span></p>
    <p><span className="ad-tit">Email</span><span className="ad-info"> : {agentData.email}</span></p>
    <p><span className="ad-tit">Phone</span><span className="ad-info"> : {agentData.phone}</span></p>
    <p><span className="ad-tit">Pincode</span><span className="ad-info"> : {agentData.pincode}</span></p>
    <p><span className="ad-tit">Property Limit</span><span className="ad-info"> : {agentData.property_limit}</span></p>
    <p><span className="ad-tit">Total Property Approved</span><span className="ad-info"> : {agentData.no_approved_property}</span></p>
    <p><span className="ad-tit">Total Property Assigned</span><span className="ad-info"> : {agentData.no_assigned_property}</span></p>
    {/* <p><span className="ad-tit"></span><span className="ad-info"> : {agentData.}</span></p>
    <p><span className="ad-tit"></span><span className="ad-info"> : {agentData.}</span></p> */}
    <button className='et-ag-profile' onClick={close}>Edit Profile</button>
    <EditProfile show={show} close={close} modelHandler={modelHandler} data={agentData}/>
  </div>
   )}
  </div>
  </div>
    </>
  )
}
