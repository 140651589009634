import { GET } from "../functions";

const getAgentBucket = async (agent_key) => {
  const path = `/agents/${agent_key}/agentproperties`;
  return await GET(path)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log("error on prop types from db > ", err);
      return err;
    });
};
export default getAgentBucket;
