import { GET } from "../functions";

const index = async () => {
  const path = `/prop_types`;
  return await GET(path)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log("error on prop types from db > ", err);
      return err;
    });
};
export default index;
