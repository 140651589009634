import styled from "styled-components";

const SubscriptionPlanCardSc = styled.div`
  padding: 1em 2em;
  width: 22em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 18px 0px rgba(193, 183, 183, 0.75);
  -webkit-box-shadow: 0px 2px 18px 0px rgba(193, 183, 183, 0.75);
  -moz-box-shadow: 0px 2px 18px 0px rgba(193, 183, 183, 0.75);

  .main_con {
    width: 100%;
  }
  .title {
    font-size: 1.3rem;
    font-weight: 700;
    color: white;
  }
  .title_con {
    background: rgb(255,140,66);
    background: linear-gradient(90deg, rgba(255,140,66,1) 0%, rgba(243,146,55,1) 0%, rgba(204,85,0,1) 100%);
    width: 100%;
  }
  .buy_now_btn {
    border: 1px solid;
    padding: 0.2em 1.5em;
    margin: 0 auto;
    color: black;
    display: flex;
    justify-content: center;
    background-color: white;
    transition: all 600ms;
  }
  .buy_now_btn:hover {
    background-color: var(--secondary-color);
    color: white;
    border-radius: 10px;
  }
  .description {
    font-size: 1rem;
    text-align: justify;
  }
  .feature_list ul li {
    list-style: none;
  }
  .price_tag_con {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 900;
  }
  .text_utility {
    text-align: center;
  }
  @media only screen and (max-width: 780px) {
    width: auto;
}
`;

export default SubscriptionPlanCardSc;
