import React, { useEffect, useState } from "react";
import Discover from "../Section/Discover/index";
import WhyTruprops from "../Section/WhyTruprops/index";
import Download from "../Section/Download";
import Guidelines from "../Section/Guideline";
import OtherServices from "../Section/OtherServices";
// import { Download } from "react-bootstrap-icons";
import { getProperties } from "../../../api/property";
import homes from "../../../data/property.json";
import "./style.css";
export default function Index() {
  const [homess, setHomes] = useState<any[]>([0]);
  const getPropertyData = async () => {
    await getProperties("is_subscribed=1&limit=5")
      .then(([statusCode, res]) => {
        if (statusCode === 200) {
          setHomes(res);
        } else {
          alert(`Error - ${statusCode}`);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getPropertyData();
  }, []);

  return (
    <div className="home sections">
      <div>
        <Discover homes={homess} />
      </div>
      <div>
        <WhyTruprops />
      </div>
      <div>
        <Download />
      </div>
      <div>
        <Guidelines />
      </div>
      {/* <div>
        <OtherServices />
      </div> */}
    </div>
  );
}
