import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { InputFieldV1 } from "../../../components/Inputs";
import loginVecotor from "../../../assets/loginPageVector.png";
import backArrow from "../../../assets/arrow_left_circle.png";
export default function Index() {
  const history = useHistory();

  // * contexts * //
  const { signIn } = useContext(AuthContext);

  const initCred = {
    username: "",
    password: "",
  };
  const [cred, setCred] = useState(initCred);
  const handleCredChange = (prop) => (value) => {
    // console.log(cred)
    setCred({ ...cred, [prop]: value });
  };

  const [showPassword, setShowPassword] = useState(false);

  // * Error

  // * validation * //

  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const emailRegex = /^\S+@\S+\.\S+$/;
  const validate = () => {
    return (
      cred?.password?.length! >= 8 &&
      cred?.username !== "" &&
      emailRegex.test(cred?.username)
    );
  };
  useEffect(() => {
    console.log(validate());
    setIsBtnDisabled(!validate());
  }, [cred]);

  // * submit handler * //
  const [error, setError] = useState(null);
  const handleSubmit = async (event) => {
    setError("");
    event.preventDefault();
    if (cred.password !== "") {
      await signIn(cred.username, cred.password).then((res) => {
        console.log(res);
        if (res.statusCode === 400) {
          setError(res?.message?.info || "Something Went Wrong");
        } else if (res?.redirectTo) {
          history.push(res?.redirectTo);
        } else {
          alert(res?.message?.info || "Something went wrong");
        }
      });
    } else {
      setError("Password cannot be empty");
    }
  };
  return (
    <>
      <div className="d-flex justify-content-center vh-100 align-items-center">
        <div className="">
          <div>
            <img
              className=""
              onClick={() => history.goBack()}
              style={{ cursor: "pointer" }}
              src={backArrow}
            />
          </div>

          <div className="pt-5 pb-0 px-5 py-5 m-2 d-flex flex-column align-items-center border border-secondary shadow-lg login-box">
            <div className="fs-3 fw-semibold login-index">Login</div>

            {/* error */}
            {error && <div className="my-1 text-danger">{error}</div>}

            {/* 
        // * input
         */}
            <div className="login-title mt-2">Let the happiness begin!</div>
            <form>
              <div>
                <div className="mt-3">
                  <InputFieldV1
                    type="email"
                    onChange={handleCredChange("username")}
                    placeholder="Email Address"
                  />
                  {cred?.username !== "" &&
                    !emailRegex.test(cred?.username) && (
                      <div className="error">Invalid email address</div>
                    )}
                </div>
                <div className="mt-3">
                  <InputFieldV1
                    // label="Password"
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    onChange={handleCredChange("password")}
                    left={
                      <>
                        <div
                          className="ms-1"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Eye /> : <EyeSlash />}
                        </div>
                      </>
                    }
                  />
                </div>
                <div className="forget-container">
                  <p
                    onClick={() => {
                      console.log("Works");
                      history.push("/forgetPassword");
                    }}
                  >
                    Forgot Password
                  </p>
                </div>
              </div>
              <div className="mt-4 w-100 d-flex justify-content-center">
                <button
                  className="btn-login w-25"
                  type="submit"
                  disabled={isBtnDisabled}
                  onClick={handleSubmit}
                >
                  Login
                </button>
              </div>
            </form>
            <div className="ps-2 w-75 text-muted text-center cursor-p">
              Don't have an account?
              <span
                className="f-b text-dark px-1"
                onClick={() => {
                  history.push("/signUp");
                }}
              >
                {" "}
                click here{" "}
              </span>{" "}
            </div>
            <div className="login-image ">
              <img className="login-vector" src={loginVecotor} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
// ramkarthicka@xbi4.com
