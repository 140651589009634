import React, { useState } from 'react'
import {GOOGLE_MAP_TOKEN} from "../../../.././../../const"
import { openMap } from '../../../../../../utils';

interface Props {
  propData:any;
}
export default function index({propData}:Props) 
{
  // const Maptile = `https://maps.googleapis.com/maps/api/staticmap?center=${propData?.lat},${propData?.lon}&zoom=12&size=250x200&key=AIzaSyARFMLB1na-BBWf7_R3-5YOQQaHqEJf6RQ` //zillow's api key
  const Maptile = `https://maps.googleapis.com/maps/api/staticmap?center=${propData?.lat},${propData?.lon}&zoom=12&size=250x200&key=${GOOGLE_MAP_TOKEN}`
  return (

    <img src={Maptile} onClick={()=>openMap(propData.lat, propData.lon)}/>
  )
}
