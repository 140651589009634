import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

export default function Index() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div>
        <Button
          className="fs-5 px-3 mt-2 d-flex flex-column flex-wrap mx-auto "
          onClick={() => {
            handleShow();
          }}
        >
          Contact Agent
        </Button>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="align-item-center">
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your Name" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter your Phone Number"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Message</Form.Label>
              <Form.Control type="text" placeholder="Enter Message" />
            </Form.Group>

            <Button variant="primary" type="submit">
              Contact Agent
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
