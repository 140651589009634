import React, { useEffect, useState, useContext } from "react";
// import "./style.css";
import Footer from "../../layouts/Footer";
import { Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import SubscribeBtn from "../Razorpay/PaymentButton";
import SubscriptionCategoryCard from "./SubscriptionCategoryCard";
import { subscriptionData } from "./Data/subscriptionData";
import getPlanCategories from "../../api/SubscriptionPlan/getPlanCategories";
import SubscriptionPlanCard from "./SubscriptionPlanCard";
import getPlanDetails from "../../api/SubscriptionPlan/getPlanDetails";
import SubscriptionPlansSc from "./index.sc";
import createOrderId from "../../api/Orders/createOrder";
import { AuthContext } from "../../context/AuthContext";
// import createGatewayRequest from "../../api/CCavenue/createGatewayRequest";

export default function Index() {
  const history = useHistory();
  const location = useLocation();
  const { userInfo } = useContext(AuthContext);

  const [planCategory, setPlanCategory] = useState<any>();
  const [planDetails, setPlanDetails] = useState<any>([]);
  // const [orderData, setOrderData] = useState<any>({});
  // const [pgFormData, setPgFormData] = useState<any>({});
  const [selectedId, setSelectedId] = useState<number>(1);
  const [showProperty, setShowProperty] = useState(false)
  const handleGetPlanCategories = async () => {
    const planCategory = await getPlanCategories();
    setPlanCategory(planCategory[1]);
  };
  const handleGetPlans = async (categoryId) => {
    const plan = await getPlanDetails(categoryId);
    setPlanDetails(plan[1]);
    if(categoryId === 3 || categoryId ===4){
      setShowProperty(true)
    }else{
      setShowProperty(false)
    }
  };
  // const handleCreateOrderId = async (planId) => {
  //   const data = {
  //     plan_id: planId,
  //     user_key: userInfo?.user_key,
  //     property_key: "",
  //   };
  //   setOrderData(data);
  // };
  // console.log("plan details........",planCategory)
  useEffect(() => {
    // Parse the query parameters from the location search string
    const searchParams = new URLSearchParams(location.search);
    const paramValue = searchParams.get('planName');
    console.log("parmas values")

    // Set the state with the query parameter value
    if (paramValue=="agent") {
      handleGetPlans("5");
      setSelectedId(5)
      handleGetPlanCategories();
    }
    else{
      handleGetPlanCategories();
    handleGetPlans("1");
    }
    window.scrollTo(0,0);
  }, []);

  // useEffect(() => {
  //   const createOrder = async () => {
  //     const orderRequestData = await createOrderId(orderData);
  //     setPgFormData(orderRequestData[1][0]);
  //     console.debug("PG form data", pgFormData);
  //   };

  //   if (Object.keys(orderData).length > 0) {
  //     createOrder();
  //   }
  // }, [orderData]);
  return (
    <SubscriptionPlansSc>
      <div className="bnr-bg">
        {/* <div className="home-icon"><Home/></div> */}
        <div className="bnr-content">
          <div onClick={() => history.push("./")}>
            <img
              className="brand-logo"
              src="https://truprops.com/static/media/brandlogo.106f7c99d4a8ffbd67c4.png"
              alt="truprops"
            />
          </div>
          <button className="list-your-prop">Subscriptions</button>
        </div>
      </div>
      <div className="pricing-cards d-flex flex-column">
        <div className="d-flex all-cards category_card_con">
          {planCategory &&
            planCategory?.map((e: any) => {
              return (
                <SubscriptionCategoryCard
                  key={e?.category_id}
                  id={e?.category_id}
                  title={e?.category_name}
                  handleGetPlans={handleGetPlans}
                  selectedId={selectedId}
                  setSelectedId={setSelectedId}
                />
              );
            })}
        </div>
        <div className="plans_detail_con">
          {planDetails &&
            planDetails?.map((plan: any) => {
              return (
                <SubscriptionPlanCard
                  planId={plan?.plan_id}
                  title={plan?.plan_name}
                  price={plan?.price}
                  description={plan?.description}
                  featuresList={plan?.features}
                  showProperty={showProperty}
                  selectedId={selectedId}
                />
              );
            })}
        </div>
      </div>
      <br></br>
      <Footer />
    </SubscriptionPlansSc>
  );
}
