import React, { useEffect, useState } from "react";
import DeleteBtn from "../../../../../assets/svg/deleteBtn";

const Index = ({ nextStep, handleFormData, prevStep, values }) => {
  const [items, setItems] = useState([
    { itemName: "Fan", quantity: 1 },
    { itemName: "TV", quantity: 1 },
    { itemName: "Geyser", quantity: 1 },
    { itemName: "Washing Machine", quantity: 1 },
  ]);
  const submitFormData = (e) => {
    e.preventDefault();
    // handleFormData("items")(items);
    // console.log("> ", items);
    alert("Details Submitted Successfully")
    nextStep();
  };

  useEffect(() => {
    handleFormData("items")({ target: { value: items } });
  }, [items]);

  const [inputValue, setInputValue] = useState("");
  const handleAddButtonClick = () => {
    const newItem = {
      itemName: inputValue,
      quantity: 1,
    };

    const newItems = [...items, newItem];

    setItems(newItems);
    setInputValue("");
  };

  const deleteItem = (index) => {
    setItems((e) => e.filter((value: any, idx: number) => idx !== index));
  };

  const handleQuantityIncrease = (index) => {
    const newItems = [...items];

    newItems[index].quantity++;
    console.log(items);
    setItems(newItems);
  };

  const handleQuantityDecrease = (index) => {
    const newItems = [...items];

    newItems[index].quantity--;

    setItems(newItems);
  };

  return (
    <>
      <div className="agree-form-header">Item List</div>
      <div className="agree-form">
        <div className="agree-form-field-item">
          <input
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
            className="add-item-input"
            placeholder="Add an item..."
          />
          <button onClick={() => handleAddButtonClick()}>+</button>
        </div>
        <div className="item-list">
          {items.map((item, index) => (
            <div className="item-container">
              <div className="item-list">{item.itemName}</div>
              <div className="quantity">
                <button
                  className=""
                  onClick={() => {
                    item?.quantity === 1
                      ? deleteItem(index)
                      : handleQuantityDecrease(index);
                  }}
                >
                  -
                </button>
                <span
                  defaultValue={values.itemQuantity}
                  onChange={handleFormData("itemQuantity")}
                >
                  {item.quantity}
                </span>
                <button
                  className=""
                  onClick={() => handleQuantityIncrease(index)}
                >
                  +
                </button>
              </div>
            </div>
          ))}
          {/* {values?.items} */}
        </div>
      </div>
      <div className="agree-btn-container">
        <button className="agree-btn" onClick={prevStep}>
          Previous
        </button>
        <button className="agree-btn" onClick={submitFormData}>
          Next
        </button>
      </div>
    </>
  );
};

export default Index;
