import React, { FunctionComponent } from "react";

type ChipDataType = {
  label: any;
  value: any;
};

interface ChipV1Props {
  chipList: ChipDataType[];
  value: string | number;
  onClick: any;
}

const ChipV1: FunctionComponent<ChipV1Props> = (props) => {
  const Chip = ({ data, selected = false }) => {
    const handleClick = (value) => {
      if (selected) {
        props.onClick(null);
      } else {
        props.onClick(value);
      }
    };
    return (
      <div
        onClick={() => {
          handleClick(data?.value);
        }}
        className={` ${
          selected ? "text-light" : "text-dark bg-light shadow"
        } p-1 px-3 cursor-p f-ll chip-btn`}
        style={{
          minWidth: "40px",
          transition: "ease-in .3s",
          backgroundColor:"#FF8504",
          borderRadius:"4px"
          
        
        }}
      >
        {data?.label}
      </div>
    );
  };

  return (
    <div className="d-flex flex-row flex-wrap">
      {props?.chipList?.map((chipData, idx) => (
        <Chip
          data={chipData}
          selected={props.value === chipData?.value}
          key={idx}
        />
      ))}
    </div>
  );
};

export default ChipV1;
