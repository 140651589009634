import sc from "styled-components";

export const Card = sc.div`
&:hover{
  // background-color: #f1f1f1;
  transform: scale(1.01);
}

margin: 16px;
  min-width: 500px;
  width:800px;

  display:flex;
  flex-direction: row;
  overflow:hidden;

  .image-section{
    display: flex;
    // flex:1;
    width: 400px;
  }

  .image-section{
    display: flex;
    flex-direction: column;
    width: 350px;
    min-width:300px;
  }

  .image-details{
    // background-color: red;
    flex:1;
    padding: 8px;
    
  }

  .badges{
    position: relative;
  }

  .image-container{
    padding: 8px;
    width: 100%;
  }

  .image-container > img{
    width: 100%;
  }

  .new{
    background-color: var(--primary-color);
    color: #fff;
    padding: 2px 8px;
    position: absolute;
    z-index: 3;
    top:0;
    left:0;
    font-weight: bold;
  }

  .verified{
    position: absolute;
    z-index: 2;
    top:12px;
    right:12px;
  }

.more-images-container{
  display:flex;
  flex-direction: row;
  align-items:center;
}

  .more-images{
    display:flex;
    flex-direction: row;
  }

  .more-image{
    width: 50px;
    height: 50px;
    background-color: #aaa;
    margin-left: 16px;
  }

  .view-more{
    font-size: 12px;
    margin-left: 16px;
    
  }

  .content-container{
    display:flex;
    flex-direction: column;
    position:relative;
    min-width: 400px;
    width:700px;
  }
  
 
  .details{
    color:#000;
    width: 90%;
    padding: 16px;
  }

  .price{
    color: var(--secondary-color);
    font-weight: bold;
    font-size: 32px;
    opacity: 0.8;
    letter-spacing: 1px;
  }

  .area{
    font-weight: 500;
    
  }
  
    .area-and-price{
      border-bottom: 1px solid #f1f1f1;
      display:flex;
      flex-direction: row;
      width:100%;
      justify-content: space-between;
      align-items:center;
      flex-wrap: wrap;
      padding: 8px 0px;
    }


  .btn-container{
    position: absolute;
    bottom: 8px;
    right:8px;
  }

  .type-and-location{
    border-bottom: 1px solid #f1f1f1;
    padding: 8px 0;
  }

  .type{
    font-size: 16px;
    font-weight: bold;
  }

  .location{
    font-size: 12px;
    color:#a1a1a1;
  }

  .configs{
    display:flex;
    flex-direction: row;
    color: var(--primary-color);
    padding: 8px 0;
  }
  .configs > div{
    // margin: 8px;
    font-size: 16px;
  }


`;
