import React, { useEffect, useState, useContext } from 'react'
import SideBar from "../Components/SideIconBar"
import PropList from "../PropList"
import LimitAlrtCard from "../Components/LimitAlrtCard"
// import getAgentLimit  from "../../../api/Agent/getAgentLimit"
import {AuthContext} from "../../../context/AuthContext"

export default function Index() {
  // const {userInfo } = useContext(AuthContext);
  const {agentData} = useContext(AuthContext);
  const{fetchData} = useContext(AuthContext);
//   const user_key = userInfo?.user_key;
//   const[agentLimit, setAgentLimit]=useState([])
//   const fetchData = async () => {
// try{
//   const res = await getAgentLimit(user_key);
//   console.log("res", res[1][0])
//   setAgentLimit(res[1][0]);
//   console.log(agentLimit, "myout")
// } catch (error) {
//   console.error(error);
// }
// };
// useEffect(() => {
// fetchData();
// console.log(agentLimit, "myout1")
// },[user_key]);

  return (
    <>
    <div className="dashboard-container">
        <div className="sidebar">
          <SideBar />
        </div>
        <div className="nearby-container">
        {agentData && (
          <>
            <PropList agentData={agentData} fetchData= {fetchData}/> 
            <LimitAlrtCard agentData={agentData}/>
          </>
        )}
        </div>

      </div>
    </>
  )
}
