import React, { useEffect } from "react";
import { AuthProvider } from "./context/AuthContext";
import { StateProvider } from "./context/StateContext";
import { ApiProvider } from "./context/ApiContext";
import Router from "./router";
import mapboxgl from "mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
mapboxgl.accessToken =
  "pk.eyJ1IjoiYXJrYXJ0aGljay14Ymk0IiwiYSI6ImNrdGIyMTFlaDBnamoyb215OGVoa3UyMDQifQ.BiEGZLhj7Z-lP3vuezmEcQ";
function App() {  
  return (
    <AuthProvider>
      <StateProvider>
        <ApiProvider>
          <Router />
        </ApiProvider>
      </StateProvider>
    </AuthProvider>
  );
}

export default App;
