import React, { useEffect, useState } from "react";
import getPaymentHistory from "../../../../api/Payment/getPaymentHistory";
import useAuth from "../../../../hooks/useAuth";
import PaymentHistoryCard from "./paymenHistoryCard";
import Pagination from "../../../../components/Pagination";
import LayoutSidebar from "../../../../components/LayoutSidebar";

const Index = () => {
  const [payments, setPayments] = useState([]);
  const auth = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(true);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleGetPaymentHistory = async () => {
    const res = await getPaymentHistory(auth?.userInfo?.user_key);
    console.log(res[1]);
    setPayments(res[1]);
    setIsLoading(false);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = payments.slice(startIndex, startIndex + itemsPerPage);

  useEffect(() => {
    handleGetPaymentHistory();
  }, []);

  return (
    <div>
      <div>
        <LayoutSidebar />
      </div>
      <h2>Payment History</h2>
      {isLoading ? (
        <div>Loading...</div>
      ) : payments.length > 0 ? (
        <div className="">
          <PaymentHistoryCard payment={currentData} />
          <Pagination
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            totalItems={payments.length}
            onPageChange={handlePageChange}
          />
        </div>
      ) : (
        <div>No data found</div>
      )}
    </div>
  );
};

export default Index;

