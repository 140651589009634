import React, { useEffect, useState } from "react";
import { SubscriptionCategoryCardSc } from "./index.sc";
import { useHistory } from "react-router-dom";

const Index = (props: any) => {
  const [isSelected, setIsSelected] = useState<any>(false);
  const handleClick = () => {
    props?.handleGetPlans(props?.id);
    setIsSelected(true);
    props?.setSelectedId(props?.id);
  };
  useEffect(() => {
    setIsSelected(props.selectedId === props.id);
  }, [props.selectedId, props.id]);

  return (
    <SubscriptionCategoryCardSc>
      <div
        className={`card-container ${isSelected ? "selected_bg_color" : ""}`}
        onClick={handleClick}
      >
        <p className="category_title">{props?.title}</p>
      </div>
    </SubscriptionCategoryCardSc>
  );
};

export default Index;
