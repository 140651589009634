import React, { useEffect, useState } from "react";
import { Form, Card, Button } from "react-bootstrap";
import { ChipV1 } from "../../../../../components";
import StateCity from "../../StateCity";
import { currentDay } from "../../../../../utils";
import { useHistory } from "react-router-dom"
import backArrow from "../../../../../assets/arrow_left_circle.png"

// creating functional component ans getting props from app.js and destucturing them
const StepOne = ({ nextStep, handleFormData, values}) => {
  //creating error state for validation
  const [error, setError] = useState("");
  const history = useHistory()
  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    e.preventDefault();

    if (values.userType.length === 0 || values.propCity.length === 0) {
      return(setError("* All fields required"));
    } 
    else {
      setError("")
      nextStep();    
    }
    // nextStep(); 
  };
console.log(error)
  const chipList = {
    userType: [
      {
        label: "Tenant",
        value: "TENANT",
      },
      {
        label: "Owner",
        value: "OWNER",
      },
    ],
  };
  const [userType, setUserType] = useState();
  return (
    <>
      <div className="agree-form-header"><div className="mx-4" onClick={() => history.goBack()} style = {{cursor:"pointer"}}><img src={backArrow}/></div>
        Create your Rental Agreement</div>
      <form action="" className="agree-form">
        <div className="agree-form-field">
          <div className="user-type">Are you <span className="required"> *</span> </div>
          <ChipV1
            chipList={chipList?.userType}
            value={values?.userType}
            onClick={(e) => {
              handleFormData("userType")({ target: { value: e } });
            }}
          />          
        </div>
        
        <div className="agree-form-field">
          <label htmlFor="">Property Located <span className="required"> *</span></label>
          <StateCity
            onCityChange={handleFormData("propCity")}
            onStateChange={handleFormData("propState")}
            values={values}
            error={error}
          />
        </div>
        <div className="agree-form-field">
          <label htmlFor="">Agreement Generated On:</label>
          <div className="current-day">{currentDay()}</div>
        </div>
      </form>
      <div className="agree-btn-container">
      {/* {error?(<div className="error">* {error}</div>):("")} */}
      {error && <p className="error"><strong>{error}</strong></p>}
        <button className="agree-btn" type="submit" onClick={submitFormData}>
          Next
        </button>        
      </div>
    </>
  );
};

export default StepOne;
