import { GET, POST, PATCH } from "../functions";
import getPropTypes from "./getPropertyTypes";

const getProperties = async (query, property_id = "") => {
  const path = `/properties/${property_id}?${query}`;
  return await GET(path)
    .then((result: any) => {
      let tempResult = result[1];
      console.log("data from API", tempResult)
      if (property_id) {
        let { additional_data, ...data } = result[1];
        console.log(additional_data);
        console.log(JSON.parse(additional_data));
        tempResult = { ...JSON.parse(additional_data), ...data };
      }

      return [result[0], tempResult];
    })
    .catch((err) => {
      console.log("error on getProperties from db > ", err);
      return err;
    });
};

const getPropertiesByid = async (property_id = "") => {
  const path = `/properties/${property_id}`;
  return await GET(path)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log("error on getProperties from db > ", err);
      return err;
    });
};
const updateProperty = async (data:object, property_id) => {
  
  const path = `/properties/${property_id}`;
  return await PATCH({apiUri:path, data})
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log("error on getProperties from db > ", err);
      return err;
    });
};

const addProperty = async (data) => {
  const path = "/properties";
  return await POST(path, data)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log("error on getProperties from db > ", err);
      return err;
    });
};
export { getProperties, addProperty, getPropTypes, getPropertiesByid, updateProperty };
