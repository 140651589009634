import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { uploadImage } from "../../../api/uploadImage";
import updateAgentProfile from "../../../api/Agent/updateAgentProfile"
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingV1 } from "../../../components/Loading";

type EditProfModelType = {
  show: boolean;
  close: () => void;
  modelHandler: () => void;
  data: {
    Name: string;
    aadhar_no: string;
    address: string;
    agent_id: string;
    city: string;
    country: string;
    email: string;
    lat: number;
    lon: number;
    no_approved_property: number;
    no_assigned_property: number;
    phone: string;
    photo_src: string;
    pincode: string;
    property_limit: number;
    response: number;
    state: string;
  };
};
type FormData = {
  name: string;
  email: string;
  phone: string;
  address: string;
  photo_src: string;
  country: string;
  state: string;
  city: string;
  pincode: string;
  aadhaar: string;
};

export default function EditProfile(props: EditProfModelType) {
  const { show, close, modelHandler, data } = props;
  const schema = yup.object().shape({
    name: yup
      .string()
      .required("Field cannot be empty")
      .min(2, "Min. 2 char Required"),
    phone: yup
      .number()
      .min(10, "Min Invalid number")
      .required("Invalid number"),
    address: yup.string().required("Address Field cannot be empty"),
    email: yup.string().email().required("Invalid Email"),
    country: yup
      .string()
      .required("Country Field cannot be empty")
      .not(["-"], "select country"),
    city: yup.string().not(["-"], "select city"),
    state: yup.string().not(["-"], "select state"),
    pincode: yup.string().required("zip code Field cannot be empty")
  });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [currentCountry, setCurrentCountry] = useState("");
  const [imageList, setImageList] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const addImage = (image, filename) => {
    setImageList([...imageList, { file: image, name: filename }]);
  };
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: data?.Name,
      address: data?.address,
      city: data?.city,
      country: data?.country,
      email: data?.email,
      phone: data?.phone,
      state: data?.state,
      pincode: data?.pincode,
      photo_src: data?.photo_src,
    },
    mode: "onBlur",
  });
  const REQUEST_PARAMS = {
    method: "GET",
    headers: {
      "X-CSCAPI-KEY":
        "QW94aFRROVVKNHJBdXZmSFZKeEtabHc3dlhzenBCQVJYWENHRkJxNA==",
    },
  };
  const getCountries = () => {
    return fetch(
      "https://api.countrystatecity.in/v1/countries/",
      REQUEST_PARAMS
    )
      .then((_) => _.json())
      .then((response) => {
        // console.log(response);
        return response;
      });
  };
  const getStatesByCountry = (countryIso2) => {
    return fetch(
      `https://api.countrystatecity.in/v1/countries/${countryIso2}/states`,
      REQUEST_PARAMS
    )
      .then((_) => _.json())
      .then((response) => {
        // console.log(response);
        return response;
      });
  };
  const getCitiesByStateAndCountry = (countryIso2, stateIso2) => {
    return fetch(
      `https://api.countrystatecity.in/v1/countries/${countryIso2}/states/${stateIso2}/cities`,
      REQUEST_PARAMS
    )
      .then((_) => _.json())
      .then((response) => {
        // console.log(response);
        return response;
      });
  };
  const handleCountryChange = (e) => {
    const iso2 = e.target.value.split("-")[1];
    // console.log(e.target.value)
    setCurrentCountry(e.target.value);
    // console.log(iso2);
    getStatesByCountry(iso2).then((_) => {
      setStates(_);
    });
  };
  const handleStateChange = (e) => {
    const iso2 = e.target.value.split("-")[1];
    const countryIso = currentCountry.split("-")[1];
    // console.log(iso2);
    getCitiesByStateAndCountry(countryIso, iso2).then((_) => setCities(_));
  };

  const updateAgentData = (data) => {
    console.log("api data", data);
  }


  const onSubmit = async (formData) => {
    setIsLoading(true)
    if(imageList.length>0){
        let links = [];
      console.log(imageList)
      try {
       const imageResponse=await uploadImage(imageList[0]?.name, imageList[0]?.file, "path/")
        links = [...links, { img_src: imageResponse}];
        console.log(imageResponse)
      } catch (error) {
        console.log(error)
      }
    console.log("Image upload completed")
    // setUploadformData(formData)
    await updateAgentProfile(data.agent_id,{...formData,photo_src:links[0].img_src} );
    }else{
        await updateAgentProfile(data.agent_id, formData)
    }
    console.log("edit profile", formData);
    setIsLoading(false);
    alert("Updated successfully")
    modelHandler();
  };

  useEffect(() => {
    getCountries().then((_) => setCountries(_));
  }, []);
  return (
    <Modal
      show={show}
      onHide={close}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader closeButton>Edit your Agent profile</ModalHeader>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label htmlFor="name" className="form-label">
              Full Name
            </label>
            <input
              id="name"
              type="text"
              name="name"
              defaultValue={data.Name}
              className={`form-control`}
              placeholder="Enter Your Full name"
              {...register("name")}
            />
            <div>
              <span className="text-danger">{errors.name?.message}</span>
            </div>
          </div>
          <div>
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              disabled
              id="email"
              type="email"
              name="email"
              className={`form-control`}
              placeholder="Enter Your Email"
              {...register("email")}
            />
            <div>
              <span className="text-danger">{errors.email?.message}</span>
            </div>
          </div>
          <div>
            <label htmlFor="phone" className="form-label">
              Phone
            </label>
            <input
              disabled
              id="phone"
              type="tel"
              name="phone"
              className={`form-control`}
              placeholder="Enter Mobile number"
              {...register("phone")}
            />
            <div>
              <span className="text-danger">{errors.phone?.message}</span>
            </div>
          </div>
          <div>
            <label htmlFor="address" className="form-label">
              Address
            </label>
            <input
              id="address"
              type="tel"
              name="address"
              className={`form-control`}
              placeholder="Enter full address"
              {...register("address")}
            />
            <div>
              <span className="text-danger">{errors.address?.message}</span>
            </div>
          </div>
          <div className="row col-12 mt-3  ">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label htmlFor="country" className="form-label">
                Country
              </label>
              <select
                id="country"
                name="country"
                className={`form-select `}
                aria-label="Default select example"
                {...register("country")}
                onChange={(_) => {
                  handleCountryChange(_);
                }}
              >
                <option value={data.country}>{data.country}</option>
                {countries
                  ? countries.map((country) => (
                      <option
                        key={country.id}
                        value={`${country.name}-${country.iso2}`}
                      >
                        {country.name}
                      </option>
                    ))
                  : ""}
              </select>
              <div>
                <span className="text-danger">{errors.country?.message}</span>
              </div>
            </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label htmlFor="state" className="form-label">
                  State
                </label>
                <select
                  id="state"
                  name="state"
                  className={`form-select`}
                  aria-label="Default select example"
                  {...register("state")}
                  onChange={(_) => {
                    handleStateChange(_);
                  }}
                >
                  <option value={data.state}>{data.state}</option>
                  {states
                    ? states.map((state) => (
                        <option
                          key={state.id}
                          value={`${state.name}-${state.iso2}`}
                        >
                          {state.name}
                        </option>
                      ))
                    : ""}
                </select>
                <div>
                  <span className="text-danger">{errors.state?.message}</span>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label htmlFor="city" className="form-label">
                  City
                </label>
                <select
                  id="city"
                  name="city"
                  className={`form-select`}
                  aria-label="Default select example"
                  {...register("city")}
                  onChange={(_) => {
                    setValue("city", _.target.value);
                  }}
                >
                  <option value={data.city}>{data?.city}</option>
                  {cities.length > 0
                    ? cities.map((city) => (
                        <option key={city.id} value={city.name}>
                          {city.name}
                        </option>
                      ))
                    : ""}
                </select>
                <div>
                  <span className="text-danger">{errors.city?.message}</span>
                </div>
              </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <label htmlFor="pincode" className="form-label">
                Pincode
              </label>
              <input
                autoComplete={"nope"}
                id="pincode"
                name="pincode"
                placeholder="Enter Zip code / Postal Code"
                type="text"
                className={`form-control`}
                {...register("pincode")}
              />
              <div>
                <span className="text-danger">{errors.pincode?.message}</span>
              </div>
            </div>
          </div>
          <div>
            <label htmlFor="ImageUpload" className="form-label">
              Upload your image
            </label>
            <input
              id="imageUpload"
              name="imageUpload"
              type="file"
              accept="image/*"
              className={`form-control`}
              onChange={({ target }) => {
                addImage(target.files[0], target.files[0].name.split(".")[0]);
              }}
              // {...register("zip_code")}
            />
          </div>
          <div>{errors?.photo_src?.message}</div>
          {
            isLoading ? <LoadingV1/> : 
          <Button type="submit">Save</Button>
          }
        </form>
      </Modal.Body>
    </Modal>
  );
}
