import React, { useEffect, useState } from "react";

export const StateContext = React.createContext(null);
export function StateProvider({ children }) {
  const [globalParams, setGlobalParams] = useState<any>({ country: "in" });
  const handleGlobalParams = (value) => {
    setGlobalParams({ ...globalParams, ...value });
  };
  const getLocation = async () => {
    await navigator.geolocation?.getCurrentPosition(async (position) => {
      await setGlobalParams({
        ...globalParams,
        coord: [position?.coords?.longitude, position?.coords?.latitude]
      });

      // console.log();
      // return [position?.coords?.latitude, position?.coords?.longitude];
    });
  };
  useEffect(() => {
    getLocation();
  }, []);
  return (
    <StateContext.Provider
      value={{ globalParams, setGlobalParams, handleGlobalParams }}
    >
      {children}
    </StateContext.Provider>
  );
}


