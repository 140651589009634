import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { GET } from "../../../api/functions";
import "./style.css";
import {useHistory} from "react-router-dom"

interface PropModalProps {
  props?: any;
  show?: boolean;
  handleClose?: () => void;
  handlePropData?: (key) => void;
  handlePropDataOrderReq?: () => void;
  selectedId?: Number;
}

export default function Index({
  props,
  show,
  handleClose,
  handlePropData,
  handlePropDataOrderReq,
  selectedId
}: PropModalProps) {
const history = useHistory();
const [fetchedUserProp, setFetchedUserProp] = useState<any>();
const [checkedProperty, setCheckedProperty] = useState();
const [isProperty, setIsProperty] = useState<boolean>(false);
  const fetchProperties = async () => {
    let looking_for 
    if(selectedId===3){
      looking_for = "SALE"
    }
    else{
      looking_for = "RENT"
    }
    const path = `/properties?user_key=${props}&looking_for=${looking_for}`;
    const listofproperties = await GET(path);
    setFetchedUserProp(listofproperties[1]);
    
  };
useEffect(() => {
  if(props){
    fetchProperties();
  }
}, [selectedId]);
useEffect(() => {
  handlePropData(checkedProperty);
}, [checkedProperty]);

// console.log("fetched used properties", fetchedUserProp)
// console.log("selected ID from props", selectedId)

  return (
    <>
      
        
          { props ? (
            <Modal size="lg" show={show} onHide={handleClose} >
            <Modal.Header closeButton>
                <Modal.Title>Select Your Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table>
      <thead>
        <th>
            Title of Prop
        </th>
        <th>
            Property Type
        </th>
        <th>
            Select
        </th>
      </thead>
      <tbody>
        
        {fetchedUserProp && fetchedUserProp.map((data)=>{
            return (
            <tr className="h-50">
                <td >{data?.title}</td>
                <td>{data?.property_type_name} </td>
                <td><input
                  type="radio"
                  name="selectedProperty"
                  value={data?.property_key}
                  checked={checkedProperty === data?.property_key}
                  onChange={() => {
                  setCheckedProperty(data?.property_key);
                  setIsProperty(true);}
                }
                /></td>
                </tr>)
            })

            }
        
      </tbody>
    </table>
        </Modal.Body>
        <Modal.Footer>
    <button className="btn btn-primary" onClick={handleClose}>Close</button>
    <button className="btn btn-success" onClick={handlePropDataOrderReq} disabled={!isProperty}>Next</button>
  </Modal.Footer></Modal>
          ):(
            <Modal show={show} onHide={handleClose} >
            <Modal.Body>
              <div>
                Not Signed in!
              </div>
            </Modal.Body>
            <Modal.Footer><button className="btn btn-primary" onClick={()=> history.push("/logIn")}>Log in</button></Modal.Footer>
            </Modal>

          )

          }
          
      
    </>
  );
}
