import { MAPBOX_TOKEN } from "../api/const"
export const reverseGeoode = async (lat, long)=>{
    var requestOptions = {
        method: 'GET'
      };
    const path = `https://api.mapbox.com/search/geocode/v6/reverse?longitude=${long}&latitude=${lat}&access_token=${MAPBOX_TOKEN}`

    const placeData = await fetch (path,requestOptions );
    const placeJson =await placeData.json();
    // console.log(placeJson.features[0].properties.name)
    return placeJson.features[1].properties.place_formatted
}