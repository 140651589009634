import { parse } from "querystring";
import { API_URL } from "./const";

//  ------------------------------POST

export const POST = async (path?: string, data?: any, token?: string) => {
  try {
    const res = await fetch(`${API_URL}${path}`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(data),
    });
    const json = await res.json();
    // console.log(json);
    return [res?.status, json];
  } catch (error) {
    console.log(`error in post api ${path} >`, error);
    return [500, error];
  }
};

export const POST2 = async (path?: string, data?: any, token?: string) => {
  try {
    const res = await fetch(`${API_URL}${path}`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(data),
    });
    console.debug(res);
    let status = res.status;
    let text;
    try {
      text = await res.text();
      // json = parse(text);
      console.log(text);
    } catch (error) {
      console.log(`error parsing response: ${error}`);
      text = null;
      status = 500;
    }
    console.log(text);
    return [status, text];
  } catch (error) {
    console.log(`error in post api ${path} >`, error);
    return [500, error];
  }
};

//  ------------------------------POST
interface patchProps {
  apiUri: string;
  data: Object;
  token?: string;
}
export const PATCH = async ({ apiUri, data, token }: patchProps) => {
  const res = await fetch(`${API_URL}${apiUri}`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "text/html",
    },
  })
    .then(async (res) => {
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`error in patch ${apiUri} >`, error);
      return [500, error];
    });
  return res;
};

export const DELETE = async (apiUri?: string, token?: string) => {
  const res = await fetch(`${API_URL}${apiUri}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "text/html",
    },
  })
    .then(async (res) => {
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`error in delete ${apiUri} >`, error);
      return [500, error];
    });
  return res;
};

// export const PUT = async (apiUri?:string, data?:any, token?:string) => {
//   const res = await fetch(`${API_URL}${apiUri}`, {
//     method: "PUT",
//     mode: "no-cors",
//     body: JSON.stringify(data),
//     headers: {
//       "Content-Type": "application/json",
//     },
//   })
//     .then((res) => {
//       console.log(res);
//       // const json = await res.json()
//       // return [res?.status, json];
//     })
//     .catch((error) => {
//       console.log(`error in put ${apiUri} >`, error);
//     });
//   return res;
// };

export const GET = async (
  apiUri?: string,
  signal?: AbortSignal,
  token?: string
) => {
  const endpoint = `${API_URL}${apiUri}`;
  const res = await fetch(endpoint, {
    method: "GET",
    mode: "cors",
    signal: signal,
  })
    .then(async (res) => {
      const json = await res.json();
      return [res?.status, json];
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log(`error in api ${apiUri} >`, error);
      return [500, error];
    });
  return res;
};
