import React, { useEffect, useState } from "react";
import { PersonFill } from "react-bootstrap-icons";
import {
  useHistory,
  useLocation,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import { useAuth } from "../../../hooks";
// import { Layout } from "../../../layouts";
import verfied from "../../../assets/verified.png";
import {
  CustomerCare,
  MyAccDashboard,
  MyAccLeads,
  MyActivities,
  MyListing,
  PaymentHistory,
} from "../components";
import Layout from "../../../layouts/v1";
import "./style.css";
import { userInfo } from "os";
// interface Props {
//   children: JSX.Element[] | JSX.Element;
// }
interface NavCardMenuProps {
  to: string;
  name: string;
}

const navMenus: NavCardMenuProps[] = [
  { name: "Dashboard", to: "/account/dashboard" },
  // { name: "Leads", to: "/account/leads" },
  { name: "My Listing", to: "/account/my-listing" },
  { name: "My Activity", to: "/account/my-activity" },
  { name: "Payment History", to: "/account/payment-history" },
  // { name: "Customer Care", to: "/account/customer-care" },
];

const routes = [
  {
    path: "/account/dashboard",
    component: MyAccDashboard,
  },
  // {
  //   path: "/account/leads",
  //   component: MyAccLeads,
  // },
  {
    path: "/account/my-listing",
    component: MyListing,
  },
  {
    path: "/account/my-activity",
    component: MyActivities,
  },
  {
    path: "/account/payment-history",
    component: PaymentHistory,
  },
  // {
  //   path: "/account/customer-care",
  //   component: CustomerCare,
  // },
];

const Index = () => {
  const history = useHistory();
  const { url, path } = useRouteMatch();
  const auth = useAuth();
  const NavCardMenu = ({ name, to }: NavCardMenuProps) => {
    // const isActive = window.location.pathname === to;
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
      setIsActive(location.pathname.includes(to));
    }, [location, to]);

    return (
      <div
        className={`nav-menu ${isActive ? "active" : ""}  py-2 px-2`}
        onClick={() => history.push(to)}
      >
        {name}
      </div>
    );
  };
  return (
    <div>
      <div className="">
        <Layout />
      </div>
      <div className="">
        <div className="d-flex flex-row">
          <div className="my-acc-card-container">
            <div className="my-acc-card p-2">
              <div className="profile-sec">
                <div className="pro_pic_con">
                  <div className="profile-image">
                    <PersonFill color="white" size={128} />
                  </div>
                </div>

                <div className="d-flex flex-row">
                  <div className="profile-name">
                    {auth?.userAttr?.["custom:first_name"]}
                  </div>
                  {auth?.userInfo?.is_aadhar_verified == 1 ? (
                    <div>
                      <img src={verfied} className="verified_icon" />
                    </div>
                  ) : null}
                </div>
              </div>
              <div
                className="edit_profile"
                onClick={() => history.push("/edit-my-profile")}
              >
                Edit Profile
              </div>
              <div className="nav-menus">
                {navMenus?.map((menu, idx) => (
                  <NavCardMenu {...menu} key={idx} />
                ))}
              </div>
            </div>
          </div>

          <div className="mx-md-5 mt-4 w-100 ">
            <Switch>
              {routes?.map((route, idx) => (
                <Route path={route.path} key={idx}>
                  <route.component />
                </Route>
              ))}
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
