import React,{useEffect, useState} from "react"; 
import { v4 as uuidv4 } from "uuid";

const CCavenue = () => {

    const data1 = 
      {
        "property_key": "067e7178-b72c-4e9c-9f1a-167c6958f7c5",
        "title": "VS house welcoming you all",
        "description": "nice house",
        "property_type_id": 1,
        "user_key": "ca1468d5-7fa3-11ed-89fa-02c30db44cf4",
        "listed_for": "SALE",
        "listed_on": "2023-03-20T06:58:08.000Z",
        "currency_id": 1,
        "address_line_1": "211 B,Big street,Muthukrishna Puram",
        "address_line_2": "Kadayanallur",
        "city_id": null,
        "lat": 9.084205,
        "lon": 77.346196,
        "bed": 2,
        "bath": 2,
        "parking": "BIKE",
        "has_basement": 1,
        "has_pool": 0,
        "is_pet_allowed": 0,
        "is_active": 1,
        "approved_by": null,
        "is_approved": 0,
        "approved_on": null,
        "open_tour": 0,
        "3d_tour": 0,
        "status": "LISTED",
        "modified_on": "2023-03-20T06:58:08.000Z",
        "built_area": 11111,
        "price": 11111,
        "deposit": 0,
        "facing": "SOUTH",
        "furnish": "FULL",
        "pref_tenant": "null",
        "available_from": "2023-03-21T00:00:00.000Z",
        "img_src": "https://sam-real-estate-norret5-bucket-dev-account.s3.amazonaws.com/path/85b51567-3390-41f0-b746-d4ad48c93dc6.jpg",
        "additional_data": "{\"landmark\":\"Sri Ulaga Hr Sec School\",\"super_builtup\":\"2222\",\"lift\":false,\"wifi\":false,\"ac\":false,\"power\":false,\"play\":false,\"fire_safety\":false,\"park\":false,\"servant_room\":false,\"gas\":true,\"sewage\":true,\"house_keeping\":false,\"rain_water\":true,\"pool\":false,\"shopping\":false,\"garden\":false,\"kitchen\":2,\"floor_type\":\"TILES\",\"survillance\":0,\"token_amt\":\"100000\",\"prices_in\":1,\"paid_tax\":1,\"occupancy\":1,\"location_data\":{\"id\":\"locality.1882778219\",\"map_bounds\":{\"swlat\":9.056192375,\"swlon\":77.264815345,\"nelat\":9.185426756,\"nelon\":77.373917613},\"center\":\"9.084205,77.346196\",\"text\":\"Kadayanallur\",\"placeName\":\"Kadayanallur, Tenkasi, Tenkasi, Tamil Nadu, India\",\"lat\":9.084205,\"lon\":77.346196},\"images\":[{\"img_src\":\"https://sam-real-estate-norret5-bucket-dev-account.s3.amazonaws.com/path/85b51567-3390-41f0-b746-d4ad48c93dc6.jpg\"},{\"img_src\":\"https://sam-real-estate-norret5-bucket-dev-account.s3.amazonaws.com/path/07131ce8-7694-46d5-abef-6ca6d440b57d.jpg\"}]}",
        "is_verified": null,
        "is_rejected": null,
        "assigned_to": null,
        "is_subscribed": 1,
        "code": "INR",
        "symbol": "₹",
        "name": "House",
        "property_id": 114,
        "others_property_id": null,
        "listed_by_details": "{\"name\": \"vinoth siva m\", \"phone\": \"9080544323\"}",
        "views": 64,
        "currency_code": "INR",
        "currency_symbol": "₹",
        "property_type_name": "House",
        "property_relation_id": 114
      }
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [propertyData, setPropertyData] = useState<any>(null);
  const [imageList, setImageList] = useState([]);
  const [dbImageList, setDbImageList] = useState([]);

  const addImage = (images) => {
    const newImages = [];
    for (let i = 0; i < images.length; i++) {
      const uuid = uuidv4();
      newImages.push({ file: images[i], name: uuid });
    }
    setImageList([...imageList, ...newImages]);
  };

  const removeImage = (image_name) => {
    setImageList(imageList?.filter((img) => img?.name !== image_name));
  };
  const dbRemoveImage = (image_name) => {
    setDbImageList(dbImageList?.filter((img) => img?.img_src !== image_name));
  };
  const validate = () => {
    return ( 
      (dbImageList?.length > 0) || (imageList?.length > 0)
    );
  };
useEffect(() => {
  let temp = data1
    let { additional_data, ...data } = data1;
    temp = { ...JSON.parse(additional_data), ...data };
    setPropertyData(temp)
    console.log()
    
}, [])
useEffect(() => {
  console.log("DB images data",dbImageList)
}, [dbImageList])

useEffect(() => {
  setIsBtnDisabled(!validate());
}, [imageList, dbImageList]);

useEffect(() => {
 console.log("Property data",propertyData)
 setDbImageList(propertyData?.images)
}, [propertyData])

  return (
    <div className="d-flex justify-content-center align-items-center" style={{
      backgroundColor: "aqua"
    }}>
      <div className=" w-50">

      <h2 >
      Images validation
      </h2>
      {dbImageList?.length > 0 ? (
              <div>
                <img
                  className="shadow bg-white rounded"
                  src={dbImageList[0]?.img_src}
                  alt="property"
                  width="100%"
                />
              </div>
            ) : imageList?.length > 0 && (
              <div>
                <img
                  className="shadow bg-white rounded"
                  src={URL.createObjectURL(imageList[0]?.file)}
                  alt="property"
                  width="100%"
                />
                </div> ) || 
               ( <div>
                <img
                  className="shadow bg-white rounded"
                  src=""
                  width={200}
                  height={150}
                ></img>
              </div>
            )}
      <div className="mt-4 mb-2 w-100">
                <div className="f-b fs-3 text-align listing-heading">
                  Choose Images
                </div>
                <span className="text-muted">Click image to remove</span>
                <div className="my-2 d-flex flex-row flex-wrap w-100 listing-container">
                  {dbImageList?.map((img, idx) => (
                    <div
                      onClick={() => dbRemoveImage(img?.img_src)}
                      className="image-container m-2"
                      key={idx}
                    >
                      <img
                        src={img?.img_src}
                        alt="property"
                        width="100%"
                      />
                    </div>
                  ))}{
                    imageList?.map((img, idx) => (
                      <div
                        onClick={() => removeImage(img?.name)}
                        className="image-container m-2"
                        key={idx}
                      >
                        <img
                          src={URL.createObjectURL(img?.file)}
                          alt="property"
                          width="100%"
                        />
                      </div>
                    ))
                  }

                  <label
                    htmlFor="image-input"
                    className="f-b custom-file-input-label ms-3"
                  >
                    <div>
                      <div>Click to add image</div>
                    </div>
                  </label>
                  <input
                    name="image-input"
                    id="image-input"
                    type="file"
                    accept="image/*"
                    onChange={({ target }) => {
                      // handleImageChange(target.files);
                      // addImage(target.files[0]);
                      addImage(target.files);
                    }}
                    className="custom-file-input"
                    multiple
                  />
                </div>
              </div>
              <div className="w-100 d-flex justify-content-center my-4">
                <button
                  className="list-your-prop"
                  type="submit"
                  disabled={isBtnDisabled}
                >
                post Your Property
                </button>
              </div>

      </div>
    </div>
  );
};

export default CCavenue;
