import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Redirect, Route } from "react-router-dom";

const AgentRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isAuthenticating, userInfo } =
    useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticating ? (
          isAuthenticated && userInfo?.is_agent ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" />
          )
        ) : (
          <>loading</>
        )
      }
    />
  );
};
export default AgentRoute;
