import { POST } from "../functions";

const addPropToBucket = async (data, agent_key) => {
  const path = `/agents/${agent_key}`;
  return await POST(path, data)
    .then((result) => {
      console.log("addPropToBucket in db > ", result);
      return result;
    })
    .catch((err) => {
      return err;
    });
};

export default addPropToBucket;
