import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../../src/layouts/Footer";
import Apply from "./ApplyV2";
import "./style.css";
import Logo from "../../assets/brandLogoBlack.png";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

export default function Index() {
  const history = useHistory();
  const { agentData, userInfo } = useContext(AuthContext);
  console.log("User Info for agent", userInfo?.is_agent, agentData?.response);

  const isAgent = userInfo?.is_agent === 1;
  const hasResponse = agentData?.response === 1;

  const handleAgentDashboardClick = () => {
    history.push("/agent/dashboard");
  };

  const handlePaymentClick = () => {
    history.push("/subscriptionPlans?planName=agent");
  };

  window.scrollTo(0,0)
  return (
    <>
      <div
        className="navigation"
        style={{
          display: "flex",
        }}
      >
        <img
          className="brand-logo-lay"
          src={Logo}
          alt="truprops"
          style={{
            height: "60px",
            cursor: "pointer",
          }}
          onClick={() => history.push("/")}
        />
      </div>
      <div className="bg">
        {!hasResponse && !isAgent ? (
          <div className="form-main">
            <div className="apply-header">
              <h1 className="listing-heading">Application</h1>
              <p>Please fill in the details below.</p>
            </div>
            <div className="apply-form-details" id="">
              <Apply />
            </div>
          </div>
        ) : hasResponse ? (
          <div
            className="d-flex flex-column justify-content-center align-items-center apply-agent"
          >
            {isAgent ? (
              <h1
                className=""
                style={{
                  color: "#4caf50",
                }}
              >
                Already an Agent!
              </h1>
            ) : (
              <h1
                className="head-content"
                style={{
                  color: "#4caf50",
                }}
              >
             
           To proceed to the Agent Dashboard, please complete the payment process.
              </h1>
            )}
            <div>
              Go to{" "}
              <span
                className="listing-heading"
                style={{
                  cursor: "pointer",
                }}
                onClick={isAgent ? handleAgentDashboardClick : handlePaymentClick}
              >
                {isAgent ? "Agent Dashboard" : "Payment"}
              </span>
            </div>
          </div>
        ) : null}

        <div className="footer-apply">
          <Footer />
        </div>
      </div>
    </>
  );
}
