import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Facebook from "../../assets/SocialMedia/facebook";
import Twitter from "../../assets/SocialMedia/twitter";
import Whatsapp from "../../assets/SocialMedia/whatsapp";
import SMS from "../../assets/SocialMedia/sms";
import { useState } from "react";
import { API_DOMAIN } from "../../api/const";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  bgcolor: "background.paper",
  // border: '2px solid',
  borderRadius: "5px",
  boxShadow: "0px 0px 1px 2px #d1d0f7",
  // boxShadow: 24,
  p: 4,
};

interface Props {
  propData: any;
}
export default function Share({ propData }: Props) {
  const URL = `https://${API_DOMAIN}/properties/${propData?.property_key}`;
  const socials = [
    {
      outlet: "Facebook",
      href: `https://www.facebook.com/sharer/sharer.php?u=${URL}`,
      label: "Share on Facebook",
      icon: <Facebook />,
    },
    {
      outlet: "Twitter",
      href: `https://twitter.com/intent/tweet?url=${URL}/`,
      label: "Share on Twitter",
      icon: <Twitter />,
    },
    {
      outlet: "Whatsapp",
      href: `https://api.whatsapp.com/send?text=${URL}/`,
      label: "Share via Whatsapp",
      icon: <Whatsapp />,
    },
  ];

  return (
    <>
      {socials.map((data) => {
        return (
          <a href={data.href} target="_blank" className="socail-media-icons">
            {data.icon}
          </a>
        );
      })}
    </>
  );
}
